import { CONST } from "../legacy/settings";
import {
    assert,
    assert_dev,
    EpochDaystoEpochMS,
    EpochDaysToStartOfWeekDays,
    EpochMStoEpochDays,
    errorToJSON,
    FrameworkError,
    FrameworkHttpError,
    gfu,
    ProcessStatuses,
    stringToHex,
    TableExportHelper,
    topSort,
    UNIT_TYPES,
    unsafeParseJWT,
} from "./GlobalHelper";
import * as pako from "pako";
import { EventEmitter } from "eventemitter3";
import { weekNumber } from "weeknumber";
import { create_master, fetch_ops, getAttachmentUrl, getLog, liveFetch, set_master_sandbox } from "./ApiHelper";
//import * as rgbToHsl from "rgb-to-hsl";
import { SERVICES } from "./services";
import { UUID5 } from "./UUID5";
import type { CanvasMeta } from "../legacy/components/Canvas/Canvas";
import { Buffer } from "buffer";
import { RT, RTSessionsData } from "./RT";
import { OnParticleCoreUpdateEvent, CanvasDataViewReducer } from "./api/coreTypes";
import {
    CoreEventsCallbackData,
    CoreEvents,
    CoreEventNames,
    DurationChangedData,
    EventDateChangedDataValues,
} from "../app/types/CoreEvents";
import { LCMDContextDependencyType, LCMDContextWhiteboardState } from "../app/LCMDContextTypes";
import { ProcessGetter } from "./api/processGetter";
import { ParticleGetter } from "./api/particleGetter";
import { OldToNewProcessIdMap } from "@/model/WorkerCore";
import { defaultTradeImage } from "@/components/Sidebar/TradeImages";
import {convertMillisecondsToDays} from "@/utils/DateUtils";

const fetch = typeof self !== "undefined" ? self.fetch : require("node-fetch");

const _LEGACY_ACTIVITY_REGEXP = /^#A(?<aid>[a-f0-9]+)(#(?<day>[0-9]+))?#(?<name>[a-z][a-zA-Z0-9.]*)$/;

//const maxCommit=0 // 72061;

export type day = number;
export type col = number;
export type row = number;
export type epoch_ms = number;

/**
 * @internal
 */
export type CanvasCalendarHeader = any & {};

/**
 * @internal
 */
export type DataModelGpaData = {
    name: string;
    color: number;
};

/**
 * @internal
 */
export type CanvasGpaData = {
    name: string;
    color: number;
};

/**
 * @beta
 */
export type CanvasStripeData = {
    /** @internal */
    _: number;
    /** @beta */
    t: number;
    /** @beta */
    i: number;
    /** @beta */
    j: number;
    /** @beta */
    e: row;
    /** @internal */
    l: number;
    /** @internal */
    _p: number[];
    /** @internal */
    _s: number[];
    //_h: string[];
    //_c: number[];
    /** @internal */
    label: string; // needed for library
    /** @beta */
    _segs: (number | string)[][];
    /** @internal */
    override?: boolean;
    /** @internal */
    overrideProps?: any;
    /** @beta */
    x?: number;
    /** @beta */
    y?: number;
    /** @beta */
    w?: number;
    /** @beta */
    h?: number;
    /** @beta */
    _x1: EpochDate;
    /** @beta */
    _x2: EpochDate;
    /** @internal */
    _grid?: CalendarGridMapping[];
    /** @beta */
    _image?: string;
    /** @beta */
    _gpa?: CanvasGpaData;
};

/**
 * @internal
 */
type CanvasStripeDataActivityExt = {
    _y: number;
    _ah: number;
    _a_: number;
    _a: any[]; // CanvasCardData
    _ay: number;
};

/**
 * @internal
 */
export type CanvasStripePatchData = CanvasStripeData & {
    _h: string[];
    //_c: number[];
};

/**
 * @internal
 */
type CanvasGatherStripeData = {
    _: number;
    t: IntId;
    i: number;
    j: number;
    y: number;
    l: number;
    _p: number[];
    _s: number[];
    //_h: string[],
    //_c: number[],
    //REMOVE_ME override?:boolean,
    //REMOVE_ME overrideProps?:any,
    _x1: number;
    _x2: number;
    grid?: CalendarGridMapping[];
    image?: string;
};

export type Dependency = { type: LCMDContextDependencyType; depTs: number };
export type DependencyList = {
    [id: number]: Dependency;
};
/**
 * @public
 */
export type CanvasTaskData = {
    _: number;
    id: number;
    name: string; // needed anymore?
    trade?: string; // trade name
    //fs:number;
    left: number;
    right: number;
    top: number;
    stripe: number;
    color: number;
    dep: number; // see task._dc
    deps: { rp: DependencyList; rs: DependencyList };
    segs: (number | string)[];
    status: number;
    v?: string; // detaisl mode
    p?: number; // percent
    c?: number; // color
    l?: string; // label
    m?: any; // meta
    tradeIcon?: string;
    atm?: boolean;
    isVirtual?: boolean;
    hasComments?: boolean;
};

/**
 * @internal
 */
export type CanvasTaktZoneData = {
    _: number;
    _s: number | number[];
    s: CanvasStripeData | CanvasStripeData[];
    name: string;
};

/**
 * @internal
 */
export type CanvasCalendarMetaException = {
    d: number;
    m: number;
    y: number | null;
};

/**
 * @internal
 */
export type CanvasCalendarMeta = {
    wd: [boolean, boolean, boolean, boolean, boolean, boolean, boolean];
    slot: CanvasCalendarMetaException[][];
};

/**
 * @public
 */
export type CanvasCalendarIntl = {
    shortDays2: {
        mon: string;
        tue: string;
        wed: string;
        thu: string;
        fri: string;
        sat: string;
        sun: string;
    };
};

/**
 * @internal
 */
export enum CanvasRTSessionState {
    ACTIVE = 0,
    INACTIVE = 1,
    ZOMBIE = 2,
}

/**
 * @internal
 */
export type CanvasRTSession = {
    session: string;
    sub: string;
    state: CanvasRTSessionState;
};

/**
 * @public
 */
export type CanvasCardData = ParticleCardData & {};

/**
 * @public
 */
export type ParticleCardData = {
    _: number;
    p: number;
    id: any; // number;
    d: number;
    n: string;
    t: number | number[];
    s: number; // status
    p_name: string; // process name,
    y: number;
    //NOT NEEDED y_: number // yTS
};

/**
 * @internal
 */
export type CanvasAppConst = {
    titleHeight: number;
    subtitleHeight: number;
    sidebarColWidth: number;
    sidebarColExtra: number;
};

/**
 * @internal
 */
export type CanvasViewConst = {
    forceCanvas: boolean /* FALSE FOR THE "OLD" PROJECT CANVAS */;
    readonly: boolean;
    label: string;
    colPx: number;
    rowPx: number;
    colHeader: number;
    colHeaderHeight: number;
    colHeaderHeight1: number;
    colHeaderHeight2: number;
    today: number;
    sidebarColImage: number;
    showStatusBar: boolean;
    showTaktzones: boolean;
    showProjectWeeks: boolean;
    stackProcesses: boolean;
    details: null | string | "status" | "stability";
    grid: number;
    tradeDefaultIcon: string;
    lcmx?: {
        _: number;
        menus: any[];
    };
    gpa: {
        gpa: boolean;
        padding: number;
        header: {
            height: number;
        };
    } & any;
    fonts?: any;
};

/**
 * @internal
 */
export type DataModelViewMeta = any;

/**
 * @internal
 */
export type CanvasViewMeta = {
    ts: number;
} & any;

/**
 * @public
 */
export class FrameworkErrorDataModelNeedsSync extends FrameworkError {
    constructor() {
        super("fw.needsSync");
    }
}

/**
 * @internal
 */
export const MAX_TASK_DAYS = 1000 * 365;
/**
 * @public
 */
export const END_OF_DAY_MS = 1; // 1 ms less

/**
 * @internal
 */
export enum DataOperationType {
    NOOP = 0,
    CREATE = 1,
    REJECT_ACCEPT = 2, // update API when changed
    UPDATE = 3,
}

/**
 * @internal
 */
export enum DataOperationTarget {
    TASKS = 0,
    ACTIVITY = 1,
    ACTIVITIES_TEMPLATE = 2,
    TRADE = 3,
    CARD = 4, // LEGACY CARDS, REPLACED BY ACTIVITIES...
    COMMENT = 5,
    HIVE = 6,
    MAX = 7,
}

/** @internal */
export type DataOperationMaxIds = [number, number, number, number, number, number, number];
/** @internal */
type DataOperationIdTranform = [
    DataOperationIdTransformSlot[],
    DataOperationIdTransformSlot[],
    DataOperationIdTransformSlot[],
    DataOperationIdTransformSlot[],
    DataOperationIdTransformSlot[],
    DataOperationIdTransformSlot[],
    DataOperationIdTransformSlot[],
];
/** @internal */
type DataOperationIdTranformTargetOpertion = [
    (op: DataOperation, opIsLocal: boolean) => void,
    (op: DataOperation, opIsLocal: boolean) => void,
    (op: DataOperation, opIsLocal: boolean) => void,
    (op: DataOperation, opIsLocal: boolean) => void,
    (op: DataOperation, opIsLocal: boolean) => void,
    (op: DataOperation, opIsLocal: boolean) => void,
    (op: DataOperation, opIsLocal: boolean) => void,
];

/** @internal */
type DataOperationIdTransformSlot = {
    id0: number;
    id1: number;
    n: number;
};

/** @internal */
type DataOperationSyncToken = {
    ofs: number;
    ops: number;
    idTransform: DataOperationIdTranform;
    rollbackIdTransform: any;
};

/**
 * @internal
 */
export type WorkerSession = {
    pid?: string;
    pid_ts?: number;
    project_token?: string;
    resource?: {
        token: string;
        sub: string;
    };
    master_token?: string;
    sandbox?: string;
    sandbox_name?: string;
    sandbox_ts?: number;
    sandbox_db?: boolean;
    ofs?: number;
};

/**
 * @internal
 */
export type DataOperation = {
    op: DataOperationType;
    target: DataOperationTarget;
    id: IntId;
    name: string;
    value: any;
    type?: any /* e.g. dependency type */;
    unit?: any /* e.g. day, ... */;
    i?: number /* only valid for "p": insert index (not real indices - like z-index, just for sorting), append if undefined, "-1"=deleted */;
    d?: number; // used for cards... fallback to -i? | also used for "#D" => set a range...
    c?: number; // create emty (c)hildren array, i.e. make it a summary task...
    z: number; // z insert level (not needed?!)
    _u: string /* user */;
    _d?: number /* epoch minutes */;
    group?: boolean /* group with next */;
    _ats?: number[]; // Affected TaskS
    _rejected?: number; // operation is rejected
    _value?: any;
    _i?: number; // adjusted i
    r_sid?: string; // referenced stream id
    r_id?: number; // referenced id
    r_ts?: number;
    _r_ts?: number; // timestamp handled
    p_scratch?: { x: number; y: number }; // deleted op appear in "scratch book" at pos
    _sid?: string; // origin StoriageID (sid)
    _rbac?: string;
    cmd?: string; // used for noop
    createdAt?: number;
};

/*
type _DataModelRecord={ [id: string]: DataModelRecord; };
export type DataModelRecord=_DataModelRecord  | number[] | number;
*/

/** @internal */
type DataValue<T> = number | number[];
type IntId = number;
export type EpochDate = number;
type WorkDays = number;
type ProjectGrid = number;

/** @internal */
type DataModelTrade = {
    _?: number;
    __: number;
    color?: DataValue<number>;
    name?: DataValue<string>;
    trade?: DataValue<string>;
};

/** @internal */
type DataModelCard = {
    _?: number;
    __: number;
    p?: DataValue<IntId>;
    date?: DataValue<number>;
    text?: DataValue<string>;
    status?: DataValue<number>;
};

/** @internal */
type DataModelActivitiesTemplate = {
    _?: number;
    __: number;
};

/**
 * @beta
 */
export type DataModelProcessAsJson = {
    id: IntId;
    start: EpochDate;
    end: EpochDate;
    y: number;
    duration: [number, number]; // value , unit
    name: string;
    fs: number;
    wf?: number;
    rw: { [rid: number]: any };
    children?: DataModelProcessAsJson[];
    lcmx: any;
    A: any /* activities */;
    gpa?: {
        value: {
            name: string;
        };
        r_id: number;
        r_sid: string;
        r_ts?: number;
    };
};

/**
 * @internal
 */
type DataModelTask = {
    _?: number;
    __: number;
    _x1: EpochDate;
    _x2: EpochDate;
    _canvasDirty?: boolean;
    _y: number;
    __y?: number; // only valid if _stripe1 is set
    _iy?: number; // layout
    _c?: number[]; // child tasks
    _rp?: { [id: number]: { type: number; depTs: number } }; // predecessor
    _rs?: { [id: number]: { type: number; depTs: number } }; // successor
    _rw?: { [id: number]: number }; // resources "work"/trades
    _cd?: number[]; // cards
    _dt?: number; // dependency timestamp
    _dd?: number; // dependency delta
    _dc?: number; // dependency color; undefined===green, >=0 red, <0 yellow
    _df?: number;
    _stripe0?: number;
    _stripe1?: number;
    _fx?: number; // number of "fixed" action cards associated with this task
    name?: DataValue<string>;
    start?: DataValue<EpochDate>;
    days?: DataValue<WorkDays>;
    end?: DataValue<EpochDate>;
    p?: DataValue<IntId>;
    stripey?: DataValue<number>;
    trade?: DataValue<IntId>; // NO LONGER USED?!, see rw
    fs?: DataValue<number>;
    wf?: DataValue<number>; // workforce
    x?: DataValue<number>; // whiteboard coordinates
    y?: DataValue<number>;
    w?: DataValue<number>;
    h?: DataValue<number>;
    image?: DataValue<{ blobId: string; contentType?: string }>;
    _name?: string; // override name
    _days?: number; // fallback days
    gpa?: DataValue<DataModelGpaData>;
    train?: DataValue<number | string>;
    teams?: DataValue<number>;
    _rsid?: string;
    _rid?: number;
    _stripey?: number; // fallback stripey
    _p?: number; // for virtual tasks
    _train?: string; // for virtual tasks
};

/** @internal */
type REMOVE_ME_DataModelRelation = {
    _?: number;
    __: number;
    type?: DataValue<number>;
    days?: DataValue<number>;
};

type DataModelNotificationId = string;

type DataModelNotification = any & { _: number };

type DataModelNotifications = { [nid: string]: DataModelNotification };

/**
 * @beta
 */
export type CanvasNotificationData = DataModelNotification & { nid: string };

type DataModeTimestamp = {
    ofs: number;
    maxIds: DataOperationMaxIds;
};

/**
 * @internal
 */
export interface DataModelCacheProvider {
    fetchStream(sid: string, start: number, end?: number): Promise<DataOperation[] | undefined>;
    cacheStream(sid: string, start: number, end: number | undefined, ops: DataOperation[]): Promise<boolean>;
}

/** @internal */
type CalendarGridMapping = {
    d0: number;
    d1: number;
    g1: number;
};

/** @beta */
type CalendarGridView = {
    /** @beta */
    cols: number;
    /** @internal */
    f?: {
        d0: number;
        d1: number;
        g0: number;
        g1: number;
    };
    /** @internal */
    key?: string;
};

export type RBAC = {
    filter?: {
        trades?: number[];
        tz?: number[];
    };
    mobile?: {
        trades?: number[];
    };
    rules?: {
        a_decl: any;
        a_rexp: any;
        stability: RBAC_Rule;
        milestoneState: RBAC_Rule;
        workforce: RBAC_Rule;
    };
};

export type RBAC_Rule = {
    regexp: RegExp;
    properties: {
        [key: string]: boolean;
    };
    // todo: find better naming
    allowExactMatch?: boolean;
};

/*
export function isWorkDay5(this:DataModel, date:Date) {
    const dayOfWeek=date.getUTCDay();
    const ret=!(0===dayOfWeek || 6==dayOfWeek);
    return ret && !(this && this.isHoliday(date));
}


export function isWorkDay6(this:DataModel, date:Date) {
    const dayOfWeek=date.getUTCDay();
    const ret=!(0===dayOfWeek);
    return ret && !(this && this.isHoliday(date));
}

export function isWorkDay7(this:DataModel, date:Date) {
    //const dayOfWeek=date.getUTCDay();
    const ret=true
    return ret && !(this && this.isHoliday(date));
}
*/

/**
 * @public
 */
export class CalendarGrid {
    /**
     * @internal
     */
    public ts: number;

    /**
     * @internal
     */
    public grid: CalendarGridMapping[] = [];

    /**
     * @beta
     */
    public view: CalendarGridView = {
        cols: 0,
    };

    /**
     * @internal
     */
    private appendMapping(day) {
        const n_grid = this.grid.length;
        if (n_grid > 0 && day === this.grid[n_grid - 1].d1) {
            // can merge
            this.grid[n_grid - 1].d1++;
        } else {
            this.grid.push({
                d0: day,
                d1: day + 1,
                g1: -1,
            });
        }
    }

    /**
     * @internal
     */
    static createAllGrid() {
        const grid: CalendarGrid = new CalendarGrid();
        grid.grid = [
            {
                d0: 0,
                d1: Number.MAX_SAFE_INTEGER,
                g1: Number.MAX_SAFE_INTEGER,
            },
        ];
        grid.view = {
            cols: 0,
        };
        return grid;
    }

    /**
     * @internal
     */
    static createGridFromPatch(gridPatch) {
        const grid: CalendarGrid = new CalendarGrid();
        grid.ts = gridPatch.ts;
        grid.grid = gridPatch.grid;
        grid.view = gridPatch.view;
        return grid;
    }

    /**
     * @internal
     */
    static _fixGridG(grid: CalendarGridMapping[]) {
        const n_grid = grid.length;
        for (let i = 0; i < n_grid; i++) {
            grid[i].g1 = grid[i].d1 - grid[i].d0 + (i > 0 ? grid[i - 1].g1 : 0);
        }
    }

    /**
     * @internal
     */
    static _addFilterMeta(grid: CalendarGrid, filter: { _x1: number; _x2: number } | null) {
        if (filter?._x1 && filter?._x2 && filter._x1 < filter._x2) {
            const d0 = EpochMStoEpochDays(filter._x1);
            const d1 = EpochMStoEpochDays(filter._x2);
            grid.view.f = {
                d0,
                d1,
                g0: grid.dateToGrid(EpochDaystoEpochMS(d0)),
                g1: grid.dateToGrid(EpochDaystoEpochMS(d1)),
            };
        }
    }

    /**
     * @internal
     */
    static createDayGrid(
        startDate: number,
        endDate: number,
        isWorkDay: (date: Date) => boolean = () => true,
        viewConst: {
            label: string;
            colPx: number;
        },
        filter: { _x1: number; _x2: number } | null,
    ) {
        const grid: CalendarGrid = new CalendarGrid();
        //const start=Date.UTC(2011, 1-1, 1);
        //const end=Date.UTC(2030, 1-1, 1);
        if (filter?._x1 && filter._x1 < startDate) {
            startDate = filter._x1;
        }
        if (filter?._x2 && filter._x2 > endDate) {
            endDate = filter._x2;
        }
        if (true) {
            startDate = EpochDaystoEpochMS(EpochDaysToStartOfWeekDays(EpochMStoEpochDays(startDate)));
        }
        const _startDate = new Date(EpochDaystoEpochMS(EpochMStoEpochDays(startDate)));
        const _endDate = new Date(EpochDaystoEpochMS(EpochMStoEpochDays(endDate)));
        /*
        //const start=Date.UTC(_startDate.getUTCFullYear()-(0), _startDate.getUTCMonth()-(1), 1);
        //const end=Date.UTC(_endDate.getUTCFullYear(), _endDate.getUTCMonth()+(1), 0);
        */
        const start = _startDate.getTime();
        const end = _endDate.getTime();
        const maxSidebarWidth = CONST.legacyCanvasSidebarWidth;
        const minColPx = viewConst.colPx * CONST.canvasZoomMin;
        const maxSidebarCols = Math.ceil(maxSidebarWidth / minColPx);

        //const start=Date.UTC(2020, 11-1, 16);
        //const end=Date.UTC(2021, 4-1, 3);
        //const MIN_COLS=500;
        const MIN_COLS = 0;

        const endDay = EpochMStoEpochDays(end);
        let _cols = 0;
        let _extraCols = 0;
        let _isCol: boolean;
        for (
            let date = new Date(start);
            (_isCol = 0 === grid.grid.length || grid.grid[grid.grid.length - 1].d1 <= endDay) ||
            _cols < MIN_COLS ||
            _extraCols < maxSidebarCols;
            date.setUTCDate(date.getUTCDate() + 1)
        ) {
            //console.log(date.toUTCString());
            const workDay = isWorkDay(date);
            if (workDay) {
                if (_isCol) {
                    _cols++;
                } else {
                    _extraCols++;
                }
                const day = EpochMStoEpochDays(date.getTime());
                grid.appendMapping(day);
            }
        }
        CalendarGrid._fixGridG(grid.grid);
        grid.view = {
            cols: Math.max(_cols - 1, 0),
            key: viewConst.label,
        };
        CalendarGrid._addFilterMeta(grid, filter);
        return grid;
    }

    /**
     * @internal
     */
    static createNumberGrid(
        viewConst: {
            label: string;
            colPx: number;
        },
        filter: { _x1: number; _x2: number } | null,
    ) {
        const grid: CalendarGrid = new CalendarGrid();
        grid.grid = [
            {
                d0: 1,
                d1: Number.MAX_SAFE_INTEGER,
                g1: Number.MAX_SAFE_INTEGER,
            },
        ];
        grid.view = {
            cols: 0,
            key: viewConst.label,
        };
        CalendarGrid._addFilterMeta(grid, filter);
        return grid;
    }

    /**
     * @internal
     */
    static cloneAndExpandGridIfNeeded(
        grid: CalendarGrid,
        isGridDay: (date: Date) => boolean,
        x: ProjectGrid,
        dx: number,
    ) {
        if (x + dx < 0) {
            const _grid = grid.grid.slice();
            let n = -(x + dx);
            const d = new Date(EpochDaystoEpochMS(_grid[0].d0));
            assert(n > 0);
            do {
                DataModel.movePrevDay(d);
                if (isGridDay(d)) {
                    n--;
                    const _d = EpochMStoEpochDays(d.getTime());
                    if (_d + 1 === _grid[0].d0) {
                        _grid[0].d0 = _d;
                    } else {
                        _grid.splice(0, 0, {
                            d0: _d,
                            d1: _d + 1,
                            g1: -1,
                        });
                    }
                }
            } while (n > 0);
            CalendarGrid._fixGridG(_grid);
            return {
                _grid: CalendarGrid.createGridFromPatch({ grid: _grid }),
                _x: 0,
            };
        } else if (x + dx >= grid.grid[grid.grid.length - 1].g1) {
            const _grid = CalendarGrid.createGridFromPatch({ grid: grid.grid.slice() });
            const d = new Date(EpochDaystoEpochMS(_grid.grid[_grid.grid.length - 1].d1));
            let n = x + dx - _grid.grid[_grid.grid.length - 1].g1;
            for (; n >= 0; DataModel.moveNextDay(d)) {
                if (isGridDay(d)) {
                    n--;
                    const _d = EpochMStoEpochDays(d.getTime());
                    _grid.appendMapping(_d);
                }
            }
            CalendarGrid._fixGridG(_grid.grid);
            return {
                _grid: _grid,
                _x: x + dx,
            };
        } else {
            return {
                _grid: grid,
                _x: x + dx,
            };
        }
    }

    /**
     * @internal
     */
    public static _dateToGrid(g: CalendarGridMapping[], date: EpochDate): ProjectGrid {
        const d = date < MAX_TASK_DAYS ? date : EpochMStoEpochDays(date);
        /*
        let i=0;
        let j=g.length;
        while(i<j) {
            const k=i+Math.floor((j+i)/2);
            if (d<g[k].d0) {
                j=k;
            } else if (d>=g[k].d1) {
                i=k+1;
            } else {
                assert(g[k].d0<=d && d<g[k].d1);
                const g0=(k>0?g[k-1].g1:0);
                const ret=g0+(d-g[k].d0);
                return ret;
            }
        }
        assert(false); //@TODO
        */
        const n = g.length;
        let i = 0;
        while (i < n && d >= g[i].d1) i++;
        assert(i < n);
        const g0 = i > 0 ? g[i - 1].g1 : 0;
        const _d = Math.min(Math.max(d, g[i].d0), g[i].d1);
        const ret = g0 + (_d - g[i].d0);
        return ret;
    }

    /**
     * @public
     */
    public dateToGrid(date: EpochDate): ProjectGrid {
        return CalendarGrid._dateToGrid(this.grid, date);
    }

    /**
     * @public
     */
    public gridToDate(x: ProjectGrid): EpochDate {
        const day = this.gridToDay(x);
        return EpochDaystoEpochMS(day);
    }

    /**
     * @public
     */
    public gridToDay(x: ProjectGrid): EpochDate {
        const g = this.grid;
        const n = g.length;
        let i = 0;
        while (i < n && x >= g[i].g1) i++;
        const g0 = i > 0 ? g[i - 1].g1 : 0;
        assert(i < n && g0 <= x && x < g[i].g1);
        const day = g[i].d0 + (x - g0);
        return day;
    }

    /**
     * @internal
     */
    public createEpochArray(timezoneOFfset?: number) {
        const f = this?.view?.f || {
            g0: 0,
            g1: "number" === typeof this?.view?.cols ? this.view.cols : 0,
        };
        assert(f.g0 <= f.g1);
        const g = this?.grid || null;
        const n = g?.length || 0;
        const l = f.g1 - f.g0;
        const ret = new Array(l);
        const o = (timezoneOFfset || 0) * 60 * 1000;
        let j = 0;
        for (let i = 0; i < n; i++) {
            // FIX ME!!!!! This is slow, but its 4am and I need a release!!!
            const _g0 = i > 0 ? g[i - 1].g1 : 0;
            const _g1 = g[i].g1;
            for (let d = g[i].d0, _g = _g0; _g < _g1; d++, _g++) {
                if (f.g0 <= _g && _g < f.g1) {
                    ret[j++] = EpochDaystoEpochMS(d) + o;
                }
            }
        }
        assert(l === j);
        return ret;
    }
}

/**
 * @internal
 */
export function isWorkDayAll(date: Date): boolean {
    return true;
}

/**
 * @internal
 */
export function isDailyBoardGrid(date: Date): boolean {
    return true;
}

/**
 * @internal
 */
export function isWorkingDayHack(this: CanvasCalendarMeta, date: Date): boolean {
    const dayOfWeek = date.getUTCDay();
    let ret = this.wd[dayOfWeek];
    if (ret) {
        const m = date.getUTCMonth();
        const d = date.getUTCDate();
        const y = date.getUTCFullYear();
        const e = this.slot[m * 31 + d];
        const n_e = e ? e.length : 0;
        for (let i = 0; ret && i < n_e; i++) {
            const x = e[i];
            if (m == x.m && d === x.d && (null === x.y || y === x.y)) {
                ret = false;
            }
        }
    }
    return ret;
}

const FONTS = [
    {
        font: "600 16px Inter",
        ascent: 8,
        descent: 12 - 8,
    },
    {
        font: "600 12px Inter",
        ascent: 8,
        descent: 12 - 8,
    },
    {
        font: "600 14px Inter",
        ascent: 8,
        descent: 14 - 8,
    },
];

/**
 * @internal
 */
export const CANVAS_FONT = {
    //font: "600 {sz}pt Inter",
    //emfont: "600 2048px Inter",
    font: "400 {sz}pt Roboto",
    emfont: "400 2048px Roboto",
    emsquare: 2048,
    emascent: 1825, // 1946
    emdescent: 443, // 512
};

/**
 * @internal
 */
export const VCONST0: CanvasViewConst = {
    forceCanvas: true,
    readonly: true,
    label: null,
    colPx: 0,
    rowPx: 0,
    colHeader: 0,
    colHeaderHeight: 0,
    colHeaderHeight1: 0,
    colHeaderHeight2: 0,
    today: 0,
    sidebarColImage: CONST.sidebarColImage,
    showStatusBar: false,
    showTaktzones: false,
    showProjectWeeks: false,
    stackProcesses: false,
    details: null,
    grid: 1,
    tradeDefaultIcon: defaultTradeImage.title,
    gpa: {
        gpa: false,
        fonts: FONTS,
        padding: 0,
        header: {
            height: 0,
        },
        canvasColor: "#f8fafa",
        selected: {
            primary: {
                style: "black",
                width: 2,
            },
            secondary: {
                style: "black",
                width: 2,
            },
            handle: {
                style: "black",
                width: 4,
            },
        },
        task: {
            border: {
                style: "rgba(0, 0, 0, 0.08)",
                width: 2,
            },
            label: {
                padding: 10,
                font: CANVAS_FONT,
                black: "black",
                white: "white",
            },
            trade: {
                light: "rgba(255, 255, 255, 0.8)",
                dark: "rgba(0, 0, 0, 0.4)",
            },
        },
    },
};

// export type CoreEventsTest = {
//     "process::startDateChanged::negativeEffect": StartDateChangedCallback,
//     "process::startDateChanged": StartDateChangedCallback,
//     "process::durationChanged::negativeEffect": (data: {value: {old: number, new: number}}, triggeredEventName: string) => void,
//     "process::durationChanged": (data: {value: {old: number, new: number}}, triggeredEventName: "process::durationChanged") => void,
// }

/**
 * @internal
 */
export class DataModel {
    public static cache: DataModelCacheProvider = null;
    public static renderer: any = null;
    private errorState: { error?: Error; rt?: Error } | null = null;
    private canvasErrorState: { error?: Error; rt?: Error } | null = null;
    public syncStreamPending: number = 0;
    //public storageName:string=null;
    private _activeSID: string = null;
    public _storageNames: { [storageName: string]: true } = {};
    private _storageName: string = null;
    public get storageName(): string {
        return this._storageName;
    }
    public set storageName(storageName: string) {
        assert(false, "use setStorageName");
    }
    public setStorageName(storageName: string) {
        this._storageNames[storageName] = true; // make sure storageName is set
        this._storageName = storageName;
    }
    public hostModel: DataModel = null;
    public userName: string = null;
    public ops: DataOperation[] = [];
    public whiteboard: boolean = false;
    private imported: DataModeTimestamp = { ofs: 0, maxIds: [0, 0, 0, 0, 0, 0, 0] };
    private commited: number = 0;
    private REMOVE_ME_syncSent: number = 0;
    public syncCommited = { ofs: 0 };
    private importFixed: number = 0;
    private history: number = 0;
    private undoStack: number[] = [];
    private undoStackPos: number = 0;
    //private filter:DataModelFilter | number | null=null;
    private filterX1X2: { _x1: number; _x2: number } | null = null;
    //private canvasFilter:DataModelFilter | number | null=null;
    public canvasWhiteboards = {
        _: -1 as number,
        activeId: null as string,
        active: null as any,
        model: null as DataModel,
        rt: null as RT,
    };
    public hive: any = {};
    public legacyFLux: {
        gpaPreview?: any;
        filter?: any;
        fs?: number; // overwrite font size
    } = {};
    // @todo: why is every function seperate and not an object like in @see Core
    private stripesFilter: ((sc: IntId) => boolean) | null = null;
    public stripesTradeFilterCB: (id: number) => boolean = null;
    public stripesDateFilterCB: (t: DataModelTask) => boolean = null;
    public pidsFilter: (tid: number) => boolean = null;
    public statusFilter: (status: ProcessStatuses) => boolean = null;

    private stripeOverride: number[] = [];
    private notifications: DataModelNotifications = {};
    private pendingNotifications: DataModelNotificationId[] = [];
    private notificationsTS: number = 0;
    private maxCommit: number = 0;
    public gridIsWorkDay: (date: Date) => boolean = null;
    public grid: CalendarGrid = null;
    public grid_ts = 0;
    public viewConst: CanvasViewConst = { ...VCONST0, ...CONST.views.at(-1) }; // weekly is default
    private canvasViewConst: CanvasViewConst | null = null;
    public viewMeta: { model: DataModelViewMeta; view: CanvasViewMeta } = {
        model: {},
        view: {
            ts: -1,
        },
    };
    private canvasViewMeta: CanvasViewMeta | null = null;
    public taskCalendarHack = null;
    public baseline: DataModel = null;
    public compat = {
        gpaFix: 1, // fix for the gpa "start" value
    };
    public gpaPreview: {
        enabled: boolean;
        ts: number;
        hostTS: number;
        trainInfos: { [trainId: number]: { id: number; start?: number; dep?: string } };
        dep?: number[];
        orderByNameSuffix: boolean;
    } = null;

    public maxIds: DataOperationMaxIds = [0, 0, 0, 0, 0, 0, 0];
    public syncMaxIds: DataOperationMaxIds = [0, 0, 0, 0, 0, 0, 0];
    public transformIds: DataOperationIdTranform = [[], [], [], [], [], [], []];
    public startDate: EpochDate = null;
    public endDate: EpochDate = null;
    //public tasksMaxId=0;
    public tasks: Record<IntId, DataModelTask> = {};
    public trades: Record<IntId, DataModelTrade> = {};
    public cards: Record<IntId, DataModelCard> = {};
    public activitiesTemplates: Record<IntId, DataModelActivitiesTemplate> = {};
    private rbac: { [sub: string]: RBAC } = {};
    public p_scratch: Record<IntId, true> = {};

    //public cardsMaxId=0;
    //public tradesMaxId=0;
    public tradesDirty: boolean = false;
    public gpaDirty: boolean = false;
    private canvasGpaDirty: boolean = false;

    private eventQueue = new Set<{ name: CoreEventNames; data?: CoreEventsCallbackData; ops: DataOperation[] }>();
    private _eventEmitter = new EventEmitter<CoreEvents>();

    public get eventEmitter() {
        return this._eventEmitter;
    }

    public callbacks: {
        cmdCommitSandbox?: (op: {
            op: DataOperationType.NOOP;
            cmd: "commit_sandbox" | "delete_sandbox";
            sid: string;
            ts: number;
            ops: number;
        }) => void;
        onReadonlyViolation?: () => void;
        onUpdate?: (model: DataModel, syncWB?: DataModel) => void;
        onCanvasProcessUnchanged?: (model: DataModel, p: CanvasTaskData, pid: number, userCtx: any) => void | boolean;
        onCanvasProcessChanged?: (model: DataModel, p: CanvasTaskData, pid: number, userCtx: any) => void | boolean;
        onCanvasProcessUpdateCtx?: (model: DataModel, pid: number, userCtx: any) => void;
        onResolveExtValue?: (ext: number | number[]) => any;
        onWhiteboardSync?: (model: DataModel, wb: DataModel, init?: boolean) => void;
        onUpdateCanvas?: (model: DataModel, userCtx: any, taskIds?: string[]) => any;
        onInitCanvas?: (model: DataModel) => any;
        onMetaCanvas?: (model: DataModel) => any;
        _coreUpdateActivities?: (
            ctx: { model: DataModel },
            tid: number,
        ) => { cards: ParticleCardData[]; _: number; _y_max: number };
        _coreGetTrades?(): any[];
        _dataViewReducer?: CanvasDataViewReducer<any, any>;
        _PPPUnchanged?: (
            ctx: OnParticleCoreUpdateEvent,
            p: CanvasTaskData,
            pGt: ProcessGetter,
            userCtx: any,
        ) => void | boolean;
        _PPPChanged?: (ctx: OnParticleCoreUpdateEvent, p: CanvasTaskData, pGt: ProcessGetter, userCtx: any) => void;
        _PPPUpdateCtx?: (ctx: OnParticleCoreUpdateEvent, pGt: ProcessGetter, userCtx: any) => void;
        _WBPUnchanged?: (
            ctx: OnParticleCoreUpdateEvent,
            p: CanvasTaskData,
            wbPGt: ProcessGetter,
            userCtx: any,
        ) => void | boolean;
        _WBPChanged?: (ctx: OnParticleCoreUpdateEvent, p: CanvasTaskData, wbPGt: ProcessGetter, userCtx: any) => void;
        _WBPUpdateCtx?: (ctx: OnParticleCoreUpdateEvent, wbPGt: ProcessGetter, userCtx: any) => void;
        _coreResolveExtValue?: (ext: number | number[], _GtHelper: ParticleGetter) => any;
        _onWBSync?: (ctx: OnParticleCoreUpdateEvent, init?: boolean) => void;
    } = {};

    public setErrorState(state: { error?: Error; rt?: Error } | null) {
        const helper = null !== state ? { ...(this.errorState || {}) } : {};
        if (undefined !== state?.error) {
            helper.error = state.error;
        }
        if (undefined !== state?.rt) {
            helper.rt = state.rt;
        }
        if (helper.rt || helper.error) {
            this.errorState = {};
            if (helper.error) {
                this.errorState.error = helper.error;
            }
            if (helper.rt) {
                this.errorState.rt = helper.rt;
            }
        } else {
            this.errorState = null; // clear error
        }
    }

    public setMaxCommit(maxCommit: number) {
        if ("number" === typeof maxCommit) {
            if (maxCommit > 0) {
                this.maxCommit = maxCommit;
            } else {
                this.maxCommit = this.imported.ofs;
            }
        } else {
            this.maxCommit = 0;
        }
        return this.maxCommit;
    }

    public getMaxCommit() {
        return this.maxCommit;
    }

    public maxIdsCopy(): DataOperationMaxIds {
        assert(this.ops.length === this.commited);
        return this.maxIds.slice() as DataOperationMaxIds;
    }

    public static RELATION(a: IntId, b: IntId): IntId {
        return (a << 16) | b;
    }
    public static RELATION_FROM(r: IntId): IntId {
        return r >> 16;
    }
    public static RELATION_TO(r: IntId): IntId {
        return r & 0xffff;
    }

    public static EpochMStoEpochDays(date: EpochDate): number {
        return date < MAX_TASK_DAYS ? date : Math.floor(date / (86400 * 1000));
    }

    public static EpochMSRoundDays(date: EpochDate): number {
        return date < MAX_TASK_DAYS ? date : Math.floor(date / (86400 * 1000)) * (86400 * 1000);
    }

    public static _calcNetWorkDays_TODO(date: Date, day: number) {
        return 0;
    }

    public static calcNetWorkDays_TODO(isWorkDay: (date: Date) => boolean, start: EpochDate, end: EpochDate) {
        return DataModel.CalcWorkDays(isWorkDay, start, end);
    }

    public static EpochEndDate_TODO(
        isWorkDay: (date: Date) => boolean,
        start: EpochDate,
        duration: number,
        unit: number,
    ): number {
        return DataModel.CalcEpochEndDayMS(isWorkDay, start, duration, unit);
    }

    public static WorkDaysToProjectDays_TODO(start: EpochDate, duration: number, unit: number) {
        return 0;
    }

    public static moveNextDay(d: Date) {
        d.setUTCDate(d.getUTCDate() + 1);
    }
    public static movePrevDay(d: Date) {
        d.setUTCDate(d.getUTCDate() - 1);
    }
    public static getDayOffset(start: EpochDate, isWorkDay: (date: Date) => boolean, date: EpochDate) {
        assert(EpochDaystoEpochMS(EpochMStoEpochDays(start)) === start);
        assert(EpochDaystoEpochMS(EpochMStoEpochDays(date)) === date);
        const d = new Date(start);
        for (let cd = 0, wd = 0; start <= date; cd++) {
            const isWd = isWorkDay(d);
            if (d.getTime() === date) {
                return isWd ? wd : -(cd + 1);
            }
            if (isWd) {
                wd++;
            }
            DataModel.moveNextDay(d);
        }
        return 0;
    }

    public static CalcEpochStartDayMS(
        isWorkDay: (date: Date) => boolean,
        start: EpochDate,
        duration: number,
        unit: number,
    ): number {
        const ret = new Date(DataModel.EpochMSRoundDays(start));
        let days = DataModel.unitToDays(duration, unit);
        if (days > 0 && !(10 === unit || 11 === unit)) {
            let wd;
            while (!(wd = isWorkDay(ret)) || days > 0) {
                if (wd) {
                    days--;
                }
                DataModel.moveNextDay(ret); // next day
            }
            assert(0 === days && isWorkDay(ret));
        } else if (days > 0) {
            ret.setUTCDate(ret.getUTCDate() + days);
        } else if (days < 0 && !(10 === unit || 11 === unit)) {
            //const _days=-days;
            let wd;
            do {
                DataModel.movePrevDay(ret); // prev day
                wd = isWorkDay(ret);
                if (wd) {
                    days++;
                }
            } while (!wd || days < 0);
            assert(0 === days && isWorkDay(ret));
            //const _wd=DataModel.CalcWorkDays(isWorkDay, ret.getTime(), start);
            //assert(_wd===_days);
        } else if (days < 0) {
            ret.setUTCDate(ret.getUTCDate() + days);
        }
        assert(ret.getTime() === DataModel.EpochMSRoundDays(ret.getTime()));
        return ret.getTime();
    }

    public static CalcEpochEndDayMS(
        isWorkDay: (date: Date) => boolean,
        start: EpochDate,
        duration: number,
        unit: number,
    ): number {
        // assert(typeof duration === "number", "duration needs be a number");
        if (start < MAX_TASK_DAYS) {
            const days = DataModel.unitToDays(duration, unit);
            return start + days;
        } else {
            const ret = new Date(DataModel.EpochMSRoundDays(start));
            let days = DataModel.unitToDays(duration, unit);
            if (days > 0 && !(10 === unit || 11 === unit)) {
                DataModel.moveNextDay(ret);
                days--; // next day
                for (; days > 0; ret.setUTCDate(ret.getUTCDate() + 1)) {
                    if (isWorkDay(ret)) {
                        days--;
                    }
                }
            } else if (days > 0) {
                ret.setUTCDate(ret.getUTCDate() + days);
            }
            assert(ret.getTime() === DataModel.EpochMSRoundDays(ret.getTime()));
            return ret.getTime();
        }
    }

    public static CalcWorkDays(isWorkDay: (date: Date) => boolean, start: EpochDate, end: EpochDate): number {
        let wd = 0;
        const d = new Date(DataModel.EpochMSRoundDays(start));
        const e = new Date(DataModel.EpochMSRoundDays(end));
        for (; d.getTime() < e.getTime(); DataModel.moveNextDay(d)) {
            if (isWorkDay(d)) {
                wd++;
            }
        }
        return wd;
    }

    public static CalcEllapsedDays(start: EpochDate, end: EpochDate) {
        const _start = DataModel.EpochMSRoundDays(start);
        const _end = DataModel.EpochMSRoundDays(end);
        return EpochMStoEpochDays(_end) - EpochMStoEpochDays(_start);
    }

    public epochDateToProjectGrid(this: DataModel, startDate: EpochDate, date: EpochDate): ProjectGrid {
        // REMOVE ME!, use dateToGrid directly
        assert(this.grid ? true : false);
        return this.grid.dateToGrid(date);
    }

    public static EpochDateToProjectGrid(grid: CalendarGrid, startDate: EpochDate, date: EpochDate): ProjectGrid {
        return grid ? grid.dateToGrid(date) : -1;
    }

    public projectGridToEpochDate(this: DataModel, startDate: EpochDate, grid: ProjectGrid): EpochDate {
        assert(this.grid ? true : false);
        return this.grid.gridToDate(grid);
    }

    public static unitToDays(days: number, units: number) {
        switch (units) {
            case 1: // minutes
                return Math.ceil(days / 24 / 60); //@TODO
            case 2: // hours
                return Math.ceil(days / 24); //@TODO
            case 3: // days
                return Math.ceil(days);
            case 4: // weeks
                return Math.ceil(5 * days);
            case 5: // month
                return Math.ceil(20 * days);
            case 10: // 10 Elapsed Days
                return days; //@TODO
            case 11: // 11 Elapsed Weeks
                return Math.ceil(5 * days); //@TODO
            case 6: // percent?
                return 0;
            default:
                assert(0 === days); //@TODO
                return 0;
        }
    }

    /*
    private static _expandMilestone(x:number, end:boolean, C:{rowPx:number, colPx:number, colHeader:number}) {
        //const _dx_2=Math.ceil(C.rowPx/C.colPx)/2;
        //const dx_2=x<MAX_TASK_DAYS?_dx_2:EpochDaystoEpochMS(_dx_2);
        const dx_2=0;
        return end?x+dx_2:x-dx_2;
    }
    */

    private static taskIntersect(t1: { _x1: number; _x2: number }, t2: { _x1: number; _x2: number }): boolean {
        if (t1._x1 === t1._x2 && t2._x1 === t2._x2) {
            return t1._x1 === t2._x1;
        } else if (t1._x1 === t1._x2) {
            return t2._x1 < t1._x1 && t1._x1 < t2._x2;
        } else if (t2._x1 === t2._x2) {
            return t1._x1 < t2._x1 && t2._x1 < t1._x2;
        } else {
            const ret = Math.max(t1._x1, t2._x1) < Math.min(t1._x2, t2._x2);
            return ret;
        }
    }

    /**
     * Checks if a given Process matches all active filters
     *
     * @param t Current Process
     * @param tfCB tradeFilterCallback
     * @param dfCB dateFilterCallback
     * @param pfCB processFilterCallback
     * @param statusFilterCB
     * @param tid processId
     * @return 0: remove from the pp  1: show in the PP as usual -1: if other processes match in the same stripe display that process blured out otherwise remove whole stripe
     * @private
     */
    private taskMatchesFilter(
        t: DataModelTask,
        tfCB: (number) => boolean,
        dfCB: (t: DataModelTask) => boolean,
        pfCB: (tid: number) => boolean,
        statusFilterCB: (processId: number) => boolean,
        tid,
    ): 1 | -1 | 0 {
        assert(t === this.tasks[tid]);

        if (pfCB && pfCB(tid)) {
            return 1;
        } else if (pfCB) {
            return 0;
        }

        const ret_tf = tfCB
            ? Object.getOwnPropertyNames(t._rw || {}).reduce((ret, rid) => {
                  ret = ret || tfCB(Number.parseInt(rid));
                  return ret;
              }, false)
            : true;
        const statusFilterMatched = statusFilterCB ? statusFilterCB(tid) : true;
        const ret_df = dfCB ? dfCB(t) : true;
        return ret_tf && statusFilterMatched ? (ret_df ? 1 : -1) : 0;
    }

    private static _updateDataValue(r: Record<string, any>, name: string, op: number, z: number, _ts: number) {
        let v = r[name];
        r._ = Math.max(r._ || 0, _ts);
        if (undefined === v) {
            assert(0 === z);
            r[name] = op;
            return op;
        } else {
            if (!Array.isArray(v)) {
                v = [v];
            }
            assert(0 <= z && z <= v.length);
            v.splice(z, 0, op);
            r[name] = v;
            return v;
        }
    }

    private static _undoDataValue(r: Record<string, any>, name: string, op: number, z: number) {
        const v = r[name];
        r._ = Math.max(r._ || 0, op);
        assert(undefined !== v);
        if (undefined === v) {
            throw "UNDO STACK INVALID!";
        } else {
            let deleted_op;
            if (!Array.isArray(v)) {
                assert(0 === z);
                deleted_op = v;
                //r[name]=undefined;
                delete r[name];
            } else {
                assert(0 <= z && z <= v.length);
                deleted_op = v[z];
                r[name] = v.slice();
                r[name].splice(z, 1);
                if (0 === r[name].length) {
                    //r[name]=undefined;
                    delete r[name];
                }
            }
            return {
                value: r[name],
                deleted: deleted_op,
            };
        }
    }

    //private taskStripeIndex:Record<number, number[]>={};
    private _findAffectedAreasFor(this: DataModel, ret: number[], taskId: number) {
        const task = this.tasks[taskId];
        let y_max = task._y;
        const stripes = this._stripes[0].stripes;
        let stripe;
        if (undefined !== task._stripe0 && this.VALUE<number>(task.start, 0) > 0 && (stripe = stripes[task._stripe0])) {
            const c = this.tasks[stripe.t]._c;
            for (let i = stripe.i; i < stripe.j; i++) {
                const t = this.tasks[c[i]];
                if (t !== task && task._y === t._y && Math.max(task._x1, t._x1) < Math.min(task._x2, t._x2)) {
                    ret.push(c[i]);
                }
                y_max = Math.max(y_max, t._y);
            }
        }
        return y_max;
    }

    private _getTaskChildList(this: DataModel, tid: number, ensure?: boolean) {
        if (tid >= 0) {
            const t = this.tasks[tid];
            assert(t ? true : false);
            let ret = t._c;
            if (ensure && Array.isArray(ret)) {
                const n = t._c.length;
                if (n > 0 && null === this.VALUE<number | null>(this.tasks[t._c[0]]?.p, null)) {
                    assert(
                        "development" !== process.env.NODE_ENV ||
                            t._c.reduce(
                                (ret, tid) => ret && null === this.VALUE<number | null>(this.tasks[tid]?.p, null),
                                true,
                            ),
                    );
                    t._c.forEach((tid) => {
                        this._clearStripeRefences(tid);
                    });
                    ret = null; // "virtual child list"=> clear
                } else {
                    assert(
                        "development" !== process.env.NODE_ENV ||
                            t._c.reduce(
                                (ret, tid) => ret && null !== this.VALUE<number | null>(this.tasks[tid]?.p, null),
                                true,
                            ),
                    );
                }
            }
            if (ensure && !ret) {
                ret = [];
                t._c = ret;
            }
            return ret;
        } else {
            return [];
        }
    }

    private _transformChildListOps(
        this: DataModel,
        _c: number[],
        i_c: number,
        op: DataOperation,
        i_op: number,
        delta: number,
    ) {
        assert("development" !== process.env.NODE_ENV || op === this.ops[i_op]);
        const n = _c.length;
        for (let i = 0; i < n; i++) {
            const i_p_op = this.OP_I(this.tasks[_c[i]].p, -1);
            assert(i_p_op >= 0 && "p" === this.ops[i_p_op].name && this.ops[i_p_op].value === op.value);
            const _i = gfu(this.ops[i_p_op]._i, this.ops[i_p_op].i, 0);
            assert(0 <= _i);
            if (_i >= i_c) {
                this.ops[i_p_op]._i = _i + delta;
                assert(0 <= this.ops[i_p_op]._i);
            }
        }
    }

    private _DEBUG_checkChildListInv(this: DataModel, _c: number[]) {
        if (false) {
            const n = _c.length;
            for (let i = 0; i < n; i++) {
                const i_p_op0 = this.OP_I(this.tasks[_c[0]].p, -1);
                const i_p_op = this.OP_I(this.tasks[_c[i]].p, -1);
                assert(
                    i_p_op0 >= 0 &&
                        i_p_op >= 0 &&
                        "p" === this.ops[i_p_op].name &&
                        this.ops[i_p_op0].value === this.ops[i_p_op].value,
                );
                assert(i === gfu(this.ops[i_p_op]._i, this.ops[i_p_op].i, 0));
            }
        }
        return true;
    }

    //private __HIT__insertIntoChildList=0;
    private _insertIntoChildList(this: DataModel, op: DataOperation, i_op: number) {
        /*
        this.__HIT__insertIntoChildList++;
        if (189===this.__HIT__insertIntoChildList){
            console.log("GURU MEDIDATION!")
        }
        */
        /*
        if(0===op.value) {
            assert("p"===op.name);
            console.log("insert("+op.id+", "+gfu(op._i, op.i, 0)+")");
        }
        */
        assert("development" !== process.env.NODE_ENV || op === this.ops[i_op]);
        if (-1 === op.value && op.p_scratch) {
            this.p_scratch[op.id] = true;
        } else {
            const _c = this._getTaskChildList(op.value, true);
            if (_c) {
                assert("development" !== process.env.NODE_ENV || this._DEBUG_checkChildListInv(_c));
                assert(-1 === _c.findIndex((ct) => ct === op.id)); // already there?
                const i = Math.min(Math.max(0, gfu(op._i, op.i, 0)), _c.length);
                this._transformChildListOps(_c, i, op, i_op, +1); // transform op
                _c.splice(i, 0, op.id);
                assert("development" !== process.env.NODE_ENV || this._DEBUG_checkChildListInv(_c));
            }
        }
    }

    private _removeFromChildList(this: DataModel, op: DataOperation, i_op: number) {
        assert("development" !== process.env.NODE_ENV || op === this.ops[i_op]);
        if (-1 === op.value && op.p_scratch) {
            assert(true === this.p_scratch[op.id]);
            delete this.p_scratch[op.id];
        } else {
            const _c = this._getTaskChildList(op.value, false);
            if (_c) {
                //            this._DEBUG_checkChildListInv(_c); // only works after splice...
                const _i = _c.findIndex((ct) => ct === op.id);
                if (_i >= 0) {
                    _c.splice(_i, 1);
                    assert(-1 === _c.findIndex((ct) => ct === op.id));
                    this._transformChildListOps(_c, _i, op, i_op, -1); // transform op
                    this._clearStripeRefences(op.id);
                    if (0 === _c.length && op.value > 0) {
                        const t = this.tasks[op.value];
                        assert(_c === t._c);
                        const p_op = this.OP(t.p);
                        if (
                            p_op &&
                            !p_op?.c &&
                            !this.VALUE(t.gpa) /* do not convert when the task has a gpa assigned to it...*/
                        ) {
                            const p = this.tasks[p_op.value];
                            if (p._x1 > 0) {
                                // do not convert a complex task into simple task by deleteting the child array, when there is no start date... (LCM2-1081)
                                delete t._c;
                            }
                        }
                    }
                }
                assert("development" !== process.env.NODE_ENV || this._DEBUG_checkChildListInv(_c));
            }
        }
    }

    private _updateRoot(task: DataModelTask) {
        if (task && task._x1 > 0 && task._x1 <= task._x2) {
            this.startDate = task._x1;
            this.endDate = task._x2;
        }
    }

    private _clearStripeRefences(this: DataModel, tid: number) {
        const t = this.tasks[tid];
        if (t) {
            t._stripe0 = undefined;
            t._stripe1 = undefined;
            if (Array.isArray(t._c)) {
                t._c.forEach((cid) => this._clearStripeRefences(cid));
            }
        }
    }

    private _isStripeDirty(t: DataModelTask) {
        let dirty = false;
        if (!dirty && "number" === typeof t._stripe0) {
            const s = (this._stripes[0]?.stripes || [])[t._stripe0];
            dirty = !(s && t._x1 > s._x1 && t._x2 < s._x2);
        }
        if (!dirty && "number" === typeof t._stripe1) {
            const s = (this._stripes[1]?.stripes || [])[t._stripe1];
            dirty = !(s && t._x1 > s._x1 && t._x2 < s._x2);
        }
        return dirty;
    }

    public _checkParentLoopOK(this: DataModel, pid: number, p: number) {
        while (p >= 0) {
            if (p === pid) {
                return false;
            } else {
                const t = this.tasks[p];
                p = this.VALUE<number>(t?.p, -1);
            }
        }
        return true;
    }

    public _rejectedOps = undefined;
    private _rejectOpRBAC(op: DataOperation, rejected_reason) {
        let t: DataModelTask;
        op._rejected = 1;
        (op as any)._rbac = rejected_reason || true;
    }

    private _updateTasks(this: DataModel, op: DataOperation, i_op: number, _ts: number, n_ops: number) {
        let task: DataModelTask = null !== op.id ? this.tasks[op.id] : { __: -1, _x1: 0, _x2: 0, _y: 0 };
        let _rw_dirty = false;
        const _u = i_op >= this.imported.ofs ? op._u : null;
        const u_rbac = _u ? this.rbac[_u] : null;
        const u_filter = u_rbac?.filter;
        const u_rules = u_rbac?.rules;
        let allow_op = true;
        let rejected_reason = null;
        if (u_rules) {
            allow_op = false;
            const a_decl = u_rules?.a_decl;
            if (a_decl) {
                const a_regexp = u_rules?.a_rexp;
                const m = op.name.match(_LEGACY_ACTIVITY_REGEXP);
                let name;
                if ((name = m?.groups?.name)) {
                    const m_name = name.match(a_regexp);
                    if (m_name) {
                        const n = m_name[1];
                        const n_v = m_name[2];
                        const rule = a_decl[n];
                        if (rule) {
                            allow_op = true;
                            let v;
                            if ((v = rule?.values)) {
                                allow_op = true === v[op.value];
                            }
                        }
                    }
                } else if ((u_rules.stability.regexp as RegExp).test(op.name)) {
                    const stability = u_rules.stability;
                    const match = op.name.match(u_rules.stability.regexp);
                    if (match) {
                        const propertyName = match[1];
                        const rule = stability.properties;
                        if (propertyName && rule) {
                            allow_op = true === stability.properties[propertyName];
                        } else if (!propertyName && stability.allowExactMatch) {
                            allow_op = true;
                        }
                    }
                } else if (u_rules.milestoneState.regexp.test(op.name)) {
                    if (u_rules.milestoneState.properties["milestone-state"]) {
                        allow_op = true;
                    }
                } else if (u_rules.workforce.regexp.test(op.name)) {
                    if (u_rules.workforce.properties["wf"]) {
                        allow_op = true;
                    }
                }
            }
        }
        if (DataOperationType.CREATE === op.op) {
            // create a new task
            assert(!task || (task && undefined === task.p)); // no parents task has been inserted and then deleted via rollback
            this.maxIds[DataOperationTarget.TASKS] = Math.max(this.maxIds[DataOperationTarget.TASKS], op.id);
            task = { __: i_op, _x1: 0, _x2: 0, _y: 0 };
            this.tasks[op.id] = task;
            if (allow_op && u_filter) {
                allow_op = false; //@TODO add CREATE firewall logic
            }
        } else {
            assert(task ? true : false);
            if (allow_op && task.__ < 0) {
                //assert(false, "operation on virtual task is not allowed"); // task is "virtual", no ops allowed!
                allow_op = false;
                rejected_reason = "fw.rbac.virtual";
                assert(null === op.id || task === this.tasks[op.id]);
            }
            if (allow_op && u_filter) {
                // pre check
                allow_op = false;
                const trades = u_filter?.trades || [];
                const _rw = task._rw || {};
                for (let i = 0; !allow_op && i < trades.length; i++) {
                    allow_op = trades[i] in _rw;
                }
            }
        }
        assert(task._x1 <= task._x2);
        const v = DataModel._updateDataValue(task, op.name, i_op, op.z, _ts);
        let invalidateStripes = false;
        // update task meta
        if (0 === op.z) {
            switch (op.name) {
                case "p":
                    {
                        {
                            const i_ops = this.OPS_I(task.p);
                            if (i_ops.length > 1) {
                                const prev_i_op = i_ops[1];
                                this._removeFromChildList(this.ops[prev_i_op], prev_i_op);
                            }
                        }
                        if (this._checkParentLoopOK(op.id, op.value)) {
                            this._insertIntoChildList(op, i_op);
                            if (op.c && !Array.isArray(task._c)) {
                                task._c = [];
                            }
                        } else {
                            console.warn("removed loop for " + i_op);
                            op.value = -1; // REMOVE LOOP IN PLACE!!!!
                        }
                        invalidateStripes = true;
                    }
                    break;
                case "stripey":
                    {
                        const op_value = this.VALUE<number>(v);
                        task._y = op_value;
                        invalidateStripes = true;
                    }
                    break;
                case "start":
                    {
                        // FIX "rollback" TOO!!!!
                        const op_value = this.VALUE<number>(v);
                        const _x1 = task._x1;
                        if (op_value < MAX_TASK_DAYS) {
                            if (!this.whiteboard) {
                                // fix copy paste bug!!
                                assert(op_value === this.ops[i_op].value);
                                this.ops[i_op].value = 0; // FIX MODEL!
                                task._x1 = 0;
                                task._x2 = 0;
                            } else {
                                const x = op_value > 0 ? Math.round((op_value - 1) * this.compat.gpaFix) + 1 : 0;
                                task._x1 = x;
                                task._x2 = x + this.VALUE<number>(task.days, 0);
                            }
                        } else {
                            task._x1 = DataModel.EpochMSRoundDays(op_value); // round to days...
                            task._x2 = DataModel.CalcEpochEndDayMS(
                                this.taskCalendarHack,
                                task._x1,
                                this.VALUE<number>(task.days, 0),
                                this.UNIT(task.days, 3 /* days */),
                            );
                        }
                        if (0 === op.id) {
                            // root task
                            this._updateRoot(task);
                        }
                        if (
                            !invalidateStripes &&
                            _x1 !== task._x1 &&
                            (!_x1 || !task._x1 || this._isStripeDirty(task))
                        ) {
                            // invalidate stripes...
                            invalidateStripes = true;
                        }
                        assert(task._x1 <= task._x2);
                    }
                    break;
                case "end":
                    {
                        //no longer used!! REMOVE ME!!!
                        const op_value = this.VALUE<number>(v);
                        if (task._x1 && task._x1 <= task._x2) {
                            task._x2 = DataModel.EpochMSRoundDays(op_value); // round to days...
                        }
                        if (0 === op.id) {
                            // root task
                            this._updateRoot(task);
                        }
                        assert(task._x1 <= task._x2);
                    }
                    break;
                case "days":
                    const op_value = this.VALUE<number>(v);
                    const _x2 = task._x2;
                    if (task._x1) {
                        let nextOp;
                        if (
                            op.group &&
                            (nextOp = this.ops[i_op + 1]).target === op.target &&
                            nextOp.id === op.id &&
                            DataOperationType.UPDATE === nextOp.op &&
                            "end" === nextOp.name
                        ) {
                            // next op will overwrite "end" (i.e. _x2) anyway, so there is no need to waste CPU to calc the _x2
                        } else {
                            // pure end ... no update to end...
                            if (task._x1 < MAX_TASK_DAYS) {
                                task._x2 = task._x1 + this.VALUE<number>(task.days, 0);
                            } else {
                                const _x2 = DataModel.CalcEpochEndDayMS(
                                    this.taskCalendarHack,
                                    task._x1,
                                    op_value,
                                    this.UNIT(v, 3 /* tasks */),
                                );
                                task._x2 = _x2;
                            }
                        }
                    }
                    if (0 === op.id) {
                        // root task
                        this._updateRoot(task);
                    }
                    assert(task._x1 <= task._x2);
                    if (!invalidateStripes && _x2 !== task._x2 && this._isStripeDirty(task)) {
                        // invalidate stripes...
                        invalidateStripes = true;
                    }
                    break;
                case "w": // no break
                case "h":
                    {
                        invalidateStripes = invalidateStripes || Array.isArray(task._c);
                    }
                    break;
                case "gpa":
                    if (this.canvasWhiteboards.model?.gpaPreview) {
                        this.canvasWhiteboards.model.gpaPreview.ts = -1; // invalidate preview
                    }
                // no break!!!!
                case "image": // no break
                case "name":
                    {
                        invalidateStripes = invalidateStripes || Array.isArray(task._c);
                    }
                    break;
                default:
                    if (op.name[0] === "#" && "A" <= op.name[2] && op.name[2] <= "Z") {
                        if (op.name[1] === "T") {
                            // Task
                            if (op.name[2] === "R") {
                                const target_id = Number.parseInt(op.name.substring(3), 16);
                                //const lag_duration=this.VALUE<number>(v);
                                const rel_type = this.TYPE(v, 0);
                                task._rp = task._rp || {};
                                const target_task = this.tasks[target_id];
                                target_task._rs = target_task._rs || {};
                                if (rel_type > 0) {
                                    task._rp[target_id] = { type: rel_type, depTs: i_op };
                                    target_task._rs[op.id] = { type: rel_type, depTs: i_op };
                                } else {
                                    delete task._rp[target_id];
                                    delete target_task._rs[op.id];
                                }
                                task._rp = { ...task._rp };
                                target_task._rs = { ...target_task._rs };
                            } else {
                                throw "TODO1";
                            }
                        } else if (op.name[1] === "R") {
                            // Resource | Reason
                            if (op.name[2] === "W") {
                                // Work
                                const res_id = Number.parseInt(op.name.substring(3), 16);
                                task._rw = task._rw || {}; //@TODO only set i_op
                                let _v;
                                if (
                                    "number" === typeof (_v = this.ops[i_op].value) &&
                                    _v >= 0 &&
                                    res_id >= 0 &&
                                    "string" === typeof this.VALUE<string>(this.trades[res_id]?.name, null)
                                ) {
                                    task._rw[res_id] = i_op;
                                } else {
                                    delete task._rw[res_id];
                                }
                                _rw_dirty = true;
                            } else if (op.name[2] === "C") {
                                // @todo: muss hier noch was gemacht werden
                            } else {
                                throw "TODO3";
                            }
                        } else if (op.name[1] === "C") {
                            //@nikhil checklist_api
                            if (op.name[2]==="L"){}
                            else if (op.name[2]==="#"){}
                            else{
                                throw "TODO4: unknown op" + op.name;
                            }
                        } else {
                            throw "TODO2: unknown op" + op.name;
                        }
                    } else if (op.name[0] === "#") {
                        //const path=op.name.slice(1).split('#');
                        //if (op.name[1]==='A' && op.name.endsWith("#day")) {
                        //const m=op.name.match(DataModel.ACTIVITY_REGEXP);
                        //}
                    } else {
                    }
                    break;
            }
        }
        if (this.imported.ofs > 0 && invalidateStripes) {
            if (null !== this._stripes[0]) {
                this._stripes[0].dirty = true;
            }
            if (null !== this._stripes[1]) {
                this._stripes[1].dirty = true;
            }
            if (this.canvasWhiteboards.model?.gpaPreview) {
                this.canvasWhiteboards.model.gpaPreview.ts = -1; // invalidate preview
            }
        }
        assert(task._x1 <= task._x2);
        if (allow_op) {
            if (u_filter) {
                if (_rw_dirty) {
                    // check again post condition
                    allow_op = false;
                    const trades = u_filter?.trades || [];
                    const _rw = task._rw || {};
                    for (let i = 0; !allow_op && i < trades.length; i++) {
                        allow_op = trades[i] in _rw;
                    }
                }
            }
        }
        if (!allow_op) {
            this._rejectOpRBAC(op, rejected_reason);
            if (this.imported.ofs > 0 && rejected_reason && Array.isArray(this._rejectedOps)) {
                this._rejectedOps.push({
                    projectId: this.storageName,
                    reason: rejected_reason,
                    i_op: i_op,
                });
            }
        }
    }

    private _rollbackTask(this: DataModel, op: DataOperation, i_op: number) {
        assert(DataOperationTarget.TASKS === op.target);
        const task = null !== op.id ? this.tasks[op.id] : null;
        if (task) {
            const ret = DataModel._undoDataValue(task, op.name, i_op, op.z);
            assert(ret.deleted === i_op);
            const v = ret.value;
            switch (op.name) {
                case "stripey":
                    {
                        let i = 0;
                        if (Array.isArray(v)) {
                            const n = v.length;
                            while (i < n && v[i] < 0) {
                                i++; // skip "ats moves..."
                            }
                        }
                        const op_i = Array.isArray(v) ? v[i] : v;
                        if (op_i > 0) {
                            task._y = this.ops[op_i].value as number;
                            task._y += i;
                        } else {
                            assert(undefined === v || (Array.isArray(v) && 0 === v.length));
                            task._y = undefined;
                        }
                        if (this.imported.ofs > 0) {
                            //@TODO maybe we add the "dirty" flag to the stripe and get rid of all the ats stuff...
                            if (null !== this._stripes[0]) {
                                this._stripes[0].dirty = true;
                            }
                            if (null !== this._stripes[1]) {
                                this._stripes[1].dirty = true;
                            }
                        }
                        /*if (true) {
                        const v_op=Array.isArray(v)?v[0]:v;
                        for(let i=v_op;i<i_op;i++) {
                            // fix _y
                            const _op=this.ops[i];
                            if (!_op._rejected) {
                                const _ats=_op._ats ||[];
                                for(let j=0;j<_ats.length;j++) {
                                    if (_ats[j]===op.id) {
                                        const _task=this.tasks[op.id];
                                        _task.__=i_op;
                                        _task._y++;
                                    }
                                }
                            }
                        }
                    }*/
                    }
                    break;
                case "p":
                    {
                        this._removeFromChildList(op, i_op);
                        const p_iop = this.OP_I(task.p, -1);
                        if (p_iop >= 0) {
                            // apply op again...
                            const p_op = this.ops[p_iop];
                            this._insertIntoChildList(p_op, p_iop);
                            if (p_op.c) {
                                if (!Array.isArray(task._c)) {
                                    task._c = [];
                                }
                            } else {
                                if (Array.isArray(task._c) && 0 === task._c.length) {
                                    delete task._c;
                                }
                            }
                        }
                        if (undefined !== op.d) {
                            throw "Legacy. No longer used...";
                        }
                        if (this.imported.ofs > 0) {
                            if (null !== this._stripes[0]) {
                                this._stripes[0].dirty = true;
                            }
                            if (null !== this._stripes[1]) {
                                this._stripes[1].dirty = true;
                            }
                            if (this.canvasWhiteboards.model?.gpaPreview) {
                                this.canvasWhiteboards.model.gpaPreview.ts = -1; // invalidate preview
                            }
                        }
                    }
                    break;
                case "start":
                    {
                        // FIX "apply" TOO!!!!
                        const _x1 = task._x1;
                        if (v) {
                            const op_value = this.VALUE<number>(v);
                            if (op_value < MAX_TASK_DAYS) {
                                if (!this.whiteboard) {
                                    // fix copy paste bug!!
                                    assert(op_value === this.ops[i_op].value);
                                    this.ops[i_op].value = 0; // FIX MODEL!
                                    task._x1 = 0;
                                    task._x2 = 0;
                                } else {
                                    const x = op_value > 0 ? Math.round((op_value - 1) * this.compat.gpaFix) + 1 : 0;
                                    task._x1 = x;
                                    task._x2 = x + this.VALUE<number>(task.days, 0);
                                }
                            } else {
                                task._x1 = DataModel.EpochMSRoundDays(op_value); // round to days...
                                task._x2 = DataModel.CalcEpochEndDayMS(
                                    this.taskCalendarHack,
                                    task._x1,
                                    this.VALUE<number>(task.days, 0),
                                    this.UNIT(task.days, 3 /* days */),
                                );
                            }
                        } else {
                            task._x1 = 0;
                            task._x2 = 0;
                        }
                        if (this.imported.ofs > 0 && _x1 !== task._x1 && (!_x1 || !task._x1)) {
                            // invalidate stripes...
                            if (null !== this._stripes[0]) {
                                this._stripes[0].dirty = true;
                            }
                            if (null !== this._stripes[1]) {
                                this._stripes[1].dirty = true;
                            }
                            if (this.canvasWhiteboards.model?.gpaPreview) {
                                this.canvasWhiteboards.model.gpaPreview.ts = -1; // invalidate preview
                            }
                        }
                    }
                    break;
                case "days":
                case "end":
                    {
                        const _op_end = this.OP_I<number>(task.end, 0);
                        const _op_days = this.OP_I<number>(task.days, 0);
                        const _op = Math.max(_op_end, _op_days); // last op wins
                        if (_op) {
                            const op = this.ops[_op];
                            const op_value = op.value;
                            if ("days" === op.name) {
                                const op_unit = op.unit || 3; /* days */
                                if (task._x1) {
                                    task._x2 = DataModel.CalcEpochEndDayMS(
                                        this.taskCalendarHack,
                                        task._x1,
                                        op_value,
                                        op_unit,
                                    );
                                }
                            } else if ("end" === op.name) {
                                if (task._x1) {
                                    task._x2 = DataModel.EpochMSRoundDays(op_value); // round to days...
                                }
                            } else {
                                assert(false);
                            }
                        } else {
                            task._x2 = task._x1;
                        }
                        if (this.canvasWhiteboards.model?.gpaPreview) {
                            this.canvasWhiteboards.model.gpaPreview.ts = -1; // invalidate preview
                        }
                    }
                    break;
                case "trade":
                    {
                        //console.log("TODO");
                    }
                    break;
                case "x":
                case "y":
                case "w":
                case "h":
                    break;
                case "gpa":
                    if (this.canvasWhiteboards.model?.gpaPreview) {
                        this.canvasWhiteboards.model.gpaPreview.ts = -1; // invalidate preview
                    }
                    break;
                case "name":
                case "fs":
                case "wf":
                case "image":
                case "nop":
                case "train":
                case "teams":
                case "milestone-state":
                    break;
                default:
                    if (op.name[0] === "#") {
                        if (op.name[1] === "R") {
                            // Resource or ReasonCode
                            if (op.name[2] === "W") {
                                // Work
                                const res_id = Number.parseInt(op.name.substring(3), 16);
                                let _v;
                                if (
                                    v &&
                                    "number" === typeof (_v = this.VALUE<number>(v)) &&
                                    _v >= 0 &&
                                    res_id >= 0 &&
                                    "string" === typeof this.VALUE<string>(this.trades[res_id]?.name, null)
                                ) {
                                    task._rw[res_id] = v;
                                } else {
                                    delete task._rw[res_id];
                                }
                            } else if (op.name[2] === "C") {
                                // must be like this
                            } else {
                                throw "TODO4.1";
                            }
                        } else if (op.name[1] === "T") {
                            // Task
                            if (op.name[2] === "R") {
                                // Relation / Dependencies
                                const task_id = Number.parseInt(op.name.substring(3), 16);
                                const target_task = this.tasks[task_id];
                                const rel_type = op.type || 0;
                                if (rel_type > 0) {
                                    assert(!task._rp[task_id] || task._rp[task_id].depTs === i_op);
                                    delete task._rp[task_id];
                                    assert(!target_task._rs[op.id] || target_task._rs[op.id].depTs === i_op);
                                    delete target_task._rs[op.id];
                                } else {
                                    assert(!task._rp[task_id]);
                                    assert(!target_task._rs[op.id]);
                                }
                                const _op_i = Array.isArray(v) ? v[0] : v;
                                const _rel_type = this.TYPE(v, 0);
                                if (_rel_type > 0) {
                                    const _op = this.ops[_op_i];
                                    assert(_op.id === op.id && "#TR" + task_id.toString(16) === _op.name);
                                    task._rp[task_id] = { type: _rel_type, depTs: _op_i };
                                    target_task._rs[op.id] = { type: _rel_type, depTs: _op_i };
                                } else {
                                    assert(undefined === _op_i || 0 === _rel_type);
                                }
                            } else {
                                throw "TODO4.2";
                            }
                        } else if (op.name[1] === "A") {
                            // Activity
                        } else if (op.name[1] === "C" || op.name[1] === "I") {
                            // Comment / action Item
                        } else if (op.name[1] === "t") {
                            // trains
                        } else {
                            throw "TODO4";
                        }
                    } else if (op.name.startsWith("lcmx.")) {
                        // extension, no action needed
                    } else {
                        throw "TODO5 " + op.name;
                    }
                    break;
            }
            if (DataOperationType.CREATE === op.op) {
                assert(undefined === task.p);
            }
        }
    }

    private _rollbackCard(this: DataModel, op: DataOperation, i_op: number) {
        assert(DataOperationTarget.CARD === op.target);
        const card = this.cards[op.id];
        if (card) {
            const ret = DataModel._undoDataValue(card, op.name, i_op, op.z);
            assert(ret.deleted === i_op);
            const v = ret.value;
            switch (op.name) {
                case "p":
                    {
                        const op_value = this.ops[i_op].value;
                        if (-1 !== op_value) {
                            const pt = this.tasks[op_value];
                            assert(undefined !== pt);
                            const _cd = pt._cd || [];
                            const i = DataModel._findCD(_cd, i_op);
                            assert(i >= 0 && _cd[i] === i_op);
                            _cd.splice(i, 1);
                            if ("release" !== process.env.NODE_ENV) {
                                for (let i = 1; i < pt._cd.length; i++) {
                                    assert(pt._cd[i - 1] < pt._cd[i]);
                                }
                            }
                        }
                        if (undefined !== v && !(Array.isArray(v) && 0 === v.length)) {
                            //add to previous task
                            const pop = Array.isArray(v) ? v[v.length - 1] : v;
                            const ptid = this.ops[pop].value;
                            if (-1 !== ptid) {
                                const pt = this.tasks[ptid];
                                assert(undefined !== pt);
                                pt._cd = pt._cd || [];
                                const _cd = pt._cd;
                                const i = DataModel._findCD(_cd, pop, true);
                                assert(
                                    i >= 0 &&
                                        (!(i > 0) || _cd[i - 1] < pop) &&
                                        (!(i + 1 < _cd.length) || pop < _cd[i + 1]),
                                );
                                pt._cd.splice(i, 0, pop);
                                if ("release" !== process.env.NODE_ENV) {
                                    for (let i = 1; i < pt._cd.length; i++) {
                                        assert(pt._cd[i - 1] < pt._cd[i]);
                                    }
                                }
                            }
                        }
                    }
                    break;
                case "text":
                case "date":
                    break;
                default:
                    break;
            }
            if (DataOperationType.CREATE === op.op) {
                assert(undefined === this.cards[op.id].p);
            }
        }
    }

    private _rollbackTrade(this: DataModel, op: DataOperation, i_op: number, _ts: number) {
        assert(DataOperationTarget.TRADE === op.target);
        const trade = this.trades[op.id];
        if (trade) {
            const ret = DataModel._undoDataValue(trade, op.name, i_op, op.z);
            assert(ret.deleted === i_op);
            this._updateTradeSubIfNeeded(op.id, trade, op.name);
            if (DataOperationType.CREATE === op.op) {
                delete this.trades[op.id];
            }
            this.tradesDirty = true;
            this._postprocessUpdateTrade(op, this.VALUE<string>(trade.name, null), _ts);
        }
    }

    private _rollbackNoop(this: DataModel, op: DataOperation, i_op: number) {}

    private _rollbackHive(this: DataModel, op: DataOperation, i_op: number) {
        const _ts = i_op;
        this._updateHivePath(op.name, _ts, (dst, n) => {
            DataModel._undoDataValue(dst, n, i_op, op.z);
            if ("com.lcmd.core.calendar.default" === n) {
                const cal = this.VALUE<any>(dst[n], null);
                this.setWorkingDayMeta(cal);
                this._update_x2 = true;
            } else {
                // TODO
            }
        });
    }

    private _updateActivity(this: DataModel, op: DataOperation, i_op: number, _ts: number) {
        // just reserve the id, its only valid in the TaskId context
        if (DataOperationType.CREATE === op.op) {
            // reserved new id
            this.maxIds[DataOperationTarget.ACTIVITY] = Math.max(this.maxIds[DataOperationTarget.ACTIVITY], op.id);
        }
        assert(undefined === op.name && undefined === op.value);
    }

    private _updateActivitiesTemplate(this: DataModel, op: DataOperation, i_op: number, _ts: number) {
        let atemplate: DataModelActivitiesTemplate = this.activitiesTemplates[op.id];
        if (DataOperationType.CREATE === op.op) {
            // create a template
            assert(!atemplate);
            this.maxIds[DataOperationTarget.ACTIVITIES_TEMPLATE] = Math.max(
                this.maxIds[DataOperationTarget.ACTIVITIES_TEMPLATE],
                op.id,
            );
            atemplate = { __: i_op };
            this.activitiesTemplates[op.id] = atemplate;
        } else {
            assert(atemplate ? true : false);
        }
        /*
        let relation:REMOVE_ME_DataModelRelation=this.relations[op.id];
        if (!relation) {
            relation={__: i_op};
            this.relations[op.id]=relation;
        }
        */
        DataModel._updateDataValue(atemplate, op.name, i_op, op.z, _ts);
    }

    private _updateTradeSubIfNeeded(trade_id, trade, name) {
        if (trade && name.length >= 28 && name.startsWith("S_")) {
            assert(trade === this.trades[trade_id]);
            const sub5 = UUID5.fromUUID5(name.substring(2));
            const sub = sub5.toUUID();
            const value = this.VALUE<any>(trade[name], null);
            let _sub = this.rbac[sub];
            if (!_sub) {
                _sub = {};
                this.rbac[sub] = _sub;
            }
            const _mobile = _sub?.mobile || {};
            const _trades = _mobile?.trades || [];
            const i_trades = _trades.indexOf(trade_id);
            if (value) {
                if (i_trades < 0) {
                    _trades.push(trade_id);
                }
            } else {
                if (i_trades >= 0) {
                    _trades.splice(i_trades, 1);
                }
            }
            _sub.mobile = _mobile;
            _mobile.trades = _trades;
        }
    }

    private _invalidateTradeTasks(task: DataModelTask, force?: boolean) {
        if (task) {
            Object.getOwnPropertyNames(task).forEach((n) => {
                if ("#" === n[0] && "R" === n[1] && "W" === n[2]) {
                    const trId = Number.parseInt(n.substring(3), 16);
                    const trade = this.trades[trId];
                    if (trade) {
                        task._ = Math.max(task._ || task.__, trade._ || trade.__);
                        if (force) {
                            task._canvasDirty = true;
                        }
                    }
                }
            });
        }
    }

    private _postprocessUpdateTrade(this: DataModel, op: DataOperation, prev_name: string, _ts: number) {
        assert(
            op.target === DataOperationTarget.TRADE &&
                (op.op === DataOperationType.UPDATE || op.op === DataOperationType.CREATE),
        );
        if ("name" === op.name && ("string" === typeof op.value) !== ("string" === typeof prev_name)) {
            // delete/undelete operation
            const trade = this.trades[op.id];
            if (trade) {
                const _del = "string" !== typeof this.VALUE<string>(trade.name, null);
                const taskIds = Object.getOwnPropertyNames(this.tasks);
                taskIds.forEach((tid) => {
                    const task = this.tasks[tid];
                    if (task) {
                        const n = "#RW" + op.id.toString(16);
                        if (_del) {
                            const _rw = task._rw && task._rw[op.id];
                            if (_rw) {
                                assert(task.__ < 0 || _rw === this.OP_I(task[n], -1));
                                if (_ts) {
                                    task._ = Math.max(task.__ || task._, _ts);
                                }
                                delete task._rw[op.id];
                            }
                        } else {
                            const rw_op = this.OP_I(task[n], -1);
                            let _op: DataOperation;
                            let value;
                            if (
                                rw_op >= 0 &&
                                "number" === typeof (value = (_op = this.ops[rw_op]).value) &&
                                value >= 0
                            ) {
                                if (_ts) {
                                    task._ = Math.max(task.__ || task._, _ts);
                                }
                                task._rw = task._rw || {};
                                task._rw[op.id] = rw_op;
                            }
                        }
                    }
                });
            }
            //console.log("UPDATE TASKS _RW!!!");
        } else {
            if (false === this.canvasTradesDirty) {
                this.canvasTradesDirty = op.id;
            } else if (-1 === this.canvasTradesDirty) {
                // forced
            } else if (true !== this.canvasTradesDirty) {
                this.canvasTradesDirty = true;
            } else {
                assert(true === this.canvasTradesDirty);
            }
        }
    }

    public syncPropsFrom(trid: number, from: DataModel, props: string[]): boolean {
        let ret = false;
        const fromNames = from._storageNames;
        const model = this;
        const trade = this.trades[trid];
        if (trade.__ >= model.syncCommited.ofs) {
            throw new FrameworkErrorDataModelNeedsSync();
        } else {
            const trop = model.ops[trade.__];
            assert(
                DataOperationTarget.TRADE === trop.target && DataOperationType.CREATE === trop.op && trid === trop.id,
            );
            if (trop.r_sid in fromNames) {
                const r_id = trop.r_id;
                assert("development" !== process.env.NODE_ENV || "number" === typeof r_id);
                if (r_id) {
                    assert(r_id <= from.maxIds[trop.target as number]);
                    const r_id_local = from._transformId(trop.target, r_id, false);
                    const _trade = from.trades[r_id_local];
                    if (_trade) {
                        assert((trop?.r_ts || 0) <= from.syncCommited.ofs);
                        //HACK!!! UPADTE-IN-PLACE
                        for (let i_prop = 0; i_prop < props.length; i_prop++) {
                            const name = props[i_prop];
                            const model_i_op = model.OP_I(trade[name], -1);
                            assert(model_i_op >= 0);
                            const from_op = from.OP(_trade[name]);
                            if (from_op && model.VALUE<string>(trade[name]) !== from_op.value) {
                                model.ops[model_i_op].value = from_op.value; //HACK!!! UPADTE-IN-PLACE
                                //model.ops[model_i_op]._r_ts=
                                //@TODO: update _
                                model.tradesDirty = true;
                                model.canvasTradesDirty = -1; // force...
                                ret = true;
                            }
                        }
                    }
                }
            }
        }
        return ret;
    }

    private _updateTrade(this: DataModel, op: DataOperation, i_op: number, _ts: number) {
        let allow_op = true;
        let trade: DataModelTrade = this.trades[op.id];
        const _u = i_op >= this.imported.ofs ? op._u : null;
        const u_rbac = _u ? this.rbac[_u] : null;
        const u_filter = u_rbac?.filter;
        const u_rules = u_rbac?.rules;
        if (u_rules) {
            allow_op = false; //@TODO
        }
        if (!trade) {
            assert(DataOperationType.CREATE === op.op);
            trade = { __: i_op };
            this.trades[op.id] = trade;
            this.maxIds[DataOperationTarget.TRADE] = Math.max(this.maxIds[DataOperationTarget.TRADE], op.id);
        }
        const prev_name = "name" === op.name ? this.VALUE<string>(trade.name, null) : null;
        DataModel._updateDataValue(trade, op.name, i_op, op.z, _ts);
        this._updateTradeSubIfNeeded(op.id, trade, op.name);
        this.tradesDirty = true;
        if (!allow_op) {
            op._rejected = 1;
        } else {
            if (DataOperationType.CREATE !== op.op) {
                this._postprocessUpdateTrade(op, prev_name, _ts);
            }
        }
    }

    private _updateComment(this: DataModel, op: DataOperation, i_op: number, _ts: number) {
        // just reserve the id
        if (DataOperationType.CREATE === op.op) {
            // reserve new id
            this.maxIds[DataOperationTarget.COMMENT] = Math.max(this.maxIds[DataOperationTarget.COMMENT], op.id);
        }
        assert(undefined === op.name && undefined === op.value);
    }

    static HIVE_REGEXP = /^((?<ref>(?<target>[A-Z])(?<hexId>[a-z0-9]+))|((?<name>[a-z0-9._]+)))*$/;
    static parseHivePath(pathStr: string) {
        const path = pathStr
            .split("#")
            .map((e, i_e) => (i_e > 0 ? e.match(DataModel.HIVE_REGEXP)?.groups || null : null));
        return path;
    }
    private _updateHivePath(pathStr: string, _ts: number, cb: (dst, n) => void) {
        const path = DataModel.parseHivePath(pathStr);
        // ensure hierarchy
        let dst = this.hive;
        let i_path = 1;
        for (; i_path + 1 < path.length; i_path++) {
            const n = path[i_path].name || path[i_path].ref;
            dst._ = Math.max(dst._ || 0, _ts);
            dst = dst[n] = dst[n] || {};
        }
        if (i_path < path.length) {
            const n = path[i_path].name || path[i_path].ref;
            dst._ = Math.max(dst._ || 0, _ts);
            cb(dst, n);
        }
    }

    private _updateHive(this: DataModel, op: DataOperation, i_op: number, _ts: number) {
        if (DataOperationType.CREATE === op.op) {
            // reserve new id
            this.maxIds[DataOperationTarget.HIVE] = Math.max(this.maxIds[DataOperationTarget.HIVE], op.id);
        }
        this._updateHivePath(op.name, _ts, (dst, n) => {
            DataModel._updateDataValue(dst, n, i_op, op.z, _ts);
            if ("com.lcmd.core.calendar.default" === n) {
                const cal = this.VALUE<any>(dst[n], null);
                this.setWorkingDayMeta(cal);
                this._update_x2 = true;
            } else if ("com.lcmdigital.rbac" === n) {
                const rbac_ops = this.OPS(dst[n]);
                for (let i_rbac_op = rbac_ops.length; i_rbac_op > 0; ) {
                    i_rbac_op--;
                    const rbac_op = rbac_ops[i_rbac_op];
                    const rule = rbac_op.value;
                    if (rule.t3 && rule.opt.sub) {
                        const sub = rule.opt.sub;
                        if (rule.opt.trades) {
                            const extendedRights = rule.opt.extendedRights;
                            let _sub = this.rbac[sub];
                            if (!_sub) {
                                _sub = {};
                                this.rbac[sub] = _sub;
                            }
                            const _filter = _sub?.filter || {};
                            const _trades = rule.opt.trades;
                            _filter.trades = _trades;
                            _sub.filter = _filter;
                            const a_decl = {
                                n: {
                                    regexp: "n",
                                },
                                m: {
                                    regexp: "m",
                                },
                                a: {
                                    regexp: "a",
                                },
                                s: {
                                    regexp: "s",
                                    values: {
                                        "0": true,
                                        "1": true,
                                        "2": Boolean(extendedRights),
                                        "3": true,
                                    },
                                },
                                wf: {
                                    regexp: "wf",
                                },
                                "lcmx.com.dreso.t3.messbarestagesziel": {
                                    regexp: "lcmx.com.dreso.t3.messbarestagesziel",
                                },
                                "lcmx.com.dreso.t3.demo.messbarestagesziel": {
                                    regexp: "lcmx.com.dreso.t3.messbarestagesziel",
                                },
                                comments: {
                                    regexp: "^#C"
                                }
                            };
                            const a_rexp_s = [
                                "^(",
                                Object.getOwnPropertyNames(a_decl)
                                    .map((rule) => [a_decl[rule].regexp].join(""))
                                    .join("|"),
                                ")(.*)$",
                            ].join("");
                            const a_rexp = new RegExp(a_rexp_s);
                            _sub.rules = {
                                a_decl: a_decl,
                                a_rexp: a_rexp,
                                stability: {
                                    regexp: new RegExp(
                                        "^lcmx\\.com\\.lcmd\\.core\\.settings\\.stability_criteria\\..+\\.criteria#?(.*)|#I\\d*#?(.*)$",
                                    ),
                                    properties: {
                                        action: Boolean(extendedRights),
                                        status: Boolean(extendedRights),
                                        actionBy: Boolean(extendedRights),
                                    },
                                    allowExactMatch: Boolean(extendedRights),
                                },
                                milestoneState: {
                                    regexp: new RegExp("milestone-state"),
                                    properties: { "milestone-state": Boolean(extendedRights) },
                                },
                                workforce: {
                                    regexp: new RegExp("^wf$"),
                                    properties: { wf: Boolean(extendedRights) },
                                },
                            };
                        } else {
                            delete this.rbac[sub];
                        }
                    }
                }
            } else if (op.name.startsWith("#lcmd#settings#")) {
                this._settings.uuid = undefined; // force refresh...
            }
        });
    }

    private static _findCD(_cd: IntId[], i_op: number, seek?: boolean) {
        let i = 0;
        let j = _cd.length;
        while (i < j) {
            const k = i + Math.floor((j - i) / 2);
            if (i_op < _cd[k]) {
                j = k;
            } else if (i_op > _cd[k]) {
                i = k + 1;
            } else {
                if (seek) {
                    return -1; // throw "ID already present";
                } else {
                    return k;
                }
            }
        }
        assert(i == j && (!(i > 0) || _cd[i - 1] < i_op) && (!(j + 1 < _cd.length) || i_op < _cd[j + 1]));
        if (seek) {
            return i;
        } else {
            return -1;
        }
    }

    private _updateCard(this: DataModel, op: DataOperation, i_op: number, _ts: number) {
        let card: DataModelCard = this.cards[op.id];
        if (!card || DataOperationType.CREATE === op.op) {
            assert(DataOperationType.CREATE === op.op);
            assert(!card || undefined === card.p); // inserted card had been rolled back via undo/redo
            card = { __: i_op };
            this.cards[op.id] = card;
            this.maxIds[DataOperationTarget.CARD] = Math.max(this.maxIds[DataOperationTarget.CARD], op.id);
        }
        const v = DataModel._updateDataValue(card, op.name, i_op, op.z, _ts);
        // update task meta
        if (0 === op.z) {
            switch (op.name) {
                case "p": {
                    if (DataOperationType.UPDATE === op.op) {
                        // remove
                        assert(Array.isArray(v));
                        const pop = v[v.length - 1];
                        const ptid = this.ops[pop].value;
                        const pt = this.tasks[ptid];
                        if (pt) {
                            const _cd = pt._cd;
                            const i = DataModel._findCD(_cd, pop);
                            assert(i >= 0 && _cd[i] === pop);
                            _cd.splice(i, 1);
                            pt._ = Math.max(pt._, i_op); // update timestampe
                        }
                    } else {
                        assert(DataOperationType.CREATE === op.op); //TODO: for UPDATE, e.g. card has been move to a new task?
                    }
                    const op_value = this.VALUE<number>(v);
                    if (-1 !== op_value) {
                        const pt = this.tasks[op_value];
                        assert(undefined !== pt);
                        pt._cd = pt._cd || [];
                        const _cd = pt._cd;
                        const i = DataModel._findCD(_cd, i_op, true);
                        assert(
                            i >= 0 && (!(i > 0) || _cd[i - 1] < i_op) && (!(i + 1 < _cd.length) || i_op < _cd[i + 1]),
                        );
                        pt._cd.splice(i, 0, i_op);
                        if ("release" !== process.env.NODE_ENV) {
                            for (let i = 1; i < pt._cd.length; i++) {
                                assert(pt._cd[i - 1] < pt._cd[i]);
                            }
                        }
                    }
                }
            }
            {
                // update task timestamp
                const p = this.VALUE<IntId>(this.cards[op.id].p, null);
                if (-1 !== p) {
                    const t = this.tasks[p];
                    if (t) {
                        this.tasks[p]._ = Math.max(t._, i_op);
                    }
                }
            }
        }
    }

    private _updateCallback: ((op: DataOperation, i_op: number, _ts: number, n_ops: number) => void)[] = [
        this._updateTasks,
        this._updateActivity,
        this._updateActivitiesTemplate,
        this._updateTrade,
        this._updateCard,
        this._updateComment,
        this._updateHive,
    ];

    public VALUE<T>(this: DataModel, v?: DataValue<T>, v0?: T | null): T | null {
        const i_op = Array.isArray(v) ? v[0] : v;
        if (/*"release"!==process.env.NODE_ENV && */ i_op < 0) {
            throw new Error("Requesting value of ATS op is invalid.");
        }
        return undefined === i_op ? v0 : (this.ops[i_op].value as T);
    }

    public UNIT(this: DataModel, v?: DataValue<number>, v0?: number): number {
        const i_op = Array.isArray(v) ? v[0] : v;
        if (/*"release"!==process.env.NODE_ENV && */ i_op < 0) {
            throw new Error("Requesting value of ATS op is invalid.");
        }
        return undefined === i_op ? v0 : (this.ops[i_op].unit as number) || v0;
    }

    public TYPE(this: DataModel, v?: DataValue<number>, v0?: number): number {
        const i_op = Array.isArray(v) ? v[0] : v;
        if (/*"release"!==process.env.NODE_ENV && */ i_op < 0) {
            throw new Error("Requesting value of ATS op is invalid.");
        }
        return undefined === i_op ? v0 : (this.ops[i_op].type as number) || v0;
    }

    public OPS_I<T>(this: DataModel, v?: DataValue<T>): number[] {
        return Array.isArray(v) ? v : undefined === v ? [] : [v];
    }

    // getOPSbyTimestamp
    public OPS<T>(this: DataModel, v?: DataValue<T>): DataOperation[] {
        const ret = (Array.isArray(v) ? v : undefined === v ? [] : [v])
            .filter((i_op) => i_op >= 0)
            .map((i_op) => {
                return this.ops[i_op];
            });
        return ret;
    }

    public getOPSbyTimestamp<T>(this: DataModel, v?: DataValue<T>): DataOperation[] {
        return this.OPS(...arguments);
    }

    public OP<T>(this: DataModel, v?: DataValue<T>): DataOperation | null {
        const ret = Array.isArray(v) ? this.ops[v[0]] : undefined === v ? null : this.ops[v];
        return ret;
    }

    public OP_I<T>(this: DataModel, v: DataValue<T>, v0: number): number | -1 {
        const ret = Array.isArray(v) ? v[0] : undefined === v ? v0 : v;
        return ret;
    }

    public VALUES<T>(this: DataModel, v?: DataValue<T>): T[] {
        const ret = (Array.isArray(v) ? v : undefined === v ? [] : [v]).map((i_op) => {
            return this.ops[i_op].value as T;
        });
        return ret;
    }

    public _pushOperation(op: DataOperation | DataOperation[], fixDate?: boolean) {
        if ("release" !== process.env.NODE_ENV) {
            const _ops = Array.isArray(op) ? op : [op];
            for (let i = 0; i < _ops.length; i++) {
                if (_ops[i]._ats) {
                    throw "NO ATS PLEASE!!!";
                }
            }
        }
        const d = fixDate ? Date.now() : 0;
        if (Array.isArray(op)) {
            const n = op.length;
            for (let i = 0; i < n; i++) {
                const _op = (op as DataOperation[])[i];
                if (fixDate && !_op._d) {
                    _op._d = Math.floor(d / 1000 / 60);
                }
                this.ops.push(_op);
            }
        } else {
            if (fixDate && !op._d) {
                op._d = Math.floor(d / 1000 / 60);
            }
            this.ops.push(op);
        }
        const _ops = Array.isArray(op) ? op : [op];
        const negativeDurationChanges: { data: DurationChangedData; ops: DataOperation[] } = {
            data: [],
            ops: [],
        };

        const positiveDurationChanges: { data: DurationChangedData; ops: DataOperation[] } = {
            data: [],
            ops: [],
        };

        const negativeStartDateChanges: { data: EventDateChangedDataValues; ops: DataOperation[] } = {
            data: [],
            ops: [],
        };
        for (let opLength = _ops.length - 1; opLength >= 0; opLength--) {
            const currentOp = _ops[opLength];
            // todo: refactor days and start emitter to be a function
            if (currentOp.target === DataOperationTarget.TASKS && currentOp.name === "start") {
                const operationsIndex =
                    this.tasks[currentOp.id] &&
                    (Array.isArray(this.tasks[currentOp.id].start)
                        ? this.tasks[currentOp.id].start[0]
                        : this.tasks[currentOp.id].start);
                const oldStartValue = operationsIndex >= 0 ? this.ops[operationsIndex].value : null;
                const newStartValue = currentOp.value;
                // this.eventQueue.set(currentOp, {name: "processes::startDateChanged", data: {value: {old: new Date(oldStartValue), new: new Date(newStartValue)}, currentOp}})
                if (oldStartValue != null && newStartValue > oldStartValue) {
                    console.log("wp::negativ change of startDate", {
                        old: new Date(oldStartValue),
                        new: new Date(newStartValue),
                    });
                    negativeStartDateChanges.data.push({
                        value: { old: new Date(oldStartValue), new: new Date(newStartValue) },
                        target: { id: currentOp.id, type: "process" },
                    });
                    negativeStartDateChanges.ops.push(currentOp);
                }
            }

            if (currentOp.target === DataOperationTarget.TASKS && currentOp.name === "days") {
                const operationsIndex =
                    this.tasks[currentOp.id] &&
                    (Array.isArray(this.tasks[currentOp.id].days)
                        ? this.tasks[currentOp.id].days[0]
                        : this.tasks[currentOp.id].days);
                const oldDaysValues = operationsIndex >= 0 ? this.ops[operationsIndex].value : null;
                const newDaysValue = currentOp.value;

                if (oldDaysValues != null && newDaysValue < oldDaysValues) {
                    positiveDurationChanges.data.push({
                        value: { old: oldDaysValues, new: newDaysValue },
                        target: { id: currentOp.id, type: "process" },
                    });
                    positiveDurationChanges.ops.push(currentOp);
                }

                if (oldDaysValues != null && newDaysValue > oldDaysValues) {
                    console.log("wp::negativ change of duration", { old: oldDaysValues, new: newDaysValue });
                    negativeDurationChanges.data.push({
                        value: { old: oldDaysValues, new: newDaysValue },
                        target: { id: currentOp.id, type: "process" },
                    });
                    negativeDurationChanges.ops.push(currentOp);
                }
            }
        }

        if (positiveDurationChanges.data.length > 0) {
            this.eventQueue.add({
                name: "processes::durationChanged::positiveEffect",
                data: positiveDurationChanges.data,
                ops: positiveDurationChanges.ops,
            });
        }

        if (negativeDurationChanges.data.length > 0) {
            this.eventQueue.add({
                name: "processes::durationChanged::negativeEffect",
                data: negativeDurationChanges.data,
                ops: negativeDurationChanges.ops,
            });
        }

        if (negativeDurationChanges.data.length > 0) {
            this.eventQueue.add({
                name: "processes::durationChanged::negativeEffect",
                data: negativeDurationChanges.data,
                ops: negativeDurationChanges.ops,
            });
        }

        if (negativeStartDateChanges.data.length > 0) {
            this.eventQueue.add({
                name: "processes::startDateChanged::negativeEffect",
                data: negativeStartDateChanges.data,
                ops: negativeStartDateChanges.ops,
            });
        }

        // here should be a good place to know that op has landed in data model
    }

    public pushOperation(op: DataOperation | DataOperation[], opt?: { ignoreReadonly?: boolean }) {
        if (0 === this.imported.ofs || !this.viewConst.readonly || opt?.ignoreReadonly) {
            const op_i = this.ops.length;
            this._pushOperation(op, true);
            if (this.imported.ofs > 0 && op_i >= this.imported.ofs) {
                assert(
                    !Array.isArray(op) || !op[op.length - 1].group || Array.isArray(op) || !(op as DataOperation).group,
                );
                assert(0 <= this.undoStackPos && this.undoStackPos <= this.undoStack.length);
                assert(op_i > 0 && !this.ops[op_i - 1].group);
                this.undoStack.splice(this.undoStackPos, this.undoStack.length - this.undoStackPos, op_i);
                this.undoStackPos++;
            }
        } else {
            if (this.callbacks.onReadonlyViolation) {
                this.callbacks.onReadonlyViolation();
            }
        }
    }

    public undo() {
        assert(0 <= this.undoStackPos && this.undoStackPos <= this.undoStack.length);
        if (this.undoStackPos > 0) {
            this.undoStackPos--;
            const op_i = this.undoStack[this.undoStackPos];
            if (op_i < this.imported.ofs) {
                throw "AHHHH";
            }
            const op = this.ops[op_i];
            this._pushOperation({
                op: DataOperationType.REJECT_ACCEPT,
                target: op.target,
                id: op_i,
                name: null,
                value: 1,
                z: 0,
                _u: this.userName,
                group: false,
            });
            return true;
        } else {
            return false;
        }
    }

    public redo() {
        assert(0 <= this.undoStackPos && this.undoStackPos <= this.undoStack.length);
        if (this.undoStackPos < this.undoStack.length) {
            const op_i = this.undoStack[this.undoStackPos++];
            if (op_i < this.imported.ofs) {
                throw "AHHHH";
            }
            const op = this.ops[op_i];
            this._pushOperation({
                op: DataOperationType.REJECT_ACCEPT,
                target: op.target,
                id: op_i,
                name: null,
                value: 0,
                z: 0,
                _u: this.userName,
                group: false,
            });
            return true;
        } else {
            return false;
        }
    }

    /*
    private _touchRel(rel:any, ts:number) { // alternative: check rp/rs timestamp in check dependencies...
        if (rel) {
            const _rel=Object.getOwnPropertyNames(rel);
            for(let i_rel=0;i_rel<_rel.length;i_rel++) {
                const relId=Number.parseInt(_rel[i_rel]);
                const t=this.tasks[relId];
                t._=Math.max(t._||0, ts);
            }
        }
    }
    */

    private _touchTarget(op: DataOperation, ts: number) {
        if (DataOperationType.NOOP !== op.op) {
            switch (op.target) {
                case DataOperationTarget.TASKS:
                    {
                        const t = this.tasks[op.id];
                        if (t) {
                            t._ = Math.max(t._ || 0, ts);
                            //this._touchRel(t._rp, ts);
                            //this._touchRel(t._rs, ts);
                        }
                    }
                    break;
                case DataOperationTarget.CARD:
                    {
                        const c = this.cards[op.id];
                        let t = undefined;
                        if (c) {
                            c._ = Math.max(c._ || 0, ts);
                            t = this.tasks[this.VALUE<IntId>(c.p)];
                        } else if ("p" === op.name) {
                            t = this.tasks[op.value];
                        }
                        if (t) {
                            t._ = Math.max(t._ || 0, ts);
                        }
                    }
                    break;
                case DataOperationTarget.TRADE:
                    {
                        const t = this.trades[op.id];
                        if (t) {
                            t._ = Math.max(t._ || 0, ts);
                            this.tradesDirty = true;
                        }
                    }
                    break;
                case DataOperationTarget.ACTIVITY:
                case DataOperationTarget.ACTIVITIES_TEMPLATE: /* TODO eventually touch all tasks assiciated with this target? */
                case DataOperationTarget.COMMENT:
                case DataOperationTarget.HIVE:
                    //noop
                    break;
                default:
                    throw "TODO!! " + JSON.stringify(op);
                    break;
            }
        }
    }

    private handleNOOP(i_op: number) {
        const op: DataOperation & any = this.ops[i_op];
        if (DataOperationType.NOOP === op.op) {
            if ("commit_sandbox" === op.cmd || "delete_sandbox" === op.cmd) {
                if (op.sid === this.storageName) {
                    //
                    if (this.callbacks.cmdCommitSandbox) {
                        this.callbacks.cmdCommitSandbox(op);
                    }
                }
            }
        }
    }

    private _enforceRBAC(op: DataOperation, _ts: number) {
        if (op._rejected) {
            const rejected_reason = (op as any)._rbac;
            const n_ops = this.ops.length;
            assert(op === this.ops[this.commited]);
            let i_rollback = this.commited;
            this._rollbackOp(i_rollback, _ts);
            this._rejectOpRBAC(this.ops[i_rollback], rejected_reason);
            while (i_rollback > 0 && this.ops[i_rollback - 1].group) {
                i_rollback--;
                this._rollbackOp(i_rollback, _ts);
                this._rejectOpRBAC(this.ops[i_rollback], rejected_reason);
            }
            while (this.ops[this.commited].group) {
                // skip group leftover
                this.commited++;
                assert(this.commited < n_ops);
                this._rejectOpRBAC(this.ops[this.commited], rejected_reason);
            }
        }
    }

    public static createIsWorkingDayHackMeta(meta: any): CanvasCalendarMeta {
        let ret = null;
        const slot: CanvasCalendarMetaException[][] = new Array(31 * 12);
        for (let i = 0; i < slot.length; i++) slot[i] = null;
        if (Array.isArray(meta?.calendar?.wd) && 7 === meta.calendar.wd.length) {
            if (Array.isArray(meta?.calendar?.exceptions)) {
                const ex = meta.calendar.exceptions;
                for (let i = 0; i < ex.length; i++) {
                    const _d0 = new Date(Array.isArray(ex[i].value) ? ex[i].value[0] : ex[i].value);
                    const _d1 = new Date(Array.isArray(ex[i].value) ? ex[i].value[1] : ex[i].value);
                    const d0 = EpochMStoEpochDays(_d0.getTime());
                    const d1 = EpochMStoEpochDays(_d1.getTime());
                    for (let i_d = d0; i_d <= d1; i_d++) {
                        const d = new Date(EpochDaystoEpochMS(i_d));
                        const _d = d.getUTCDate();
                        const _m = d.getUTCMonth();
                        const _y = d.getUTCFullYear();
                        const _s = _m * 31 + _d;
                        slot[_s] = slot[_s] || [];
                        if ("yearly" === ex[i].repeat) {
                            slot[_s].push({
                                d: _d,
                                m: _m,
                                y: null,
                            });
                        } else {
                            slot[_s].push({
                                d: _d,
                                m: _m,
                                y: _y,
                            });
                        }
                    }
                }
            }
            ret = {
                wd: meta.calendar.wd,
                slot: slot,
            };
        }
        if (!ret || false === ret.wd.reduce((ret, v) => ret || v, false) /* test all non-working days */) {
            if (6 !== meta && 7 !== meta) {
                meta = 5;
            }
            ret = {
                wd: [meta >= 7, true, true, true, true, true, meta >= 6],
                slot: slot,
            };
        }
        return ret;
    }
    public _isWorkingDayMeta = null;
    public getWorkingDayMeta() {
        return this._isWorkingDayMeta;
    }
    // @refactor: type correctly => number is wrong here and leads to confusion
    public setWorkingDayMeta(meta: any) {
        this._isWorkingDayMeta = DataModel.createIsWorkingDayHackMeta(meta);
        this.taskCalendarHack = isWorkingDayHack.bind(this._isWorkingDayMeta);
        if (this.gridIsWorkDay !== isDailyBoardGrid) {
            // update grid
            if (this.gridIsWorkDay === isWorkDayAll) {
                this.setGrid(isWorkDayAll);
            } else {
                this.setGrid(this.taskCalendarHack);
            }
        }
    }

    /*private _forensic={
        tid: 96696,
        _x1: [],
        _x2: []
    }*/

    private _update_x2 = false;

    /**
     * Handles all updates (own and synced)
     * @param importDone
     */
    public updateModel(importDone?: boolean) {
        assert(false === this._update_x2);
        const n_ops = this.maxCommit <= 0 ? this.ops.length : Math.min(this.ops.length, this.maxCommit);
        if (this.commited < n_ops) {
            if (0 === this.commited) {
                // ensure root task
                this.tasks[0] =
                    this.tasks[0] ||
                    ({
                        __: 0,
                        _c: [],
                        _x1: 0,
                        _x2: 0,
                        _y: 0,
                        __y: 0,
                    } as DataModelTask);
                const cfg = (this.ops[0] as any).cfg || {};
                if (7 === cfg?.calendar || 6 === cfg?.calendar) {
                    this.setWorkingDayMeta(cfg.calendar);
                } else {
                    this.setWorkingDayMeta(5);
                }
                this.setGrid(this.taskCalendarHack); // workdays only by default
                const ver = cfg?.ver || 0;
                this.compat.gpaFix = this.whiteboard && ver < 1 ? 5 /* old gpa start value are multiple of 5 */ : 1;
            }
            for (; this.commited < n_ops; this.commited++) {
                const op = this.ops[this.commited];
                const _sid = op._sid;
                if (_sid) {
                    assert("string" === typeof _sid);
                    if (_sid === this._storageName || _sid === this._activeSID) {
                        // duplicate
                        delete op._sid; // no longer needed
                    } else {
                        assert(!(_sid in this._storageNames)); // why is there a duplicate?
                        this._storageNames[_sid] = true;
                        this._activeSID = _sid;
                    }
                }
                if (DataOperationType.REJECT_ACCEPT === op.op) {
                    if (this.commited >= this.importFixed) {
                        const commited = this.commited;
                        // rollback
                        this.__rollback(op.id, commited);
                        let i = op.id;
                        for (; this.ops[i].group; i++) {
                            this.ops[i]._rejected = op.value;
                            this._touchTarget(this.ops[i], commited);
                        }
                        op._value = this.ops[i]._rejected;
                        this.ops[i]._rejected = op.value;
                        this._touchTarget(this.ops[i], commited);
                        // apply again
                        for (; this.commited < commited; this.commited++) {
                            const _op = this.ops[this.commited];
                            if (DataOperationType.REJECT_ACCEPT !== _op.op && !_op._rejected) {
                                this._updateCallback[_op.target].call(this, _op, this.commited, commited, n_ops);
                                this._enforceRBAC(_op, this.commited);
                            }
                        }
                    } else {
                        assert("_rejected" in this.ops[op.id]); // fixed on import: _fixMaxIdsHelper
                        //we can not really check this, since UNDO, REDO chains: this.ops[op.id]._rejected!==op.value
                    }
                } else if (op.op <= 0) {
                    this.handleNOOP(this.commited); // no op
                } else {
                    if (!op._rejected) {
                        this._updateCallback[op.target].call(this, op, this.commited, this.commited, n_ops);
                        this._enforceRBAC(op, this.commited);
                    }
                }
                if (this._update_x2) {
                    // needed when e.g. calendar changed...
                    const taskIds = Object.getOwnPropertyNames(this.tasks);
                    const n_taskIds = taskIds.length;
                    for (let i = 0; i < n_taskIds; i++) {
                        const tid = Number.parseInt(taskIds[i]);
                        const t = this.tasks[tid];
                        assert(t);
                        if (!Array.isArray(t._c) && t._x1 > MAX_TASK_DAYS) {
                            // only real tasks, no summary tasks
                            const _x2 = DataModel.CalcEpochEndDayMS(
                                this.taskCalendarHack,
                                t._x1,
                                this.VALUE<number>(t.days, t._days),
                                this.UNIT(t.days, 3 /* tasks */),
                            );
                            t._x2 = _x2;
                        }
                    }
                    this._updateRoot(this.tasks[0]);
                    this._update_x2 = false;
                }
                /*if (true && this._forensic) {
                    const t=this.tasks[this._forensic.tid];
                    if (t) {
                        if (0===this._forensic._x1.length || this._forensic._x1[this._forensic._x1.length-1].value!==t._x1) {
                            this._forensic._x1.push({
                                value: t._x1,
                                date: new Date(t._x1).toUTCString(),
                                i_op: this.commited,
                                _d: this.ops[this.commited]._d?new Date((this.ops[this.commited]._d*1000*60)).toString():null
                            });
                        }
                        if (0===this._forensic._x2.length || this._forensic._x2[this._forensic._x2.length-1].value!==t._x2) {
                            this._forensic._x2.push({
                                value: t._x2,
                                date: new Date(t._x2).toUTCString(),
                                i_op: this.commited,
                                _d: this.ops[this.commited]._d?new Date((this.ops[this.commited]._d*1000*60)).toString():null
                            });
                        }
                    }
                }*/
            }
        }
        assert(false === this._update_x2);
        if (importDone) {
            this.imported = { ofs: this.commited, maxIds: this.maxIdsCopy() };
            this.history = this.imported.ofs;
            this.REMOVE_ME_syncSent = this.imported.ofs;
            this.syncCommited = { ofs: this.commited };
            const ret = this.calcProjectStartEnd();
            if (ret.startDate > 0 && ret.startDate < ret.endDate) {
                this.startDate = ret.startDate;
                this.endDate = ret.endDate;
            }
        }
        if (
            this.hostModel &&
            this.gpaPreview?.enabled &&
            (this.gpaPreview.ts < this.commited ||
                (Array.isArray(this.gpaPreview.dep) &&
                    this.gpaPreview.dep.reduce(
                        (ret, taskId) =>
                            ret ||
                            (this.hostModel.tasks[taskId]?._ || this.hostModel.tasks[taskId]?.__ || 0) >=
                                this.gpaPreview.hostTS,
                        false,
                    )))
        ) {
            this.hostModel._updateGPAPreview(this);
            assert(this.gpaPreview.ts === this.commited);
        }
        if (this.callbacks.onUpdate) {
            this.callbacks.onUpdate(this);
        }

        this.eventQueue.forEach(({ name, data, ops }) => {
            assert(data.length === ops.length, "data and operations needs to be of same length");

            const dataToEmit: Parameters<CoreEvents[keyof CoreEvents]>[0] = [];

            ops.forEach((op, index) => {
                if (op._rejected) {
                    return;
                }

                const opId = this.ops.indexOf(op);
                dataToEmit.push({
                    // @ts-ignore
                    value: data[index].value,
                    target: data[index].target,
                    opId,
                });
            });

            if (dataToEmit.length > 0) {
                // @ts-ignore
                this.eventEmitter.emit(name, dataToEmit, name);
            }
        });
        this.eventQueue.clear();

        assert(0 === this.ops.length || !this.ops[this.ops.length - 1].group, "Invalid group flags");
    }

    public canvasStartDate: number = null;
    public canvasEndDate: number = null;
    public canvasStripes: CanvasStripePatchData[] = [];
    private canvasTasks: CanvasTaskData[] = [];
    private taskMetaData: WeakMap<DataModelTask, number> = new WeakMap<DataModelTask, number>();
    //private canvasTaktZones:(CanvasTaktZoneData)[]=[];
    private canvasCards: {
        _: number;
        s: {
            t: number;
            i: number;
            j: number;
        };
        name: string;
        cards: CanvasCardData[];
        y_max?: number;
        cells: {
            name: string;
            hSpan: number;
            vSpan: number;
        };
    }[] = null;
    private canvasTradesDirty: boolean | number = true;

    /*
    public resetCanvas() {
        this.canvasStartDate=null;
        this.canvasEndDate=null;
        this.canvasStripes=[];
        this.canvasTasks=[];
        this.canvasCards=[];
    }
    */

    private _gatherStripes(
        ctx: {
            C: CanvasViewConst;
            f: number;
            _f: string;
            extraLine: number;
            tfCB: (number) => boolean; // trade filter
            dfCB: (t: DataModelTask) => boolean; // date filter
            pfCB: (tid: number) => boolean; // process ids filter
            statusFilterCB: (processId: number) => boolean; // process status filter
            so: number[];
            stripes: CanvasGatherStripeData[];
            REMOVE_ME_stripeMap: { [key: number]: number };
            p: number[];
            e: number | null;
            l: number;
            minColsImage: number;
            showTaktzones: boolean;
        },
        sc: IntId,
        l: number,
        _filter: ((sc: IntId) => void) | null,
    ) {
        ctx.p.push(sc);
        const t = this.tasks[sc];
        const ignoreStripe = true === ctx.showTaktzones && null === this.VALUE(t.gpa, null);
        if (0 === sc || (Array.isArray(t._c) && t._c.length >= 0) || t._x1 > 0) {
            if (Array.isArray(t._c) && t._c.length > 0) {
                const n = t._c.length;
                for (let i = 0; i < n; ) {
                    const _t = this.tasks[t._c[i]];
                    let i_so;
                    if (false /* TODO: is this needed? */ && !(t._x1 > 0)) {
                        // task deleted
                        _t[ctx._f] = undefined;
                        i++;
                    } else if (
                        (!(Array.isArray(_t._c) && _t._c.length >= 0) && (i_so = ctx.so.indexOf(t._c[i])) >= 0) ||
                        (Array.isArray(_t._c) && 0 === _t._c.length)
                    ) {
                        _t[ctx._f] = undefined;
                        this._gatherStripes(
                            ctx,
                            t._c[i],
                            l + 1,
                            /*filter===sc?0:filter*/ null === _filter || _filter(sc) ? null : _filter,
                        );
                        i++;
                    } else if (Array.isArray(_t._c) && _t._c.length >= 0) {
                        // non-leaf
                        _t[ctx._f] = undefined;
                        this._gatherStripes(
                            ctx,
                            t._c[i],
                            l + 1,
                            /*filter===sc?0:filter*/ null === _filter || _filter(sc) ? null : _filter,
                        );
                        i++;
                    } else if (!ignoreStripe && (null === _filter || _filter(sc))) {
                        // leaf, try to find more...
                        //let _y=(ctx.stripes.length>0 && ctx.stripes[ctx.stripes.length-1].override?(ctx.stripes.length>1?ctx.stripes[ctx.stripes.length-2].y:0):0);
                        let _y = 0;
                        const override = ctx.so.indexOf(sc) >= 0;
                        let count = 0;
                        let weak_count = 0;
                        let filtered_ret: number = 0;
                        const _t_x2 = _t._x2; // (ctx.C.gpa.gpa?_t._x1+1:_t._x2);
                        let _x1;
                        let _x2;
                        if (
                            _t._x1 > 0 &&
                            (0 === ctx.f ||
                                (filtered_ret = !ctx.tfCB && !ctx.dfCB && !ctx.pfCB && !ctx.statusFilterCB ? 1 : 0) ||
                                (filtered_ret = this.taskMatchesFilter(_t, ctx.tfCB, ctx.dfCB, ctx.pfCB, ctx.statusFilterCB, t._c[i])))
                        ) {
                            ctx.REMOVE_ME_stripeMap[t._c[i]] = ctx.stripes.length;
                            _t[ctx._f] = ctx.stripes.length;
                            _y = Math.max(_y, _t._y);
                            if (filtered_ret > 0) {
                                _x1 = _t._x1;
                                _x2 = _t_x2;
                                count++;
                            } else {
                                weak_count++;
                            }
                        } else {
                            _t[ctx._f] = undefined;
                        }
                        if (null === ctx.e || ctx.e < _t_x2) {
                            ctx.e = _t_x2;
                        }
                        let _ = Math.max(t._, _t._);
                        let __t;
                        let j = i + 1;
                        while (
                            j < n &&
                            !(Array.isArray((__t = this.tasks[t._c[j]])._c) && __t._c.length >= 0) &&
                            ctx.so.indexOf(t._c[j]) < 0
                        ) {
                            if (
                                __t._x1 > 0 &&
                                (0 === ctx.f ||
                                    (filtered_ret = !ctx.tfCB && !ctx.dfCB && !ctx.pfCB && !ctx.statusFilterCB? 1 : 0) ||
                                    (filtered_ret = this.taskMatchesFilter(__t, ctx.tfCB, ctx.dfCB, ctx.pfCB, ctx.statusFilterCB, t._c[j])))
                            ) {
                                ctx.REMOVE_ME_stripeMap[t._c[j]] = ctx.stripes.length;
                                __t[ctx._f] = ctx.stripes.length;
                                _y = Math.max(_y, __t._y);
                                _ = Math.max(_, __t._);
                                const __t_x2 = __t._x2; // (ctx.C.gpa.gpa?__t._x1+1:__t._x2);
                                if (null === ctx.e || ctx.e < __t_x2) {
                                    ctx.e = __t_x2;
                                }
                                if (filtered_ret > 0) {
                                    _x1 = Math.min(_x1, __t._x1);
                                    _x2 = Math.max(_x2, __t_x2);
                                    count++;
                                } else {
                                    weak_count++;
                                }
                            } else {
                                __t[ctx._f] = undefined;
                            }
                            j++;
                        }
                        if (count > 0) {
                            const y_start = ctx.stripes.length > 0 ? ctx.stripes[ctx.stripes.length - 1].y : 0;
                            const _y_end = y_start + _y + 1 + ctx.extraLine;
                            const y_end = Math.max(_y_end, y_start + ctx.minColsImage);
                            const grid: CalendarGridMapping[] = undefined;
                            ctx.stripes.push({
                                _: Math.max(
                                    _,
                                    ctx.p.reduce((ret, tid) => {
                                        const t = this.tasks[Math.abs(tid)];
                                        return Math.max(ret, t?._ || t?.__ || 0);
                                    }, 0),
                                ),
                                t: sc,
                                i: i,
                                j: j,
                                y: y_end,
                                l: 0 === i && n === j ? l : -l,
                                _p: ctx.p.slice(),
                                _s: ctx.p.map((t) => 0),
                                /*
                                _h: ctx.p.map(t=>this.VALUE<string>(this.tasks[t].name), ""),
                                _c: ctx.p.map((t)=>{
                                    const rw=Object.getOwnPropertyNames(this.tasks[t]._rw||{});
                                    let _c=0xA3A3A3;
                                    if (false && rw.length>0) {
                                        const tradeId=Number.parseInt(rw[0]);
                                        const op=this.ops[this.tasks[t]._rw[rw[0]]];
                                        const __c=this.VALUE<number>(this.trades[tradeId].color, _c);
                                        if ("number"===typeof(__c)) {
                                            _c=__c;
                                        }
                                    }
                                    return _c;
                                }),
                                */
                                /*
                                override: override?true:undefined,
                                overrideProps: override?{
                                    left: this.epochDateToProjectGrid(this.startDate, t._x1)*ctx.C.colPx,
                                    right: this.epochDateToProjectGrid(this.startDate, t._x2)*ctx.C.colPx
                                }:undefined,
                                */
                                _x1,
                                _x2,
                                grid,
                                image: getAttachmentUrl(this.VALUE<any>(t.image, undefined)),
                            });
                            ctx.l = Math.max(ctx.l, l);
                        } else if (weak_count > 0) {
                            // we optimistically commited the tasks, we need to clear them...
                            for (let k = i; k < j; k++) {
                                const t_k = this.tasks[t._c[k]];
                                if (undefined !== t_k[ctx._f]) {
                                    weak_count--;
                                    t_k[ctx._f] = undefined;
                                    delete ctx.REMOVE_ME_stripeMap[t._c[k]];
                                }
                            }
                            assert(0 === weak_count);
                        }
                        i = j;
                    } else {
                        _t[ctx._f] = undefined;
                        i++;
                    }
                }
            } else {
                const i_so = ctx.so.indexOf(sc);
                if (
                    !ctx.tfCB &&
                    !ctx.dfCB &&
                    !ignoreStripe &&
                    (null === _filter ||
                        _filter(sc)) /*true || Array.isArray(t._c) || i_so>=0*/ /* || Array.isArray(t._c)*/
                ) {
                    const _ = t._;
                    const y_start = ctx.stripes.length > 0 ? ctx.stripes[ctx.stripes.length - 1].y : 0;
                    const y_end = y_start + Math.max(1, ctx.minColsImage);
                    ctx.stripes.push({
                        _: Math.max(
                            _,
                            ctx.p.reduce((ret, tid) => {
                                const t = this.tasks[Math.abs(tid)];
                                return Math.max(ret, t?._ || t?.__ || 0);
                            }, 0),
                        ),
                        t: sc,
                        i: 0,
                        j: 0,
                        y: y_end,
                        l: l,
                        _p: ctx.p.slice(),
                        _s: ctx.p.map((t) => 0),
                        /*
                        _h: ctx.p.map(t=>this.VALUE<string>(this.tasks[t].name), ""),
                        _c: ctx.p.map((t)=>{
                            const rw=Object.getOwnPropertyNames(this.tasks[t]._rw||{});
                            let _c=0xA3A3A3;
                            if (false && rw.length>0) {
                                const tradeId=Number.parseInt(rw[0]);
                                const op=this.ops[this.tasks[t]._rw[rw[0]]];
                                const __c=this.VALUE<number>(this.trades[tradeId].color, _c);
                                if ("number"===typeof(__c)) {
                                    _c=__c;
                                }
                            }
                            return _c;
                        }),
                        */
                        /*
                        override: i_so>=0?true:undefined,
                        overrideProps: i_so>=0?{
                            left: this.epochDateToProjectGrid(this.startDate, t._x1)*ctx.C.colPx,
                            right: this.epochDateToProjectGrid(this.startDate, t._x2)*ctx.C.colPx
                        }:undefined,
                        */
                        _x1: t._x1,
                        _x2: t._x2,
                        image: getAttachmentUrl(this.VALUE<any>(t.image, undefined)),
                    });
                    ctx.l = Math.max(ctx.l, l + 1);
                }
            }
        }
        ctx.p.pop();
    }

    private _stripes = [null, null];
    public gatherStripesIfNeeded(f: 0 | 1) {
        const tfCB = this.stripesTradeFilterCB; // trade filter
        const dfCB = this.stripesDateFilterCB; // date filter
        const pfCB = this.pidsFilter; // pids filter
        if (null === this._stripes[f] || true === this._stripes[f]?.dirty) {
            //console.log(JSON.stringify(["gatherStripesIfNeeded", f]))
            // gather "stripe
            const ctx = {
                C: this.viewConst,
                f: f,
                _f: "_stripe" + f,
                tfCB: f > 0 ? tfCB : null,
                dfCB: f > 0 ? dfCB : null,
                pfCB: f > 0 ? pfCB : null,
                statusFilterCB: f > 0 ? this.statusFilter : null,
                extraLine: f > 0 && tfCB ? 0 : 1,
                so: f > 0 && !tfCB ? this.stripeOverride.slice() : [],
                dirty: false,
                stripes: [],
                REMOVE_ME_stripeMap: {},
                e: null,
                l: 1,
                p: [],
                l_min: 0,
                minColsImage: Math.ceil(this.viewConst.sidebarColImage / this.viewConst.rowPx),
                showTaktzones: this.viewConst.showTaktzones,
            };
            this._gatherStripes(ctx, 0, 0, f ? this.stripesFilter : null);

            if (ctx.showTaktzones && ctx.stripes.length > 0) {
                // bloody hack => reorder the stripes and update the "_stripe1" property as every task
                for (let i_stripe = ctx.stripes.length; i_stripe > 0; ) {
                    i_stripe--;
                    const y_prev = i_stripe > 0 ? ctx.stripes[i_stripe - 1].y : 0;
                    const h = ctx.stripes[i_stripe].y - y_prev;
                    ctx.stripes[i_stripe].y = h;
                    ctx.stripes[i_stripe]._i_stripe = i_stripe;
                }
                ctx.stripes = ctx.stripes.sort((s1, s2) => {
                    const t1 = this.tasks[s1.t];
                    const t2 = this.tasks[s2.t];
                    const t1_gpa = this.VALUE<DataModelGpaData>(t1?.gpa, null);
                    const t2_gpa = this.VALUE<DataModelGpaData>(t2?.gpa, null);
                    const t1_gpa_name = t1_gpa?.name || "";
                    const t2_gpa_name = t2_gpa?.name || "";
                    let ret = t1_gpa_name.localeCompare(t2_gpa_name);
                    if (0 === ret) {
                        ret = s1._i_stripe - s2._i_stripe;
                    }
                    return ret;
                });
                const helper = {};
                for (let i_stripe = 0; i_stripe < ctx.stripes.length; i_stripe++) {
                    const y_prev = i_stripe > 0 ? ctx.stripes[i_stripe - 1].y : 0;
                    ctx.stripes[i_stripe].y = y_prev + ctx.stripes[i_stripe].y;
                    helper[ctx.stripes[i_stripe]._i_stripe] = i_stripe;
                    delete ctx.stripes[i_stripe]._i_stripe;
                }
                Object.getOwnPropertyNames(this.tasks).forEach((_tid) => {
                    const task = this.tasks[_tid];
                    assert(ctx.REMOVE_ME_stripeMap[_tid] === task._stripe1);
                    const _stripe = helper[task._stripe1];
                    task._stripe1 = _stripe;
                    ctx.REMOVE_ME_stripeMap[_tid] = _stripe;
                });
            }

            // postprocess stripes
            ctx.l++;
            for (let i = 0; i < ctx.stripes.length; i++) {
                assert(0 < ctx.stripes[i]._p.length && ctx.stripes[i]._p.length <= ctx.l);
                const t = ctx.stripes[i]._p[ctx.stripes[i]._p.length - 1];
                while (ctx.stripes[i]._p.length < ctx.l) {
                    assert(t >= 0);
                    ctx.stripes[i]._p.push(-t);
                    ctx.stripes[i]._s.push(0);
                    //ctx.stripes[i]._h.push(null);
                    //ctx.stripes[i]._c.push(ctx.stripes[i]._c[ctx.stripes[i]._c.length-1]);
                }
            }
            // calc spans
            for (let i = ctx.stripes.length; i > 1; ) {
                i--;
                assert(
                    ctx.stripes[i - 1]._p.length === ctx.stripes[i - 1]._s.length &&
                        ctx.stripes[i]._p.length === ctx.stripes[i]._s.length,
                );
                for (
                    let j = 0;
                    j < ctx.stripes[i - 1]._p.length &&
                    ctx.stripes[i]._p.length &&
                    ctx.stripes[i - 1]._p[j] === ctx.stripes[i]._p[j];
                    j++
                ) {
                    ctx.stripes[i - 1]._s[j] = ctx.stripes[i]._s[j] + 1;
                    ctx.stripes[i]._s[j] = -1; // hack
                    //ctx.stripes[i]._h[j]=null; // hack
                }
            }
            // calc l_min
            if (false && null !== this.stripesFilter && ctx.stripes.length > 0) {
                const stripe0 = ctx.stripes[0];
                while (ctx.l_min + 1 < stripe0._s.length && stripe0._s[ctx.l_min] + 1 === ctx.stripes.length) {
                    ctx.l_min++;
                }
            } else {
                ctx.l_min = 1;
            }
            this._stripes[f] = ctx;
        }
        return this._stripes[f];
    }

    public stackTasksIfNeeded() {
        const stack =
            this.viewConst.stackProcesses; /* null!==this.stripesTradeFilterCB /* || null!==this.stripesDateFilterCB*/
        const ctx = this.gatherStripesIfNeeded(1);
        if (stack) {
            ctx.stack = true;
            const stripes = ctx.stripes;
            const n_stripes = stripes.length;
            let y_end = 0;
            for (let i_stripe = 0; i_stripe < n_stripes; i_stripe++) {
                const stripe = stripes[i_stripe];
                if (true) {
                    //@TODO check dirty flag
                    let y_max = 0;
                    const t = stripe.t;
                    const t_i = stripe.i;
                    const t_j = stripe.j;
                    const task = this.tasks[t];
                    const ca = task?._c;
                    if (Array.isArray(ca) && t_i < t_j && t_j <= ca.length) {
                        for (let i_c = t_i; i_c < t_j; i_c++) {
                            const c = this.tasks[ca[i_c]];
                            if (c && i_stripe === c._stripe1 && c._x1 > 0) {
                                // stack
                                c.__y = 0;
                                for (let j_c = t_i; j_c < i_c; ) {
                                    const _c = this.tasks[ca[j_c]];
                                    if (
                                        _c &&
                                        c._stripe1 === _c._stripe1 &&
                                        c.__y === _c.__y &&
                                        _c._x1 > 0 &&
                                        DataModel.taskIntersect(c, _c)
                                    ) {
                                        c.__y++;
                                        if (c.__y > y_max) y_max = c.__y;
                                        j_c = 0; // check again
                                    } else {
                                        j_c++;
                                    }
                                }
                            }
                        }
                    }
                    const y_start = y_end;
                    y_end += y_max + 1 + ctx.extraLine;
                    y_end = Math.max(y_end, y_start + (this.viewConst.sidebarColImage > 0 ? ctx.minColsImage : 0));
                    stripe._y = y_end;
                }
            }
        } else {
            ctx.stack = true;
            const stripes = ctx.stripes;
            const n_stripes = stripes.length;
            let y_end = 0;
            for (let i_stripe = 0; i_stripe < n_stripes; i_stripe++) {
                const stripe = stripes[i_stripe];
                if (true) {
                    //@TODO check dirty flag
                    const y_max = 0;
                    const t = stripe.t;
                    const t_i = stripe.i;
                    const t_j = stripe.j;
                    const task = this.tasks[t];
                    const ca = task?._c;
                    if (Array.isArray(ca) && t_i < t_j && t_j <= ca.length) {
                        for (let i = t_i; i < t_j; i++) {
                            const t = this.tasks[ca[i]];
                            const _iy = this.OP_I(t.stripey, t.__);
                            const __y = this.VALUE<number>(t.stripey, t._stripey || 0);
                            t._iy = _iy;
                            t.__y = __y;
                        }
                        const _ca = ca.slice(t_i, t_j).sort((tid1, tid2) => {
                            let ret;
                            const t1 = this.tasks[tid1];
                            const t2 = this.tasks[tid2];
                            if (0 === t1._x1 || 0 === t2._x1) {
                                ret = t1._x1 - t2._x1;
                                if (0 === ret) {
                                    ret = tid1 - tid2;
                                }
                            } else {
                                assert("development" !== process.env.NODE_ENV || (t1._x1 > 0 && t2._x1 > 0));
                                ret = t1.__y - t2.__y;
                                if (0 === ret) {
                                    ret = t1._iy - t2._iy;
                                }
                            }
                            return ret;
                        });
                        const n = _ca.length;
                        let pending = [];
                        let k_ca = 0;
                        while (k_ca < n && 0 === this.tasks[_ca[k_ca]]._x1) k_ca++;
                        assert(k_ca <= n);
                        let y = k_ca < n ? this.tasks[_ca[k_ca]].__y : 0;
                        while (pending.length > 0 || k_ca < n) {
                            let current = [];
                            const next = [];
                            const n_pending = pending.length;
                            let i_pending = 0;
                            let i_ca = k_ca;
                            while ((i_ca < n && this.tasks[_ca[i_ca]].__y === y) || i_pending < n_pending) {
                                let c;
                                if (
                                    i_pending < n_pending &&
                                    !(
                                        i_ca < n &&
                                        this.tasks[_ca[i_ca]].__y === pending[i_pending].__y &&
                                        this.tasks[_ca[i_ca]]._iy < pending[i_pending]._iy
                                    )
                                ) {
                                    c = pending[i_pending++];
                                } else {
                                    c = this.tasks[_ca[i_ca++]];
                                }
                                assert(c?.__y === y);
                                const n_current = current.length;
                                for (let i_current = 0; i_current < n_current; i_current++) {
                                    const _c = current[i_current];
                                    if (_c) {
                                        assert(
                                            c !== _c &&
                                                (undefined === c._stripe1 || i_stripe === c._stripe1) &&
                                                (undefined === _c._stripe1 || i_stripe === _c._stripe1) &&
                                                c.__y === _c.__y,
                                        );
                                        if (_c._x1 > 0 && DataModel.taskIntersect(c, _c)) {
                                            _c.__y++;
                                            _c._iy = c._iy;
                                            next.push(_c);
                                            current[i_current] = null;
                                        }
                                    }
                                }
                                current = current.filter((c) => c);
                                current.push(c);
                            }
                            k_ca = i_ca;
                            if (next.length > 0) {
                                y++;
                            } else if (k_ca < n) {
                                y = this.tasks[_ca[k_ca]].__y;
                            } else {
                                assert(!(next.length > 0 || k_ca < n));
                            }
                            pending = next;
                            if ("development" === process.env.NODE_ENV) {
                                for (let i = 1; i < pending.length; i++) {
                                    assert(pending[i - 1].__y === pending[i].__y);
                                    assert(pending[i - 1]._iy <= pending[i]._iy);
                                }
                                //console.log(JSON.stringify({y:y, pending:pending.length}));
                            }
                        }
                        const y_start = y_end;
                        y_end += y + 1 + ctx.extraLine;
                        y_end = Math.max(y_end, y_start + (this.viewConst.sidebarColImage > 0 ? ctx.minColsImage : 0));
                        stripe._y = y_end;
                    } else {
                        y_end += Math.max(1, ctx.minColsImage);
                        stripe._y = y_end;
                    }
                }
            }
        }
        return ctx;
    }

    private _updateStripes(ctx: any, force?: boolean) {
        const C = this.viewConst;
        const stripes: (CanvasGatherStripeData & { _y: number })[] = ctx.stripes;
        const n_stripes = stripes.length;
        const stripesPatch: (CanvasStripePatchData | number)[] = [];
        const canvasStripes = this.canvasStripes;
        let i_stripe = 0;
        let i_canvasStripe = 0;
        while (i_stripe < n_stripes) {
            const y0 = i_stripe > 0 ? (ctx.stack ? stripes[i_stripe - 1]._y : stripes[i_stripe - 1].y) : 0;
            const y = ctx.stack ? stripes[i_stripe]._y : stripes[i_stripe].y;
            while (
                i_canvasStripe < canvasStripes.length &&
                !(
                    canvasStripes[i_canvasStripe].t === stripes[i_stripe].t &&
                    canvasStripes[i_canvasStripe]._ === stripes[i_stripe]._ &&
                    canvasStripes[i_canvasStripe].i === stripes[i_stripe].i &&
                    canvasStripes[i_canvasStripe].j === stripes[i_stripe].j &&
                    canvasStripes[i_canvasStripe].e === y &&
                    canvasStripes[i_canvasStripe].l === stripes[i_stripe].l &&
                    canvasStripes[i_canvasStripe]._s.length === ctx.l &&
                    canvasStripes[i_canvasStripe]._s.length === stripes[i_stripe]._s.length &&
                    canvasStripes[i_canvasStripe]._s.reduce((r, v, i) => r && v === stripes[i_stripe]._s[i], true)
                )
            ) {
                if (stripesPatch.length > 0 && stripesPatch[stripesPatch.length - 1] < 0) {
                    (stripesPatch[stripesPatch.length - 1] as number)--;
                } else {
                    stripesPatch.push(-1);
                }
                canvasStripes.splice(i_canvasStripe, 1);
            }
            if (
                i_canvasStripe < canvasStripes.length &&
                canvasStripes[i_canvasStripe].t === stripes[i_stripe].t &&
                canvasStripes[i_canvasStripe]._ === stripes[i_stripe]._ &&
                canvasStripes[i_canvasStripe].i === stripes[i_stripe].i &&
                canvasStripes[i_canvasStripe].j === stripes[i_stripe].j &&
                canvasStripes[i_canvasStripe].e === y &&
                canvasStripes[i_canvasStripe].l === stripes[i_stripe].l &&
                canvasStripes[i_canvasStripe]._s.length === ctx.l &&
                canvasStripes[i_canvasStripe]._s.length === stripes[i_stripe]._s.length &&
                canvasStripes[i_canvasStripe]._s.reduce(
                    (r, v, i) => r && v === stripes[i_stripe]._s[i],
                    true,
                ) /*&& canvasStripes[i_canvasStripe].override===stripes[i_stripe].override */ &&
                canvasStripes[i_canvasStripe]._x1 === stripes[i_stripe]._x1 &&
                canvasStripes[i_canvasStripe]._x2 === stripes[i_stripe]._x2 &&
                true !== force
            ) {
                if (stripesPatch.length > 0 && stripesPatch[stripesPatch.length - 1] > 0) {
                    (stripesPatch[stripesPatch.length - 1] as number)++;
                } else {
                    stripesPatch.push(1);
                }
            } else {
                assert(
                    i_canvasStripe === canvasStripes.length ||
                        canvasStripes[i_canvasStripe]._ <
                            stripes[i_stripe]
                                ._ /* || canvasStripes[i_canvasStripe].override!==stripes[i_stripe].override */ ||
                        canvasStripes[i_canvasStripe]._x1 !== stripes[i_stripe]._x1 ||
                        canvasStripes[i_canvasStripe]._x2 !== stripes[i_stripe]._x2 ||
                        true === force,
                );
                const t = this.tasks[stripes[i_stripe].t];
                const task_gpa = this.VALUE<DataModelGpaData>(this.tasks[stripes[i_stripe].t].gpa, null);
                const gpa: CanvasGpaData = task_gpa ? { ...task_gpa } : undefined;
                const name = this.VALUE<string>(this.tasks[stripes[i_stripe].t].name, "");
                const _p = stripes[i_stripe]._p;
                const _s = stripes[i_stripe]._s;
                //const _h=_p.map(t=>this.VALUE<string>(this.tasks[t].name+(this.VALUE<DataModelGpaData>(this.tasks[t].gpa))), "");
                const _h = _p.map((_t, _i) => {
                    if (_t >= 0 && _s[_i] >= 0) {
                        const t = this.tasks[_t];
                        const name = this.VALUE<string>(t.name, "");
                        //const gpa=this.VALUE<DataModelGpaData>(t.gpa, null);
                        //return name+(gpa?(["[", gpa.name||"?", "]"].join('')):"");
                        return name;
                    } else {
                        return null;
                    }
                });
                const canvasStripe: CanvasStripePatchData = {
                    _: stripes[i_stripe]._,
                    t: stripes[i_stripe].t,
                    i: stripes[i_stripe].i,
                    j: stripes[i_stripe].j,
                    e: y,
                    l: stripes[i_stripe].l,
                    _p: _p,
                    _s: stripes[i_stripe]._s,
                    _h: _h,
                    _segs: null,
                    //_c: stripes[i_stripe]._c,
                    label: name,
                    _x1: stripes[i_stripe]._x1,
                    _x2: stripes[i_stripe]._x2,
                    _grid: stripes[i_stripe].grid,
                    _image: stripes[i_stripe].image,
                    _gpa: gpa,
                };
                const t_x = this.VALUE(t.x, undefined /* enable x when we are in gpa mode */);
                if (C.gpa.gpa) {
                    // set coordinates if we have x
                    if (stripes[i_stripe].t > 0) {
                        const _left = 0; // this.epochDateToProjectGrid(null, stripes[i_stripe]._x1);
                        const _right = this.epochDateToProjectGrid(null, stripes[i_stripe]._x2);
                        canvasStripe.x = t_x || 0;
                        canvasStripe.y = this.VALUE(t.y, 0);
                        canvasStripe.w = Math.max(
                            this.VALUE(t.w, C.gpa.defaultW),
                            C.gpa.minW,
                            (_right - _left) * C.colPx,
                        );
                        canvasStripe.h = Math.max(
                            this.VALUE(t.h, C.gpa.defaultH),
                            C.gpa.minH,
                            (y - y0 - 1) * C.rowPx + C.gpa.header.height,
                        );
                        //canvasStripe._segs=stripes[i_stripe]._h.map((h)=>(h?this.poorMansSegmentation(h, C):null)); //@TODO do we really need to segment any _h???
                        canvasStripe._segs = _h.map((h) =>
                            h ? this.textSegmentation(h, canvasStripe.w, C.gpa.header.height, C.gpa.padding, 0) : null,
                        );
                    } else {
                        canvasStripe.x = null;
                        canvasStripe.y = null;
                        canvasStripe.w = null;
                        canvasStripe.h = null;
                    }
                } else if (C.forceCanvas) {
                    canvasStripe.x = 0;
                    canvasStripe.y = y0 * C.rowPx;
                    canvasStripe.w = null; // needs grid...
                    canvasStripe.h = (y - y0) * C.rowPx;
                    canvasStripe._segs = _h.map((h) => (h ? this.poorMansSegmentation(h, C) : null)); //@TODO do we really need to segment any _h???
                }
                /*if (stripes[i_stripe].override) {
                    canvasStripe.override=true;
                    canvasStripe.overrideProps=stripes[i_stripe].overrideProps;
                }*/
                canvasStripes.splice(i_canvasStripe, 0, canvasStripe);
                stripesPatch.push(canvasStripes[i_canvasStripe]);
            }
            i_canvasStripe++;
            i_stripe++;
        }
        if (i_canvasStripe < canvasStripes.length) {
            const delta = canvasStripes.length - i_canvasStripe;
            stripesPatch.push(-delta);
            canvasStripes.splice(i_canvasStripe, delta);
        }
        return stripesPatch;
    }

    public setAllGrid() {
        this.grid = CalendarGrid.createAllGrid();
    }

    public updateGridBasedOnWorkDays(showNonWorkingDays: boolean) {
        if (showNonWorkingDays) {
            this.setGrid(isWorkDayAll);
        } else {
            this.setGrid(this.taskCalendarHack);
        }
    }

    public setGrid(isWorkDay: (date: Date) => boolean) {
        this.gridIsWorkDay = isWorkDay;
        this.grid = null;
    }

    private _updateGrid(taskIds: string[]) {
        if (
            null === this.grid ||
            EpochMStoEpochDays(this.filterX1X2?._x1 || 0) !== (this.grid?.view?.f?.d0 || 0) ||
            EpochMStoEpochDays(this.filterX1X2?._x2 || 0) !== (this.grid?.view?.f?.d1 || 0) ||
            this.viewConst?.label !== this.grid?.view?.key
        ) {
            this.grid = this.buildGrid(this.gridIsWorkDay);
            return true;
        } else {
            return false;
        }
    }

    public buildGrid(isWorkDay: (date: Date) => boolean = null) {
        if (2===this.viewConst?.colHeader) {
            return CalendarGrid.createNumberGrid(this.viewConst, this.filterX1X2);
        } else {
            return CalendarGrid.createDayGrid(
                this.canvasStartDate,
                this.canvasEndDate,
                isWorkDay,
                this.viewConst,
                this.filterX1X2,
            );
        }
    }

    private _settings = { uuid: undefined, global: {} as any, user: {} as any };
    private _getSettings() {
        const _settings = this._settings;
        if (_settings.uuid !== this.userName) {
            const _hive = this.hive?.lcmd?.settings || {};
            const uuid5 = UUID5.fromUUID(this.userName).toUUID5();
            _settings.global = _hive?.global || {};
            if (uuid5) {
                const uuidProp = ["U", uuid5.toLocaleLowerCase()].join("");
                _settings.user = (_hive || {})[uuidProp] || {};
            } else {
                _settings.user = {};
            }
        }
        return _settings;
    }

    private updateCanvasExtIfNeeded() {
        const cb = this.callbacks.onResolveExtValue;
        const ext = this.hive?.lcmd?.ext;
        if (ext && (this.viewConst.lcmx?._ || 0) !== (ext._ || 0)) {
            this.viewConst = {
                ...this.viewConst,
                lcmx: {
                    _: ext._,
                    menus: [],
                },
            };
            const exts = Object.getOwnPropertyNames(ext);
            for (let i_ext = 0; i_ext < exts.length; i_ext++) {
                const n = exts[i_ext];
                if ("_" !== n) {
                    const value: any = cb && cb(ext[n]);
                    this.viewConst.lcmx.menus = (value?.menu?.project || []).reduce((ret, menu) => {
                        ret.push({ ...menu, key: [n, menu.id].join(".") });
                        return ret;
                    }, this.viewConst.lcmx.menus);
                }
            }
        }
    }

    private _createSubStoragePatch(
        type: "wb",
        subStorageCanvas: {
            _: number;
            active: any;
            activeId: string;
        },
    ) {
        let patch = undefined;
        const ss = (this.hive?.lcmd || {})[type];
        if (subStorageCanvas._ !== (ss?._ || 0)) {
            subStorageCanvas._ = ss?._ || 0;
            let active = null;
            let activeId = null;
            const items = Object.getOwnPropertyNames(ss || {})
                .map((uuid5) => {
                    if (27 === uuid5.length && "U" === uuid5[0]) {
                        const s = ss[uuid5];
                        const id = UUID5.fromUUID5(uuid5.substring(1).toUpperCase()).toUUID();
                        if (id === subStorageCanvas.activeId) {
                            activeId = id;
                            active = s;
                        }
                        return {
                            id: id,
                            name: this.VALUE<string>(s.name, null),
                        };
                    } else {
                        return null;
                    }
                })
                .filter((w) => "string" === typeof w?.name);
            subStorageCanvas.activeId = activeId;
            subStorageCanvas.active = active;
            patch = { items, active: activeId };
        }
        return patch;
    }

    public static _setWhiteboardViewConst(model: DataModel, readonly: boolean) {
        const titleHeight = 52;
        const padding = 0;
        const between2 = 9;
        model.viewConst = {
            ...model.viewConst,
            readonly: readonly,
            label: "wb",
            colHeader: 2,
            colPx: (122 + 2 * between2) / 5,
            rowPx: 122 + 2 * between2,
            fonts: FONTS,
            grid: 5,
            gpa: {
                gpa: true,
                minW: 122 + 18,
                minH: titleHeight,
                defaultW: 122 + 2 * between2,
                defaultH: 122 + 2 * between2 + titleHeight,
                padding: between2,
                header: {
                    height: titleHeight,
                    font: 0,
                    textColor: "#2C3032",
                },
                border: {
                    style: "#D0D4D6",
                    width: 2,
                },
                canvasColor: "#F1F3F3",
                backgroundColor: "white",
                selected: {
                    primary: {
                        style: "black",
                        width: 2,
                    },
                    secondary: {
                        style: "black",
                        width: 2,
                    },
                    handle: {
                        style: "black",
                        width: 4,
                    },
                },
                task: {
                    border: {
                        style: "rgba(0, 0, 0, 0.08)",
                        width: 2,
                    },
                    label: {
                        padding: 10,
                        font: CANVAS_FONT,
                        black: "black",
                        white: "white",
                    },
                },
                pointerFont: 1,
                grid: {
                    small: {
                        style: "#E1E4E6",
                        width: 1,
                    },
                    strong: {
                        style: "#E1E4E6",
                        width: 2,
                    },
                },
            },
        } as any;
    }

    public async _setSubStorageActive(
        type: "wb",
        subStorageCanvas: { _: number; active: any; activeId: string; model: DataModel; rt: RT },
        id: string,
        master_token: string,
        readonly: boolean,
    ) {
        let _ret: boolean | Error = false;
        if (subStorageCanvas.model && subStorageCanvas.model?.gpaPreview?.enabled) {
            subStorageCanvas.model.gpaPreview.enabled = false;
            this._updateGPAPreview(subStorageCanvas.model);
        }
        if (subStorageCanvas.rt) {
            subStorageCanvas.rt.disconnect();
            subStorageCanvas.rt = null;
        }
        const _id = id ? "U" + UUID5.fromUUID(id).toUUID5().toLowerCase() : null;
        const s = _id ? ((this.hive?.lcmd || {})[type] || {})[_id] : null;
        if (s) {
            let model: DataModel = null;
            try {
                const ret = await DataModel.fetchStreamOps(master_token, id, 0);
                const ops: DataOperation[] = ret.ops;
                if (ops.length > 0) {
                    model = DataModel.loadOps(ops, true, { whiteboard: true });
                    model.callbacks.onCanvasProcessUnchanged = this.callbacks.onCanvasProcessUnchanged;
                    model.callbacks.onCanvasProcessChanged = this.callbacks.onCanvasProcessChanged;
                    model.callbacks.onCanvasProcessUpdateCtx = this.callbacks.onCanvasProcessUpdateCtx;
                    DataModel._setWhiteboardViewConst(model, readonly);
                    model.setStorageName(id);
                    model.hostModel = this;
                    model.userName = this.userName;
                    if (this.callbacks.onUpdate) {
                        const n_ops = this.ops.length;
                        this.callbacks.onUpdate(this, model); // calls _onWBSync, can change canvas.model.canvasWhiteboards.model will be updated below, see (WBUPDATE)
                        assert(n_ops === this.ops.length, "concurrent update");
                        model.updateModel();
                    }
                    subStorageCanvas.active = s;
                    subStorageCanvas.activeId = id;
                    subStorageCanvas.model = model;
                    subStorageCanvas.rt = new RT();
                    _ret = true;
                } else {
                    assert(false === _ret);
                }
            } catch (e) {
                _ret = e;
                assert(true !== _ret);
            }
        } else {
            assert(false === _ret);
        }
        if (true !== _ret) {
            assert(null === subStorageCanvas.rt); // should have been cleared above...
            subStorageCanvas.active = null;
            subStorageCanvas.activeId = null;
            subStorageCanvas.model = null;
            subStorageCanvas.rt = null;
        }
        subStorageCanvas._ = -1; // force update
        return _ret;
    }

    public async _cloneAndSetSubstorageActive(
        type: "wb",
        clone: { sid: string; ofs: number },
        subStorageCanvas: { _: number; active: any; activeId: string; model: DataModel; rt: RT },
        master_token: string,
        readonly: boolean,
    ) {
        const ret = await this.createWhiteboard(master_token, {}, clone);
        if (ret?.id) {
            await this._setSubStorageActive("wb", subStorageCanvas, ret.id, master_token, readonly);
        }
    }

    public _setWhiteboardState(ops: DataOperation[], id: string, state: LCMDContextWhiteboardState) {
        const type = "wb";
        const wbId = ["U", UUID5.fromUUID(id).toUUID5().toLowerCase()].join("");
        if (undefined !== state?.name?.value) {
            const name = state.name.value || "";
            ops.push({
                op: DataOperationType.UPDATE,
                target: DataOperationTarget.HIVE,
                id: -1,
                z: 0,
                _u: this.userName,
                group: true,
                name: ["#lcmd#", type, "#", wbId, "#name"].join(""),
                value: name,
            });
        }
        return ops;
    }

    private poorMansCanvas = null;
    private poorMansSegmentation(text: string, C: any) {
        const ret: (string | number)[] = [0]; // font 0 by default
        if (null === this.poorMansCanvas) {
            try {
                this.poorMansCanvas = new (self as any).OffscreenCanvas(C.colPx, C.rowPx);
                //this.poorMansCanvas.style.fontKerning="none";
                //this.poorMansCanvas.style.textRendering="geometricPrecision";
                const ctx = this.poorMansCanvas.getContext("2d");
                ctx.font = CANVAS_FONT.emfont;
                ctx.fillStyle = "black";
            } catch (e) {
                this.poorMansCanvas = false; // disable for good
            }
        }
        if (this.poorMansCanvas) {
            const segments = text.split(" "); // poor mans segmentation
            const n_segments = segments.length;
            const ctx = this.poorMansCanvas.getContext("2d");
            // measure generate segments
            for (let i_seg = 0; i_seg < n_segments; i_seg++) {
                const s = segments[i_seg] + (i_seg + 1 < n_segments ? " " : "");
                const m = ctx.measureText(s);
                ret.push(m.width, s);
            }
            if (false) {
                const text = "Hello World";
                const m0 = ctx.measureText(text);
                const fs2 = 12 * 2;
                const font = CANVAS_FONT.font.replace("{sz}", (fs2 / 2).toString());
                ctx.font = font;
                const m1 = ctx.measureText(text);
                const ascentEmu = Math.floor((CANVAS_FONT.emascent * fs2 * 6350) / CANVAS_FONT.emsquare);
                const descentEmu = Math.floor((CANVAS_FONT.emdescent * fs2 * 6350) / CANVAS_FONT.emsquare);
                const ascentPx = (ascentEmu / 914400) * 96;
                const descentPx = (descentEmu / 914400) * 96;
                console.log("AHHH");
            }
        } else {
            ret.push(Number.MAX_SAFE_INTEGER);
            ret.push(text);
        }
        return ret;
    }

    private textSegmentation(text: string, w: number, h: number, p: number, fs: number) {
        const ret: (string | number)[] = [];
        const m = DataModel.renderer.measureTextEx(text, Math.abs(fs || 24), w - p - p, h - p - p, 0, fs <= 0);
        ret.push(m.fs);
        ret.push(m.lineAscent);
        ret.push(m.lineDescent);
        const lc = m.lines.length;
        for (let i = 0, ofs = 0; i < lc; i++) {
            const l = m.lines[i];
            ret.push(l.black_width);
            ret.push(text.substring(ofs, l.end).trimEnd());
            ofs = l.end;
        }
        return ret;
    }

    private calcProjectStartEnd() {
        let _startDate: EpochDate = null === this.startDate ? Number.MAX_SAFE_INTEGER : this.startDate; // start date
        let _endDate: EpochDate = null === this.endDate ? Number.MIN_SAFE_INTEGER : this.endDate; // end date
        Object.getOwnPropertyNames(this.tasks).forEach((t) => {
            const task = this.tasks[t];
            const ret = undefined !== task._stripe1;
            if (ret && 0 < task._x1 && task._x1 < _startDate) _startDate = task._x1;
            if (ret && 0 < task._x2 && task._x2 > _endDate) _endDate = task._x2;
        });
        return {
            startDate: _startDate,
            endDate: _endDate,
        };
    }

    public updateCanvas(force?: boolean) {
        this.updateModel();
        const canvasTradesDirty = this.canvasTradesDirty;
        this.canvasTradesDirty = false;
        /*
        if (this.ops.length>0 && DataOperationType.NOOP===this.ops[this.ops.length-1].op && "commit_sandbox"===(this.ops[this.ops.length-1] as any).cmd) {
            // SANDBOX has been commited...
            throw new Error("commit_sandbox");
        }
        */
        let gpaDirty: boolean = undefined;
        if (force || this.gpaDirty !== this.canvasGpaDirty) {
            gpaDirty = this.gpaDirty;
            this.canvasGpaDirty = this.gpaDirty;
        }
        this.updateCanvasExtIfNeeded();
        let viewConstPatch = undefined;
        if (this.viewConst !== this.canvasViewConst) {
            this.canvasViewConst = this.viewConst;
            viewConstPatch = this.canvasViewConst;
            force = true;
            this.grid = null; // force grid...
            for (let i_stripe = 0; i_stripe < this._stripes.length; i_stripe++) {
                if (this._stripes[i_stripe]) {
                    this._stripes[i_stripe].dirty = true;
                }
            }
        }
        const C = this.viewConst;
        let viewMetaPatch = undefined;
        if (this.viewMeta.view !== this.canvasViewMeta) {
            this.canvasViewMeta = this.viewMeta.view;
            viewMetaPatch = this.canvasViewMeta;
        }
        const M = this.canvasViewMeta;

        const gridHelper = this.grid;
        this.grid = "GRID INVALID" as any; //DEBUG
        const ctx = this.stackTasksIfNeeded();
        // tasks
        let _startDate: EpochDate = null === this.startDate ? Number.MAX_SAFE_INTEGER : this.startDate; // start date
        let _endDate: EpochDate = null === this.endDate ? Number.MIN_SAFE_INTEGER : this.endDate; // end date
        const taskIds = Object.getOwnPropertyNames(this.tasks).filter((t) => {
            const task = this.tasks[t];
            let i_gpa_op: number;
            let gpa_op: DataOperation;
            let gpa_ts;
            const m = this.canvasWhiteboards.model;
            if (
                false /* TODO FIX ME */ &&
                !this.gpaDirty &&
                m &&
                (i_gpa_op = this.OP_I(task.gpa, -1)) >= 0 &&
                (gpa_op = this.ops[i_gpa_op]).r_sid in m._storageNames &&
                (gpa_ts = gpa_op._r_ts || gpa_op.r_ts || 0) < m.commited
            ) {
                assert(m.storageName === this.canvasWhiteboards.activeId);
                const _task = m.tasks[gpa_op.r_id];
                const _r_ts = Math.max(m.OP_I(_task.name, -1) /*, m.OP_I(_task.color, -1)*/);
                if (_r_ts >= gpa_ts) {
                    const _value: DataModelGpaData = gpa_op.value;
                    const name = m.VALUE<string>(_task.name);
                    const color = undefined;
                    if (_value.name !== name || _value.color !== color) {
                        this.gpaDirty = true;
                    }
                }
                gpa_op._r_ts = m.commited;
            }
            assert((undefined !== ctx.REMOVE_ME_stripeMap[t]) === (undefined !== task._stripe1));
            const ret = undefined !== task._stripe1;
            if (ret && 0 < task._x1 && task._x1 < _startDate) _startDate = task._x1;
            if (ret && 0 < task._x2 && task._x2 > _endDate) _endDate = task._x2;
            if (false !== canvasTradesDirty) {
                this._invalidateTradeTasks(task, -1 === canvasTradesDirty);
            }
            return ret;
        });
        if (_startDate > _endDate) {
            _startDate = EpochDaystoEpochMS(C.today);
            _endDate = _startDate;
        }
        if (_startDate === _endDate) {
            // 5 days min..
            _endDate = DataModel.CalcEpochEndDayMS(this.taskCalendarHack, _startDate, 5, 3 /*days*/);
        }
        if (C.forceCanvas || 2 === C.colHeader) {
            // sort according to stripes from whiteboard
            taskIds.sort((tid1, tid2) => {
                const t1 = this.tasks[tid1];
                const t2 = this.tasks[tid2];
                let ret = t1._stripe1 - t2._stripe1;
                if (0 === ret) {
                    ret = Number.parseInt(tid1) - Number.parseInt(tid2);
                }
                return ret;
            });
        }
        const _settings = this._getSettings();
        const userCtx = this.callbacks.onUpdateCanvas && this.callbacks.onUpdateCanvas(this, undefined, taskIds);
        this.grid = gridHelper; // DEBUG
        if (_startDate !== this.canvasStartDate || _endDate !== this.canvasEndDate) {
            this.canvasStartDate = _startDate;
            this.canvasEndDate = _endDate;
            force = true;
            this.grid = null;
        }
        let gridPatch = undefined;
        if (this._updateGrid(taskIds)) {
            this.grid.ts = ++this.grid_ts;
            force = true;
            gridPatch = {
                ts: this.grid.ts,
                grid: this.grid.grid,
                view: this.grid.view,
                meta: this._isWorkingDayMeta,
            };
        }
        const onCanvasProcessUnchanged = this.callbacks.onCanvasProcessUnchanged;
        const onCanvasProcessChanged = this.callbacks.onCanvasProcessChanged;
        const onCanvasProcessUpdateCtx = this.callbacks.onCanvasProcessUpdateCtx;
        const stripesPatch = this._updateStripes(ctx, force);
        const n_taskIds = taskIds.length;
        const canvasTasks = this.canvasTasks;
        const taskPatch: (CanvasTaskData | number)[] = [];
        let i_taskIds = 0;
        let i_canvasTask = 0;
        while (i_taskIds < n_taskIds || i_canvasTask < canvasTasks.length) {
            const taskId = i_taskIds < n_taskIds ? Number.parseInt(taskIds[i_taskIds]) : null;
            const task: DataModelTask = i_taskIds < n_taskIds ? this.tasks[taskIds[i_taskIds]] : null;
            const canvasDirty = task?._canvasDirty;
            if (canvasDirty) {
                delete task._canvasDirty; // clear flag
            }
            while (
                i_canvasTask < canvasTasks.length &&
                (i_taskIds === n_taskIds || canvasTasks[i_canvasTask].id < taskId)
            ) {
                if (taskPatch.length > 0 && taskPatch[taskPatch.length - 1] < 0) {
                    (taskPatch[taskPatch.length - 1] as number)--;
                } else {
                    taskPatch.push(-1);
                }
                canvasTasks.splice(i_canvasTask, 1);
                this.taskMetaData.delete(task);
                //i_canvasTask++;
            }
            if (i_taskIds < n_taskIds) {
                const t_ = task._ || task.__;
                if (
                    "release" !== process.env.NODE_ENV &&
                    !(
                        i_canvasTask === canvasTasks.length ||
                        canvasTasks[i_canvasTask].id !== taskId ||
                        canvasTasks[i_canvasTask]._ <= t_
                    )
                ) {
                    console.error(
                        "UPDATE ERROR" +
                            JSON.stringify({
                                snap: this._snap(),
                                task0: canvasTasks[i_canvasTask],
                                task1: task,
                                op0: this.ops[canvasTasks[i_canvasTask]._],
                                op1: this.ops[t_],
                            }),
                    );
                }
                assert(
                    i_canvasTask === canvasTasks.length ||
                        canvasTasks[i_canvasTask].id !== taskId ||
                        canvasTasks[i_canvasTask]._ <= t_,
                );
                //const stripe=this.VALUE<number>((task as any).stripe);
                assert(ctx.REMOVE_ME_stripeMap[taskId] === task._stripe1);
                const stripe = task._stripe1;
                const e0 = stripe > 0 ? this.canvasStripes[stripe - 1].e : 0;
                const top = (e0 + (ctx.stack ? task.__y : task._y)) * this.viewConst.rowPx;
                const dep = task._dc;
                const deps: any = task._rs || task._rp ? { rs: task._rs, rp: task._rp } : null;
                const p = this.VALUE<number>(task.p, -1);
                // debugger;
                // const trade=this.VALUE<number>(task.trade);
                /*
                const green=0xB4C85D;
                const darkGreen=0x90A04A;
                const green_hsl=rgbToHsl((green>>16)&0xFF, (green>>8)&0xFF ,(green>>0)&0xFF);
                const darkGreen_hsl=rgbToHsl((darkGreen>>16)&0xFF, (darkGreen>>8)&0xFF ,(darkGreen>>0)&0xFF);

                const blue_hsl=rgbToHsl((74)&0xFF, (144)&0xFF ,(226)&0xFF);
                const darkBlue_hsl=rgbToHsl((59)&0xFF, (115)&0xFF ,(181)&0xFF);
                */

                const vt_dirty = task.__ < -1;
                if (vt_dirty) {
                    /* >=0 "normal task", ==-1 "virtual task", <-1 "virtual task with dirty flag" */
                    task.__ = -1; // clear dirty flag
                }
                /*
                const rw=Object.getOwnPropertyNames(task._rw||{});
                let _c=0xB4C85D;
                if (rw.length>0) {
                    const tradeId=Number.parseInt(rw[0]);
                    const op=this.ops[task._rw[rw[0]]];
                    const __c=this.VALUE<number>(this.trades[tradeId].color, _c);
                    if ("number"===typeof(__c)) {
                        _c=__c;
                    }
                }
                */
                //const _c=this.VALUE<number>(this.trades[trade]?.color, 0xB4C85D);
                //const _c=0xB4C85D;
                //const color="#"+_c.toString(16).padStart(6, '0');
                //const _hsl=rgbToHsl((_c>>16)&0xFF, (_c>>8)&0xFF ,(_c>>0)&0xFF);
                //const hsl="hsl("+_hsl.join(',')+")";
                //const _l=Number.parseFloat(_hsl[2]);
                //const l=Math.min(100, _l+10);
                //const hsl1="hsl("+[_hsl[0], _hsl[1], l+"%"].join(',')+")";
                //const hsl="hsl("+[_hsl[0], _hsl[1], "40%"].join(',')+")";
                //const hsl1="hsl("+[_hsl[0], _hsl[1], "80%"].join(',')+")";

                //const _rgb=[(_c>>16)&0xFF, (_c>>8)&0xFF ,(_c>>0)&0xFF];
                //const hsl="rgb("+_rgb.join(',')+")";
                //const hsl1="rgb("+_rgb.map((v)=>{
                //    return Math.min(Math.max(0, Math.round(v*1.2)), 255);
                //}).join(',')+")";
                onCanvasProcessUpdateCtx && onCanvasProcessUpdateCtx(this, taskId, userCtx);

                let ct: CanvasTaskData;
                if (
                    i_canvasTask < canvasTasks.length &&
                    (ct = canvasTasks[i_canvasTask]).id === taskId &&
                    ct._ === task._ &&
                    ct.top === top &&
                    ct.dep === dep &&
                    ct.deps?.rp === deps?.rp &&
                    ct.deps?.rs === deps?.rs &&
                    !canvasDirty &&
                    true !== force &&
                    !vt_dirty &&
                    (!onCanvasProcessUnchanged || false !== onCanvasProcessUnchanged(this, ct, taskId, userCtx))
                ) {
                    // no change
                    if (taskPatch.length > 0 && taskPatch[taskPatch.length - 1] > 0) {
                        (taskPatch[taskPatch.length - 1] as number)++;
                    } else {
                        taskPatch.push(1);
                    }
                } else {
                    const stripe_grid = this.canvasStripes[stripe]._grid;
                    /*
                    const task_x1=true ||2!==C.colHeader?task._x1:EpochDaystoEpochMS(EpochDaysToStartOfWeekDays(EpochMStoEpochDays(task._x1)));
                    const task_x2=true ||2!==C.colHeader?task._x2:EpochDaystoEpochMS(EpochDaysToStartOfWeekDays(EpochMStoEpochDays(task._x2)+6));
                    */
                    const task_x1 = task._x1;
                    const task_x2 = task._x2;

                    assert(!stripe_grid); //@TODO implement me
                    const _left = !stripe_grid
                        ? this.epochDateToProjectGrid(_startDate, task_x1)
                        : CalendarGrid._dateToGrid(stripe_grid, task_x1);
                    const _right =
                        true || 2 !== C.colHeader
                            ? task_x1 < task_x2
                                ? !stripe_grid
                                    ? this.epochDateToProjectGrid(_startDate, task_x2)
                                    : CalendarGrid._dateToGrid(stripe_grid, task_x2)
                                : _left
                            : _left + (task_x1 === task_x2 ? 0 : 1);
                    const left = _left * C.colPx;
                    const right = _right * C.colPx;
                    const canvasTask: CanvasTaskData = {
                        _: t_,
                        id: taskId,
                        name: "", // _name,
                        segs: null, //
                        left: left,
                        right: right,
                        top: top,
                        stripe: stripe,
                        color: 0,
                        dep: dep,
                        deps: deps,
                        status: 0,
                    };
                    if (onCanvasProcessChanged) {
                        onCanvasProcessChanged(this, canvasTask, taskId, userCtx);
                    }
                    const fs =
                        this.legacyFLux?.fs ||
                        this.VALUE<number>(_settings.user.fs, 0) ||
                        this.VALUE<number>(_settings.global.fs, 0) ||
                        0;
                    canvasTask.segs =
                        (C.forceCanvas || 2 === C.colHeader) &&
                        this.textSegmentation(
                            canvasTask.name,
                            right === left ? C.rowPx : right - left,
                            C.rowPx,
                            2 === C.colHeader ? 20 : 6,
                            fs > 0 ? -fs : fs, // make sure its always negative because milestones need always to render in text adaption mode
                        );

                    canvasTasks.splice(i_canvasTask, 0, canvasTask);
                    taskPatch.push(canvasTasks[i_canvasTask]);
                    this.taskMetaData.set(task, i_canvasTask);
                }
                i_taskIds++;
                i_canvasTask++;
            }
        }
        this.callbacks.onUpdateCanvas && this.callbacks.onUpdateCanvas(this, userCtx);
        const rows = this.canvasStripes.length > 0 ? this.canvasStripes[this.canvasStripes.length - 1].e : 0;
        const cols = this.grid?.view.cols || 0;

        let filterPatch = undefined;
        if (undefined !== this.legacyFLux.filter) {
            filterPatch = this.legacyFLux.filter;
            delete this.legacyFLux.filter;
        }
        const wbPatch = this._createSubStoragePatch("wb", this.canvasWhiteboards);
        const rejectedOps = this._rejectedOps;
        this._rejectedOps = Array.isArray(rejectedOps) ? [] : undefined;

        let errorPatch = undefined;
        if (this.errorState !== this.canvasErrorState) {
            this.canvasErrorState = this.errorState;
            errorPatch = this.canvasErrorState
                ? {
                      error: this.canvasErrorState.error ? errorToJSON(this.canvasErrorState.error) : undefined,
                      rt: this.canvasErrorState.rt ? errorToJSON(this.canvasErrorState.rt) : undefined,
                  }
                : null;
        }

        return {
            start: _startDate,
            l_min: ctx.l_min,
            l_max: ctx.l,
            rows: rows,
            cols: cols,
            filterPatch: filterPatch,
            wbPatch: wbPatch,
            stripesPatch: stripesPatch,
            tasksPatch: taskPatch,
            gridPatch: gridPatch,
            viewConstPatch: viewConstPatch,
            viewMetaPatch: viewMetaPatch,
            gpaDirty,
            rejectedOps: Array.isArray(rejectedOps) && rejectedOps.length > 0 ? rejectedOps : undefined,
            errorPatch,
        };
    }

    static _cmpCardsData(a: CanvasCardData, b: CanvasCardData) {
        let ret = a.p - b.p;
        if (0 == ret) {
            //ret=b.id-a.id;
            ret = a.id.localeCompare(b.id);
        }
        return ret;
    }

    static _calcCardsDataPatch(cards0: CanvasCardData[], cards: CanvasCardData[]) {
        const patch = [];
        let j = 0;
        for (let i = 0; i < cards.length; i++) {
            for (; j < cards0.length && DataModel._cmpCardsData(cards0[j], cards[i]) < 0; j++) {
                if (patch.length > 0 && patch[patch.length - 1] < 0) {
                    patch[patch.length - 1]--;
                } else {
                    patch.push(-1);
                }
            }
            if (j < cards0.length && DataModel._cmpCardsData(cards0[j], cards[i]) === 0) {
                // no change or update
                if (
                    cards0[j].id === cards[i].id &&
                    cards0[j].p === cards[i].p &&
                    cards0[j]._ === cards[i]._ &&
                    cards0[j].y === cards[i].y
                ) {
                    // no change
                    if (patch.length > 0 && patch[patch.length - 1] > 0) {
                        patch[patch.length - 1]++;
                    } else {
                        patch.push(1);
                    }
                    j++;
                } else {
                    // update
                    patch.push(cards[i]);
                }
            } else {
                // insert
                patch.push(cards[i]);
            }
        }
        assert(j <= cards0.length);
        if (j < cards0.length) {
            patch.push(-(cards0.length - j));
        }
        return patch;
    }

    public _layoutCards(_q: CanvasCardData[], _: number) {
        if (_q.length > 1) {
            // layout
            _q.sort((a, b) => {
                let cmp = EpochMStoEpochDays(a.d) - EpochMStoEpochDays(b.d);
                if (0 === cmp) {
                    if (a.id < 0 && b.id > 0) {
                        cmp = +1;
                    } else if (a.id > 0 && b.id < 0) {
                        cmp = -1;
                    } else if (a.id < 0 && b.id < 0) {
                        cmp = a.p - b.p;
                        assert(0 !== cmp);
                    } else {
                        assert(a.id > 0 && b.id > 0);
                        const a_op = Array.isArray(this.cards[a.id].date)
                            ? this.cards[a.id].date[0]
                            : this.cards[a.id].date;
                        const b_op = Array.isArray(this.cards[b.id].date)
                            ? this.cards[b.id].date[0]
                            : this.cards[b.id].date;
                        cmp = b_op - a_op;
                    }
                }
                return cmp;
            });
            for (let i = 0; i < _q.length; i++) {
                const dd = EpochMStoEpochDays(_q[i].d);
                const dy = _q[i].d - dd;
                assert(dy >= 0);
                let delta = 0;
                let _dd;
                let j = i;
                while (j > 0 && (_dd = EpochMStoEpochDays(_q[j - 1].d)) === dd) {
                    const _dy = _q[j - 1].d - _dd;
                    assert(_dy >= 0);
                    if (dy + delta === _dy) {
                        delta++;
                        j = i;
                    } else {
                        j--;
                    }
                }
                if (delta > 0) {
                    assert(_q[i]._ <= _);
                    _q[i]._ = _;
                    _q[i].d += delta; // HACK/TRICK: we use the ms to store the y-coordinate..
                }
            }
            if (true) {
                const __q = _q.sort((a, b) => a.d - b.d);
                for (let i = 1; i < __q.length; i++) {
                    assert(__q[i - 1].d < __q[i].d);
                }
            }
        }
        _q.sort(DataModel._cmpCardsData);
    }

    private static HISTORY_VALUE_MAP = {
        op: null,
        taskId: null,
        name: "name",
        stripe: "taktzone",
        stripey: "position",
        start: "start",
        days: "days",
        trade: "trade",
        fs: null,
    };

    private _getGroup(i: number, i_min: number, i_max: number): [number, number] {
        let j = i;
        while (i > i_min && this.ops[i - 1].group) i--;
        while (j < i_max && this.ops[j].group) j++;
        return [i, j];
    }

    private _getGroupOpDetails(group: [number, number]) {
        const details = this.ops.slice(group[0], group[1] + 1).reduce(
            (ret, o) => {
                ret[o.name] = o.value;
                return ret;
            },
            {
                op: group[0],
            },
        );
        return details;
    }

    private _getChangedValues(details) {
        const _changed = Object.getOwnPropertyNames(details)
            .filter((v) => {
                const ret = null !== DataModel.HISTORY_VALUE_MAP[v];
                return ret;
            })
            .map((v) => DataModel.HISTORY_VALUE_MAP[v] || "{" + JSON.stringify(v) + "}");
        return _changed;
    }

    public updateHistory_REMOVE_ME() {
        const history = Math.max(this.history, this.syncCommited.ofs);
        const ret = {
            history: this.ops
                .slice(this.history, history)
                .map((o, i) => this.history + i)
                .filter((i) => !this.ops[i].group)
                .map((i) => {
                    const _g = this._getGroup(i, this.history, this.syncCommited.ofs);
                    const op = this.ops[_g[0]];
                    const user = op._u || "nn";
                    switch (op.op) {
                        case DataOperationType.CREATE: {
                            const _changed = this._getChangedValues(this._getGroupOpDetails(_g));
                            return {
                                _: _g[0],
                                _tg: op.target,
                                _id: op.id,
                                _u: user,
                                _op: "create",
                                _c: _changed,
                            };
                        }
                        case DataOperationType.UPDATE: {
                            const _changed = this._getChangedValues(this._getGroupOpDetails(_g));
                            return {
                                _: _g[0],
                                _tg: op.target,
                                _id: op.id,
                                _u: user,
                                _op: "update",
                                _c: _changed,
                            };
                        }
                        case DataOperationType.REJECT_ACCEPT: {
                            const _op = this.ops[op.id];
                            const __g = this._getGroup(op.id, this.imported.ofs, this.syncCommited.ofs);
                            const _changed = this._getChangedValues(this._getGroupOpDetails(__g));
                            if (op.value) {
                                return {
                                    _: _g[0],
                                    _tg: op.target,
                                    _id: _op.id,
                                    _u: user,
                                    _op: "undo",
                                    _c: _changed,
                                };
                            } else {
                                return {
                                    _: _g[0],
                                    _tg: op.target,
                                    _id: _op.id,
                                    _u: user,
                                    _op: "redo",
                                    _c: _changed,
                                };
                            }
                        }
                        default:
                            return {
                                _: _g[0],
                                _tg: null,
                                _id: null,
                                _u: user,
                                _op: "???",
                                _c: [],
                            };
                    }
                }),
        };
        this.history = history;
        return ret;
    }

    public _adjustYIndex(this: DataModel, c: number[], c_i: number, c_j: number, updateOps?: boolean) {
        assert(Array.isArray(c) && c_i <= c_j && c_j <= c.length);
        for (let i = c_i; i < c_j; i++) {
            const t_i: DataModelTask = this.tasks[c[i]];
            assert(t_i && "number" === typeof t_i.stripey && t_i._y === this.ops[t_i.stripey as number].value);
            for (let j = i; j > c_i; ) {
                j--;
                const t_j: DataModelTask = this.tasks[c[j]];
                if (DataModel.taskIntersect(t_i, t_j)) {
                    t_i._y = Math.max(t_i._y, t_j._y + 1);
                    if (updateOps) {
                        this.ops[t_i.stripey as number].value = t_i._y;
                    }
                }
            }
        }
    }

    public _adjustYIndexAfterImport(this: DataModel, updateOps?: boolean) {
        const stripes_ctx = this.gatherStripesIfNeeded(1);
        const stripes: CanvasStripeData[] = stripes_ctx.stripes;
        const n_stripes = stripes.length;
        for (let i_stripe = 0; i_stripe < n_stripes; i_stripe++) {
            const stripe = stripes[i_stripe];
            const c = this.tasks[stripe.t]?._c;
            this._adjustYIndex(c, stripe.i, stripe.j, updateOps);
        }
    }

    private _fixMaxIdsHelper(this: DataOperationMaxIds, ops: DataOperation[], i_op: number) {
        const op = ops[i_op];
        if (DataOperationType.CREATE === op.op) {
            //assert(this[op.target as number]<op.id); not true on import...
            this[op.target as number] = Math.max(this[op.target as number], op.id);
        } else if (DataOperationType.UPDATE === op.op) {
            assert(op.id <= this[op.target as number]);
        } else if (DataOperationType.REJECT_ACCEPT === op.op) {
            assert(0 <= op.id && op.id <= ops.length);
            //[importFixed]: ops[op.id]._rejected=op.value;
        } else {
            assert(DataOperationType.NOOP === op.op);
        }
    }

    _fixGroupingIfNeeded(ops: DataOperation[]) {
        const n = ops.length;
        if (n > 0 && ops[n - 1].group) {
            console.warn("FIX GROUPING FOR: " + ops[n - 1].group);
            ops[n - 1].group = false;
        }
    }

    _fixImportedTS() {
        this._fixGroupingIfNeeded(this.ops);
        const n = this.ops.length;
        let i = 0;
        const maxIds: DataOperationMaxIds = [0, 0, 0, 0, 0, 0, 0];
        const fixMaxIds = this._fixMaxIdsHelper.bind(maxIds);
        for (; i < n && this.ops[i].group; i++) {
            fixMaxIds(this.ops, i);
        }
        assert(i < n);
        fixMaxIds(this.ops, i++);
        assert(i <= n);
        this.imported = { ofs: i, maxIds: maxIds.slice() as DataOperationMaxIds };
        this.history = this.imported.ofs;
        for (; i < n; i++) {
            fixMaxIds(this.ops, i);
        }
        assert(i == n);
        this.REMOVE_ME_syncSent = n;
        //this.syncCommited=n;
        this.syncCommited = { ofs: n };
        //this.importFixed=n;
    }

    static loadOps(ops: DataOperation[], importDone?: boolean, options?: { maxCommit?: number; whiteboard?: boolean }) {
        const m = new DataModel();
        m.whiteboard = options?.whiteboard || false;
        m.ops = ops;
        m._fixImportedTS();
        if (options?.maxCommit > 0) {
            m.setMaxCommit(options.maxCommit);
        }
        m.updateModel(importDone);
        return m;
    }

    static loadCompressedOps(buffer: ArrayBuffer) {
        const ops_raw = pako.inflate(buffer);
        const ops_utf8 = new TextDecoder("utf-8").decode(ops_raw);
        const ops = JSON.parse(ops_utf8);
        const m = new DataModel();
        m.ops = ops;
        if (m.ops.length > 0) {
            // import grouping
            const o = m.ops;
            const n = o.length;
            for (let i = 1; i < n; i++) o[i - 1].group = true;
            assert(!o[n - 1].group);
            m.updateModel();
            m._fixImportedTS();
            m.updateModel();
        }
        return m;
    }

    getCompressedOps() {
        const ops_raw = pako.deflate(JSON.stringify(this.ops));
        return ops_raw.buffer;
    }

    public uploadModel(resource_token?: string) {
        return new Promise(
            (
                resolve: (resp: { pid?: string; rid?: string; sid?: string; token: string }) => void,
                reject: (err) => void,
            ) => {
                this.updateModel(true);
                create_master(
                    this.getRawOps(),
                    (error, create_master_response) => {
                        if (error) {
                            reject(error);
                        } else {
                            set_master_sandbox(create_master_response.token, (error, result) => {
                                if (error) {
                                    reject(error);
                                } else {
                                    resolve(result);
                                }
                            });
                        }
                    },
                    {
                        resource_token,
                    },
                );
            },
        );
    }

    getFontSizeTodos(gatherStripes?: boolean) {
        const C = this.viewConst;
        const ret_tasks = [];
        this.updateModel();
        const taskIds = Object.getOwnPropertyNames(this.tasks);
        const n = taskIds.length;
        for (let i = 0; i < n; i++) {
            const t = this.tasks[taskIds[i]];
            if (Array.isArray(t._c) && t._c.length >= 0) {
            } else {
                const name = this.VALUE<string>(t.name, null);
                const left = DataModel.EpochMStoEpochDays(t._x1) * C.colPx;
                const right = DataModel.EpochMStoEpochDays(t._x2) * C.colPx;
                ret_tasks.push({
                    id: taskIds[i],
                    name: name,
                    left: left,
                    right: Math.max(right, left + C.colPx),
                });
            }
        }
        if (false !== gatherStripes) {
            // do initial layout!
            const ctx = this.gatherStripesIfNeeded(0);
            const stripes = ctx.stripes;
            // minimize
            for (let i_stripe = 0; i_stripe < stripes.length; i_stripe++) {
                const stripe = stripes[i_stripe];
                const t = this.tasks[stripe.t];
                const tcs = (t._c || [])
                    .slice(stripe.i, stripe.j)
                    .map((i_tc) => {
                        const tc = this.tasks[i_tc];
                        tc._y = 0;
                        return i_tc;
                    })
                    .sort((t_a, t_b) => {
                        const ret = this.tasks[t_a]._x1 - this.tasks[t_b]._x1;
                        return 0 != ret ? ret : this.tasks[t_a].__ - this.tasks[t_b].__;
                    });
                for (let i_tc = 0; i_tc < tcs.length; i_tc++) {
                    const tc = this.tasks[tcs[i_tc]];
                    assert(0 === tc._y);
                    for (let j = 0; j < i_tc; ) {
                        if (tc._y === this.tasks[tcs[j]]._y && DataModel.taskIntersect(tc, this.tasks[tcs[j]])) {
                            tc._y++;
                            j = 0;
                        } else {
                            j++;
                        }
                    }
                    const stripey_ops = this.OPS(tc.stripey);
                    assert(1 === stripey_ops.length);
                    stripey_ops[0].value = tc._y;
                }
            }
            this._stripes[0].dirty = true;
            if (this._stripes[1]) this._stripes[1].dirty = true;
        }
        return {
            tasks: ret_tasks,
        };
    }

    setFontSizes(fs: { [id: string]: number }) {
        if (this.ops.length > 0) {
            const userName = "import";
            assert(false === this.ops[this.ops.length - 1].group);
            this.ops[this.ops.length - 1].group = true;
            const fsa = Object.getOwnPropertyNames(fs);
            for (let i = 0; i < fsa.length; i++) {
                const _tid = fsa[i];
                const tid = Number.parseInt(_tid);
                const _fs = fs[_tid];
                this.pushOperation({
                    op: DataOperationType.UPDATE,
                    target: DataOperationTarget.TASKS,
                    id: tid,
                    name: "fs",
                    value: _fs,
                    z: 0,
                    _u: userName,
                    group: true,
                });
            }
            assert(true === this.ops[this.ops.length - 1].group);
            this.ops[this.ops.length - 1].group = false;
            if (null !== this._stripes[0]) {
                this._stripes[0].dirty = true;
            }
            if (null !== this._stripes[1]) {
                this._stripes[1].dirty = true;
            }
        }
    }

    static loadStream(
        storageName: string,
        token: string,
        startOfs: number,
        endOfs: number,
        importDone?: boolean,
        options?: { maxCommit?: number },
    ) {
        return new Promise(async (_resolve: (m: DataModel) => void, reject: (err) => void) => {
            const resolveHelper = (cachedOps: DataOperation[], ops: DataOperation[], storageName, userName) => {
                assert(Array.isArray(cachedOps));
                assert(cachedOps.length + ops.length === endOfs);
                if (0 === cachedOps.length) {
                    cachedOps = ops;
                    ops = [];
                } else if (ops.length > 0) {
                    assert(cachedOps.length > 0);
                    const n_ops = ops.length;
                    for (let i_op = 0; i_op < n_ops; i_op++) {
                        cachedOps.push(ops[i_op]);
                    }
                    ops = []; // allow GC
                }
                assert(cachedOps.length === endOfs);
                const m = DataModel.loadOps(cachedOps, importDone, options);
                m.setStorageName(storageName);
                m.userName = userName;
                _resolve(m);
            };
            const _token = unsafeParseJWT(token);
            let _cachedOps: DataOperation[] = null;
            if (
                DataModel.cache &&
                _token.sub &&
                (_cachedOps = await DataModel.cache.fetchStream(_token.sid, startOfs || 0, endOfs)) &&
                (startOfs || 0) + _cachedOps.length === endOfs
            ) {
                resolveHelper(_cachedOps, [], storageName, _token.sub);
            } else {
                const n_ops = Array.isArray(_cachedOps) ? _cachedOps.length : 0;
                const _startOfs = (startOfs || 0) + n_ops;
                assert(_startOfs < endOfs);
                liveFetch(
                    SERVICES.STORAGE_SERVICE,
                    {
                        endpoint: "storage",
                        op: "fetch_storage",
                        token: token,
                        startOfs: 0,
                        endOfs: 0,
                        chunked: true,
                    },
                    token,
                )
                    .then(async (response) => {
                        if (200 === response.status) {
                            const data = response.data;
                            if (Array.isArray(data?.ops)) {
                                if (Array.isArray(data.chunked)) {
                                    assert(0 === data.ops.length);
                                    data.chunked.push({
                                        sid: _token.sid,
                                        ofs: endOfs,
                                    });
                                    assert(
                                        data.chunked.reduce((ret, item, i_ret, a_ret) => {
                                            const prev_ofs = i_ret > 0 ? a_ret[i_ret - 1].ofs : 0;
                                            return ret && prev_ofs <= item.ofs;
                                        }, true),
                                    );
                                    const chunks = data.chunked;
                                    const n_chunks = chunks.length;
                                    let useCache = true;
                                    let ops = null;
                                    for (let i_chunk = 0; i_chunk < n_chunks; i_chunk++) {
                                        const chunk = chunks[i_chunk];
                                        const chunk_startOfs = i_chunk > 0 ? chunks[i_chunk - 1].ofs : 0;
                                        const chunk_endOfs = chunk.ofs;
                                        const __startOfs = Math.max(chunk_startOfs, _startOfs);
                                        const __endOfs = Math.min(chunk_endOfs, endOfs);
                                        if (__startOfs < __endOfs) {
                                            assert(_startOfs + (ops || []).length === __startOfs);
                                            const ctx = await DataModel.fetchOpsChunked({
                                                cacheName: _token.sid,
                                                sid: chunk.sid,
                                                token: token,
                                                useCache: useCache,
                                                ofs0: _startOfs,
                                                ofs: __startOfs,
                                                ops: ops,
                                                endOfs: __endOfs,
                                                chunked: chunk.sid,
                                            });
                                            ops = ctx.ops;
                                            useCache = ctx.useCache;
                                        }
                                    }
                                    resolveHelper(_cachedOps || [], ops, data.storageName, data.sub);
                                } else {
                                    assert(false); // legacy!!!
                                    assert(!data.chunked); //@TODO implement chunked stream
                                    assert(_startOfs + data.ops.length === endOfs);
                                    if (DataModel.cache) {
                                        await DataModel.cache.cacheStream(_token.sid, _startOfs, endOfs, data.ops);
                                    }
                                    resolveHelper(_cachedOps, data.ops, data.storageName, data.sub);
                                }
                            } else {
                                reject(new FrameworkHttpError(response.status));
                            }
                        } else {
                            reject(new FrameworkHttpError(response.status));
                        }
                    })
                    .catch(reject);
            }
        });
    }

    createSandbox(storageName: string, userName?: string) {
        const ofs = this.commitTS();
        return new Promise((resolve: (m: DataModel) => void, reject: (err) => void) => {
            liveFetch(
                SERVICES.STORAGE_SERVICE,
                {
                    endpoint: "storage",
                    op: "create_branch",
                    storageName: storageName,
                    startOfs: ofs,
                },
                undefined,
            )
                .then((response) => {
                    const data = response.data;
                    this.setStorageName(data.storageName);
                    resolve(data);
                })
                .catch(reject);
        });
    }

    static openSandbox(token: string, sandbox: string) {
        return new Promise((resolve: (resp: any) => void, reject: (err) => void) => {
            liveFetch(
                SERVICES.STORAGE_SERVICE,
                {
                    endpoint: "storage",
                    op: "open_branch",
                    token: token,
                    sid: sandbox,
                },
                token,
            )
                .then((response) => {
                    const data = response.data;
                    resolve(data);
                })
                .catch(reject);
        });
    }

    static authenticateMasterUser(token: string, sid: string) {
        return new Promise((resolve: (resp: any) => void, reject: (err) => void) => {
            liveFetch(
                SERVICES.STORAGE_SERVICE,
                {
                    endpoint: "storage",
                    op: "auth_master",
                    token: token,
                    sid: sid,
                },
                token,
            )
                .then((response) => {
                    const data = response.data;
                    resolve(data);
                })
                .catch(reject);
        });
    }

    static pushStreamOps(
        storageName: string,
        sync: {
            ops: DataOperation[];
            ofs: number;
        },
        options?: {
            commitSandbox: number;
            deleteSandbox?: boolean;
            meta?: {
                forcedSync?: boolean;
            };
        },
        master_token?: string,
    ) {
        return new Promise(
            async (
                resolve: (ret: { startOfs: number; ofs: number; ops: DataOperation[]; adjust: number }) => void,
                reject: (err) => void,
            ) => {
                liveFetch(
                    SERVICES.STORAGE_SERVICE,
                    {
                        endpoint: "storage",
                        op: "commit_storage",
                        storageName: storageName,
                        startOfs: sync.ofs,
                        ops: sync.ops,
                        commitSandbox: options?.commitSandbox,
                        deleteSandbox: options?.deleteSandbox,
                        meta: options?.meta || undefined,
                        major: SERVICES.MAJOR,
                    },
                    master_token,
                )
                    .then(async (response) => {
                        if (response.ok) {
                            const data = response.data;
                            if (DataModel.cache && data?.storageName && Array.isArray(data?.ops)) {
                                assert(data.storageName === storageName);
                                if (data.ops.length > 0) {
                                    await DataModel.cache.cacheStream(data.storageName, data.ofs, undefined, data.ops);
                                } else {
                                    assert(0 === data.ops.length && sync.ofs + sync.ops.length === data.ofs);
                                    await DataModel.cache.cacheStream(storageName, sync.ofs, undefined, sync.ops);
                                }
                            }
                            resolve(data);
                        } else {
                            reject(new FrameworkHttpError(response.status));
                        }
                    })
                    .catch(reject);
            },
        );
    }

    static fetchStreamOps(auth_token: string, storageName: string, ofs: number) {
        return new Promise(
            async (resolve: (ret: { startOfs: number; ops: DataOperation[] }) => void, reject: (err) => void) => {
                let _cachedOps;
                if (
                    DataModel.cache &&
                    storageName &&
                    (_cachedOps = await DataModel.cache.fetchStream(storageName, ofs, undefined))
                ) {
                    resolve({
                        ops: _cachedOps,
                        startOfs: ofs,
                    });
                } else {
                    fetch_ops(
                        auth_token,
                        async (error, result) => {
                            if (error) {
                                reject(error);
                            } else {
                                if (
                                    DataModel.cache &&
                                    result.storageName === storageName &&
                                    Array.isArray(result.ops)
                                ) {
                                    assert(result.storageName === storageName && result.startOfs === ofs);
                                    await DataModel.cache.cacheStream(
                                        result.storageName,
                                        result.startOfs,
                                        undefined,
                                        result.ops,
                                    );
                                }
                                resolve(result);
                            }
                        },
                        ofs,
                        undefined,
                        storageName,
                    );
                }
            },
        );
    }

    cloneOps(start: number, end: number) {
        const ops = this.ops.slice(start, end).map((e) => {
            const ret = Object.assign({}, e);
            delete ret._ats;
            delete ret._value;
            delete ret._rejected;
            delete ret._i;
            delete ret._r_ts;
            delete ret._sid;
            delete ret._rbac;
            return ret;
        });
        return ops;
    }

    getRawOps() {
        const ret = this.ops.map((op) => {
            const ret = Object.assign({}, op);
            delete ret._ats;
            delete ret._value;
            delete ret._rejected;
            delete ret._i;
            delete ret._r_ts;
            delete ret._sid;
            delete ret._rbac;
            return ret;
        });
        return ret;
    }

    _getSafeSyncState(sessionId: number, ops: DataOperation[], ofs: number, clone?: boolean) {
        // THIS FUNCTION SHOULD NOT HAVE ANY SIDEEFFECT ON THE MODEL!!!!
        const syncMaxIds = this.syncMaxIds.slice();
        const _syncMaxIds = JSON.stringify(this.syncMaxIds);
        const _transformIds = JSON.stringify(this.transformIds);
        let unsafe = false;
        const ret = {
            ofs: ofs,
            ops: ops.map((e) => {
                if (DataOperationType.NOOP !== e.op && DataOperationType.REJECT_ACCEPT !== e.op) {
                    const id = e.id;
                    let _id;
                    if (DataOperationType.CREATE === e.op) {
                        if (id > syncMaxIds[e.target as number]) {
                            syncMaxIds[e.target as number] = id;
                        } else {
                            unsafe = true; // NOT SAFE! WOULD CAUSE A SIDE EFFECT
                        }
                        _id = id;
                    } else if (DataOperationType.UPDATE === e.op) {
                        _id = this._transformClientId(e.target, id);
                    } else {
                        assert(false); // handle me!
                    }
                    if (true || clone || e._ats || id !== _id) {
                        const ret = Object.assign({}, e);
                        delete ret._ats;
                        delete ret._value;
                        delete ret._rejected;
                        delete ret._i;
                        delete ret._r_ts;
                        delete ret._sid;
                        delete ret._rbac;
                        ret.id = _id;
                        this._transformTargetOp[ret.target as number].call(this, ret, true);
                        return ret;
                    } else {
                        assert(false); // only works if no transform op...
                        return e;
                    }
                } else {
                    if (clone) {
                        return Object.assign({}, e);
                    } else {
                        return e;
                    }
                }
            }),
            sessionId: sessionId,
        };
        assert(_syncMaxIds === JSON.stringify(this.syncMaxIds)); // make sure there is no side effect!!!
        assert(_transformIds === JSON.stringify(this.transformIds)); // make sure there is no side effect!!!
        return unsafe ? null : ret;
    }

    getSync(sessionId: number, clone?: boolean) {
        assert(
            this.imported.ofs <= this.syncCommited.ofs &&
                this.syncCommited.ofs <= this.REMOVE_ME_syncSent &&
                (this.maxCommit > 0 || this.REMOVE_ME_syncSent <= this.commited),
        );
        const REMOVE_ME_idTransform: DataOperationIdTranform = [[], [], [], [], [], [], []];
        const transformClientIdCtx = {
            syncMaxIds: this.syncMaxIds.slice() as DataOperationMaxIds,
            n: this.transformIds.map((t) => t.length),
        };
        const ret = {
            ofs: this.syncCommited.ofs,
            ops: this.ops.slice(this.syncCommited.ofs, this.commited).map((e) => {
                if (DataOperationType.NOOP !== e.op && DataOperationType.REJECT_ACCEPT !== e.op) {
                    const id = e.id;
                    let _id;
                    if (DataOperationType.CREATE === e.op) {
                        _id = this._createNewMasterIdForLocalId(e.target, id);
                    } else if (DataOperationType.UPDATE === e.op) {
                        _id = this._transformClientId(e.target, id);
                    } else {
                        assert(false); // handle me!
                    }
                    //const id=e.id;
                    //const _id=this._transformClientId(e.target, id);
                    if (true || clone || e._ats || id !== _id) {
                        const ret = Object.assign({}, e);
                        delete ret._ats;
                        delete ret._value;
                        delete ret._rejected;
                        delete ret._i;
                        delete ret._r_ts;
                        delete ret._sid;
                        delete ret._rbac;
                        ret.id = _id;
                        this._transformTargetOp[ret.target as number].call(this, ret, true);
                        return ret;
                    } else {
                        assert(false); // only works if no transform op...
                        return e;
                    }
                } else {
                    if (clone) {
                        return Object.assign({}, e);
                    } else {
                        return e;
                    }
                }
            }),
            sessionId: sessionId,
        };
        this.REMOVE_ME_syncSent = Math.max(this.syncCommited.ofs, this.commited);
        return {
            sync: ret,
            token: {
                ofs: ret.ofs,
                ops: ret.ops.length,
                idTransform: REMOVE_ME_idTransform,
                rollbackIdTransform: transformClientIdCtx,
            } as DataOperationSyncToken,
        };
    }

    private _createNewLocalIdForMasterId(target: DataOperationTarget, id: IntId): IntId {
        if (id <= this.maxIds[target as number]) {
            // we have a conflict
            const _id = ++this.maxIds[target as number];
            this.transformIds[target as number].push({
                //@TODO group...
                id0: id,
                id1: _id,
                n: 1,
            });
            return _id;
        } else {
            assert(id > this.maxIds[target as number]);
            this.maxIds[target as number] = id;
            return id;
        }
    }

    /**
     *
     * @param op
     * @param opIsLocal
     * @private
     *
     * @todo: transform op for ReasonCodes
     */
    private _transformTasksOp(op: DataOperation, opIsLocal: boolean) {
        assert(DataOperationTarget.TASKS === op.target);
        if ("p" === op.name) {
            op.value = this._transformId(op.target, op.value, opIsLocal);
        } else if (op.name && "#" === op.name[0]) {
            if ("T" === op.name[1]) {
                // op.name[2] unused/ignored
                const target_id = Number.parseInt(op.name.substring(3), 16);
                const id = this._transformId(DataOperationTarget.TASKS, target_id, opIsLocal);
                const name = op.name.substring(0, 3) + id.toString(16);
                if (target_id !== id) {
                    op.name = name;
                } else {
                    assert(name === op.name);
                }
            } else if ("R" === op.name[1] && op.name[2] !== "C") {
                // op.name[2] unused/ignored
                const target_id = Number.parseInt(op.name.substring(3), 16);
                const id = this._transformId(DataOperationTarget.TRADE, target_id, opIsLocal);
                const name = op.name.substring(0, 3) + id.toString(16);
                if (target_id !== id) {
                    op.name = name;
                } else {
                    assert(name === op.name);
                }
            } else if ("A" === op.name[1]) {
                const match = op.name.match(_LEGACY_ACTIVITY_REGEXP);
                if (match) {
                    const day = match.groups.day ? Number.parseInt(match.groups.day) : null;
                    const id =
                        day >= MAX_TASK_DAYS ? this._transformId(DataOperationTarget.ACTIVITY, day, opIsLocal) : day;
                    const _name = ["", "A" + match.groups.aid, id.toString(10)];
                    if (match.groups.name) {
                        _name.push(match.groups.name);
                    }
                    const name = _name.join("#");
                    if (id !== day) {
                        op.name = name;
                    } else {
                        assert(name === op.name);
                    }
                } else {
                    console.debug("Invalid activity name " + op.name);
                }
            } else if ("D" === op.name[1]) {
                // "D"ays/Date do not neet to be transformed...
            } else if ("a" === op.name[1]) {
                // attachments do not need to be transformed...
            } else if (("C" === op.name[1] && op.name[2] !== "L") || "I" === op.name[1]) { //@nikhil => this line is the fix for your issue with NaN. It should not go inside this block if the second index is a L. It should go in the next else if
                // @todo: lässt sich das nicht einfach mit einem reg replace umsetzen ?
                const op_name = op.name.split("#");
                const target_id = Number.parseInt(op_name[1].substring(1), 16);
                const id = this._transformId(DataOperationTarget.TASKS, target_id, opIsLocal);
                const name = ["", op_name[1].substring(0, 1) + id.toString(16), ...op_name.slice(2)].join("#");
                if (target_id !== id) {
                    op.name = name;
                } else {
                    assert(name === op.name);
                }
            } else if (op.name.startsWith("#RC")|| op.name.startsWith("#CL")) {
                // @todo: lässt sich das nicht einfach mit einem reg replace umsetzen ?
                const op_name = op.name.split("#");
                const target_id = Number.parseInt(op_name[1].substring(2), 16);
                const id = this._transformId(DataOperationTarget.TASKS, target_id, opIsLocal);
                const name = ["", op_name[1].substring(0, 2) + id.toString(16), ...op_name.slice(2)].join("#");
                if (target_id !== id) {
                    op.name = name;
                } else {
                    assert(name === op.name);
                }
            } else if ("t" === op.name[1]) {
                // trains do not need to be transformed...
            } else {
                assert(false); //@TODO
            }
        }
    }

    private _transformTradesOp(op: DataOperation) {
        assert(DataOperationTarget.TRADE === op.target);
    }

    private _transformCardsOp(op: DataOperation, opIsLocal: boolean) {
        assert(DataOperationTarget.CARD === op.target);
        if ("p" === op.name) {
            op.value = this._transformId(DataOperationTarget.TASKS, op.value, opIsLocal);
        }
    }

    private _transformNoop(op: DataOperation) {}

    private _transformTargetOp: DataOperationIdTranformTargetOpertion = [
        this._transformTasksOp, // TASKS = 0,
        this._transformNoop, // REMVOE_ME_STRIPES = 1,
        this._transformNoop, // REMOVE_ME_RELATION = 2,
        this._transformTradesOp, // TRADE = 3,
        this._transformCardsOp, // CARD= 4,
        this._transformNoop, // COMMENT= 5,
        this._transformNoop, // HIVE= 6,
    ];

    private _transformMasterId(target: DataOperationTarget, id: IntId): IntId {
        if (null !== id) {
            const a = this.transformIds[target as number];
            const n = a.length;
            for (let i = n; i > 0; ) {
                i--;
                if (a[i].id0 <= id && id < a[i].id0 + a[i].n) {
                    return a[i].id1 + (id - a[i].id0);
                } else if (false && a[i].id1 <= id && id < a[i].id1 + a[i].n) {
                    assert(false); // already used by client!
                    throw "Error!";
                }
            }
        }
        return id;
    }

    private _createNewMasterIdForLocalId(target: DataOperationTarget, id: IntId): IntId {
        if (null !== id) {
            if (id <= this.syncMaxIds[target as number]) {
                // we have a conflict
                const _id = ++this.syncMaxIds[target as number];
                const a = this.transformIds[target as number];
                a.push({
                    id0: _id,
                    id1: id,
                    n: 1,
                });
                return _id;
            } else {
                assert(id > this.syncMaxIds[target as number]);
                this.syncMaxIds[target as number] = id;
                return id;
            }
        }
    }

    private _transformClientId(target: DataOperationTarget, id: IntId): IntId {
        let t: DataModelTask;
        if (DataOperationTarget.TASKS === target && (t = this.tasks[id]) && t.__ < 0) {
            // virtual task
            id = null;
        }
        if (null !== id) {
            const a = this.transformIds[target as number];
            const n = a.length;
            for (let i = n; i > 0; ) {
                i--;
                if (a[i].id1 <= id && id < a[i].id1 + a[i].n) {
                    // client to master mapping -> use it...
                    return a[i].id0 + (id - a[i].id1);
                } else if (false && a[i].id0 <= id && id < a[i].id0 + a[i].n) {
                    return a[i].id1 + (id - a[i].id0);
                }
            }
        }
        return id;
    }

    public _transformId(target: DataOperationTarget, id: IntId, idIsLocal: boolean) {
        return idIsLocal ? this._transformClientId(target, id) : this._transformMasterId(target, id);
    }

    /*
    private _transformMasterId(target: DataOperationTarget, id: IntId): IntId {
        const a=this.tranformIds[target as number];
        const n=a.length;
        for(let i=0;i<n;i++) { //@TODO use binary search...
            if (a[i].id0<=id && id<a[i].id0+a[i].n) {
                return id+a[i].id1-a[i].id0;
            }
        }
        return id;
    }

    private _transformLocalId(target: DataOperationTarget, id: IntId, transformOut:DataOperationIdTranform): IntId {
        const id0=id;
        const a=this.tranformIds[target as number];
        const n=a.length;
        for(let i=0;i<n;i++) {
            if (a[i].id0<=id && id<a[i].id0+a[i].n) {
                id=id+a[i].id1-a[i].id0;
            }
        }
        if (transformOut && id0!==id) {
            transformOut[target as number].push({
                id0: id0,
                id1: id,
                n: 1
            });
        }
        return id;
    }
    */

    commitSync(
        sync: {
            ofs: number;
            ops: DataOperation[];
        },
        token: DataOperationSyncToken,
    ) {
        // rollback
        assert(this.syncCommited.ofs <= sync.ofs);
        if (sync.ops.length > 0) {
            assert(this.storageName === sync.ops[0]._sid);
            delete sync.ops[0]._sid; // no longer needed
        }
        if (sync.ops.length > 0 && sync.ofs === token.ofs) {
            // master has new stuff, commit failed
            {
                // rollback
                // rollback transform
                assert(this.transformIds.length === token.rollbackIdTransform.n.length);
                for (let i = 0; i < this.transformIds.length; i++) {
                    assert(this.transformIds[i].length >= token.rollbackIdTransform.n[i]);
                    if (this.transformIds[i].length > token.rollbackIdTransform.n[i]) {
                        this.transformIds[i] = this.transformIds[i].slice(0, token.rollbackIdTransform.n[i]);
                    }
                    assert(this.transformIds[i].length === token.rollbackIdTransform.n[i]);
                }
                // rollback syncMax
                this.syncMaxIds = token.rollbackIdTransform.syncMaxIds;
            }
            const DEBUG = Object.assign({}, this._snap());
            assert(
                this.imported.ofs <= this.syncCommited.ofs &&
                    this.syncCommited.ofs <= this.commited &&
                    this.commited === this.ops.length,
            );
            assert(this.syncCommited.ofs === sync.ofs);
            const commited = this.commited;
            this.rollback(sync.ofs, undefined);
            assert(this.commited === sync.ofs);
            const sync_commited_count = sync.ops.length;
            let _hasTrans =
                this.transformIds.reduce((ret, t) => ret || t.length > 0, false) ||
                "development" === process.env.NODE_ENV;
            for (let i = 0; i < sync.ops.length; i++) {
                if (DataOperationType.NOOP === sync.ops[i].op || DataOperationType.REJECT_ACCEPT === sync.ops[i].op) {
                    // no op
                } else {
                    this.syncMaxIds[sync.ops[i].target as number] = Math.max(
                        this.syncMaxIds[sync.ops[i].target as number],
                        sync.ops[i].id,
                    );
                    if (DataOperationType.CREATE === sync.ops[i].op) {
                        //const _helper=sync.ops[i].id;
                        sync.ops[i].id = this._createNewLocalIdForMasterId(sync.ops[i].target, sync.ops[i].id);
                        //console.log("MAP["+(sync.ops[i].target)+"] "+(_helper)+" -> "+(sync.ops[i].id));
                        _hasTrans = true;
                    } else if (DataOperationType.UPDATE === sync.ops[i].op) {
                        sync.ops[i].id = this._transformMasterId(sync.ops[i].target, sync.ops[i].id);
                    } else {
                        assert(false); // handle me!
                    }
                    _hasTrans &&
                        this._transformTargetOp[sync.ops[i].target as number].call(
                            this,
                            sync.ops[i],
                            false /* opIsLocal*/,
                        );
                    /* REMOVE ME: moved to this._transformTargetOp
                    if ("p"===sync.ops[i].name) {
                        sync.ops[i].value=this._transformId(sync.ops[i].target, sync.ops[i].value);
                    }
                    */
                }
            }
            for (let i = sync.ofs; i < this.ops.length; i++) {
                if (DataOperationType.REJECT_ACCEPT === this.ops[i].op) {
                    if (this.ops[i].id >= sync.ofs) {
                        this.ops[i].id += sync.ops.length;
                    }
                }
            }
            //this.ops.splice(sync.ofs, 0, ...sync.ops); // insert new ops
            sync.ops.forEach(function (v, i) {
                this.ops.splice(sync.ofs + i, 0, v);
            }, this);
            this.updateModel();
            this.syncCommited.ofs += sync.ops.length;
            assert(sync.ofs + sync.ops.length === this.syncCommited.ofs && this.syncCommited.ofs <= this.commited);
            this.REMOVE_ME_syncSent = this.syncCommited.ofs; // remove me
            assert(this.imported.ofs <= this.syncCommited.ofs && this.syncCommited.ofs <= this.REMOVE_ME_syncSent);
            /*
            if (this.syncCommited.ofs<this.ops.length) {
                const n=this.ops.length;
                for(let i=this.syncCommited.ofs;i<n;i++) {
                    for(let j=sync.ofs;j<this.syncCommited.ofs;j++) {
                        console.log("transform "+JSON.stringify(this.ops[i]+" wrt "+JSON.stringify(this.ops[j])));
                    }
                }
            }
            */
            {
                // adjust undo stack
                for (let i = 0; i < this.undoStack.length; i++) {
                    if (this.undoStack[i] >= sync.ofs) {
                        this.undoStack[i] += sync.ops.length;
                    }
                }
            }
            return 0;
        } else if (0 === sync.ops.length && sync.ofs === token.ofs + token.ops) {
            // master has commited new stuff
            assert(this.syncCommited.ofs <= sync.ofs);
            for (let i = 0; i < this.transformIds.length; i++) {
                assert(0 === token.idTransform[i].length);
                /*
                if (token.idTransform[i].length>0) {
                    this.tranformIds[i].push(...token.idTransform[i]);
                }
                */
            }
            this.syncCommited.ofs = sync.ofs;
            return 1;
        } else {
            // out-of-bound-data?
            return -1;
        }
    }

    __rollback(ts: number, _ts: number) {
        assert(this.imported.ofs <= ts && ts <= this.commited);
        for (let i = this.commited; i > ts; ) {
            i--;
            if (DataOperationType.REJECT_ACCEPT !== this.ops[i].op && !this.ops[i]._rejected) {
                this._rollbackOp(i, _ts);
            }
        }
        assert(0 === ts || !this.ops[ts - 1].group); // breaking groups!
        this.commited = ts;
    }

    _rollbackOp(i_op: number, _ts: number) {
        if (DataOperationType.NOOP !== this.ops[i_op].op) {
            switch (this.ops[i_op].target) {
                case DataOperationTarget.TASKS:
                    this._rollbackTask(this.ops[i_op], i_op);
                    break;
                case DataOperationTarget.ACTIVITY:
                    this._rollbackNoop(this.ops[i_op], i_op);
                    break;
                case DataOperationTarget.ACTIVITIES_TEMPLATE:
                    this._rollbackNoop(this.ops[i_op], i_op);
                    break;
                case DataOperationTarget.TRADE:
                    this._rollbackTrade(this.ops[i_op], i_op, _ts);
                    break;
                case DataOperationTarget.CARD: // REMOvE ME!!
                    this._rollbackCard(this.ops[i_op], i_op);
                    break;
                case DataOperationTarget.COMMENT:
                    this._rollbackNoop(this.ops[i_op], i_op);
                    break;
                case DataOperationTarget.HIVE:
                    this._rollbackHive(this.ops[i_op], i_op);
                    break;
                default:
                    assert(false); // TODO
                    break;
            }
        }
    }

    rollback(ts: number, _ts: number) {
        assert(this.imported.ofs <= ts && ts <= this.commited);
        let ts0 = ts;
        const fix = {};
        for (let i = this.commited; i > ts; ) {
            i--;
            if (!this.ops[i]._rejected) {
                if (DataOperationType.REJECT_ACCEPT === this.ops[i].op) {
                    if (this.ops[i].id < ts) {
                        ts0 = Math.min(ts0, this.ops[i].id);
                        fix[this.ops[i].id] = this.ops[i]._value;
                    }
                } else {
                    this._rollbackOp(i, _ts); // this._rollbackTasks(this.ops[i], i);
                }
            }
        }
        if ("release" !== process.env.NODE_ENV) {
            try {
                if (!(0 == ts || !this.ops[ts - 1].group)) {
                    console.log("OMG: AHHHH");
                }
            } catch (e) {
                console.log("OMG: OHHHHH");
            }
        }
        assert(0 == ts || !this.ops[ts - 1].group); // breaking groups!
        this.commited = ts;
        if (ts0 < ts) {
            const commited = this.commited;
            this.__rollback(ts0, _ts);
            const a_fix = Object.getOwnPropertyNames(fix);
            const n_fix = a_fix.length;
            for (let i_fix = 0; i_fix < n_fix; i_fix++) {
                const fix_rejected = fix[a_fix[i_fix]];
                let i_op = Number.parseInt(a_fix[i_fix]);
                for (; this.ops[i_op].group; i_op++) {
                    this.ops[i_op]._rejected = fix_rejected;
                }
                this.ops[i_op]._rejected = fix_rejected;
            }
            // apply again
            for (; this.commited < commited; this.commited++) {
                const _op = this.ops[this.commited];
                if (DataOperationType.REJECT_ACCEPT !== _op.op && !_op._rejected) {
                    this._updateCallback[_op.target].call(this, _op, this.commited, commited, commited);
                    this._enforceRBAC(_op, this.commited);
                }
            }
        }
    }

    private static _equalOp(op1: DataOperation, op2: DataOperation) {
        return op1.target === op2.target && op1.id === op2.id && op1.value === op2.value;
    }

    private static _equalOps(ops1: DataOperation[], ops2: DataOperation[]) {
        let ret = ops1.length === ops2.length;
        for (let i = 0; ret && i < ops1.length; i++) {
            ret = ret && DataModel._equalOp(ops1[i], ops2[i]);
        }
        return ret;
    }

    static equalTo(m1: DataModel, m2: DataModel) {
        let ret = m1.startDate == m2.startDate && m1.endDate === m2.endDate;
        // tasks
        m1.gatherStripesIfNeeded(0);
        m2.gatherStripesIfNeeded(0);
        m1.stackTasksIfNeeded();
        m2.stackTasksIfNeeded();
        const m1_tasksIds = Object.getOwnPropertyNames(m1.tasks).filter((tid) => undefined !== m1.tasks[tid].p);
        const m2_tasksIds = Object.getOwnPropertyNames(m2.tasks).filter((tid) => undefined !== m2.tasks[tid].p);
        ret = ret && m1_tasksIds.length === m2_tasksIds.length;
        for (let i = 0; ret && i < m1_tasksIds.length; i++) {
            ret = ret && m1_tasksIds[i] === m2_tasksIds[i];
            if (ret) {
                const t1 = m1.tasks[m1_tasksIds[i]] as DataModelTask;
                const t2 = m2.tasks[m2_tasksIds[i]] as DataModelTask;
                if (t1.__y !== t2.__y) {
                    const m1_stripey_ops = m1.OPS<number>(t1.stripey);
                    const m2_stripey_ops = m2.OPS<number>(t2.stripey);
                    const stripey_eq_ops = DataModel._equalOps(m1_stripey_ops, m2_stripey_ops);
                    console.log(JSON.stringify(stripey_eq_ops));
                }
                ret =
                    ret &&
                    t1._x1 === t2._x1 &&
                    t1._x2 === t2._x2 &&
                    t1.__y === t2.__y &&
                    t1._stripe0 === t2._stripe0 &&
                    DataModel._equalOps(m1.OPS<string>(t1.name), m2.OPS<string>(t2.name)) &&
                    DataModel._equalOps(m1.OPS<number>(t1.start), m2.OPS<number>(t2.start)) &&
                    DataModel._equalOps(m1.OPS<number>(t1.days), m2.OPS<number>(t2.days)) &&
                    DataModel._equalOps(m1.OPS<number>(t1.p), m2.OPS<number>(t2.p)) &&
                    DataModel._equalOps(m1.OPS<number>(t1.stripey), m2.OPS<number>(t2.stripey)) &&
                    DataModel._equalOps(m1.OPS<number>(t1.trade), m2.OPS<number>(t2.trade));
                if (!ret) {
                    const m1_start_ops = m1.OPS<number>(t1.start);
                    const m2_start_ops = m2.OPS<number>(t2.start);
                    const start_eq_ops = DataModel._equalOps(m1_start_ops, m2_start_ops);
                    const m1_stripey_ops = m1.OPS<number>(t1.stripey);
                    const m2_stripey_ops = m2.OPS<number>(t2.stripey);
                    const equals_stripey_ops = DataModel._equalOps(m1_stripey_ops, m2_stripey_ops);
                    console.error(
                        JSON.stringify({
                            t1: t1,
                            t2: t2,
                        }),
                    );
                }
            }
        }
        /* REMOVED: TODO compare stripes
        const m1_stripesIds=Object.getOwnPropertyNames(m1.REMOVE_ME_stripes);
        const m2_stripesIds=Object.getOwnPropertyNames(m2.REMOVE_ME_stripes);
        ret=ret && m1_stripesIds.length===m2_stripesIds.length;
        for(let i=0;ret && i<m1_stripesIds.length;i++) {
            ret=ret && m1_stripesIds[i]===m2_stripesIds[i];
            if (ret) {
                const s1=m1.REMOVE_ME_stripes[m1_stripesIds[i]] as REMOVE_ME_DataModelStripe;
                const s2=m2.REMOVE_ME_stripes[m2_stripesIds[i]] as REMOVE_ME_DataModelStripe;
                ret=ret && DataModel._equalOps(m1.OPS<number>(s1.name), m2.OPS<number>(s2.name));
            }
        }
        */
        // trades
        const m1_tradesIds = Object.getOwnPropertyNames(m1.trades);
        const m2_tradesIds = Object.getOwnPropertyNames(m2.trades);
        ret = ret && m1_tradesIds.length === m2_tradesIds.length;
        for (let i = 0; ret && i < m1_tradesIds.length; i++) {
            ret = ret && m1_tradesIds[i] === m2_tradesIds[i];
            if (ret) {
                const t1 = m1.trades[m1_tradesIds[i]] as DataModelTrade;
                const t2 = m2.trades[m2_tradesIds[i]] as DataModelTrade;
                ret = ret && DataModel._equalOps(m1.OPS<number>(t1.color), m2.OPS<number>(t2.color));
            }
        }
        return ret;
    }

    _cloneModel(ts: number, trim?: boolean) {
        assert(ts >= this.imported.ofs);
        const _m = new DataModel();
        _m.ops = this.ops.slice(0, ts).map((e, _ts) => {
            const ret = Object.assign({ _clone_ts: _ts }, e);
            delete ret._ats;
            return ret;
        });
        if (trim) {
            for (let i = 0; i < _m.ops.length; i++) {
                switch (_m.ops[i].op) {
                    case DataOperationType.REJECT_ACCEPT:
                        {
                            _m.ops[_m.ops[i].id]._rejected = _m.ops[i].value;
                        }
                        break;
                    case DataOperationType.CREATE:
                    case DataOperationType.UPDATE:
                    //no break
                    default:
                        break;
                }
            }
            let i = 0;
            while (i < _m.ops.length) {
                if (DataOperationType.REJECT_ACCEPT === _m.ops[i].op || _m.ops[i]._rejected) {
                    _m.ops.splice(i, 1);
                } else {
                    delete _m.ops[i]._rejected;
                    i++;
                }
            }
        }
        _m._fixImportedTS();
        _m.updateCanvas();
        return _m;
    }

    _snap() {
        assert(this.imported.ofs <= this.syncCommited.ofs && this.syncCommited.ofs <= this.REMOVE_ME_syncSent);
        return {
            imported: this.imported.ofs,
            commited: this.commited,
            syncSent: this.REMOVE_ME_syncSent,
            syncCommited: { ...this.syncCommited },
        };
    }

    commitTS() {
        assert(this.imported.ofs <= this.syncCommited.ofs && this.syncCommited.ofs <= this.REMOVE_ME_syncSent);
        return this.commited;
    }

    syncCommitedTS() {
        assert(this.imported.ofs <= this.syncCommited.ofs && this.syncCommited.ofs <= this.REMOVE_ME_syncSent);
        return this.syncCommited;
    }

    syncSentTS() {
        assert(this.imported.ofs <= this.syncCommited.ofs && this.syncCommited.ofs <= this.REMOVE_ME_syncSent);
        return this.REMOVE_ME_syncSent;
    }

    _rollbackSentTS(ts: number) {
        assert(
            this.imported.ofs <= this.syncCommited.ofs &&
                this.syncCommited.ofs <= this.REMOVE_ME_syncSent &&
                this.REMOVE_ME_syncSent <= this.commited,
        );
        assert(ts <= this.REMOVE_ME_syncSent);
        this.REMOVE_ME_syncSent = ts;
    }

    _checkInvariant() {
        // tasks do not overlap!
        //this.updateModel();
        //this._stripes[0].dirty=true;
        this.gatherStripesIfNeeded(0);
        const taskIds = Object.getOwnPropertyNames(this.tasks);
        const n = taskIds.length;
        for (let i = 0; i < n; i++) {
            for (let j = i + 1; j < n; j++) {
                const ti = this.tasks[taskIds[i]];
                const tj = this.tasks[taskIds[j]];
                if (false && Array.isArray(ti._c) && 0 === ti._c.length) {
                    return false; // is this used? empty array?
                }
                if (false && Array.isArray(tj._c) && 0 === tj._c.length) {
                    return false; // is this used? empty array?
                }
                if (Array.isArray(ti._c) || -1 === this.VALUE(ti.p, -1) || 0 === ti._x1) {
                    //assert !this._stripes[0].stripes[ti._stripe0]
                    if (this._stripes[0].stripes[ti._stripe0]) {
                        return false;
                    }
                } else {
                    //assert this._stripes[0].stripes[ti._stripe0]
                    if (!this._stripes[0].stripes[ti._stripe0]) {
                        return false;
                    }
                }
                if (Array.isArray(tj._c) || -1 === this.VALUE(tj.p, -1) || 0 === tj._x1) {
                    //assert !this._stripes[0].stripes[tj._stripe0]
                    if (this._stripes[0].stripes[tj._stripe0]) {
                        return false;
                    }
                } else {
                    //assert this._stripes[0].stripes[tj._stripe0]
                    if (!this._stripes[0].stripes[tj._stripe0]) {
                        return false;
                    }
                }
                if (
                    false &&
                    !Array.isArray(ti._c) &&
                    !Array.isArray(tj._c) &&
                    ti._stripe0 === tj._stripe0 &&
                    ti.__y === tj.__y &&
                    Math.max(ti._x1, tj._x1) < Math.min(ti._x2, tj._x2)
                ) {
                    return false;
                }
            }
        }
        return true;
    }

    public _getTaskParents(t: DataModelTask) {
        const p = [];
        for (let _p = this.VALUE<IntId>(t.p); _p > 0; ) {
            const _t = this.tasks[_p];
            assert(_t ? true : false);
            p.splice(0, 0, {
                id: _p,
                name: this.VALUE<string>(_t.name, null),
            });
            _p = this.VALUE<IntId>(_t.p);
        }
        return p;
    }

    public gatherBaselines() {
        const k = this.imported.ofs;
        const n = this.commited;
        let lastValidGroup;

        const ret = [
            {
                i: -1,
                u: "import",
                d: 0,
            },
        ];
        lastValidGroup = ret.at(0);

        for (let i = k; i < n; i++) {
            const op = this.ops[i];
            if ((0 === i || !this.ops[i - 1].group) && op._d && op._u) {
                let m = ret.length;
                let _op = this.ops[ret.at(-1).i];
                if (m === 1 || _op._u !== op._u || _op._d !== op._d) {
                    assert(0 === i || !this.ops[i - 1].group);

                    if (lastValidGroup) {
                        lastValidGroup.i = i;
                    }

                    if (op.target == DataOperationTarget.HIVE) {
                        lastValidGroup = null;
                        continue;
                    }

                    ret.unshift({
                        i: i,
                        u: op._u,
                        d: op._d,
                    });
                    lastValidGroup = ret.at(0);
                }
            }
        }

        if (lastValidGroup) {
            lastValidGroup.i = n;
        }

        return ret;
    }

    public setBaseline(revId: number) {
        if (0 <= revId && revId <= this.commited && (0 === revId || !this.ops[revId - 1].group)) {
            const m = DataModel.loadOps(this.cloneOps(0, revId));
            m.setMaxCommit(revId);
            m.updateModel();
            m.ops = this.ops; // save memory...
            this.baseline = m;
        } else {
            this.baseline = null;
        }
    }

    private _getTaskTree(ctx: {}, c: number[]) {
        if (Array.isArray(c) && c.length > 0) {
            const ret = [];
            for (let i_c = 0; i_c < c.length; i_c++) {
                const t_c = this.tasks[c[i_c]];
                const _c = this._getTaskTree(ctx, t_c._c);
                if (null !== _c) {
                    ret.push({
                        id: c[i_c],
                        name: this.VALUE<string>(t_c.name, null),
                        c: _c,
                    });
                }
            }
            return ret;
        } else {
            return null;
        }
    }

    getTaskTree() {
        const ctx = {};
        const ret = this._getTaskTree(ctx, this.tasks[0]._c);
        return ret;
    }

    getStripesTree() {
        const ctx = this.gatherStripesIfNeeded(0);
        const stripes = ctx.stripes;
        const n_stripes = stripes.length;
        const p: IntId[] = []; // stack
        const stack: any[] = [];
        for (let i_stripe = 0; i_stripe < n_stripes; i_stripe++) {
            const stripe = stripes[i_stripe];
            let i_p = 0;
            while (i_p < stripe._p.length && i_p < p.length && stripe._p[i_p] === p[i_p]) i_p++;
            for (; i_p < p.length; p.pop()) {
                stack[stack.length - 2].c.push(stack.pop());
            }
            while (i_p < stripe._p.length && stripe._p[i_p] >= 0) {
                p.push(stripe._p[i_p]);
                stack.push({
                    id: stripe._p[i_p],
                    name: stripe._h[i_p] || null,
                    c: [],
                });
                i_p++;
            }
        }
        for (; p.length > 1; p.pop()) {
            stack[stack.length - 2].c.push(stack.pop());
        }
        return stack[0];
    }

    setFilterCB(cbs: {
        stripesFilter?: (sc: IntId) => boolean | null;
        stripesTradeFilterCB?: (id: number) => boolean;
        filterX1X2: { _x1: number; _x2: number } | null;
        pidsFilter: (tid: number) => boolean;
        statusFilter: (processId: number) => boolean
    }) {
        if (cbs.stripesFilter !== undefined) {
            this.stripesFilter = cbs.stripesFilter;
        }

        if (cbs.stripesTradeFilterCB !== undefined) {
            this.stripesTradeFilterCB = cbs.stripesTradeFilterCB;
        }

        if (cbs.pidsFilter !== undefined) {
            this.pidsFilter = cbs.pidsFilter;
        }

        if(cbs.statusFilter !== undefined){
            this.statusFilter = cbs.statusFilter;
        }

        if (cbs.filterX1X2 !== undefined) {
            this.filterX1X2 = cbs.filterX1X2;
            this.stripesDateFilterCB = this.filterX1X2
                ? function (this: any, t: DataModelTask) {
                      return DataModel.taskIntersect(this, t);
                  }.bind(this.filterX1X2)
                : null;
        }
        this._stripes[1] = null;
        this.gatherStripesIfNeeded(1); // force recalc stripes
        if (Array.isArray(this.canvasCards)) {
            for (let i = 0; i < this.canvasCards.length; i++) {
                this.canvasCards[i]._ = 0; // force update ...
            }
        }
    }

    setStripeOverride(stripeOverride: number[]) {
        this.stripeOverride = stripeOverride;
        this._stripes[1] = null;
        this.gatherStripesIfNeeded(1);
    }

    addStripeOverride(stripeOverride: number[]) {
        const this_stripeOverride = this.stripeOverride.slice();
        for (let i = 0; i < stripeOverride.length; i++) {
            if (-1 === this_stripeOverride.indexOf(stripeOverride[i])) {
                this_stripeOverride.push(stripeOverride[i]);
            }
        }
        this.setStripeOverride(this_stripeOverride);
    }

    removeStripeOverride(stripeOverride: number[]) {
        const this_stripeOverride = this.stripeOverride.slice();
        for (let i = 0; i < stripeOverride.length; i++) {
            if (this_stripeOverride.indexOf(stripeOverride[i]) >= 0) {
                this_stripeOverride.splice(i, 1);
            }
        }
        this.setStripeOverride(this_stripeOverride);
    }

    public toCSVDate(d: Date) {
        if (d) {
            const _d = d.toISOString();
            return _d.substring(0, 4 + 1 + 2 + 1 + 2);
        } else {
            return "";
        }
    }

    public toCSVStr(str) {
        return '"' + (undefined === str || null === str ? "" : str.toString()).split('"').join('""') + '"';
    }

    exportAsChangesCSV(opt?: {}) {
        const sep = ",";
        const lines = [] as string[];
        {
            const header = [];
            header.push(this.toCSVStr("Change-Id"));
            header.push(this.toCSVStr("Target"));
            header.push(this.toCSVStr("Target-Id"));
            header.push(this.toCSVStr("Operation"));
            header.push(this.toCSVStr("Value"));
            header.push(this.toCSVStr("User"));
            header.push(this.toCSVStr("Date"));
            lines.push(header.join(sep));
        }
        const tasksIds = Object.getOwnPropertyNames(this.tasks).map((_tid) => Number.parseInt(_tid));
        tasksIds.reduce((lines, tid) => {
            const task = this.tasks[tid];
            const user = "";
            const date = new Date();
            const i_p = this.OP_I<number>(task.p, null);
            if (null === i_p) {
                // mark ID as used
                console.log(task.p);
            } else if (-1 === this.ops[i_p].value) {
                // DELETED
                const name = this.VALUE<string>(task.name, "");
                lines.push(
                    [
                        i_p,
                        this.toCSVStr("PROCESS"),
                        this.ops[i_p].id,
                        this.toCSVStr("DELETED"),
                        this.toCSVStr(name),
                        this.toCSVStr(user),
                        this.toCSVDate(date),
                    ].join(sep),
                );
            } else {
                lines.push(
                    [
                        i_p,
                        this.toCSVStr("PROCESS"),
                        this.ops[i_p].id,
                        this.toCSVStr("TAKTZONE"),
                        this.ops[i_p].value,
                        this.toCSVStr(user),
                        this.toCSVDate(date),
                    ].join(sep),
                );
                // export other values
                let i_op;
                if (null !== (i_op = this.OP_I<number>(task.name, null))) {
                    lines.push(
                        [
                            i_op,
                            this.toCSVStr("PROCESS"),
                            this.ops[i_op].id,
                            this.toCSVStr("NAME"),
                            this.toCSVStr(this.ops[i_op].value),
                            this.toCSVStr(user),
                            this.toCSVDate(date),
                        ].join(sep),
                    );
                }
                if (!Array.isArray(task._c)) {
                    //
                    if (null !== (i_op = this.OP_I<number>(task.start, null))) {
                        const start = new Date(this.ops[i_op].value);
                        lines.push(
                            [
                                i_op,
                                this.toCSVStr("PROCESS"),
                                this.ops[i_op].id,
                                this.toCSVStr("START"),
                                this.toCSVDate(start),
                                this.toCSVStr(user),
                                this.toCSVDate(date),
                            ].join(sep),
                        );
                    }
                    if (null !== (i_op = this.OP_I<number>(task.days, null))) {
                        const value = this.ops[i_op].value;
                        const unit = this.ops[i_op].unit || 3;
                        const duration = value + " " + UNIT_TYPES[unit];
                        lines.push(
                            [
                                i_op,
                                this.toCSVStr("PROCESS"),
                                this.ops[i_op].id,
                                this.toCSVStr("DURATION"),
                                this.toCSVStr(duration),
                                this.toCSVStr(user),
                                this.toCSVDate(date),
                            ].join(sep),
                        );
                    }
                }
            }
            return lines;
        }, lines);
        return Buffer.from(
            lines
                .filter((line, i_line) => 0 === i_line || Number.parseInt(line.split(sep)[0]) >= this.imported.ofs)
                .join("\n"),
            "utf-8",
        );
    }

    private _exportAsProcessesCSV(ctx: { lines; sep }, tid: number, level: number) {
        const task = this.tasks[tid];
        if (tid > 0) {
            const start = Array.isArray(task._c) ? 0 : this.VALUE<number>(task.start, 0);
            const days = Array.isArray(task._c) ? -1 : this.VALUE<number>(task.days, -1);
            const duration = days >= 0 ? [days, UNIT_TYPES[this.UNIT(task.days, 3 /* days */)]].join(" ") : "";
            const name = this.VALUE<string>(task.name, "");
            let _start: any = start ? new Date(start) : null;
            if (_start) {
                _start.setUTCHours(8);
                _start = _start.toISOString();
                _start = _start.substring(0, _start.length - 5);
            } else {
                _start = "";
            }
            ctx.lines.push([tid, level, this.toCSVStr(name), _start, this.toCSVStr(duration)].join(ctx.sep));
        }
        if (Array.isArray(task._c)) {
            for (let i = 0; i < task._c.length; i++) {
                this._exportAsProcessesCSV(ctx, task._c[i], level + 1);
            }
        }
    }

    exportAsProcessesCSV(opt?: {}) {
        const sep = ",";
        const lines = [] as string[];
        {
            const header = [];
            header.push(this.toCSVStr("Process-Id"));
            header.push(this.toCSVStr("Level"));
            header.push(this.toCSVStr("Name"));
            header.push(this.toCSVStr("Start"));
            header.push(this.toCSVStr("Duration"));
            lines.push(header.join(sep));
        }
        this._exportAsProcessesCSV({ lines, sep }, 0, 0);
        return Buffer.from(lines.join("\n"), "utf-8");
    }

    exportLog(opt?: {
        startOfs?: number;
        resolveSubs?: (writer, sub_index, name_index, done: () => TableExportHelper) => void;
    }) {
        const sep = ",";
        const writer = new TableExportHelper();
        {
            writer.pushHeader("Log-Id");
            writer.pushHeader("Date");
            writer.pushHeader("User-Id");
            writer.pushHeader("User");
            writer.pushHeader("Change");
            writer.pushHeader("Group");
            writer.pushHeader("Property");
            writer.pushHeader("Value");
            const _startOfs = "number" === typeof opt?.startOfs ? opt.startOfs : this.imported.ofs;
            for (let i_op = _startOfs; i_op < this.commited; i_op++) {
                const op = this.ops[i_op];
                if (!op._rejected) {
                    const u = op._u || null;
                    //const un=opt?.resolveSub?opt.resolveSub(u):null;
                    const un = null;
                    const _d = op._d || null;
                    const d = _d ? new Date(_d * 60 * 1000) : null;
                    let m = "UNDOCUMENTED";
                    let m_prop = "";
                    let m_value: any = JSON.stringify(op);
                    switch (op.target) {
                        case DataOperationTarget.TASKS:
                            m = "TASK#" + op.id.toString(10);
                            switch (op.name) {
                                case "start":
                                    m_prop = "start";
                                    m_value = op.value > 0 ? new Date(op.value) : null;
                                    break;
                                case "days":
                                    m_prop = "duration";
                                    if (op.unit) {
                                        m_value = [op.value, UNIT_TYPES[op.unit] || "?"].join("");
                                    } else {
                                        m_value = op.value;
                                    }
                                    break;
                                case "stripey":
                                    m_prop = "y-index";
                                    m_value = op.value;
                                    break;
                                case "name":
                                    m_prop = "name";
                                    m_value = op.value;
                                    break;
                                case "fs":
                                    m_prop = "fs";
                                    m_value = op.value;
                                    break;
                                case "p":
                                    m_prop = "parent";
                                    m_value = op.value >= 0 ? "TASK#" + op.value.toString(10) : "DELETED";
                                    break;
                                default:
                                    if (!op.name) {
                                        m_prop = "null";
                                        m_value = op.value || null;
                                    } else if (op.name && op.name.startsWith("#A")) {
                                        const match = op.name.match(_LEGACY_ACTIVITY_REGEXP);
                                        if (match?.groups) {
                                            const aid = Number.parseInt(match.groups.aid);
                                            const day = match.groups.day ? Number.parseInt(match.groups.day) : null;
                                            assert(aid >= 0);
                                            m = "CARD#" + op.id.toString(10) + "-" + aid + "#" + day;
                                            switch (match.groups.name) {
                                                case "n":
                                                    m_prop = "name";
                                                    m_value = op.value;
                                                    break;
                                                case "m":
                                                    m_prop = "description";
                                                    m_value = op.value;
                                                    break;
                                                case "y":
                                                    m_prop = "y-index";
                                                    m_value = op.value;
                                                    break;
                                                case "day":
                                                    m_prop = "day";
                                                    if (op.value >= 0) {
                                                        m_value = op.value.toString(10) + "d";
                                                    } else {
                                                        m_value = (-op.value).toString(10) + "ed";
                                                    }
                                                    break;
                                                case "s":
                                                    m_prop = "status";
                                                    switch (op.value) {
                                                        case 0:
                                                            m_value = "OPEN";
                                                            break;
                                                        case 1:
                                                            m_value = "IN_PROGRESS";
                                                            break;
                                                        case 2:
                                                            m_value = "DONE";
                                                            break;
                                                        case 3:
                                                            m_value = "IN_APPROVAL";
                                                            break;
                                                        default:
                                                            m_value = op.value;
                                                            break;
                                                    }
                                                    break;
                                                default:
                                                    if (match.groups.name && match.groups.name.startsWith("lcmx.")) {
                                                        m_prop = match.groups.name;
                                                        m_value = op.value?.value || op.value;
                                                    } else if (
                                                        match.groups.name &&
                                                        27 == match.groups.name.length &&
                                                        "a" === match.groups.name[0]
                                                    ) {
                                                        const blobId = UUID5.fromUUID5(
                                                            match.groups.name.substring(1),
                                                        ).toUUID();
                                                        m_prop = "attachment";
                                                        m_value = blobId;
                                                    } else {
                                                        m_prop = "UNDOCUMENTED " + match.groups.name;
                                                        m_value = "";
                                                    }
                                                    break;
                                            }
                                        }
                                    } else if (op.name && op.name.startsWith("#RW")) {
                                        const res_id = Number.parseInt(op.name.substring(3), 16);
                                        m_prop = "TRADE#" + res_id;
                                        m_value = op.value;
                                    } else if (op.name && op.name.startsWith("#TR")) {
                                        const trade_id = Number.parseInt(op.name.substring(3), 16);
                                        m_prop = "PREDECESSOR#" + trade_id;
                                        m_value = op.value.toString();
                                    } else if (op.name.startsWith("lcmx.")) {
                                        m_prop = op.name;
                                        m_value = op.value?.value || op.value;
                                    } else if (op.name && op.name.startsWith("#C")) {
                                        const comment_id = Number.parseInt(op.name.substring(2), 16);
                                        m_prop = "COMMENT#" + comment_id;
                                        m_value = op.value.toString();
                                    } else {
                                        m_prop = "??? " + JSON.stringify(op);
                                        //m_prop="UNDOCUMENTED "+op.name;
                                    }
                                    break;
                            }
                            break;
                        case DataOperationTarget.TRADE:
                            m = "TRADE#" + op.id.toString(10);
                            break;
                        case DataOperationTarget.ACTIVITY:
                            m = "CARD";
                            break;
                        case DataOperationTarget.COMMENT:
                            //m="COMMENT#"+op.id.toString(10);
                            break;
                    }
                    writer.pushRow([i_op, d, u, un, m, op.group ? 1 : 0, m_prop, m_value]);
                }
            }
        }
        if (opt.resolveSubs) {
            opt.resolveSubs(writer, 2, 3, () => {
                return writer;
            });
            return null;
        } else {
            //return Buffer.from(lines.reduce((ret, line)=>{ret.push(line.join(sep)); return ret;}, []).join('\n'), "utf-8");
            return writer;
        }
    }

    private _getTaskList(
        ctx: {
            i: number;
            ret: any[];
        },
        ta: number[],
        l: number,
        filter: number,
    ) {
        for (let i_ta = 0; i_ta < ta.length; i_ta++) {
            const tid = ta[i_ta];
            const t = this.tasks[tid];
            //ctx.ret.push(tid);
            const ctx_i = ++ctx.i;
            const p = this.VALUE<number>(t.p);
            if (0 === filter || tid === filter) {
                ctx.ret.push({
                    i: ctx_i,
                    id: tid,
                    l: l,
                    _p: p,
                    _i_p: i_ta,
                    name: this.VALUE<string>(t.name),
                    start: this.VALUE<number>(t.start),
                    days: this.VALUE<number>(t.days),
                    unit: this.UNIT(t.days, 3 /*days*/),
                });
            }
            if (Array.isArray(t._c) && t._c.length >= 0) {
                this._getTaskList(ctx, t._c, l + 1, tid === filter ? 0 : filter);
            } else {
            }
        }
    }

    getTaskList(rootFilter?: number) {
        const ctx = {
            i: 0,
            ret: [],
        };
        this._getTaskList(ctx, this.tasks[0]._c, 0, rootFilter || 0);
        return ctx.ret;
    }

    setDuration(taskId: number, duration: number, unit?: number) {
        const t = this.tasks[taskId];
        if (t) {
            //const _days=this.VALUE<number>(t.days);
            //const _unit=this.UNIT(t.days, 3 /* days */);
            if (0 <= duration) {
                this.pushOperation({
                    op: DataOperationType.UPDATE,
                    target: DataOperationTarget.TASKS,
                    id: taskId,
                    name: "days",
                    value: duration,
                    unit: unit || 3 /* days */,
                    z: 0,
                    _u: this.userName,
                    group: false,
                });
            }
        }
    }

    setWorkforce(taskId: number, value: number) {
        const t = this.tasks[taskId];
        if (t) {
            if (value >= 0) {
                this.pushOperation({
                    op: DataOperationType.UPDATE,
                    target: DataOperationTarget.TASKS,
                    id: taskId,
                    name: "wf",
                    value: value,
                    z: 0,
                    _u: this.userName,
                    group: false,
                });
            }
        }
    }

    updateTrade(
        trade: {
            id: number;
            name?: string;
            trade?: string;
            color?: number;
            icon?: string;
            subs?: { [sub: string]: any };
        },
        force?: boolean,
    ) {
        const ops = [];
        const tradeId = trade.id < 0 ? this.maxIds[DataOperationTarget.TRADE] + 1 : trade.id;
        //assert(trade.id<0 || tradeId<=this.maxIds[DataOperationTarget.TRADE]); not always true because of the builder...
        const _t: any = tradeId <= this.maxIds[DataOperationTarget.TRADE] ? this.trades[tradeId] : {};
        if (force || _t) {
            if ("string" === typeof trade.name || null === trade.name || trade.id < 0) {
                ops.push({
                    op: trade.id < 0 ? DataOperationType.CREATE : DataOperationType.UPDATE,
                    target: DataOperationTarget.TRADE,
                    id: tradeId,
                    name: "name",
                    value: trade.name,
                    z: 0,
                    _u: this.userName,
                    group: true,
                });
            }
            if (undefined !== trade.trade) {
                ops.push({
                    op: DataOperationType.UPDATE,
                    target: DataOperationTarget.TRADE,
                    id: tradeId,
                    name: "trade",
                    value: trade.trade,
                    z: 0,
                    _u: this.userName,
                    group: true,
                });
            }
            if ("number" === typeof trade.color) {
                ops.push({
                    op: DataOperationType.UPDATE,
                    target: DataOperationTarget.TRADE,
                    id: tradeId,
                    name: "color",
                    value: trade.color,
                    z: 0,
                    _u: this.userName,
                    group: true,
                });
            }
            if (typeof trade.icon === "string" && trade.icon != "") {
                ops.push({
                    op: DataOperationType.UPDATE,
                    target: DataOperationTarget.TRADE,
                    id: tradeId,
                    name: "icon",
                    value: trade.icon,
                    z: 0,
                    _u: this.userName,
                    group: true,
                });
            }
            if (trade.subs) {
                const subs = Object.getOwnPropertyNames(trade.subs);
                for (let i_sub = 0; i_sub < subs.length; i_sub++) {
                    const sub = subs[i_sub];
                    const sub5 = UUID5.fromUUID(sub);
                    if (sub5) {
                        const s_name = "S_" + sub5.toUUID5();
                        if (false === trade.subs[sub]) {
                            //remove ...
                            if (_t && s_name in _t) {
                                // remove
                                ops.push({
                                    op: DataOperationType.UPDATE,
                                    target: DataOperationTarget.TRADE,
                                    id: tradeId,
                                    name: s_name,
                                    value: null,
                                    z: 0,
                                    _u: this.userName,
                                    group: true,
                                });
                            }
                        } else {
                            // add
                            if (!(_t && s_name in _t)) {
                                ops.push({
                                    op: DataOperationType.UPDATE,
                                    target: DataOperationTarget.TRADE,
                                    id: tradeId,
                                    name: s_name,
                                    value: {},
                                    z: 0,
                                    _u: this.userName,
                                    group: true,
                                });
                            }
                        }
                    }
                }
            }
        }
        if (ops.length > 0) {
            ops[ops.length - 1].group = false;
            this.pushOperation(ops);
        }
    }

    deleteTasks(a_tid: number[]) {
        const ops = [];
        for (let i = 0; i < a_tid.length; i++) {
            const t = this.tasks[a_tid[i]];
            if (t && t.__ >= 0) {
                ops.push({
                    op: DataOperationType.UPDATE,
                    target: DataOperationTarget.TASKS,
                    id: a_tid[i],
                    name: "p",
                    value: -1, // del
                    z: 0,
                    _u: this.userName,
                    group: true,
                });
            } else {
                // do not delete virtual tasks
            }
        }
        if (ops.length > 0) {
            ops[ops.length - 1].group = false;
            this.pushOperation(ops);
        }
    }

    processToJSON(processId: number): DataModelProcessAsJson {
        const t = this.tasks[processId];
        if (t) {
            const ret: DataModelProcessAsJson = {
                id: processId,
                start: t._x1,
                end: t._x2,
                y: t.__y,
                duration: [this.VALUE<EpochDate>(t.days, t._days), this.UNIT(t.days, 3 /* days */)],
                name: this.VALUE<string>(t.name, t._name),
                fs: this.VALUE<number>(t.fs, undefined),
                wf: this.VALUE<number>(t.wf, undefined),
                rw: Object.getOwnPropertyNames(t._rw || {}).reduce((ret, _n) => {
                    const rw_value = t._rw[_n];
                    const res_id = Number.parseInt(_n);
                    if (rw_value < 0) {
                        // virtual task
                        assert(t.__ < 0 && -1 === rw_value);
                        ret[res_id] = 0;
                    } else if (rw_value >= 0) {
                        const op = this.ops[rw_value];
                        assert(op && t.__ >= 0);
                        ret[res_id] = op.value;
                    }
                    return ret;
                }, {}),
                lcmx: Object.getOwnPropertyNames(t)
                    .filter((n) => n.startsWith("lcmx."))
                    .reduce((ret, n) => {
                        const v = this.VALUE<any>(t[n], undefined);
                        if (undefined !== v) {
                            ret[n] = v;
                        }
                        return ret;
                    }, {}),
                A: Object.getOwnPropertyNames(t)
                    .filter((n) => n.startsWith("#A"))
                    .reduce((ret, n) => {
                        const v = this.VALUE<any>(t[n], undefined);
                        if (undefined !== v) {
                            ret[n] = v;
                        }
                        return ret;
                    }, {}),
            };
            const gpa_op = this.OP(t.gpa);
            if (gpa_op) {
                ret.gpa = {
                    value: gpa_op.value,
                    r_sid: gpa_op.r_sid,
                    r_id: gpa_op.r_id,
                    r_ts: gpa_op.r_ts,
                };
            }
            if (Array.isArray(t._c) || this.OP(t.p)?.c /* for children / taktzone */) {
                const _c = (Array.isArray(t._c) ? t._c : []).map((processId) => this.processToJSON(processId));
                ret.children = _c;
            } else {
                assert(undefined === ret.children);
            }
            return ret;
        } else {
            return null;
        }
    }

    private _notficationId = 0;
    putNotification(target: DataOperationTarget, id: IntId, notification: DataModelNotification | null) {
        const nid = [target.toString(16), id.toString(16)].join(".");
        this.notifications[nid] = notification;
        notification._ = ++this._notficationId;
        {
            // add to pending notfications
            const _p = this.pendingNotifications;
            const _n = _p.length;
            let _i = 0;
            while (_i < _n && _p[_i] < nid) _i++;
            if (_i < _n && _p[_i] === nid) {
                // found, do nothing...
            } else {
                _p.splice(_i, 0, nid);
                if ("release" === process.env.NODE_ENV) {
                    for (let i = 1; i < _p.length; i++) {
                        assert(_p[i - 1] < _p[i]);
                    }
                }
            }
        }
    }

    private _onTaskUpdated(tid: IntId) {
        const t = this.tasks[tid];
        if (t) {
            // check cards
            const cd = t._cd || [];
            const n_cd = cd.length;
            for (let i_cd = 0; i_cd < n_cd; i_cd++) {
                const p_op = this.ops[cd[i_cd]];
                assert("p" === p_op.name);
                const cardId = p_op.id;
                const card = this.cards[cardId];
                if (card) {
                    const card_date = EpochMStoEpochDays(this.VALUE<IntId>(card.date));
                    const _x1 = EpochMStoEpochDays(t._x1);
                    const _x2 = EpochMStoEpochDays(t._x2);
                    if (card_date < _x1 || card_date >= _x2) {
                        this.putNotification(DataOperationTarget.TASKS, tid, {
                            title: "Dailyboard",
                            text:
                                "Die Karte " +
                                this.VALUE<string>(card.text, "?") +
                                " befindet sich außerhalb der Datumsgrenzen des Prozess " +
                                this.VALUE<string>(t.name, "?") +
                                ".",
                            button: ["Prozess verschieben...", "Prozess aufsplitten...", "Ignorieren..."],
                        });
                    }
                }
            }
        }
    }

    public updateNotifications() {
        this.notificationsTS = Math.max(this.notificationsTS, this.imported.ofs);
        const _updatedTasks = {};
        const n = this.syncCommited.ofs;
        let i = this.notificationsTS;
        while (i < n) {
            const op = this.ops[i++];
            switch (op.target) {
                case DataOperationTarget.TASKS:
                    _updatedTasks[op.id] = true;
                    break;
                case DataOperationTarget.CARD:
                    {
                        const card = this.cards[op.id];
                        if (card) {
                            const p = this.VALUE<IntId>(card.p);
                            _updatedTasks[p] = true;
                        }
                    }
                    break;
                default:
                    break;
            }
        }
        this.notificationsTS = i;
        const updatedTasks = Object.getOwnPropertyNames(_updatedTasks);
        const n_updatedTasks = updatedTasks.length;
        for (let i_t = 0; i_t < n_updatedTasks; i_t++) {
            this._onTaskUpdated(Number.parseInt(updatedTasks[i_t]));
        }
    }

    public getPendingNotifications(flush?: boolean): CanvasNotificationData[] {
        const ret = this.pendingNotifications.map((nid) => Object.assign({ nid: nid }, this.notifications[nid]));
        if (flush) {
            this.pendingNotifications = [];
        }
        return ret;
    }

    public pushUpdateCardOps(
        ops: DataOperation[],
        card: any,
        ctx: {
            cardsMaxId: number;
        },
    ) {
        console.log("pushUpdateCardOps=" + JSON.stringify(card));
        //if (undefined===card.id || null===card.id || card.id<0) {
        if (!("number" === typeof card.id && card.id >= 0)) {
            // card id not valid
            const templateId = card.id < 0 ? -card.id : undefined;
            card.id = ++ctx.cardsMaxId;
            ops.push({
                op: DataOperationType.CREATE,
                target: DataOperationTarget.CARD,
                id: card.id,
                name: "p",
                value: card.p,
                i: 0,
                d: templateId, // templateId is "deleted"/used/gone..
                z: 0,
                _u: this.userName,
                group: true,
            });
            assert(card.d); // new cards need a date
        }
        if (card.d) {
            ops.push({
                op: DataOperationType.UPDATE,
                target: DataOperationTarget.CARD,
                id: card.id,
                name: "date",
                value: card.d,
                z: 0,
                _u: this.userName,
                group: true,
            });
        }
        if (card.n) {
            ops.push({
                op: DataOperationType.UPDATE,
                target: DataOperationTarget.CARD,
                id: card.id,
                name: "text",
                value: card.n,
                z: 0,
                _u: this.userName,
                group: true,
            });
        }
        if (card.s >= 0) {
            ops.push({
                op: DataOperationType.UPDATE,
                target: DataOperationTarget.CARD,
                id: card.id,
                name: "status",
                value: card.s,
                z: 0,
                _u: this.userName,
                group: true,
            });
        }
        if (card.t >= 0) {
            if (card.p >= 0) {
                const t_ = Array.isArray(card.t_) ? card.t_ : "number" === typeof card.t_ ? [card.t_] : [];
                for (let i = 0; i < t_.length; i++) {
                    if (t_ !== card.t) {
                        // remove existing trades
                        assert_dev(t_ >= 0, "LCM2-725");
                        ops.push({
                            op: DataOperationType.UPDATE,
                            target: DataOperationTarget.TASKS,
                            id: card.p,
                            name: "#RW" + t_.toString(16),
                            value: -1, // removed...
                            z: 0,
                            _u: this.userName,
                            group: true,
                        });
                    }
                }
                assert_dev(card.t >= 0, "LCM2-725");
                ops.push({
                    op: DataOperationType.UPDATE,
                    target: DataOperationTarget.TASKS,
                    id: card.p,
                    name: "#RW" + card.t.toString(16),
                    value: 0,
                    z: 0,
                    _u: this.userName,
                    group: true,
                });
            }
        }
    }

    public static calcGridStart(
        task_start: EpochDate,
        grid: CalendarGrid,
        isGridDay: (date: Date) => boolean,
        dx: number,
        isWorkingDay: (date: Date) => boolean,
    ): {start: number, dx: number} {
        const start_grid = grid.dateToGrid(task_start);
        const { _grid, _x } = CalendarGrid.cloneAndExpandGridIfNeeded(grid, isGridDay, start_grid, dx);
        let originalStart = _grid.gridToDate(_x);
        let movedStart = originalStart;
        if (isWorkingDay) {
            movedStart = DataModel.adjustToWorkingDay(isWorkingDay, originalStart, dx);
        }
        return {start: movedStart, dx: dx + (convertMillisecondsToDays(movedStart) - convertMillisecondsToDays(originalStart))};
    }

    public static adjustToWorkingDay(isWorkDay: (Date) => boolean, date: EpochDate, dx: number) {
        const ret = new Date(date);
        while (!isWorkDay(ret)) {
            if (dx < 0) {
                DataModel.movePrevDay(ret);
            } else {
                DataModel.moveNextDay(ret);
            }
        }
        assert(ret.getTime() === DataModel.EpochMSRoundDays(ret.getTime()));
        return ret.getTime();
    }

    public static dateAddWorkingDays(isWorkDay: (Date) => boolean, _date: EpochDate, delta: number) {
        const date = new Date(_date);
        while (delta > 0) {
            DataModel.moveNextDay(date);
            if (isWorkDay(date)) {
                delta--;
            }
        }
        while (delta < 0) {
            DataModel.movePrevDay(date);
            if (isWorkDay(date)) {
                delta++;
            }
        }
        assert(date.getTime() === DataModel.EpochMSRoundDays(date.getTime()));
        return date.getTime();
    }

    /**
     *
     * @param src
     * @param ctx
     * @param grid
     * @param dx
     * @param dy
     * @param parent is always an area
     * @param isCopyMode
     * @param isWorkingDay
     */
    public insertTaskFromJSON(
        src: DataModelProcessAsJson,
        ctx: { tid: number },
        grid: CalendarGrid,
        dx: number,
        dy: number,
        // taktzone/ area
        parent: {
            pid: IntId;
            i_pid: number;
        },
        isCopyMode: boolean,
        isWorkingDay: (Date) => boolean,
    ): { ops: DataOperation[][]; oldToNewProcessIdMap: OldToNewProcessIdMap, dx: number } {
        const _ops: DataOperation[][] = [];
        const oldToNewProcessIdMap: OldToNewProcessIdMap = new Map();
        if (src) {
            const {start, dx: newDx} =
                src.start < MAX_TASK_DAYS
                    ? {start: Math.max(1, src.start + dx), dx: dx}
                    : isWorkingDay
                      ? DataModel.calcGridStart(src.start, grid, this.gridIsWorkDay, dx, isWorkingDay)
                      : {start: src.start, dx};
            if (start < MAX_TASK_DAYS || !isWorkingDay || isWorkingDay(new Date(start))) {
                dx = newDx;
                const y = src.y;

                //(new) id of process to paste
                const tid = isCopyMode ? ++ctx.tid : src.id;
                oldToNewProcessIdMap.set(src.id, tid);
                assert(this._checkParentLoopOK(tid, parent.pid));
                _ops.push([
                    {
                        op: isCopyMode ? DataOperationType.CREATE : DataOperationType.UPDATE,
                        target: DataOperationTarget.TASKS,
                        id: tid,
                        name: "p",
                        value: parent.pid,
                        i: parent.i_pid++,
                        z: 0,
                        _u: this.userName,
                        group: true,
                        c: Array.isArray(src.children) ? 1 : undefined,
                    } as any,
                ]);
                const ops: DataOperation[] = [];
                ops.push({
                    op: DataOperationType.UPDATE,
                    target: DataOperationTarget.TASKS,
                    id: tid,
                    name: "start",
                    value: 0 < start && start < MAX_TASK_DAYS ? (start - 1) / this.compat.gpaFix + 1 : start,
                    z: 0,
                    _u: this.userName,
                    group: true,
                });
                if (isCopyMode) {
                    ops.push({
                        op: DataOperationType.UPDATE,
                        target: DataOperationTarget.TASKS,
                        id: tid,
                        name: "days",
                        value: src.duration[0],
                        unit: src.duration[1],
                        z: 0,
                        _u: this.userName,
                        group: true,
                    });
                }
                /*
                ops.push({
                    op: DataOperationType.UPDATE,
                    target: DataOperationTarget.TASKS,
                    id: tid,
                    name: "end",
                    value: end,
                    z: 0,
                    _u: this.userName,
                    group: true
                });
                */
                if ("number" === typeof y || 0 !== dy) {
                    ops.push({
                        op: DataOperationType.UPDATE,
                        target: DataOperationTarget.TASKS,
                        id: tid,
                        name: "stripey",
                        value: Math.max(0, (y || 0) + dy),
                        z: 0,
                        _u: this.userName,
                        group: true,
                    });
                }
                if (isCopyMode) {
                    if (src.name) {
                        ops.push({
                            op: DataOperationType.UPDATE,
                            target: DataOperationTarget.TASKS,
                            id: tid,
                            name: "name",
                            value: src.name,
                            z: 0,
                            _u: this.userName,
                            group: true,
                        });
                    }
                    if (src.fs > 0) {
                        ops.push({
                            op: DataOperationType.UPDATE,
                            target: DataOperationTarget.TASKS,
                            id: tid,
                            name: "fs",
                            value: src.fs,
                            z: 0,
                            _u: this.userName,
                            group: true,
                        });
                    }
                    if ("number" === typeof src.wf && src.wf >= 0) {
                        ops.push({
                            op: DataOperationType.UPDATE,
                            target: DataOperationTarget.TASKS,
                            id: tid,
                            name: "wf",
                            value: src.wf,
                            z: 0,
                            _u: this.userName,
                            group: true,
                        });
                    }
                    const rws = Object.getOwnPropertyNames(src.rw || {});
                    rws.forEach((_rid) => {
                        const rid = Number.parseInt(_rid, 10);
                        assert_dev(rid >= 0, "LCM2-725");
                        ops.push({
                            op: DataOperationType.UPDATE,
                            target: DataOperationTarget.TASKS,
                            id: tid,
                            name: "#RW" + rid.toString(16),
                            value: 0, // do not copy value
                            z: 0,
                            _u: this.userName,
                            group: true,
                        });
                    });
                    if (src.lcmx) {
                        Object.getOwnPropertyNames(src.lcmx).forEach((n) => {
                            const v = src.lcmx[n];
                            ops.push({
                                op: DataOperationType.UPDATE,
                                target: DataOperationTarget.TASKS,
                                id: tid,
                                name: n,
                                value: v,
                                z: 0,
                                _u: this.userName,
                                group: true,
                            });
                        });
                    }
                    if (src.A) {
                        Object.getOwnPropertyNames(src.A).forEach((n) => {
                            const v = src.A[n];
                            ops.push({
                                op: DataOperationType.UPDATE,
                                target: DataOperationTarget.TASKS,
                                id: tid,
                                name: n,
                                value: v,
                                z: 0,
                                _u: this.userName,
                                group: true,
                            });
                        });
                    }
                    if (src.gpa) {
                        ops.push({
                            op: DataOperationType.UPDATE,
                            target: DataOperationTarget.TASKS,
                            id: tid,
                            name: "gpa",
                            value: src.gpa.value,
                            r_sid: src.gpa.r_sid,
                            r_id: src.gpa.r_id,
                            r_ts: src.gpa.r_ts,
                            z: 0,
                            _u: this.userName,
                            group: true,
                        });
                    }
                }
                _ops.push(ops);
                if (!isWorkingDay && isCopyMode && Array.isArray(src.children)) {
                    const c = src.children;
                    for (let i = 0; i < c.length; i++) {
                        const { ops, oldToNewProcessIdMap: oldToNew, dx: childDx } = this.insertTaskFromJSON(
                            c[i],
                            ctx,
                            grid,
                            dx,
                            dy,
                            {
                                pid: tid,
                                i_pid: i,
                            },
                            true,
                            null,
                        );
                        dx = childDx;
                        _ops.push(...ops);
                        oldToNew.forEach((newProcessId, oldProcessId) => {
                            oldToNewProcessIdMap.set(oldProcessId, newProcessId);
                        });
                    }
                }
            }
        }
        return {
            ops: _ops,
            oldToNewProcessIdMap,
            dx
        };
    }

    public insertTasksFromJSON(
        src: DataModelProcessAsJson[],
        grid: CalendarGrid,
        dx: number,
        dy: number,
        p: {
            pid: IntId;
            i_pid: number;
            tz_pid: string;
        },
        isCopyMode = false,
    ): { ops: DataOperation[][]; oldToNewProcessIdMap: OldToNewProcessIdMap } {
        const ops: DataOperation[][] = [];
        const oldToNewProcessIdMap: OldToNewProcessIdMap = new Map();
        const isWorkingDay = this.taskCalendarHack;
        const n = src.length;
        const ctx = {
            tid: this.maxIds[DataOperationTarget.TASKS],
        };
        for (let i = 0; i < n; i++) {
            if (
                "number" === typeof p?.pid &&
                p.pid >= 0 &&
                src[i].start > 0 &&
                "number" === typeof src[i].duration[0] &&
                src[i].duration[0] >= 0
            ) {
                const { ops: _ops, oldToNewProcessIdMap: oldToNew, dx: newDx } = this.insertTaskFromJSON(
                    src[i],
                    ctx,
                    grid,
                    dx,
                    dy,
                    p,
                    isCopyMode,
                    isWorkingDay,
                );
                dx = newDx;
                ops.push(..._ops);
                oldToNew.forEach((newProcessId, oldProcessId) => {
                    oldToNewProcessIdMap.set(oldProcessId, newProcessId);
                });
            }
        }
        return { ops, oldToNewProcessIdMap };
    }

    public reduceAndFinalizeOps(ops: DataOperation[][]): DataOperation[] {
        const _ret: DataOperation[] = [];
        ops = ops.map((_ops) => {
            // remove CREATE ops and put them to front
            const _create_ops = _ops.filter((op) => DataOperationType.CREATE === op.op);
            if (_create_ops.length > 0) {
                _ret.push(..._create_ops);
                return _ops.reduce((ret, op) => {
                    if (DataOperationType.CREATE === op.op) {
                        assert("stripey" !== op.name); // remove create op
                    } else {
                        ret.push(op);
                    }
                    return ret;
                }, []);
            } else {
                return _ops;
            }
        });
        ops.filter((_ops) => _ops.findIndex((op) => "stripey" === op.name) < 0).reduce((ret, _ops) => {
            // non stripey ops first
            ret.push(..._ops);
            return ret;
        }, _ret);
        ops.filter((_ops) => _ops.findIndex((op) => "stripey" === op.name) >= 0)
            .sort((_ops1, _ops2) => {
                // stripe ops sorted by stripey value next
                const yOp1 = _ops1.findIndex((op) => "stripey" === op.name);
                const yOp2 = _ops2.findIndex((op) => "stripey" === op.name);
                if (yOp1 >= 0 && yOp2 >= 0) {
                    return _ops1[yOp1].value - _ops2[yOp2].value;
                }
            })
            .reduce((ret, _ops) => {
                ret.push(..._ops);
                return ret;
            }, _ret);
        assert(_ret.reduce((ret, op) => ret && op.group, true));
        if (_ret.length > 0) {
            _ret[_ret.length - 1].group = false;
        }
        return _ret;
    }

    public setCanvasDirtyFlag(taskId: number) {
        const task = this.tasks[taskId];
        if (task) {
            task._canvasDirty = true;
        }
    }

    public setCanvasDirtyFlags(taskIds: number[]) {
        taskIds.forEach((taskId) => {
            this.setCanvasDirtyFlag(taskId);
        });
    }

    static generateCalendarHeader_default(
        C: CanvasViewConst,
        grid: CalendarGrid,
        startDate: number,
        cols: number,
        renderProjectWeeks?: boolean,
    ) {
        const g = grid.grid;
        const ret = {
            cols: cols,
            weeks: [],
            month: [],
        };
        let _cw = -1;
        let _cm = -1;
        let _cy = -1;
        for (let i_grid = 0; i_grid < g.length; i_grid++) {
            for (let d = g[i_grid].d0; d < g[i_grid].d1; d++) {
                const date = new Date(EpochDaystoEpochMS(d));
                const cw = weekNumber(new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000));
                if (_cw !== cw) {
                    ret.weeks.push({
                        left:
                            ret.weeks.length > 0
                                ? ret.weeks[ret.weeks.length - 1].left + ret.weeks[ret.weeks.length - 1].width
                                : 0, // i_grid*C.colPx*1,
                        width: 1 * C.colPx,
                        text: cw.toString(10),
                    });
                    _cw = cw;
                } else {
                    assert(ret.weeks.length > 0 && cw.toString(10) === ret.weeks[ret.weeks.length - 1].text);
                    ret.weeks[ret.weeks.length - 1].width += 1 * C.colPx;
                }
                const cm = date.getUTCMonth();
                const cy = date.getUTCFullYear();
                if (_cm !== cm || _cy !== cy) {
                    ret.month.push({
                        left:
                            ret.month.length > 0
                                ? ret.month[ret.month.length - 1].left + ret.month[ret.month.length - 1].width
                                : 0,
                        width: C.colPx,
                        text:
                            new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
                                .toLocaleString("default", { month: "short" })
                                .toLocaleUpperCase() +
                            " " +
                            date.getUTCFullYear(),
                    });
                    _cm = cm;
                    _cy = cy;
                } else {
                    assert(ret.month.length > 0);
                    ret.month[ret.month.length - 1].width += 1 * C.colPx;
                }
            }
        }
        if (renderProjectWeeks) {
            ret.weeks = ret.weeks.map((w, i) => {
                w.text = `PW ${(i + 1).toString()} \n CW ${w.text}`;
                return w;
            });
        }
        return ret;
    }

    static generateCalendarHeader_days(
        C: CanvasViewConst,
        grid: CalendarGrid,
        startDate: number,
        cols: number,
        meta: CanvasCalendarMeta,
        intl: CanvasCalendarIntl,
        renderProjectWeeks?: boolean,
    ) {
        const isWorkingDay = isWorkingDayHack.bind(meta);
        const g = grid.grid;

        let weeks = [];
        const days = [];
        let _cw = -1;
        for (let i_grid = 0; i_grid < g.length; i_grid++) {
            for (let d = g[i_grid].d0; d < g[i_grid].d1; d++) {
                const date = new Date(EpochDaystoEpochMS(d));
                const cw = weekNumber(new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000));
                if (_cw !== cw) {
                    weeks.push({
                        left: weeks.length > 0 ? weeks[weeks.length - 1].left + weeks[weeks.length - 1].width : 0, // i_grid*C.colPx*1,
                        width: 1 * C.colPx,
                        text: cw.toString(10),
                        m: new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000).toLocaleDateString(
                            undefined,
                            { month: "short" },
                        ),
                        y: new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000).toLocaleDateString(
                            undefined,
                            { year: "numeric" },
                        ),
                    });
                    _cw = cw;
                } else {
                    assert(weeks.length > 0 && cw.toString(10) === weeks[weeks.length - 1].text);
                    weeks[weeks.length - 1].width += 1 * C.colPx;
                }
                days.push({
                    left: days.length * C.colPx,
                    width: C.colPx,
                    text: date.getUTCDate().toString(),
                    d: date.getUTCDay(),
                    wd: isWorkingDay(date),
                });
            }
        }

        if (renderProjectWeeks) {
            weeks = weeks.map((w, i) => {
                w.text = `PW ${(i + 1).toString()}`;
                return w;
            });
        }
        return {
            cols: cols,
            rnd1: 2,
            rnd2: 1,
            month: weeks,
            weeks: days,
        };
    }

    static generateCalendarHeader_order(C: CanvasViewConst, grid: CalendarGrid, startDate: number, cols: number) {
        const g = grid.grid;
        const weeks = [];
        const days = [];
        let i = 0;
        for (let i_grid = 0; i_grid < g.length; i_grid++) {
            for (let d = g[i_grid].d0; d < g[i_grid].d1; d++, i++) {
                const date = d < MAX_TASK_DAYS ? null : new Date(EpochDaystoEpochMS(d));
                weeks.push({
                    left: i * C.colPx,
                    width: C.colPx,
                    text:
                        null != date
                            ? date.toLocaleDateString(undefined, { month: "2-digit", day: "2-digit", year: "2-digit" })
                            : "",
                });
                days.push({
                    left: i * C.colPx,
                    width: C.colPx,
                    text: i.toString(10),
                });
            }
        }
        return {
            cols: cols,
            month: weeks,
            weeks: days,
        };
    }

    static generateCalendarHeader(
        C: CanvasViewConst,
        grid: CalendarGrid,
        startDate: number,
        cols: number,
        meta: CanvasCalendarMeta,
        intl: CanvasCalendarIntl,
        renderProjectWeeks?: boolean,
    ) {
        if (1 === C.colHeader) {
            return this.generateCalendarHeader_days(C, grid, startDate, cols, meta, intl, renderProjectWeeks);
        } else if (2 === C.colHeader) {
            //@TODO do not use C.colHeader to make his decision, but check whether grid<MAX
            //return this.generateCalendarHeader_order(C, grid, startDate, cols);
            //return this.generateCalendarHeader_days(C, grid, startDate, cols);
            return {
                cols: 0,
                month: [],
                weeks: [],
            };
        } else {
            return this.generateCalendarHeader_default(C, grid, startDate, cols, renderProjectWeeks);
        }
    }

    private _addPatchOp(
        patchOps: DataOperation[],
        pendingTargets: { [key: number]: boolean },
        target: any,
        i_op: number,
    ) {
        if (target) {
            const op = this.ops[i_op];
            let _i_op;
            if ((_i_op = this.OP_I(target[op.name], undefined)) === i_op) {
                const patch_op = Object.assign({}, op);
                delete patch_op._ats;
                delete patch_op._value;
                delete patch_op._rejected;
                if (true === pendingTargets[op.id] && DataOperationType.CREATE !== op.op) {
                    delete pendingTargets[op.id];
                    patch_op.op = DataOperationType.CREATE;
                } else {
                    if (DataOperationType.CREATE === op.op) {
                        assert(true === pendingTargets[op.id]);
                        delete pendingTargets[op.id];
                    } else {
                        assert(!pendingTargets[op.id]);
                    }
                }
                patch_op.group = true;
                patchOps.push(patch_op);
            } else {
                assert("number" === typeof _i_op || (undefined === _i_op && 0 !== op._rejected));
            }
        } else {
            assert(0 !== this.ops[i_op]._rejected);
        }
    }

    public createPatch() {
        const import_ts = this.imported.ofs;
        const commit_ts = this.commited;
        assert(this.ops.length === commit_ts);
        const pendingTargets = [{}, {}, {}, {}, {}];
        assert(DataOperationTarget.MAX === pendingTargets.length);
        const patchOps = [];
        for (let i_op = import_ts; i_op < commit_ts; i_op++) {
            const op = this.ops[i_op];
            if (DataOperationType.REJECT_ACCEPT === op.op) {
                if (op.id < import_ts) {
                    // needed
                    assert(false, "AHHH");
                } else {
                    // not needd
                }
            } else if (DataOperationType.NOOP === op.op) {
            } else {
                assert(DataOperationType.CREATE === op.op || DataOperationType.UPDATE === op.op);
                const target = op.target;
                if (DataOperationType.CREATE === op.op && !op._rejected) {
                    pendingTargets[target][op.id] = DataOperationType.CREATE === op.op;
                }
                switch (target) {
                    case DataOperationTarget.TASKS:
                        {
                            this._addPatchOp(patchOps, pendingTargets[target], this.tasks[op.id], i_op);
                        }
                        break;
                    case DataOperationTarget.TRADE:
                        {
                            this._addPatchOp(patchOps, pendingTargets[target], this.trades[op.id], i_op);
                        }
                        break;
                    case DataOperationTarget.CARD:
                        {
                            this._addPatchOp(patchOps, pendingTargets[target], this.cards[op.id], i_op);
                        }
                        break;
                    default:
                        assert(false);
                        break;
                }
            }
        }
        if (patchOps.length > 0) {
            assert(patchOps.reduce((ret, op) => ret && op.group, true));
            patchOps[patchOps.length - 1].group = false;
        }
        return patchOps;
    }

    public gatherWBS(isStripes: boolean, selTIDs?: number[], collapsedTIDs?: number[]): [any, any] {
        const p_scratch = isStripes
            ? Object.getOwnPropertyNames(this.p_scratch)
                  .map((_id) => Number.parseInt(_id))
                  .filter((id) => 0 !== id)
            : null;
        const ret = [];
        collapsedTIDs = Array.isArray(collapsedTIDs) ? collapsedTIDs : [];
        let path0: any[] | null = null;
        let path1: any[] | null = null;
        const _sel = [];
        const stack: any[] = [
            {
                c: null === p_scratch ? [0] : [0, ...p_scratch],
                i: 0,
                l: 0,
                n: -1,
                cl: false, // collapsed
            },
        ];
        while (stack.length > 0) {
            const s = stack[stack.length - 1];
            const n = s.c.length;
            if (s.i < n) {
                const ichld = s.i++;
                s.n = ret.length;
                const tid = s.c[ichld];
                const t = this.tasks[tid];
                assert(t ? true : false);
                const p = this.VALUE<number>(t.p, -1);
                if (1 === stack.length || p >= 0) {
                    if (selTIDs && Array.isArray(selTIDs)) {
                        const i_sel = selTIDs.indexOf(tid);
                        if (i_sel >= 0) {
                            const path = stack.map((item) => ({
                                tid: item.c[item.i - 1],
                                i_tid: item.i - 1,
                                i_wbs: item.n,
                            }));
                            if (null === path0) {
                                assert(null === path1);
                                path0 = path;
                            } else {
                                if (path0.length <= path.length) {
                                    const helper = path0.slice(0, Math.max(path0.length - 1, 0)).reduce((ret, _, i) => {
                                        ret = ret && path0[i].tid === path[i].tid;
                                        return ret;
                                    }, true);
                                    assert(helper);
                                    path1 = path.slice();
                                } else {
                                    _sel.push({ path0, path1 });
                                    path0 = path.slice();
                                    path1 = null;
                                }
                            }
                        } else {
                            if (path0) {
                                if (!s.cl) {
                                    _sel.push({ path0, path1 });
                                    path0 = null;
                                    path1 = null;
                                }
                            }
                        }
                    }
                    const idx = ret.length;
                    const isTZ = Array.isArray(t._c);
                    const _i = tid > 0 ? (this.OP(t.p) as any).i : undefined;
                    const cl = s.cl || collapsedTIDs.indexOf(tid) >= 0;
                    ret.push({
                        idx: idx,
                        tid: tid,
                        l: s.l,
                        n: this.VALUE<string>(t.name, null),
                        s: isTZ ? (cl ? 2 : 1) : 0,
                    });
                    if (isTZ) {
                        stack.push({
                            c: t._c,
                            i: 0,
                            l: s.l + 1,
                            n: idx,
                            cl: cl,
                        });
                    }
                } else {
                    // deleted..
                }
            } else {
                stack.pop();
            }
        }
        /*
        assert(!("development"===process.env.NODE_ENV && Array.isArray(path0)) || path0.reduce((r, e)=>r && e.tid===ret[e.i_wbs].tid, true));
        assert(!("development"===process.env.NODE_ENV && Array.isArray(path1)) || path1.reduce((r, e)=>r && e.tid===ret[e.i_wbs].tid, true));
        //console.log(JSON.stringify({path0, path1}, null, 4));
        */
        if (path0) {
            _sel.push({ path0, path1 });
            path0 = null;
            path1 = null;
        }

        /*
        console.log("----------");
        _sel.forEach((item)=>{
            console.log("- "+JSON.stringify((item.path0||[]).map((item)=>item.tid)));
            console.log("  "+JSON.stringify((item.path1||[]).map((item)=>item.tid)));
        });
        */

        const sels: {
            root: number; // tid
            first_tid: number;
            last_tid: number;
            first_wbs: number;
            last_wbs: number;
        }[] = _sel
            .map((sel) => {
                const l0 = sel.path0.length;
                if (l0 >= 2) {
                    if (sel.path1) {
                        assert(l0 <= sel.path1.length);
                        return {
                            // range selection
                            root: sel.path0[l0 - 2].tid,
                            root_tid: sel.path0[l0 - 2].i_tid,
                            first_tid: sel.path0[l0 - 1].i_tid,
                            last_tid: sel.path1[l0 - 1].i_tid,
                            first_wbs: sel.path0[l0 - 1].i_wbs,
                            last_wbs: sel.path1[l0 - 1].i_wbs,
                        };
                    } else {
                        return {
                            // single selection
                            root: sel.path0[l0 - 2].tid,
                            root_tid: sel.path0[l0 - 2].i_tid,
                            first_tid: sel.path0[l0 - 1].i_tid,
                            last_tid: sel.path0[l0 - 1].i_tid,
                            first_wbs: sel.path0[l0 - 1].i_wbs,
                            last_wbs: sel.path0[l0 - 1].i_wbs,
                        };
                    }
                } else {
                    assert(1 === sel.path0.length && ret.length > 0);
                    return isStripes
                        ? {
                              // root
                              root: sel.path0[l0 - 1].tid,
                              root_tid: -1,
                              first_tid: -1,
                              last_tid: -1,
                              first_wbs: -1,
                              last_wbs: -1,
                          }
                        : null;
                }
            })
            .filter((sel) => null !== sel);

        /*
        console.log("----------");
        sels.forEach((item)=>{
            console.log("- "+JSON.stringify(item));
        });
        */

        /*
        let sel:null|{
            root: number, // tid
            first_tid: number,
            last_tid: number,
            first_wbs: number,
            last_wbs: number
        }=null;
        if (null!==path0) {
            if (null!==path1) {
                let i=0; while(i<path0.length && i<path1.length && path0[i].tid===path1[i].tid) i++;
                if(i<path0.length && i<path1.length) {
                    assert(i>0 && path0[i-1].tid===path1[i-1].tid && path0[i-1].i_wbs===path1[i-1].i_wbs && path0[i-1].i_tid===path1[i-1].i_tid);
                    sel={  // range selection
                        root: path0[i-1].tid,
                        first_tid: path0[i].i_tid,
                        last_tid: path1[i].i_tid,
                        first_wbs: path0[i].i_wbs,
                        last_wbs: path1[i].i_wbs,
                    }
                } else {
                    if (i>1) {
                        assert(path0[i-1].tid===path1[i-1].tid && path0[i-1].i_wbs===path1[i-1].i_wbs && path0[i-1].i_tid===path1[i-1].i_tid);
                        sel={  // single selection
                            root: path0[i-2].tid,
                            first_tid: path0[i-1].i_tid,
                            last_tid: path0[i-1].i_tid,
                            first_wbs: path0[i-1].i_wbs,
                            last_wbs: path0[i-1].i_wbs,
                        }
                    } else {
                        sel=null;
                    }
                }
            } else {
                if (path0.length>1) {
                    const i=path0.length;
                    sel={  // single selection
                        root: path0[i-2].tid,
                        first_tid: path0[i-1].i_tid,
                        last_tid: path0[i-1].i_tid,
                        first_wbs: path0[i-1].i_wbs,
                        last_wbs: path0[i-1].i_wbs,
                    }
                } else {
                    sel=null;
                }
            }
        } else {
            assert(null===path1);
            // no selection...
            sel=null;
        }
        */
        //console.log(JSON.stringify(sel));
        return [ret, sels];
    }

    private static _CANVAS_WBS_ID = 0;
    public canvasWBS: {
        id: number;
        ts: number;
        collapsedTIDs: number[];
        selTIDs: number[];
        helper: { [n: number]: any };
        clipboard: DataModelProcessAsJson[];
        stripes: any[] | null;
        intl: { [key: string]: string };
    } | null = null;
    public initWBS(opt?: { stripes: boolean; intl: { [key: string]: string } }) {
        this.canvasWBS = {
            id: ++DataModel._CANVAS_WBS_ID,
            ts: 0,
            collapsedTIDs: [],
            selTIDs: [],
            helper: [],
            clipboard: [],
            stripes: opt?.stripes ? [] : null,
            intl: opt?.intl || null,
        };
        return this.canvasWBS.id;
    }
    public cleanupWBS(id: number) {
        if (this.canvasWBS?.id === id) {
            this.canvasWBS = null;
        } else {
            // out-of-bound
        }
    }
    public updateWBS(id: number, init?: boolean) {
        const isStripes = Array.isArray(this.canvasWBS.stripes);
        const patch = [];
        const stripes = isStripes ? [] : undefined;
        const patch_sel = undefined;
        if (this.canvasWBS?.id === id) {
            const helper = this.canvasWBS.helper;
            const [wn, sels] = this.gatherWBS(isStripes, this.canvasWBS.selTIDs, this.canvasWBS.collapsedTIDs);
            if (
                true === init &&
                (!Array.isArray(this.canvasWBS.collapsedTIDs) || 0 === this.canvasWBS.collapsedTIDs.length)
            ) {
                // do some initial collpasing...
                assert(!Array.isArray(this.canvasWBS.collapsedTIDs) || 0 === this.canvasWBS.collapsedTIDs.length);
                const tids = [];
                const n = wn.length;
                for (let j = 0; j < n; ) {
                    const i = j;
                    let f = true;
                    for (; j < n && wn[i].l === wn[j].l; j++) f = f && 0 === wn[j].s;
                    if (f && i > 0) {
                        if (1 === wn[i - 1].s) {
                            // adjust _s
                            wn[i - 1].s = 2;
                            tids.push(wn[i - 1].tid);
                        } else {
                            assert(0 === wn[i - 1].s);
                        }
                    }
                }
                this.canvasWBS.collapsedTIDs = tids;
            }
            const n_wn = wn.length;
            const nhelper = {};
            let _y: number = undefined;
            let l_max: number = undefined;
            // create patch; TODO: remove layout here, since its done in _updateRenderStripeTree while rendering
            for (let i_wn = 0; i_wn < n_wn; ) {
                const t: any = wn[i_wn];
                const h = helper[t.tid];
                if (isStripes && 0 === t.l) {
                    l_max = 1;
                    for (let i_wn = 0; i_wn < n_wn; ) {
                        const t: any = wn[i_wn];
                        l_max = Math.max(l_max, t.l);
                        i_wn++;
                        if (2 === t.s) {
                            while (i_wn < n_wn && wn[i_wn].l > t.l) i_wn++; // skip subtree
                        }
                    }
                    _y = 20;
                    const root = this.tasks[t.tid];
                    const p_scratch = this.OP(root.p)?.p_scratch;
                    const x = 0 === t.tid ? 0 : "number" === typeof p_scratch?.x ? p_scratch.x : 200;
                    const y = 0 === t.tid ? 0 : "number" === typeof p_scratch?.y ? p_scratch.y : 200;
                    const w = l_max * 10 + 50;
                    const h = _y;
                    const n = t.n || (0 === t.tid ? this.canvasWBS.intl["Project"] : "");
                    stripes.push({
                        _: root._ || root.__,
                        t: t.tid,
                        e: 0,
                        x,
                        y,
                        w,
                        h,
                        segs: this.textSegmentation(n, w, h, 0, 0),
                    });
                } else if (
                    !h?._f &&
                    h?.tid === t.tid &&
                    h.l === t.l &&
                    h.n === t.n &&
                    h.s === t.s &&
                    h._s === t._s &&
                    h._y === t._y &&
                    h._h === t._h
                ) {
                    // exists and is not changed...
                    const h_i = h.i;
                    h.i = patch.length;
                    patch.push(h_i);
                    assert(t.tid === h.tid);
                    nhelper[t.tid] = h;
                } else {
                    // new
                    t.i = patch.length;
                    if (isStripes) {
                        const _H = 20;
                        let _h = _H;
                        if (2 !== t.s) {
                            for (let j_wn = i_wn + 1; j_wn < n_wn && wn[j_wn].l > t.l; ) {
                                const _t = wn[j_wn++];
                                if (2 === _t.s) {
                                    while (j_wn < n_wn && wn[j_wn].l > _t.l) j_wn++; // skip subtree
                                }
                                _h += _H;
                            }
                        }
                        t._y = _y;
                        t._h = _h;
                        const xofs = t.l * 10;
                        const left = xofs;
                        const right = stripes[stripes.length - 1].w; // (l_max)*10+50;
                        stripes[stripes.length - 1].h = Math.max(stripes[stripes.length - 1].h, t._y + t._h);
                        //stripes[stripes.length-1].e++;
                        patch.push({
                            id: t.tid,
                            left,
                            right,
                            top: t._y,
                            h: t._h,
                            stripe: stripes.length - 1,
                            level: t.l,
                            s: t.s,
                            segs: this.textSegmentation(t.n, right - left, _H, 0, 0),
                        });
                        _y += 20;
                    } else {
                        patch.push(t);
                    }
                    nhelper[t.tid] = t;
                }
                i_wn++;
                if (2 === t.s) {
                    while (i_wn < n_wn && wn[i_wn].l > t.l) i_wn++; // skip subtree
                }
            }
            if (isStripes && wn.length > 0) {
                //assert(false); //@TODO
            }
            this.canvasWBS.helper = nhelper;
        } else {
            // out-of-bound...
        }
        return {
            id: this.canvasWBS.id,
            ts: this.canvasWBS.ts,
            patch: patch,
            patch_sel: patch_sel,
            readonly: this.viewConst.readonly,
            stripes: stripes,
        };
    }

    public insertWBSRows(id: number, ts: number, tid: number, count: number) {
        if (this.canvasWBS?.id === id) {
            assert(this.canvasWBS.ts + 1 === ts);
            this.canvasWBS.ts = ts;
            const t = this.tasks[tid];
            if (t) {
                const pid = this.VALUE<number>(t.p);
                if (pid >= 0) {
                    const p = this.tasks[pid];
                    if (p) {
                        const c = p._c;
                        if (Array.isArray(c)) {
                            const i_c = c.indexOf(tid);
                            if (i_c >= 0) {
                                let _tid = this.maxIds[DataOperationTarget.TASKS];
                                this.pushOperation({
                                    op: DataOperationType.CREATE,
                                    target: DataOperationTarget.TASKS,
                                    id: ++_tid,
                                    name: "p",
                                    value: pid,
                                    i: i_c,
                                    z: 0,
                                    _u: this.userName,
                                    c: 1,
                                    group: false,
                                });
                            }
                        }
                    }
                }
            }
        } else {
            // out-of-bound...
        }
    }

    public deleteWBSRow(ops: DataOperation[], id: number, ts: number, tids: number[]) {
        if (this.canvasWBS?.id === id) {
            assert(this.canvasWBS.ts + 1 === ts);
            this.canvasWBS.ts = ts;
            const collapsedTIDs = Array.isArray(this.canvasWBS.collapsedTIDs) ? this.canvasWBS.collapsedTIDs : [];
            const [wn, sels] = this.gatherWBS(Array.isArray(this.canvasWBS.stripes), tids, collapsedTIDs);
            if (sels.length > 0) {
                for (let i_sel = 0; i_sel < sels.length; i_sel++) {
                    const sel = sels[i_sel];
                    const r = this.tasks[sel.root];
                    assert(
                        r &&
                            Array.isArray(r._c) &&
                            0 <= sel.first_tid &&
                            sel.first_tid <= sel.last_tid &&
                            sel.last_tid < r._c.length,
                    );
                    for (let i_c = sel.first_tid; i_c <= sel.last_tid; i_c++) {
                        const tid = r._c[i_c];
                        ops.push({
                            op: DataOperationType.UPDATE,
                            target: DataOperationTarget.TASKS,
                            id: tid,
                            name: "p",
                            value: -1,
                            i: 0,
                            z: 0,
                            _u: this.userName,
                            group: true,
                        });
                    }
                }
            }
            /*
            const t=this.tasks[tid];
            if (t) {
                this.pushOperation({
                    op: DataOperationType.UPDATE,
                    target: DataOperationTarget.TASKS,
                    id: tid,
                    name: "p",
                    value: -1,
                    i: 0,
                    z: 0,
                    _u: this.userName,
                    c: 1,
                    group: false
                });
            }
*/
        } else {
            // out-of-bound...
        }
    }

    public indentWBSRow(ops: DataOperation[], id: number, ts: number, tids: number[]) {
        if (this.canvasWBS?.id === id) {
            assert(this.canvasWBS.ts + 1 === ts);
            this.canvasWBS.ts = ts;
            const collapsedTIDs = Array.isArray(this.canvasWBS.collapsedTIDs) ? this.canvasWBS.collapsedTIDs : [];
            const [wn, sels] = this.gatherWBS(Array.isArray(this.canvasWBS.stripes), tids, collapsedTIDs);
            if (sels.length > 0) {
                for (let i_sel = 0; i_sel < sels.length; i_sel++) {
                    const sel = sels[i_sel];
                    const r = this.tasks[sel.root];
                    assert(
                        r &&
                            Array.isArray(r._c) &&
                            0 <= sel.first_tid &&
                            sel.first_tid <= sel.last_tid &&
                            sel.last_tid < r._c.length,
                    );
                    const c_first = sel.first_tid;
                    const c_last = sel.last_tid;
                    if (c_first > 0 && c_first <= c_last) {
                        const c = r._c;
                        const p = c[c_first - 1];
                        const p_c = this.tasks[p]._c || [];
                        const p_c_n = p_c.length;
                        const i_clps = collapsedTIDs.indexOf(p);
                        if (-1 === i_clps) {
                            // do not indent on collapsed paragraphs
                            if (i_clps >= 0) {
                                collapsedTIDs.splice(i_clps, 1); // uncollapse parent
                            }
                            for (let i_c = c_first; i_c <= c_last; i_c++) {
                                const _c = this.OP(this.tasks[c[i_c]].p).c;
                                assert(this._checkParentLoopOK(c[i_c], p));
                                ops.push({
                                    op: DataOperationType.UPDATE,
                                    target: DataOperationTarget.TASKS,
                                    id: c[i_c],
                                    name: "p",
                                    value: p,
                                    i: p_c_n + i_c - c_first,
                                    z: 0,
                                    _u: this.userName,
                                    c: _c,
                                    group: true,
                                } as any);
                            }
                        }
                    }
                }
            }
        } else {
            // out-of-bound...
        }
    }

    public outdentWBSRow(ops: DataOperation[], id: number, ts: number, tids: number[]) {
        if (this.canvasWBS?.id === id) {
            assert(this.canvasWBS.ts + 1 === ts);
            this.canvasWBS.ts = ts;
            const collapsedTIDs = Array.isArray(this.canvasWBS.collapsedTIDs) ? this.canvasWBS.collapsedTIDs : [];
            const [wn, sels] = this.gatherWBS(Array.isArray(this.canvasWBS.stripes), tids, collapsedTIDs);
            if (sels.length > 0) {
                for (let i_sel = 0; i_sel < sels.length; ) {
                    // fix sel end
                    const sel = sels[i_sel];
                    const r = this.tasks[sel.root];
                    assert(
                        r &&
                            Array.isArray(r._c) &&
                            0 <= sel.first_tid &&
                            sel.first_tid <= sel.last_tid &&
                            sel.last_tid < r._c.length,
                    );
                    sel.last_tid = r._c.length - 1;
                    sel.last_Wbs = -1; // no longer valid
                    for (i_sel++; i_sel < sels.length && sel.root === sels[i_sel].root; i_sel++) {
                        sels.splice(i_sel, 1);
                    }
                }
                for (let i_sel = 0; i_sel < sels.length; i_sel++) {
                    const sel = sels[i_sel];
                    if (sel.root > 0) {
                        const r = this.tasks[sel.root];
                        const r_p = this.VALUE<number>(r.p, -1);
                        assert(
                            r &&
                                r_p >= 0 &&
                                this.tasks[r_p]._c[sel.root_tid] === sel.root &&
                                Array.isArray(r._c) &&
                                0 <= sel.first_tid &&
                                sel.first_tid <= sel.last_tid &&
                                sel.last_tid < r._c.length,
                        );
                        const c = r._c || [];
                        const c_n = c.length;
                        for (let i_c = sel.first_tid; i_c < c_n; i_c++) {
                            const _c = this.OP(this.tasks[c[i_c]].p).c;
                            assert(this._checkParentLoopOK(c[i_c], r_p));
                            ops.push({
                                op: DataOperationType.UPDATE,
                                target: DataOperationTarget.TASKS,
                                id: c[i_c],
                                name: "p",
                                value: r_p,
                                i: sel.root_tid + 1 + i_c - sel.first_tid,
                                z: 0,
                                _u: this.userName,
                                c: _c,
                                group: true,
                            } as any);
                        }
                    }
                }
            }
        } else {
            // out-of-bound...
        }
    }

    public renameWBSRow(id: number, ts: number, tid: number, name: string) {
        if (this.canvasWBS?.id === id) {
            assert(this.canvasWBS.ts + 1 === ts);
            this.canvasWBS.ts = ts;
            const t = this.tasks[tid];
            if (t) {
                this.pushOperation({
                    op: DataOperationType.UPDATE,
                    target: DataOperationTarget.TASKS,
                    id: tid,
                    name: "name",
                    value: name,
                    z: 0,
                    _u: this.userName,
                    group: false,
                });
            }
        } else {
            // out-of-bound...
        }
    }

    public appendWBSRow(id: number, ts: number, name: string) {
        if (this.canvasWBS?.id === id) {
            assert(this.canvasWBS.ts + 1 === ts);
            this.canvasWBS.ts = ts;
            const tid = this.maxIds[DataOperationTarget.TASKS] + 1;
            const c = this.tasks[0]._c || [];
            this.pushOperation({
                op: DataOperationType.CREATE,
                target: DataOperationTarget.TASKS,
                id: tid,
                name: "p",
                value: 0,
                i: c.length,
                c: 1,
                z: 0,
                _u: this.userName,
                group: false,
            });
            this.pushOperation({
                op: DataOperationType.UPDATE,
                target: DataOperationTarget.TASKS,
                id: tid,
                name: "name",
                value: name,
                z: 0,
                _u: this.userName,
                group: false,
            });
        } else {
            // out-of-bound...
        }
    }

    public collapse(id: number, ts: number, tid: number, collapse: boolean) {
        if (this.canvasWBS?.id === id) {
            assert(this.canvasWBS.ts + 1 === ts);
            this.canvasWBS.ts = ts;
            const i = this.canvasWBS.collapsedTIDs.indexOf(tid);
            if (collapse && -1 === i) {
                this.canvasWBS.collapsedTIDs.push(tid);
            } else if (!collapse && i >= 0) {
                this.canvasWBS.collapsedTIDs.splice(i, 1);
            }
        } else {
            // out-of-bound...
        }
    }

    /*
    public REMOVE_ME_treeSelect(id:number, ts:number, tids:number[]) {
        if (this.canvasWBS?.id===id) {
            assert(this.canvasWBS.ts+1===ts);
            this.canvasWBS.ts=ts;
            const wbs=this.canvasWBS.wbs
            let path0:number[]|null=null;
            let path1:number[]|null=null;
            const stack=[];
            const n=wbs.length;
            for(let i=0;i<n;i++) {
                while(wbs[i].l<stack.length) {
                    stack.pop();
                }
                const tid=wbs[i].tid;
                stack.push(tid);
                const i_tid=tids.indexOf(tid);
                if (i_tid>=0) {
                    if (null===path0) {
                        path0=stack.slice();
                    } else {
                        path1=stack.slice();
                    }
                }
            }
            if (null!==path0) {
                if (null!==path1) {
                    let i=0; while(i<path0.length && i<path1.length && path0[i]===path1[i]) i++;
                    if(i<path0.length && i<path1.length) {
                        assert(i>0 && path0[i-1]===path1[i-1]);
                        this.canvasWBS.treeSel=[path0[i-1], path0[i], path1[i]]; // range selection
                    } else {
                        if (i>0) {
                            assert(path0[i-1]===path1[i-1]);
                            this.canvasWBS.treeSel=path0[path0.length-1]; // single selection
                        } else {
                            this.canvasWBS.treeSel=null;
                        }
                    }
                } else {
                    if (path0.length>0) {
                        this.canvasWBS.treeSel=path0[path0.length-1]; // single selection
                    } else {
                        this.canvasWBS.treeSel=null;
                    }
                }
            } else {
                assert(null===path1);
                // no selection...
                this.canvasWBS.treeSel=null;
            }
            console.log("SELCHG "+JSON.stringify(this.canvasWBS.treeSel));
        } else {
            // out-of-bound...
        }
    }
    */
    public selectWBSTIDs(id: number, ts: number, tids: number[]) {
        if (this.canvasWBS?.id === id) {
            assert(this.canvasWBS.ts + 1 === ts);
            this.canvasWBS.ts = ts;
            this.canvasWBS.selTIDs = tids;
        }
    }

    public copyWBS(ops: DataOperation[], id: number, ts: number, tids: number[], cut?: boolean) {
        if (this.canvasWBS?.id === id) {
            assert(this.canvasWBS.ts + 1 === ts);
            this.canvasWBS.ts = ts;
            this.canvasWBS.clipboard = [];
            const [wn, selectedAreas] = this.gatherWBS(
                Array.isArray(this.canvasWBS.stripes),
                tids,
                this.canvasWBS.collapsedTIDs,
            );
            if (selectedAreas.length > 0) {
                for (let selection = 0; selection < selectedAreas.length; selection++) {
                    // fix sel end
                    const currentSelection = selectedAreas[selection];
                    if (currentSelection.root_tid >= 0) {
                        const r = this.tasks[currentSelection.root];
                        assert(
                            r &&
                                Array.isArray(r._c) &&
                                0 <= currentSelection.first_tid &&
                                currentSelection.first_tid <= currentSelection.last_tid &&
                                currentSelection.last_tid < r._c.length,
                        );
                        for (
                            let childIndex = currentSelection.first_tid;
                            childIndex <= currentSelection.last_tid;
                            childIndex++
                        ) {
                            const processId = r._c[childIndex];
                            const taskJSON = this.processToJSON(processId);
                            this.canvasWBS.clipboard.push(taskJSON);
                            if (cut) {
                                ops.push({
                                    op: DataOperationType.UPDATE,
                                    target: DataOperationTarget.TASKS,
                                    id: processId,
                                    name: "p",
                                    value: -1,
                                    i: 0,
                                    z: 0,
                                    _u: this.userName,
                                    group: true,
                                });
                            }
                        }
                    } else {
                        assert(
                            currentSelection.root >= 0 &&
                                -1 === currentSelection.root_tid &&
                                -1 === currentSelection.first_tid &&
                                -1 === currentSelection.last_tid &&
                                -1 === currentSelection.first_wbs &&
                                -1 === currentSelection.last_wbs,
                        );
                        const tid = currentSelection.root;
                        const taskJSON = this.processToJSON(tid);
                        this.canvasWBS.clipboard.push(taskJSON);
                        if (cut) {
                            ops.push({
                                op: DataOperationType.UPDATE,
                                target: DataOperationTarget.TASKS,
                                id: tid,
                                name: "p",
                                value: -1,
                                i: 0,
                                z: 0,
                                _u: this.userName,
                                group: true,
                            });
                        }
                    }
                }
            }
        }
    }

    public pasteWBS(
        id: number,
        ts: number,
        tid: number,
        isCopyMode: boolean,
    ): { ops: DataOperation[][]; oldToNewProcessIdMapCollection: OldToNewProcessIdMap[] } {
        const ops: DataOperation[][] = [];
        const oldToNewProcessIdMapCollection: Array<Map<number, number>> = [];
        if (this.canvasWBS?.id === id) {
            assert(this.canvasWBS.ts + 1 === ts);
            this.canvasWBS.ts = ts;
            if (Array.isArray(this.canvasWBS.clipboard)) {
                let p: number = -1;
                let i_p: number;
                if (tid > 0) {
                    const t = this.tasks[tid];
                    if (t) {
                        p = this.VALUE<number>(t.p);
                        i_p = this.tasks[p]._c.indexOf(tid);
                        assert(i_p >= 0 && tid === this.tasks[p]._c[i_p]);
                    }
                } else if (-1 === tid && Array.isArray(this.tasks[0]._c)) {
                    p = 0;
                    i_p = this.tasks[0]._c.length;
                }
                if (p >= 0) {
                    assert(0 <= i_p && i_p <= this.tasks[p]._c.length);
                    const ctx = {
                        tid: this.maxIds[DataOperationTarget.TASKS],
                    };
                    for (let i_clp = 0; i_clp < this.canvasWBS.clipboard.length; i_clp++) {
                        const src = this.canvasWBS.clipboard[i_clp];
                        const { ops: _ops, oldToNewProcessIdMap: oldToNew } = this.insertTaskFromJSON(
                            src,
                            ctx,
                            null,
                            0,
                            0,
                            {
                                pid: p,
                                i_pid: i_p + i_clp,
                            },
                            isCopyMode,
                            null,
                        );
                        if (ops) {
                            ops.push(..._ops);
                            oldToNewProcessIdMapCollection.push(oldToNew);
                        }
                    }
                }
            }
        }
        return { ops, oldToNewProcessIdMapCollection };
    }

    public moveWBS(ops: DataOperation[], id: number, ts: number, tid: number, moveToId: number) {
        if (this.canvasWBS?.id === id) {
            assert(this.canvasWBS.ts + 1 === ts);
            this.canvasWBS.ts = ts;
            const t = this.tasks[tid];
            const moveTo = this.tasks[moveToId];
            if (t && moveTo) {
                const pid = this.VALUE<number>(moveTo.p);
                if (pid >= 0) {
                    const i_p = this.tasks[pid]._c.indexOf(moveToId);
                    assert(i_p >= 0 && moveToId === this.tasks[pid]._c[i_p]);
                    try {
                        assert(this._checkParentLoopOK(tid, pid));
                        ops.push({
                            op: DataOperationType.UPDATE,
                            target: DataOperationTarget.TASKS,
                            id: tid,
                            name: "p",
                            value: pid,
                            i: i_p,
                            z: 0,
                            _u: this.userName,
                            group: true,
                        });
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        }
    }

    public cloneWBS(
        id: number,
        ts: number,
        tid: number,
        opt: { count: number; gpa?: number },
    ): { ops: DataOperation[][]; oldToNewProcessIdMapCollection: OldToNewProcessIdMap[] } {
        const ops: DataOperation[][] = [];
        const oldToNewProcessIdMapCollection: OldToNewProcessIdMap[] = [];
        if (this.canvasWBS?.id === id) {
            assert(this.canvasWBS.ts + 1 === ts);
            this.canvasWBS.ts = ts;
            const t = this.tasks[tid];
            if (t) {
                const pid = this.VALUE<number>(t.p, -1);
                const p = pid >= 0 ? this.tasks[pid] : null;
                if (Array.isArray(p?._c)) {
                    const i_pid = p._c.indexOf(tid);
                    if (i_pid >= 0) {
                        const ctx = {
                            tid: this.maxIds[DataOperationTarget.TASKS],
                        };
                        assert(tid === p._c[i_pid]);
                        const copy = this.processToJSON(tid);
                        if ("number" === typeof opt?.gpa && opt.gpa > 0) {
                            const gpaModel = this.canvasWhiteboards.model;
                            let gpaTask;
                            if (gpaModel && gpaModel.VALUE<number>((gpaTask = gpaModel.tasks[opt.gpa])?.p, -1) >= 0) {
                                copy.gpa = {
                                    value: {
                                        name: gpaModel.VALUE<string>(gpaTask.name, ""),
                                    },
                                    r_sid: gpaModel.storageName,
                                    r_id: opt.gpa,
                                    r_ts: gpaModel.commitTS(),
                                };
                                ops.push([
                                    {
                                        op: DataOperationType.UPDATE,
                                        target: DataOperationTarget.TASKS,
                                        id: tid,
                                        name: "gpa",
                                        value: copy.gpa.value,
                                        r_sid: copy.gpa.r_sid,
                                        r_id: copy.gpa.r_id,
                                        r_ts: copy.gpa.r_ts,
                                        z: 0,
                                        _u: this.userName,
                                        group: true,
                                    },
                                ]);
                            }
                        }
                        for (let i = 1; i < opt.count; i++) {
                            const { ops: _ops, oldToNewProcessIdMap: oldToNew } = this.insertTaskFromJSON(
                                copy,
                                ctx,
                                null,
                                0,
                                0,
                                {
                                    pid: pid,
                                    i_pid: i_pid + i,
                                },
                                true,
                                null,
                            );
                            ops.push(..._ops);
                            oldToNewProcessIdMapCollection.push(oldToNew);
                        }
                    }
                }
            }
        }
        return { oldToNewProcessIdMapCollection, ops };
    }

    /* REALLY NEEDED?
    private cmpCanvasCards(c1:CanvasCardData, c2:CanvasCardData) {
        let ret=c1.p-c2.p;
        if (0===ret) {
            ret=c1.d-c2.d;
            if (0===ret) {
                ret=c1.y-c2.y;
                if (0===ret) {
                    ret=c2.y_-c1.y_;
                    if (0===ret) {
                        const c1_id=c1.id.split('_');
                        const c1_pid=Number.parseInt(c1_id[1], 16);
                        const c1_aid=Number.parseInt(c1_id[2], 16);
                        const c1_i=Number.parseInt(c1_id[3], 16);
                        const c2_id=c2.id.split('_');
                        const c2_pid=Number.parseInt(c2_id[1], 16);
                        const c2_aid=Number.parseInt(c2_id[2], 16);
                        const c2_i=Number.parseInt(c2_id[3], 16);
                        assert(c1_pid===c1.p && c2_pid===c2.p);
                        ret=c1_aid-c2_aid;
                        if (0===ret) {
                            ret=c1_i-c2_i;
                        }
                    }
                }
            }
        }
        return ret;
    }
    */

    /*
    public gatherActivitiesForTask(tid:number, opt?:{
        resolve?: {
            wf:boolean,
            name: boolean
        }
    }): { _:number, cards:any[], tid: any, _y_max:number, aids:any, t:any } {
        //assert(false);
        return {
            _: 0,
            cards: [],
            tid,
            _y_max: 0,
            aids: [],
            t: null
        };
    }
    */

    public _updateActivitiesForStripes(ctx: {
        stripes_ctx: any;
        stripes: (CanvasStripeData & CanvasStripeDataActivityExt)[];
    }) {
        //@TODO add cache !!!: first iter strips and then cards..
        const _ctx = {
            model: this,
        };
        const n_stripes = ctx.stripes.length;
        for (let i_stripe: number = 0; i_stripe < n_stripes; i_stripe++) {
            // @TODO check "_a_" for lazy updates (we need a "_s_" to compare to ...)
            let h = 0;
            const a: CanvasCardData[] = [];
            let _ = 0;
            const stripe = ctx.stripes[i_stripe];
            const c = this.tasks[stripe.t]._c;
            if (false && 24719 === stripe.t) {
                console.log("AHHH");
            }
            const _h = stripe._y - (i_stripe > 0 ? (ctx.stripes[i_stripe - 1] as any)._y : 0);
            if (Array.isArray(c) && c.length > 0) {
                const delta = new Int32Array(_h);
                assert(0 <= stripe.i && stripe.j <= c.length);
                for (let i_c = stripe.i; i_c < stripe.j; i_c++) {
                    const tid = c[i_c];
                    const t = this.tasks[tid];
                    if (i_stripe === t._stripe1) {
                        if (false && 43217 === tid) {
                            console.log("AHHH");
                        }
                        const ret = this.callbacks._coreUpdateActivities(_ctx, tid);
                        assert(0 <= t.__y && t.__y < _h);
                        delta[t.__y] = Math.max(delta[t.__y], ret._y_max);
                        _ = Math.max(_, ret._);
                        a.push(...ret.cards);
                    }
                }
                // pre_calc delta
                for (let i = 1; i < _h; i++) delta[i] += delta[i - 1];
                assert(0 === h);
                const n_a = a.length;
                for (let i_a = 0; i_a < n_a; i_a++) {
                    const t = this.tasks[a[i_a].p];
                    if (false && (2 === a[i_a].p || 249 === a[i_a].p)) {
                        console.log("AHHH");
                    }
                    assert(0 <= t.__y && t.__y < _h);
                    a[i_a].y = t.__y + a[i_a].y + (t.__y > 0 ? delta[t.__y - 1] : 0);
                    h = Math.max(h, a[i_a].y + 1);
                }
                //a.sort(this.cmpCanvasCards); REALLY NEEDED
                if (false && h > 0) {
                    console.log("" + i_stripe + " " + stripe._ah + " " + stripe._a.length);
                }
            }
            stripe._a = a;
            stripe._ah = h;
            stripe._a_ = _;
        }
    }

    private static eqCells(cells1: any, cells2: any) {
        return cells1.length === cells2.length && JSON.stringify(cells1) === JSON.stringify(cells2); // hack!!! @TODO speed me up
    }

    public updateActivities() {
        if (!Array.isArray(this.canvasCards)) {
            return;
        }
        assert(this.callbacks._coreUpdateActivities); // not set?!

        const meta = {
            d0: null,
            d1: null,
            df: this.filterX1X2,
        };
        const stripes_ctx = this.stackTasksIfNeeded();
        const stripes: (CanvasStripeData & CanvasStripeDataActivityExt)[] = stripes_ctx.stripes as (CanvasStripeData &
            CanvasStripeDataActivityExt)[];
        const n_stripes = stripes.length;
        const ctx = {
            stripes_ctx: stripes_ctx,
            stripes: stripes,
        };
        this._updateActivitiesForStripes(ctx);
        // update y taking activities hights into account...
        const canvasCards = []; //@TODO patch!!!!
        let _ = 0;
        for (let i_s = 0; i_s < n_stripes; i_s++) {
            const stripe = stripes[i_s];
            _ = Math.max(_, stripe._a_);
            stripe._ay = (i_s > 0 ? (stripes[i_s - 1] as any)._ay : 0) + stripe._ah;
            const filterCB = this.filterX1X2
                ? function (card) {
                      return this._d1 <= card.d && card.d < this._d2;
                  }.bind({
                      _d1: EpochMStoEpochDays(this.filterX1X2._x1),
                      _d2: EpochMStoEpochDays(this.filterX1X2._x2),
                  })
                : () => true;
            const [cards, ah] = (stripe._a as CanvasCardData[]).reduce(
                (ret, a) => {
                    if (filterCB(a)) {
                        if (null === meta.d0 || a.d < meta.d0) {
                            meta.d0 = a.d;
                        }
                        if (null === meta.d1 || a.d > meta.d1) {
                            meta.d1 = a.d;
                        }
                        ret[0].push(a);
                        ret[1] = Math.max(ret[1], a.y + 1);
                    }
                    return ret;
                },
                [[] as CanvasCardData[], 0],
            );

            const cells = new Array(stripe._s.length - 1);
            for (let i_cell = 0; i_cell < cells.length; i_cell++) {
                const j_cell = i_cell + 1;
                let hSpan = stripe._p[j_cell] < 0 ? 0 : 1;
                while (hSpan && j_cell + hSpan < stripe._s.length && stripe._p[j_cell] === -stripe._p[j_cell + hSpan])
                    hSpan++;
                const name =
                    stripe._p[j_cell] >= 0 && stripe._s[j_cell] >= 0
                        ? this.VALUE<string>(this.tasks[stripe._p[j_cell]].name, "")
                        : null;
                cells[i_cell] = {
                    name: name,
                    vSpan: stripe._s[j_cell] + 1,
                    hSpan: hSpan,
                    t: stripe._p[j_cell],
                };
            }
            canvasCards.push({
                _: stripe._a_,
                s: { t: stripe.t, i: stripe.i, j: stripe.j },
                name: stripe.label || this.VALUE(this.tasks[stripe.t]?.name, ""),
                image: this.VALUE<any>(this.tasks[stripe.t]?.image, undefined),
                cards: cards,
                y_max: ah, // stripe._ay-(i_s>0?(stripes[i_s-1] as any)._ay:0),
                _i_s: i_s,
                cells: cells,
            });
        }

        const patch = [];
        let j = 0;
        for (let i = 0; i < canvasCards.length; i++) {
            for (
                ;
                j < this.canvasCards.length &&
                !(
                    this.canvasCards[j].s.t === canvasCards[i].s.t &&
                    this.canvasCards[j].s.i === canvasCards[i].s.i &&
                    this.canvasCards[j].s.j === canvasCards[i].s.j &&
                    this.canvasCards[j].y_max === canvasCards[i].y_max &&
                    DataModel.eqCells(this.canvasCards[j].cells, canvasCards[i].cells)
                );
                j++
            ) {
                if (patch.length > 0 && patch[patch.length - 1] < 0) {
                    patch[patch.length - 1]--;
                } else {
                    patch.push(-1);
                }
            }
            if (
                j < this.canvasCards.length &&
                this.canvasCards[j].s.t === canvasCards[i].s.t &&
                this.canvasCards[j].s.i === canvasCards[i].s.i &&
                this.canvasCards[j].s.j === canvasCards[i].s.j &&
                this.canvasCards[j].y_max === canvasCards[i].y_max &&
                DataModel.eqCells(this.canvasCards[j].cells, canvasCards[i].cells)
            ) {
                // no change or update
                assert(this.canvasCards[j]._ <= canvasCards[i]._);
                if (this.canvasCards[j]._ === canvasCards[i]._) {
                    // no change
                    if (patch.length > 0 && patch[patch.length - 1] > 0) {
                        patch[patch.length - 1]++;
                    } else {
                        patch.push(1);
                    }
                    j++;
                } else {
                    // update
                    const _patch = DataModel._calcCardsDataPatch(this.canvasCards[j].cards, canvasCards[i].cards);
                    //const tz=taktZones[canvasCards[i].i_z];
                    patch.push({
                        _: canvasCards[i]._,
                        name: canvasCards[i].name,
                        image: getAttachmentUrl(canvasCards[i].image),
                        s: canvasCards[i].s,
                        i_patch: j + 1,
                        cards: _patch,
                        y_max: canvasCards[i].y_max,
                        cells: canvasCards[i].cells,
                    });
                }
            } else {
                // insert
                //const tz=taktZones[canvasCards[i].i_z];
                patch.push({
                    _: canvasCards[i]._,
                    name: canvasCards[i].name,
                    image: getAttachmentUrl(canvasCards[i].image),
                    cards: canvasCards[i].cards,
                    s: canvasCards[i].s,
                    y_max: canvasCards[i].y_max,
                    cells: canvasCards[i].cells,
                });
            }
        }
        assert(j <= this.canvasCards.length);
        if (j < this.canvasCards.length) {
            patch.push(-(this.canvasCards.length - j));
        }
        this.canvasCards = canvasCards;
        return [meta, patch];
    }

    private static _CANVAS_ACTIVITIES_ID = 0;
    public canvasActivities: {
        id: number;
        tid: number;
    } | null = null;
    public initActivitiesView(tid: number) {
        this.canvasActivities = {
            id: ++DataModel._CANVAS_ACTIVITIES_ID,
            tid: tid,
        };
        return this.canvasActivities.id;
    }
    public cleanupActivitiesView(id: number) {
        if (this.canvasActivities?.id === id) {
            this.canvasActivities = null;
        } else {
            // out-of-bound
        }
    }

    gatherHistory(ts0: number) {
        const allProps = {};
        const tids = Object.getOwnPropertyNames(this.tasks);
        const n_tids = tids.length;
        for (let i_tid = 0; i_tid < n_tids; i_tid++) {
            const tid = Number.parseInt(tids[i_tid]);
            const t = this.tasks[tid];
            const p = this.VALUE<number>(t?.p, -1);
            if (p >= 0) {
                const props = Object.getOwnPropertyNames(t).filter((n) => !n.startsWith("_"));
                const created = t.__ >= ts0;
                /*
                const op_name=this.OP_I(t.name, 0);
                if (op_name>=ts0) {
                    console.log((created?"CREATED":"CHANGED")+": "+tid+" "+this.VALUE<string>(op_name));
                }
                */
            } else if (tid > 0) {
                console.log("DELETED: " + tid);
            }
        }
        console.log("END");
    }

    /*
    private taskHasTrade(t:DataModelTask, subTrades:{[tid:number]:true}) {
        const ret=Object.getOwnPropertyNames(subTrades||{}).reduce((ret, tid)=>{
            ret=ret || "number"===typeof((t._rw||{})[tid]);
            return ret;
        }, false);
        return ret;
    }

    public updateSingleCardState(ret_ops:DataOperation[], card:any, ctx:{}, subTrades:{[tid:number]:true}) {
        const id=(card?.id||"").split('_');
        if (4===id.length && 'C'===id[0]) {
            const tid=Number.parseInt(id[1], 16);
            const aid=Number.parseInt(id[2], 16);
            const i_day=Number.parseInt(id[3], 16);
            const ret=this.gatherActivitiesForTask(tid);
            if (ret.t && !Number.isNaN(tid) && !Number.isNaN(aid) && !Number.isNaN(i_day) && this.taskHasTrade(ret.t, subTrades)) {
                if ("number"===typeof(card.s)) {
                    // status
                    ret_ops.push({
                        op: DataOperationType.UPDATE,
                        target: DataOperationTarget.TASKS,
                        id: ret.tid,
                        name: ["#A", aid.toString(16), "#", i_day.toString(10), "#s"].join(''),
                        value: card.s,
                        z: 0,
                        _u: this.userName,
                        group: true
                    });
                }
                if ("string"===typeof(card.m)) {
                    // comment
                    ret_ops.push({
                        op: DataOperationType.UPDATE,
                        target: DataOperationTarget.TASKS,
                        id: ret.tid,
                        name: ["#A", aid.toString(16), "#", i_day.toString(10), "#m"].join(''),
                        value: card.m,
                        z: 0,
                        _u: this.userName,
                        group: true
                    });
                }
            }
        }
    }
    */

    public addAttachment(
        ops: DataOperation[],
        card: { tid: number; aid: number; i: number },
        blobUUID: string,
        meta: { fileName?: string; contentType?: string },
    ) {
        const blobUUID5 = UUID5.fromUUID(blobUUID).toUUID5();
        const name =
            "number" === typeof card?.tid && card.tid >= 0 && "number" === typeof card?.aid && card.aid >= 0
                ? ["#A", card.aid.toString(16), "#", card.i.toString(10), "#a", blobUUID5].join("")
                : "number" === typeof card?.tid && card.tid >= 0
                  ? ["#a", blobUUID5].join("")
                  : null;
        if (name) {
            ops.push({
                op: DataOperationType.UPDATE,
                target: DataOperationTarget.TASKS,
                id: card.tid,
                name: name,
                value: meta,
                z: 0,
                _u: this.userName,
                group: true,
            });
        } else {
            //console.warn("TODO");
        }
    }

    public deleteAttachment(ops: DataOperation[], card: { tid: number; aid: number; i: number }, blobUUID: string) {
        const blobUUID5 = UUID5.fromUUID(blobUUID).toUUID5();
        const name =
            "number" === typeof card?.tid && card.tid >= 0 && "number" === typeof card?.aid && card.aid >= 0
                ? ["#A", card.aid.toString(16), "#", card.i.toString(10), "#a", blobUUID5].join("")
                : "number" === typeof card?.tid && card.tid >= 0
                  ? ["#a", blobUUID5].join("")
                  : null;
        if (name) {
            ops.push({
                op: DataOperationType.UPDATE,
                target: DataOperationTarget.TASKS,
                id: card.tid,
                name: name,
                value: false, // deleted
                z: 0,
                _u: this.userName,
                group: true,
            });
        } else {
            //console.warn("TODO");
        }
    }

    public addTaskComment(tid: number, text: string) {
        const commentId = this.maxIds[DataOperationTarget.COMMENT] + 1;
        const ops = [];
        ops.push({
            op: DataOperationType.CREATE,
            target: DataOperationTarget.COMMENT,
            id: commentId,
            z: 0,
            _u: this.userName,
            group: true,
        });
        ops.push({
            op: DataOperationType.UPDATE,
            target: DataOperationTarget.TASKS,
            id: tid,
            name: "#C" + commentId.toString(16),
            value: text,
            z: 0,
            _u: this.userName,
            group: false,
        });
        this.pushOperation(ops, {ignoreReadonly: true});
        this.updateModel();
    }

    async submitChanges(cmd: "commit_sandbox" | "delete_sandbox") {
        const _snap = this._snap();
        const importedTS = _snap.imported;
        const commitedTS = _snap.commited;
        assert(commitedTS === this.ops.length);
        this.pushOperation({
            op: DataOperationType.NOOP,
            cmd: cmd,
            sid: this.storageName,
            ts: importedTS,
            ops: commitedTS - importedTS,
            _u: this.userName,
        } as any);
        this.updateModel();
        const syncRet = this.getSync(0);
        const pushRet = await DataModel.pushStreamOps(this.storageName, syncRet.sync, {
            commitSandbox: ("delete_sandbox" === cmd ? -1 : 1) * (syncRet.sync.ofs + syncRet.sync.ops.length),
            deleteSandbox: "delete_sandbox" === cmd,
        });
        if (pushRet.ops.length > 0) {
            return false;
        } else {
            if (1 === this.commitSync(pushRet, syncRet.token)) {
                return true;
            } else {
                return false;
            }
        }
    }

    async commitChanges(
        master_sid: string,
        merge_token: {
            sandbox: string;
            sandbox_ofs: number;
        },
    ) {
        const _snap = this._snap();
        const commitedTS = _snap.commited;
        const importedTS = _snap.imported;
        const syncRet = this.getSync(0);
        const ret = await DataModel.pushStreamOps(master_sid, syncRet.sync, {
            commitSandbox: syncRet.sync.ofs + syncRet.sync.ops.length,
        });
        if (ret.ops.length > 0) {
            return false;
        } else {
            // OK
            const ops: DataOperation[] = [
                {
                    op: DataOperationType.NOOP,
                    cmd: "merged_sandbox",
                    sid: master_sid,
                    ts: importedTS,
                    ops: commitedTS - importedTS,
                    _u: this.userName,
                } as any,
            ];
            const pushRet = await DataModel.pushStreamOps(
                merge_token.sandbox,
                {
                    ofs: merge_token.sandbox_ofs,
                    ops: ops,
                },
                {
                    commitSandbox: -(merge_token.sandbox_ofs + ops.length), // <0 signal close of sandbox
                },
            );
            if (pushRet.ops.length > 0) {
                return false;
            } else {
                // done
                return true;
            }
        }
    }

    static async createMergeRequest(
        master_token: string,
        master_ret: { master: string; ofs: number; ts: number },
        sandbox: string,
        merge_token: { sandbox: string; sandbox_ofs: number },
    ) {
        const _master_token = JSON.parse(atob(master_token.split(".")[1]));
        const master_sid = _master_token.sid;
        const master_model = await DataModel.loadStream(master_ret.master, master_token, 0, master_ret.ofs, true); //@TODO: use master token from openSandbox...
        {
            // get sandbox
            const syncRet = master_model.getSync(0);
            assert(master_ret.ofs === syncRet.sync.ofs);
            const _ret = await DataModel.pushStreamOps(sandbox, {
                ofs: syncRet.sync.ofs,
                ops: [], // only get!!
            });
            const commitRet = master_model.commitSync(_ret, syncRet.token);
            assert(0 === commitRet); // merged upstream changes
            assert(master_model.commitTS() >= master_ret.ts);
            if (master_model.commitTS() > master_ret.ts) {
                // sandbox has been modified after commit... just update it...
                master_ret.ts = master_model.commitTS();
            }
            assert(master_model.commitTS() === master_ret.ts);
            Object.assign(merge_token, {
                sandbox: sandbox,
                sandbox_ofs: _ret.ofs + _ret.ops.length,
            });
        }
        const master_model_snap = master_model._snap();
        const importedTS = master_model_snap.imported;
        const commitedTS = master_model_snap.commited;
        assert(
            commitedTS === master_model.ops.length &&
                master_ret.ts === master_model.ops.length &&
                master_ret.ofs === importedTS,
        );
        const merge_model = new DataModel();
        {
            // apply local....
            merge_model._pushOperation(master_model.cloneOps(0, importedTS));
            merge_model.updateModel(true);
            merge_model._pushOperation(master_model.cloneOps(importedTS, commitedTS));
            merge_model.updateModel();
        }
        {
            // sync upstream
            const syncRet = merge_model.getSync(0);
            assert(importedTS === syncRet.sync.ofs);
            const ret = await DataModel.pushStreamOps(master_sid, {
                ofs: syncRet.sync.ofs,
                ops: [], // only get!!
            });
            const commitRet = merge_model.commitSync(ret, syncRet.token);
            assert(0 === commitRet); // merged upstream changes
        }
        return merge_model;
    }

    selectView(
        view?: number,
        details?: string,
        options?: {
            sidebarColImage?: number;
            grid?: number;
            showStatusBar?: boolean;
            showTaktzones?: boolean;
            stackProcesses?: boolean;
            showProjectWeeks?: boolean;
        },
    ) {
        let C = null;
        if ("number" === typeof view) {
            if (0 <= view && view < CONST.views.length) {
                C = {
                    ...this.viewConst,
                    ...CONST.views[view],
                    details: undefined !== details ? details : this.viewConst.details,
                };
            }
        } else if (undefined !== details) {
            C = { ...this.viewConst, details: details };
        } else if (options) {
            C = { ...this.viewConst, ...options };
        }
        if (C && JSON.stringify(C) !== JSON.stringify(this.viewConst)) {
            // deep compare hack...
            this.viewConst = C;
        }
    }

    pushLCMX(ext: any, group?: boolean) {
        if (ext.appendOps) {
            if (undefined === ext.ts || ext.ts === this.commited) {
                for (let i = 0; i < ext.appendOps.length; i++) {
                    const op = ext.appendOps[i];
                    if ("string" === typeof op._fix) {
                        // comment
                    } else if (op._fix) {
                        if (DataOperationType.REJECT_ACCEPT === op.op) {
                            //console.log(this.ops[op.id]);
                            //console.log(op._fix);
                            assert(
                                this.ops[op.id].op === op._fix.op &&
                                    this.ops[op.id].target === op._fix.target &&
                                    this.ops[op.id].id === op._fix.id &&
                                    this.ops[op.id].name === op._fix.name &&
                                    this.ops[op.id].value === op._fix.value &&
                                    this.ops[op.id].unit === op._fix.unit,
                            );
                        } else {
                            assert(false); //@TODO
                        }
                    }
                }
                this.pushOperation(ext.appendOps);
                this.updateModel();
                this.updateActivities();
            } else {
                console.warn("Patch out of sync " + ext.ts + "!=" + this.commited);
            }
        } else if (Array.isArray(ext) || ext.id) {
            const ops: DataOperation[] = [];
            const a = Array.isArray(ext) ? ext : [ext];
            for (let i = 0; i < a.length; i++) {
                const ext = a[i];
                if ("com.lcmd.core.calendar.default" === ext.id) {
                    // fix calendar is needed
                    const isWorkingDay = isWorkingDayHack.bind(DataModel.createIsWorkingDayHackMeta(ext));
                    const taskIds = Object.getOwnPropertyNames(this.tasks);
                    const n_taskIds = taskIds.length;
                    for (let i = 0; i < n_taskIds; i++) {
                        const tid = Number.parseInt(taskIds[i]);
                        const t = this.tasks[tid];
                        assert(t);
                        if (!Array.isArray(t._c) && t._x1 > MAX_TASK_DAYS && t.__ >= 0) {
                            // only real tasks, no summary tasks
                            const d = new Date(t._x1);
                            if (!isWorkingDay(d)) {
                                const t_start = this.VALUE<number>(t.start);
                                const _t_start = DataModel.EpochMSRoundDays(t_start);
                                const _start = DataModel.adjustToWorkingDay(isWorkingDay, _t_start, 1);
                                const start = _start + (t_start - _t_start);
                                ops.push({
                                    op: DataOperationType.UPDATE,
                                    target: DataOperationTarget.TASKS,
                                    id: tid,
                                    name: "start",
                                    value: start,
                                    z: 0,
                                    _u: this.userName,
                                    group: true,
                                });
                            }
                        }
                    }
                }
                ops.push({
                    op: DataOperationType.UPDATE,
                    target: DataOperationTarget.HIVE,
                    id: -1,
                    z: 0,
                    _u: this.userName,
                    group: group || i + 1 < a.length,
                    name: "#lcmd#ext#" + ext.id,
                    value: ext,
                });
            }
            if (Array.isArray(ext)) {
                this.pushOperation(ops);
            } else {
                this.ops.push(...ops);
                this._clearUndoStack();
            }
        }
    }

    _clearUndoStack() {
        // clear undo stack...
        this.undoStack = [];
        this.undoStackPos = 0;
    }

    getRBACInfo(sub: string) {
        const rbac = {
            ...this.rbac[sub],
            rules: { ...this.rbac[sub]?.rules, a_rexp: undefined },
            filter: { ...this.rbac[sub]?.filter },
        };
        if (rbac.filter) {
            (rbac.filter as any).contraints = [];
            /*
            if (!rbac.filter.trades) {
                console.log("AHA"+sub);
            }
            */
            (rbac.filter.trades || []).reduce(
                (ret, id) => {
                    const _name = this.VALUE<string>(this.trades[id]?.name, null);
                    const _trade = this.VALUE<string>(this.trades[id]?.trade, null);
                    const trade = _trade || _name || "?";
                    ret.push(trade);
                    return ret;
                },
                (rbac.filter as any).contraints,
            );
        }
        return rbac;
    }

    getProjectInfo() {
        return {
            sub: this.userName,
            rbac: Object.getOwnPropertyNames(this.rbac).reduce((ret, sub) => {
                ret[sub] = this.getRBACInfo(sub);
                return ret;
            }, {}),
            startDate: this.tasks[0]?._x1 || null,
            endDate: this.tasks[0]?._x2 || null,
        };
    }

    private static refPathCache = new Map();
    // @todo: muss das regexp angepasst werden ?
    private static REF_REGEXP =
        /^((?<ref>(?<target>[A-Z])(?<shortname>[A-Z])?(?<hexId>-?[a-f0-9]+))|((?<name>[a-z0-9._][a-zA-Z0-9._ ]*)))*$/;
    static parseRefPath(pathStr: string) {
        if (pathStr.startsWith("#")) {
            if(DataModel.refPathCache.has(pathStr)){
                return DataModel.refPathCache.get(pathStr);
            }

            const path = pathStr
                .substring(1)
                .split("#")
                .map((e) => {
                    const groups = e.match(DataModel.REF_REGEXP)?.groups;
                    if (groups) {
                        return { ...groups, id: groups.hexId ? Number.parseInt(groups.hexId, 16) : -1 } as {
                            id: number;
                            name?: string;
                            target?: string;
                            shortname?: string;
                            hexid?: string;
                        };
                    } else {
                        return null;
                    }
                });
            this.refPathCache.set(pathStr, path);
            return path;
        } else {
            return null;
        }
    }

    gatherUsedIds() {
        const refIds = [{}, {}, {}, {}, {}, {}, {}];
        assert("development" !== process.env.NODE_ENV || DataOperationTarget.MAX === refIds.length);
        const tids = Object.getOwnPropertyNames(this.tasks).map((tid) => Number.parseInt(tid));
        const n_tids = tids.length;
        for (let i = 0; i < n_tids; i++) {
            if (false && 14975 === i) {
                console.log("AHHH");
            }
            const tid = tids[i];
            const t = this.tasks[tid];
            Object.getOwnPropertyNames(t)
                .filter((n) => !n.startsWith("_"))
                .forEach((n) => {
                    let id;
                    if ("p" === n) {
                        refIds[DataOperationTarget.TASKS][id] = refIds[DataOperationTarget.TASKS][id] || [];
                        refIds[DataOperationTarget.TASKS][id].push(this.OP_I(t[n], null));
                    } else if (n.startsWith("#")) {
                        const path = DataModel.parseRefPath(n);
                        path.forEach((ref: any) => {
                            if (!ref) {
                                console.log("AHHH");
                            }
                            if (ref.target) {
                                switch (ref.target) {
                                    case "R":
                                        if (ref.shortname !== "W") {
                                            break;
                                        }
                                        refIds[DataOperationTarget.TRADE][ref.id] =
                                            refIds[DataOperationTarget.TRADE][ref.id] || [];
                                        refIds[DataOperationTarget.TRADE][ref.id].push(this.OP_I(t[n], null));
                                        break;
                                    case "T":
                                        refIds[DataOperationTarget.TASKS][ref.id] =
                                            refIds[DataOperationTarget.TASKS][ref.id] || [];
                                        refIds[DataOperationTarget.TASKS][ref.id].push(this.OP_I(t[n], null));
                                        break;
                                    case "A":
                                        refIds[DataOperationTarget.ACTIVITY][ref.id] =
                                            refIds[DataOperationTarget.ACTIVITY][ref.id] || [];
                                        refIds[DataOperationTarget.ACTIVITY][ref.id].push(this.OP_I(t[n], null));
                                        break;
                                    case "I": // Stabi or ActionItem Id attached to process
                                    case "C": // commentId attached to process
                                        refIds[DataOperationTarget.COMMENT][ref.id] =
                                            refIds[DataOperationTarget.COMMENT][ref.id] || [];
                                        refIds[DataOperationTarget.COMMENT][ref.id].push(this.OP_I(t[n], null));
                                        break;

                                    default:
                                        assert(false); // handle me!
                                        break;
                                }
                            } else {
                                assert(ref.name);
                            }
                        });
                    } else if (n.startsWith("lcmx.")) {
                        //@TODO
                    } else {
                        if (
                            !{
                                name: true,
                                start: true,
                                days: true,
                                stripey: true,
                                fs: true,
                            }[n]
                        ) {
                            console.log("Unhandled: " + n);
                        }
                    }

                    /* if (n.startsWith("#R")) {
                    assert('A'<=n[2] && n[2]<='Z');
                    id=Number.parseInt()
                } else if (n.startsWith("#T")) {
                    assert('A'<=n[2] && n[2]<='Z');
                } else {
                    assert(false); //@TODO
                }*/
                });
        }
        return refIds;
    }

    async generatePatch(opt: {}, meta: CanvasMeta) {
        const ret = {
            pid: undefined,
            sid: undefined,
            lid: undefined,
            rid: undefined,
            mime: undefined,
            tasks: [],
        };
        const master_token = unsafeParseJWT(meta.session.master_token);
        ret.pid = master_token.pid;
        ret.sid = master_token.sid;
        ret.lid = master_token.lid;
        const ofs0 = this.imported.ofs;

        const logRet = await new Promise(async (resolve: (ret: any) => void, reject: (reason: any) => void) => {
            getLog(meta.session.master_token, ret.pid, ret.lid, (error, result) => {
                if (error) {
                    resolve(null);
                } else {
                    resolve(result);
                }
            });
        });
        if (logRet) {
            ret.rid = logRet?.file?.id;
            ret.mime = logRet?.file?.type;
        }

        const [wbs] = this.gatherWBS(Array.isArray(this.canvasWBS.stripes));
        for (let i_wbs = 0; i_wbs < wbs.length; i_wbs++) {
            const item = wbs[i_wbs];
            const t = this.tasks[item.tid];
            let chg = null;
            if (this.OP_I(t.start, -1) >= ofs0) {
                chg = chg || {};
                chg.start = new Date(this.VALUE<number>(t.start, -1)).toISOString();
            }
            if (this.OP_I(t.days, -1) >= ofs0) {
                chg = chg || {};
                chg.duration = this.VALUE<number>(t.days, -1);
                chg.duration_unit = this.UNIT(t.days, 3 /* days */);
            }
            ret.tasks.push({
                tid: item.tid,
                chg: chg,
            });
        }
        return ret;
    }

    private async _createSubStorage(
        type: "wb",
        token: string,
        params: { name?: string },
        clone?: { sid: string; ofs: number },
        ops?: DataOperation[],
    ) {
        const ret: any = await (() =>
            new Promise(
                (resolve: (resp: { pid?: string; rid?: string; token: string }) => void, reject: (err) => void) => {
                    const opsValid =
                        Array.isArray(ops) &&
                        ops.length > 0 &&
                        DataOperationType.NOOP === ops[0]?.op &&
                        "WB" === ops[0]?.cmd;
                    create_master(
                        opsValid
                            ? ops
                            : ([
                                  {
                                      ...params,
                                      op: DataOperationType.NOOP,
                                      cmd: "WB",
                                      clone: clone || undefined,
                                      _u: this.userName,
                                  } as any,
                              ] as DataOperation[]),
                        (error, result) => {
                            if (error) {
                                reject(error);
                            } else {
                                resolve(result);
                            }
                        },
                        {
                            resource_token: token || undefined,
                            clone: clone || undefined,
                        },
                    );
                },
            ))();

        if (ret?.storageName) {
            const ops: DataOperation[] = [];
            const wbClone = ["U", UUID5.fromUUID(ret.storageName).toUUID5().toLowerCase()].join("");
            let name = params.name || "";
            if (clone) {
                const wbOrig = ["U", UUID5.fromUUID(clone.sid).toUUID5().toLowerCase()].join("");
                ops.push({
                    op: DataOperationType.UPDATE,
                    target: DataOperationTarget.HIVE,
                    id: -1,
                    z: 0,
                    _u: this.userName,
                    group: true,
                    name: ["#lcmd#", type, "#", wbOrig, "#name"].join(""),
                    value: null, // delete cloned wb
                    clone: clone,
                } as DataOperation & { clone: { sid: string; ofs: number } });
                name = this.VALUE<string>(this.hive.lcmd.wb[wbOrig].name, "");
            }
            ops.push({
                op: DataOperationType.UPDATE,
                target: DataOperationTarget.HIVE,
                id: -1,
                z: 0,
                _u: this.userName,
                group: true,
                name: ["#lcmd#", type, "#", wbClone, "#name"].join(""),
                value: name,
            });
            if (ops.length > 0) {
                ops[ops.length - 1].group = false;
                this.pushOperation(ops);
            }
            this.updateModel();
            return {
                id: ret.storageName,
            };
        } else {
            return {};
        }
    }

    public async createWhiteboard(
        token: string,
        params: {},
        clone?: { sid: string; ofs: number },
        ops?: DataOperation[],
    ) {
        return this._createSubStorage("wb", token, params, clone, ops);
    }

    public getSafeIDs(ids: { pids?: number[]; trids?: number[]; hids?: number[] }) {
        const ret = {
            pids: (ids.pids || []).map((pid) => {
                const t = this.tasks[pid];
                if (t && t.__ >= 0) {
                    if (t.__ > this.syncCommited.ofs) {
                        // id must be synced!
                        throw new FrameworkErrorDataModelNeedsSync();
                    } else {
                        return "P" + this._transformId(DataOperationTarget.TASKS, pid, true);
                    }
                } else {
                    return null;
                }
            }),
            trids: (ids.trids || []).map((trid) => {
                const trade = this.trades[trid];
                if (trade) {
                    if (trade.__ > this.syncCommited.ofs) {
                        // id must be synced!
                        throw new FrameworkErrorDataModelNeedsSync();
                    } else {
                        return "R" + this._transformId(DataOperationTarget.TRADE, trid, true);
                    }
                } else {
                    return null;
                }
            }),
            hids: (ids.hids || []).map((hid) => {
                if (this.commited > this.syncCommited.ofs) {
                    // id must be synced!
                    throw new FrameworkErrorDataModelNeedsSync();
                } else {
                    return "H" + this._transformId(DataOperationTarget.HIVE, hid, true).toString(16);
                }
            }),
        };
        return ret;
    }

    public getUnsafeIDs(safeIDs: { pids?: string[]; trids?: string[]; hids?: string[] }) {
        const pids = (safeIDs?.pids || []).reduce((ret, safeID) => {
            if ("string" === typeof safeID && safeID.startsWith("P")) {
                const _id = Number.parseInt(safeID.substring(1));
                ret.push(this._transformId(DataOperationTarget.TASKS, _id, false));
            } else {
                ret.push(-1); // invalid
            }
            return ret;
        }, [] as number[]);
        const trids = (safeIDs?.trids || []).reduce((ret, safeID) => {
            if ("string" === typeof safeID && safeID.startsWith("R")) {
                const _id = Number.parseInt(safeID.substring(1));
                ret.push(this._transformId(DataOperationTarget.TRADE, _id, false));
            } else {
                ret.push(-1); // invalid
            }
            return ret;
        }, [] as number[]);
        const hids = (safeIDs?.hids || []).reduce((ret, safeID) => {
            if ("string" === typeof safeID && safeID.startsWith("H")) {
                const _id = Number.parseInt(safeID.substring(1), 16);
                ret.push(this._transformId(DataOperationTarget.HIVE, _id, false));
            } else {
                ret.push(-1); // invalid
            }
            return ret;
        }, [] as number[]);
        return {
            pids: pids,
            trids: trids,
            hids: hids,
        };
    }

    public hasTaskId(id: number, sid?: string): boolean {
        return (!sid || sid in this._storageNames) && this.tasks[id] ? true : false;
    }

    public hasTradeId(id: number, sid?: string): boolean {
        return (!sid || sid in this._storageNames) && this.trades[id] ? true : false;
    }

    public isTaskDel(t: DataModelTask) {
        let p = this.VALUE<number>(t.p, -1);
        while (p > 0 && (t = this.tasks[p])) p = this.VALUE<number>(t.p, -1);
        return p < 0;
    }

    public setCanvasViewMeta(model: DataModelViewMeta, view: CanvasViewMeta) {
        this.viewMeta.model = { ...this.viewMeta.model, ...model };
        this.viewMeta.view = { ...this.viewMeta.view, ...view };
    }

    public _createTask(
        props: {
            _x1: number;
            _x2: number;
            _rw: { [id: number]: number }; // resources "work"/trades
            _name: string;
            _days: number;
            _rsid: string; // Referenced Storage ID
            _rid: number; // Referenced ID
            _train: string | number;
            _stripey: number;
            _p: number;
            _wf: number;
        },
        cachedId?: number,
    ) {
        const id: number = "number" === typeof cachedId ? cachedId : ++this.maxIds[DataOperationTarget.TASKS];
        const task: DataModelTask = { ...props, __: -2 /* initially marked "dirty" */, _: this.commited } as any;
        this.tasks[id] = task;
        assert(task._x1 <= task._x2);
        /* no longer needed I guess...
        if ((null===this.startDate || task._x1<this.startDate)) {
            this.startDate=task._x1;
        }
        if ((null===this.endDate || task._x2>this.endDate)) {
            this.endDate=task._x2;
        }
        */
        const invalidateStripes = true;
        if (this.imported.ofs > 0 && invalidateStripes) {
            if (null !== this._stripes[0]) {
                this._stripes[0].dirty = true;
            }
            if (null !== this._stripes[1]) {
                this._stripes[1].dirty = true;
            }
        }
        return id;
    }

    private _gpaPreviewHeap: { [storageName: string]: { [id: string]: number } } = {};
    private _gpaPreviewCache: number[] = [];
    private _gpaOrderRegExp = /^.*\[(\d+)\]\w*$/;
    public _updateGPAPreview(
        gpaModel: DataModel,
        commit?: {
            maxIds: DataOperationMaxIds;
            ops: DataOperation[];
        },
    ) {
        //const startDate=this.VALUE<number>(this.tasks[0].start, 0);
        const preview = gpaModel?.gpaPreview?.enabled;
        if (preview) {
            const orderByNameSuffix = gpaModel.gpaPreview.orderByNameSuffix;
            gpaModel.gpaPreview.dep = undefined;
            gpaModel.gpaPreview.ts = gpaModel.commited;
            gpaModel.gpaPreview.hostTS = this.commited;
            /*if (this.callbacks.onTaktung) {
                this.callbacks.onTaktung(this, gpaModel, {});
            }*/
            const tzs: { [gpaId: number]: { tzId: number; taktOfs: number; i: number; o: number }[] } = {};
            {
                // gather tzs
                const stack: number[] = [0];
                while (stack.length > 0) {
                    const tid = stack.pop();
                    const task = this.tasks[tid];
                    if (Array.isArray(task._c)) {
                        const n = task._c.length;
                        if (n > 0 && null !== this.VALUE<number | null>(this.tasks[task._c[0]]?.p, null)) {
                            assert(
                                "development" !== process.env.NODE_ENV ||
                                    task._c.reduce(
                                        (ret, tid) =>
                                            ret && null !== this.VALUE<number | null>(this.tasks[tid]?.p, null),
                                        true,
                                    ),
                            );
                            for (let i = n; i > 0; ) {
                                i--;
                                stack.push(task._c[i]);
                            }
                        } else {
                            assert(
                                "development" !== process.env.NODE_ENV ||
                                    task._c.reduce(
                                        (ret, tid) =>
                                            ret && null === this.VALUE<number | null>(this.tasks[tid]?.p, null),
                                        true,
                                    ),
                            );
                            const gpa_op = this.OP(task.gpa);
                            if (
                                gpa_op &&
                                gpa_op.value &&
                                gpa_op.r_sid in gpaModel._storageNames &&
                                gpaModel.tasks[gpa_op.r_id]
                            ) {
                                const z = tzs[gpa_op.r_id] || [];
                                const n = this.VALUE<string>(task.name, "");
                                const m = orderByNameSuffix ? n.match(this._gpaOrderRegExp) : null;
                                const o = m ? Number.parseInt(m[1]) : Number.MAX_SAFE_INTEGER;
                                z.push({ tzId: tid, taktOfs: 0, i: z.length, o });
                                tzs[gpa_op.r_id] = z;
                            }
                            this.tasks[tid]._c = []; // clear generated
                        }
                    }
                }
            }
            if (orderByNameSuffix) {
                Object.getOwnPropertyNames(tzs).forEach((_gpaId) => {
                    tzs[_gpaId].sort((a, b) => {
                        const ret = a.o - b.o;
                        return 0 === ret ? a.i - b.i : ret;
                    });
                });
            }
            const unsortedGpaTrains = Object.getOwnPropertyNames(tzs).reduce((ret, _gpaId) => {
                const gpaId = Number.parseInt(_gpaId);
                const gpa: DataModelTask = gpaModel.tasks[gpaId];
                if (Array.isArray(gpa?._c) && gpa._c.length > 0) {
                    const _c = gpa._c.sort((_a, _b) => {
                        const a = gpaModel.tasks[_a];
                        const b = gpaModel.tasks[_b];
                        let ret = a._x2 - b._x2;
                        if (0 === ret) {
                            ret = a._y - b._y;
                            if (0 === ret) {
                                ret = _a - _b;
                            }
                        }
                        return ret;
                    });
                    const trains = [];
                    const n_c = _c.length;
                    const scheduledTrains = {};
                    for (let i_c = 0; i_c < n_c; i_c++) {
                        const c_tid = _c[i_c];
                        const c_task = gpaModel.tasks[c_tid];
                        if (c_task && c_task._x1 > 0) {
                            const _trainId = gpaModel.VALUE<number | string>(c_task.train, "");
                            const trainId = "string" === typeof _trainId && _trainId.length > 0 ? _trainId : null;
                            const scheduledTrain = trainId ? scheduledTrains[trainId] : null;
                            if (scheduledTrain) {
                                scheduledTrain._c.push(c_tid);
                            } else {
                                const _takt = gpaModel.VALUE<number>(
                                    gpa[["#t#", stringToHex(trainId as string), "#takt"].join("")],
                                    0,
                                );
                                const train = {
                                    trainId,
                                    //teams: Math.max(1, gpaModel.VALUE<number>(c_task.teams, 1)),
                                    _c: [c_tid],
                                    _x1: c_task._x1,
                                    _x2: c_task._x2,
                                    _takt,
                                    _tasks: null,
                                    _taktOfs: null,
                                };
                                trains.push(train);
                                if (trainId) {
                                    scheduledTrains[trainId] = train;
                                }
                            }
                        }
                    }
                    const trainInfo = (gpaModel.gpaPreview?.trainInfos || {})[gpaId];
                    const dep = trainInfo?.dep;
                    let startDate = trainInfo?.start;
                    if ("string" === typeof dep && dep.length > 0) {
                        if ("#" === dep[0]) {
                            const taskId = Number.parseInt(dep.substring(1), 10);
                            const task = this.tasks[taskId];
                            if (task) {
                                startDate = task._x2;
                                if (undefined === gpaModel.gpaPreview.dep) {
                                    gpaModel.gpaPreview.dep = [];
                                }
                                gpaModel.gpaPreview.dep.push(taskId);
                            }
                        } else {
                            const stripe = gpaModel.canvasStripes.find((stripe) => stripe.label === dep);
                            if (stripe?.t > 0) {
                                startDate = -(stripe.t + 1);
                            } else {
                                startDate = 0;
                            }
                        }
                    }
                    if (startDate < 0 || startDate > MAX_TASK_DAYS) {
                        ret.push({
                            start: startDate,
                            gpaId,
                            tzs: tzs[gpaId],
                            trains,
                            _valid: false,
                            _x1: null,
                            _x2: null,
                        });
                    }
                }
                return ret;
            }, []);

            const gpaTrains = topSort(
                unsortedGpaTrains.filter((gpaTrain) => {
                    if (gpaTrain.start < 0) {
                        const gpaId = -(gpaTrain.start + 1);
                        const _i_gpa = unsortedGpaTrains.findIndex((train) => gpaId === train.gpaId);
                        return _i_gpa >= 0;
                    } else {
                        return true;
                    }
                }),
                (gpaTrain) => {
                    if (gpaTrain.start < 0) {
                        const gpaId = -(gpaTrain.start + 1);
                        const _i_gpa = unsortedGpaTrains.findIndex((train) => gpaId === train.gpaId);
                        assert(
                            0 <= _i_gpa &&
                                _i_gpa <= unsortedGpaTrains.length &&
                                gpaId === unsortedGpaTrains[_i_gpa].gpaId,
                        );
                        return [unsortedGpaTrains[_i_gpa]];
                    } else {
                        return [];
                    }
                },
            );

            const n_gpaTrains = gpaTrains.length;
            for (let i_gpa = 0; i_gpa < n_gpaTrains; i_gpa++) {
                const gpa = gpaTrains[i_gpa];
                const trains = gpa.trains;
                const n_trains = trains.length;
                for (let i_train = 0; i_train < n_trains; i_train++) {
                    const train = trains[i_train];
                    const maxParallelTeams = train._c.reduce((ret, _c_tid) => {
                        const t = gpaModel.tasks[_c_tid];
                        const t_teams = Math.max(1, gpaModel.VALUE<number>(t?.teams, 1));
                        return Math.min(t_teams, ret);
                    }, Number.MAX_SAFE_INTEGER);
                    assert(0 < maxParallelTeams && maxParallelTeams < Number.MAX_SAFE_INTEGER);
                    const takt = train._c.reduce((ret, _c_tid) => {
                        const t = gpaModel.tasks[_c_tid];
                        const t_teams = Math.max(1, gpaModel.VALUE<number>(t?.teams, 1));
                        const t_len = t._x2 - t._x1;
                        const maxTeams = Math.min(t_teams - maxParallelTeams, t_len);
                        const maxTeamsAdjusted = maxTeams >= maxParallelTeams ? maxParallelTeams : 0;
                        //const maxTeamsAdjusted=(maxTeams>=maxParallelTeams?Math.floor(maxTeams/maxParallelTeams):0);
                        const t_takt = Math.ceil(t_len / (maxTeamsAdjusted + 1));
                        return Math.max(ret, t_takt);
                    }, 0);
                    train._takt = Math.max(train._takt, takt);
                    train._maxParallelTeams = maxParallelTeams;
                }
            }
            //console.log(gpaTrains);
            for (let i_gpa = 0; i_gpa < n_gpaTrains; i_gpa++) {
                const trains = gpaTrains[i_gpa].trains;
                const n_trains = trains.length;
                const tzs = gpaTrains[i_gpa].tzs;
                const n_tz = tzs.length;
                const trainStartDay = 0;
                for (let i_train = 0; i_train < n_trains; i_train++) {
                    const train = trains[i_train];
                    const _c = train._c;
                    const n_c = _c.length;
                    /*
                    const trainTotalTaktDays=train._takt*Math.floor(Math.max(n_tz-1, 0)/train._maxParallelTeams);
                    if (i_train>0) {
                        const prevTrain=trains[i_train-1];
                        const prevTrainTotalTaktDays=prevTrain._takt*Math.floor(Math.max(n_tz-1, 0)/prevTrain._maxParallelTeams);
                        if (prevTrainTotalTaktDays>trainTotalTaktDays) {
                            const delta=prevTrainTotalTaktDays-trainTotalTaktDays;
                            trainStartDay+=delta; //train._maxParallelTeams;
                        }
                    }
                    */
                    //let trainEndDay=trainStartDay;
                    assert(null === train._taktOfs);
                    train._taktOfs = 0;
                    let trainTaktOfs = 0;
                    const tz_c = [];
                    for (let i_tz = 0; i_tz < n_tz; ) {
                        const maxParallelTeams = train._maxParallelTeams;
                        for (let i_parallel = 0; i_parallel < maxParallelTeams && i_tz < n_tz; i_parallel++, i_tz++) {
                            const tz = tzs[i_tz];
                            const tzId = tz.tzId;
                            for (let i_c = 0; i_c < n_c; i_c++) {
                                const gpaPid = _c[i_c];
                                const gpaP = gpaModel.tasks[gpaPid];
                                assert(gpaP._x1 > 0 && gpaP._x2 > 0);
                                const _x1 = gpaP._x1;
                                const _x2 = gpaP._x2;
                                const days = _x2 - _x1;
                                const startDay = trainStartDay + trainTaktOfs + _x1;
                                const endDay = startDay + days;
                                assert(0 <= startDay && startDay <= endDay && endDay < MAX_TASK_DAYS);
                                /*
                                if (null===tz.start) {
                                    assert(null===tz.end);
                                    tz.start=startDay;
                                    tz.end=endDay;
                                } else {
                                    assert(null!==tz.end);
                                    tz.end=Math.max(tz.end, endDay);
                                }
                                */
                                if (trainTaktOfs < tz.taktOfs) {
                                    const delta = tz.taktOfs - trainTaktOfs;
                                    train._taktOfs = Math.max(train._taktOfs, delta);
                                }
                                tz_c.push({
                                    gpaPid,
                                    tzId,
                                    i_tz: i_tz,
                                    start: startDay,
                                    end: endDay,
                                });
                                tz.taktOfs = trainTaktOfs;
                                /*
                                console.log(JSON.stringify({
                                    gpaP: gpaPid,
                                    tz: tzId,
                                    i_tz: i_tz,
                                    name: gpaModel.VALUE<string>(gpaP.name, "?"),
                                    start: startDay,
                                    end: endDay,
                                    days
                                }));
                                */
                            }
                        }
                        trainTaktOfs += train._takt;
                    }
                    //console.log(`/train=${i_train} taktOfs=${train._taktOfs}`);
                    for (let i_tz = 0; i_tz < n_tz; i_tz++) {
                        const tz = tzs[i_tz];
                        tz.taktOfs += train._taktOfs;
                    }
                    assert(null === train._tasks);
                    train._tasks = tz_c;
                }
            }
            const gpaStorageName = gpaModel.storageName;
            this._gpaPreviewHeap[gpaStorageName] = this._gpaPreviewHeap[gpaStorageName] || {};
            const gpaPreviewHeap = this._gpaPreviewHeap[gpaStorageName];
            if (commit) {
                const gpaPreviewCache = this._gpaPreviewCache;
                const n_gpaPreviewCache = gpaPreviewCache.length;
                for (let i_gpaPreviewCache = 0; i_gpaPreviewCache < n_gpaPreviewCache; i_gpaPreviewCache++) {
                    const cachedTaskId = gpaPreviewCache[i_gpaPreviewCache];
                    const cachedTask = this.tasks[cachedTaskId];
                    const _taskUId = [cachedTask._rid.toString(16), cachedTask._p.toString(16)].join("_");
                    assert(!(_taskUId in gpaPreviewHeap));
                    gpaPreviewHeap[_taskUId] = cachedTaskId;
                    delete this.tasks[cachedTaskId];
                }
                this._gpaPreviewCache = [];
            }
            const gpaPreviewCache = this._gpaPreviewCache;
            let i_gpaPreviewCache = 0;
            for (let i_gpa = 0; i_gpa < n_gpaTrains; i_gpa++) {
                const gpaTrain = gpaTrains[i_gpa];
                assert(false === gpaTrain._valid); // already renderer? why?
                const _gpaStartDate = gpaTrain.start;
                const gpaStartDate =
                    _gpaStartDate < 0
                        ? ((gpaId) => {
                              const _i_gpa = gpaTrains.findIndex((train) => gpaId === train.gpaId);
                              assert(_i_gpa >= 0);
                              const gpaTrain = gpaTrains[_i_gpa];
                              assert(true === gpaTrain?._valid); // why not... topsort wrong?
                              return null === gpaTrain._x2 ? gpaTrain.start : gpaTrain._x2;
                          })(-(_gpaStartDate + 1))
                        : _gpaStartDate;
                assert(gpaStartDate > MAX_TASK_DAYS);
                gpaTrain.start = gpaStartDate;
                const trains = gpaTrain.trains;
                const n_trains = trains.length;
                for (let i_train = 0; i_train < n_trains; i_train++) {
                    const train = trains[i_train];
                    const tasks = train._tasks;
                    assert(Array.isArray(tasks) && null !== train._taktOfs);
                    const n_tasks = tasks.length;
                    for (let i_task = 0; i_task < n_tasks; i_task++) {
                        const task = tasks[i_task];
                        assert(0 <= task.start && task.start <= task.end && task.end < MAX_TASK_DAYS);
                        //const tz=tzs[task.i_tz];
                        //assert(tz && tz.start<=task.start && task.end<=tz.end);
                        const days = task.end - task.start;
                        //@TODO chache a little: use train start as a base und cache...
                        const _x1 = DataModel.CalcEpochStartDayMS(
                            this.taskCalendarHack,
                            gpaStartDate,
                            task.start + train._taktOfs - 1,
                            3 /*working days*/,
                        );
                        const _x2 = DataModel.CalcEpochStartDayMS(this.taskCalendarHack, _x1, days, 3 /*working days*/);
                        assert(MAX_TASK_DAYS < _x1 && _x1 <= _x2);
                        if (null === gpaTrain._x1 || _x1 < gpaTrain._x1) {
                            gpaTrain._x1 = _x1;
                        }
                        if (null === gpaTrain._x2 || _x2 > gpaTrain._x2) {
                            gpaTrain._x2 = _x2;
                        }
                        {
                            const gpaPid = task.gpaPid;
                            const tzId = task.tzId;
                            const tz = this.tasks[tzId];
                            const gpaP = gpaModel.tasks[gpaPid];
                            assert(gpaP && tz);
                            const _name = gpaModel.VALUE<string>(gpaP.name, "");
                            const _rw = Object.getOwnPropertyNames(gpaP._rw || {}).reduce((ret, _resId) => {
                                const res = gpaModel.trades[_resId];
                                if (
                                    res &&
                                    gpaModel.ops[res.__]?.r_sid in this._storageNames &&
                                    this.trades[gpaModel.ops[res.__].r_id]
                                ) {
                                    ret[gpaModel.ops[res.__].r_id] = -1;
                                }
                                return ret;
                            }, {});
                            const wf = gpaModel.VALUE<number>(gpaP.wf, 0);
                            if (commit) {
                                const taskId = ++commit.maxIds[DataOperationTarget.TASKS];
                                assert(this._checkParentLoopOK(taskId, tzId));
                                commit.ops.push({
                                    op: DataOperationType.CREATE,
                                    target: DataOperationTarget.TASKS,
                                    id: taskId,
                                    name: "p",
                                    value: tzId,
                                    z: 0,
                                    _u: this.userName,
                                    group: true,
                                });
                                commit.ops.push({
                                    op: DataOperationType.UPDATE,
                                    target: DataOperationTarget.TASKS,
                                    id: taskId,
                                    name: "start",
                                    value: _x1,
                                    z: 0,
                                    _u: this.userName,
                                    group: true,
                                });
                                commit.ops.push({
                                    op: DataOperationType.UPDATE,
                                    target: DataOperationTarget.TASKS,
                                    id: taskId,
                                    name: "days",
                                    value: days,
                                    unit: 3 /* days */,
                                    z: 0,
                                    _u: this.userName,
                                    group: true,
                                });
                                commit.ops.push({
                                    op: DataOperationType.UPDATE,
                                    target: DataOperationTarget.TASKS,
                                    id: taskId,
                                    name: "stripey",
                                    value: gpaP._y,
                                    z: 0,
                                    _u: this.userName,
                                    group: true,
                                });
                                commit.ops.push({
                                    op: DataOperationType.UPDATE,
                                    target: DataOperationTarget.TASKS,
                                    id: taskId,
                                    name: "name",
                                    value: _name,
                                    z: 0,
                                    _u: this.userName,
                                    group: true,
                                });
                                Object.getOwnPropertyNames(_rw).forEach((_tradeId) => {
                                    assert(this.trades[_tradeId]);
                                    commit.ops.push({
                                        op: DataOperationType.UPDATE,
                                        target: DataOperationTarget.TASKS,
                                        id: taskId,
                                        name: "#RW" + Number.parseInt(_tradeId).toString(16),
                                        value: 0,
                                        z: 0,
                                        _u: this.userName,
                                        group: true,
                                    });
                                });
                                if (wf > 0) {
                                    commit.ops.push({
                                        op: DataOperationType.UPDATE,
                                        target: DataOperationTarget.TASKS,
                                        id: taskId,
                                        name: "wf",
                                        value: wf,
                                        z: 0,
                                        _u: this.userName,
                                        group: true,
                                    });
                                }
                                Object.getOwnPropertyNames(gpaP)
                                    .filter((n) => n.startsWith("#A"))
                                    .reduce((ret, n) => {
                                        const v = gpaModel.VALUE<any>(gpaP[n], undefined);
                                        if (undefined !== v) {
                                            commit.ops.push({
                                                op: DataOperationType.UPDATE,
                                                target: DataOperationTarget.TASKS,
                                                id: taskId,
                                                name: n,
                                                value: v,
                                                z: 0,
                                                _u: this.userName,
                                                group: true,
                                            });
                                        }
                                        return ret;
                                    }, {});
                            } else {
                                const gpaTrain = gpaModel.VALUE<string | number>(gpaP.train, undefined);
                                let cachedTaskId: number;
                                let cachedTask;
                                while (
                                    i_gpaPreviewCache < gpaPreviewCache.length &&
                                    !(
                                        gpaStorageName ==
                                            (cachedTask =
                                                this.tasks[(cachedTaskId = gpaPreviewCache[i_gpaPreviewCache])])
                                                ._rsid &&
                                        gpaPid === cachedTask._rid &&
                                        gpaTrain === cachedTask._train &&
                                        tzId === cachedTask._p
                                    )
                                ) {
                                    assert(cachedTask === this.tasks[cachedTaskId]);
                                    const _taskUId = [cachedTask._rid.toString(16), cachedTask._p.toString(16)].join(
                                        "_",
                                    );
                                    assert(!(_taskUId in gpaPreviewHeap));
                                    gpaPreviewHeap[_taskUId] = cachedTaskId;
                                    delete this.tasks[cachedTaskId];
                                    gpaPreviewCache.splice(i_gpaPreviewCache, 1);
                                }
                                let _task: number = cachedTaskId;
                                //let ref:DataModelTask=null;
                                if (i_gpaPreviewCache < gpaPreviewCache.length) {
                                    assert(
                                        cachedTask &&
                                            "number" === typeof cachedTaskId &&
                                            cachedTask === this.tasks[cachedTaskId] &&
                                            gpaStorageName === cachedTask._rsid &&
                                            gpaPid === cachedTask._rid &&
                                            tzId === cachedTask._p &&
                                            gpaTrain === cachedTask._train,
                                    );
                                    assert(cachedTask.__ < 0);
                                    cachedTask.__--; // mark as "dirty"
                                    cachedTask._x1 = _x1;
                                    cachedTask._x2 = _x2;
                                    cachedTask._name = _name;
                                    cachedTask._rw = _rw;
                                    cachedTask._days = days;
                                    cachedTask._rsid = gpaStorageName;
                                    cachedTask._rid = gpaPid;
                                    cachedTask._train = gpaTrain;
                                    cachedTask._stripey = gpaP._y;
                                    cachedTask._p = tzId;
                                    cachedTask._wf = wf;
                                } else {
                                    assert(gpaPreviewCache.length === i_gpaPreviewCache);
                                    const _taskUId = [gpaPid.toString(16), tzId.toString(16)].join("_");
                                    const _cacheHit = gpaPreviewHeap[_taskUId];
                                    if (undefined !== _cacheHit) {
                                        assert("number" === typeof _cacheHit);
                                        delete gpaPreviewHeap[_taskUId]; // remove from gpaPreviewHeap
                                    }
                                    _task = this._createTask(
                                        {
                                            _x1,
                                            _x2,
                                            _name,
                                            _rw,
                                            _days: days,
                                            _rsid: gpaStorageName,
                                            _rid: gpaPid,
                                            _train: gpaTrain,
                                            _stripey: gpaP._y,
                                            _p: tzId,
                                            _wf: wf,
                                        },
                                        _cacheHit,
                                    );
                                    assert("number" === typeof _task);
                                    gpaPreviewCache.push(_task);
                                }
                                i_gpaPreviewCache++;
                                tz._c.push(_task);
                                assert(
                                    "development" !== process.env.NODE_ENV ||
                                        tz._c.reduce(
                                            (ret, tid) =>
                                                ret &&
                                                null === this.VALUE<number | null>(this.tasks[tz._c[tid]]?.p, null),
                                            true,
                                        ),
                                );
                            }
                        }
                    }
                }
                gpaTrain._valid = true; // handled
            }
        } else {
            // disabled
            const tids = Object.getOwnPropertyNames(this.tasks);
            const n_tids = tids.length;
            for (let i_tid = 0; i_tid < n_tids; i_tid++) {
                const task = this.tasks[tids[i_tid]];
                if (Array.isArray(task._c)) {
                    const n = task._c.length;
                    if (n > 0 && null !== this.VALUE<number | null>(this.tasks[task._c[0]]?.p, null)) {
                        assert(
                            "development" !== process.env.NODE_ENV ||
                                task._c.reduce(
                                    (ret, tid) => ret && null !== this.VALUE<number | null>(this.tasks[tid]?.p, null),
                                    true,
                                ),
                        );
                        // nothing to do...
                    } else {
                        assert(
                            "development" !== process.env.NODE_ENV ||
                                task._c.reduce(
                                    (ret, tid) => ret && null === this.VALUE<number | null>(this.tasks[tid]?.p, null),
                                    true,
                                ),
                        );
                        task._c = []; // clear generated
                    }
                }
            }
            const gpaStorageName = gpaModel.storageName;
            this._gpaPreviewHeap[gpaStorageName] = this._gpaPreviewHeap[gpaStorageName] || {};
            const gpaPreviewHeap = this._gpaPreviewHeap[gpaStorageName];
            const gpaPreviewCache = this._gpaPreviewCache;
            const n_gpaPreviewCache = gpaPreviewCache.length;
            for (let i_gpaPreviewCache = 0; i_gpaPreviewCache < n_gpaPreviewCache; i_gpaPreviewCache++) {
                const cachedTaskId = gpaPreviewCache[i_gpaPreviewCache];
                const cachedTask = this.tasks[cachedTaskId];
                const _taskUId = [cachedTask._rid.toString(16), cachedTask._p.toString(16)].join("_");
                assert(!(_taskUId in gpaPreviewHeap));
                gpaPreviewHeap[_taskUId] = cachedTaskId;
                delete this.tasks[cachedTaskId];
            }
            this._gpaPreviewCache = [];
            /*if (this.callbacks.onTaktung) {
                this.callbacks.onTaktung(null, null, null);
            }*/
        }
    }

    private canvasSessions: CanvasRTSession[] = [];
    private canvasSessionData: RTSessionsData = null;
    public updateCanvasSessions(sessions: string[], data: RTSessionsData): CanvasRTSession | null {
        const _now = data._now;
        let changes = false;
        const csessions = this.canvasSessions;
        const cdata = this.canvasSessionData;
        const n = sessions.length;
        let m = csessions.length;
        let j = 0;
        for (let i = 0; i < n; i++) {
            const session = sessions[i];
            let cmp;
            while (j < m && (cmp = csessions[j].session.localeCompare(session)) < 0) {
                csessions.splice(j, 1);
                m--;
                assert_dev(csessions.length === m);
                changes = true;
            }
            const _ts = data[session]._ts;
            const delta = _now - _ts;
            const state =
                delta < 1 * 60 * 1000
                    ? CanvasRTSessionState.ACTIVE
                    : delta < 5 * 60 * 1000
                      ? CanvasRTSessionState.INACTIVE
                      : CanvasRTSessionState.ZOMBIE;
            if (j < m && 0 === cmp) {
                // item exists
                if (CanvasRTSessionState.ZOMBIE === state) {
                    csessions.splice(j, 1); // remove
                    m--;
                    assert_dev(csessions.length === m);
                    changes = true;
                } else {
                    changes = changes || csessions[j].state !== state;
                    csessions[j].state = state;
                    j++;
                }
            } else {
                assert(j === m || cmp > 0); // new item
                if (CanvasRTSessionState.ZOMBIE !== state) {
                    csessions.splice(j, 0, { session, sub: data[session].sub, state: CanvasRTSessionState.ACTIVE });
                    j++;
                    m++;
                    assert_dev(csessions.length === m);
                    changes = true;
                }
            }
        }
        assert_dev(csessions.length === m && j <= m);
        while (j < m) {
            csessions.splice(j, 1);
            m--;
            assert_dev(csessions.length === m);
            changes = true;
        }
        this.canvasSessionData = data;
        return changes ? this.canvasSessions : (null as any);
    }

    static async fetchOpsChunked(props: {
        cacheName: string;
        sid: string;
        token: string;
        useCache: boolean;
        ofs: number;
        endOfs: number;
        ops: DataOperation[];
        ofs0: number;
        chunked?: string;
    }) {
        let chunked = true;
        while (chunked) {
            const resp = await new Promise((resolve: (ret: any) => void, reject: (e: Error) => void) => {
                fetch_ops(
                    props.token,
                    async (error, result) => {
                        if (error) {
                            reject(error);
                        } else {
                            assert(
                                result.storageName === props.sid &&
                                    result.startOfs === props.ofs &&
                                    Array.isArray(result.ops) &&
                                    (undefined === props.endOfs || result.startOfs + result.ops.length <= props.endOfs),
                            );
                            if (
                                props.useCache &&
                                DataModel.cache &&
                                result.storageName === props.sid &&
                                Array.isArray(result.ops)
                            ) {
                                try {
                                    await DataModel.cache.cacheStream(
                                        props.cacheName,
                                        result.startOfs,
                                        result.endOfs,
                                        result.ops,
                                    );
                                } catch (e) {
                                    console.warn("cache failed " + e.toString());
                                    props.useCache = false;
                                }
                            }
                            resolve(result);
                        }
                    },
                    props.ofs,
                    props.endOfs,
                    props.sid,
                    props.chunked || true,
                );
            });
            assert(resp.startOfs === props.ofs && Array.isArray(resp.ops));
            props.ofs += resp.ops.length;
            if (null === props.ops) {
                props.ops = resp.ops;
            } else {
                const _ops = resp.ops;
                const _n_ops = _ops.length;
                for (let _i_op = 0; _i_op < _n_ops; _i_op++) {
                    props.ops.push(_ops[_i_op]);
                }
            }
            //assert(sync.sync.ofs+props.ops.length===props.ofs);
            assert(props.ofs0 + props.ops.length === props.ofs);
            chunked = resp.chunked;
        }
        return props;
    }

    async doInitialSyncFromCache(cache: DataModelCacheProvider, token: string, maxCommit?: number) {
        let useCache = true;
        try {
            const sync = this.getSync(0);
            assert(0 === sync.sync.ops.length); // why is there something to sync?
            if (cache && 0 === sync.sync.ops.length) {
                let ops = await cache.fetchStream(this.storageName, sync.sync.ofs, undefined);
                if (Array.isArray(ops)) {
                    if (maxCommit) {
                        assert(sync.sync.ofs <= maxCommit, "invalid maxCommit");
                        if (sync.sync.ofs + ops.length >= maxCommit) {
                            ops = ops.slice(0, maxCommit - sync.sync.ofs);
                            assert(0 === ops.length || !ops[ops.length - 1].group, "invalid maxCommit");
                        }
                        assert(sync.sync.ofs + ops.length <= maxCommit);
                    }

                    console.log("Init sync from cache " + ops.length);
                    if (ops.length > 0) {
                        this._fixGroupingIfNeeded(ops);
                        const ret = this.commitSync(
                            {
                                ofs: sync.sync.ofs,
                                ops,
                            },
                            sync.token,
                        );
                        assert(0 === ret);
                    }
                }
            }
        } catch (e) {
            console.warn("Cache failed " + e.toString());
            useCache = false;
            //@TODO clear cache!
        }
        try {
            const sync = this.getSync(0);
            assert(0 === sync.sync.ops.length); // why is there something to sync?
            const ctx = await DataModel.fetchOpsChunked({
                cacheName: this.storageName,
                sid: this.storageName,
                token: token,
                useCache: useCache,
                ofs0: sync.sync.ofs,
                ofs: sync.sync.ofs,
                ops: null,
                endOfs: maxCommit,
            });
            if ((ctx.ops || []).length > 0) {
                this._fixGroupingIfNeeded(ctx.ops || []);
                const ret = this.commitSync(
                    {
                        ofs: sync.sync.ofs,
                        ops: ctx.ops || [],
                    },
                    sync.token,
                );
                assert(0 === ret);
            }
        } catch (e) {
            console.error("initial sync failed");
            throw e;
        }
    }
}
