import { create } from "zustand";

type ProjectPeriodState = {
    startDate: Date;
    endDate: Date;
    setProjectPeriod: (startDate: Date, endDate: Date) => void;
};

export const defaultProjectPeriod = {
    startDate: new Date(),
    endDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
};

export const useProjectPeriodStore = create<ProjectPeriodState>((set) => ({
    startDate: defaultProjectPeriod.startDate,
    endDate: defaultProjectPeriod.endDate,
    setProjectPeriod: (startDate: Date, endDate: Date) => {
        set({ startDate, endDate });
    },
    reset: () => set((state) => ({ ...state, ...defaultProjectPeriod })),
}));
