import React, { useEffect, useMemo, useState } from "react";
import { IStyleFunctionOrObject, mergeStyleSets } from "@fluentui/merge-styles";
import { intl } from "./GlobalHelperReact";
import {
    ContextualMenu,
    DatePicker,
    IChoiceGroupStyleProps,
    IChoiceGroupStyles,
    IContextualMenuProps,
    IDatePickerProps,
    IIconProps,
    Modal,
    IContextualMenuItem,
    DefaultButton,
    TextField,
    ITextFieldStyleProps,
    ITextFieldStyles,
    Dropdown,
    IDropdownStyleProps,
    IDropdownStyles,
    IChoiceGroupOption,
    ChoiceGroup,
    IconButton as FluentUIIconButton,
} from "@fluentui/react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { weekNumber } from "weeknumber";
import { EpochMStoEpochDays, HelperDateTo24TimeStr, HelperDateToUTCEpochMS, UNIT_TYPES } from "@/model/GlobalHelper";
import { useParticleContext } from "./api/ParticleContext";
import { getCurrentLanguage } from "@/components/utils/date/locale";
import { MainWorkerPipe } from "./MainWorkerPipe";
import { Progress } from "@/components/ui/progress";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { cn } from "@/lib/utils";
import { CalendarIcon, ChevronDown, CircleHelp, ExternalLink, LogOut, Paperclip, User, UserRound } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { de, enUS, fr } from "date-fns/locale";
import { LCMDDatepicker } from "@/components/common/LCMDDatepicker";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { LCMDTooltip } from "@/components/common/Tooltip/LCMDTooltip";

const classNames = mergeStyleSets({
    control: {
        display: "flex",
        boxSizing: "border-box",
        backgroundColor: "#F1F3F3",
        borderRadius: 4,
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 16,
        paddingBottom: 16,
        columnGap: 20,
    },
    container: {
        display: "flex",
        boxSizing: "border-box",
        flexDirection: "column",
        flexGrow: 1,
        flexShrink: 1,
    },
    containerDuration: {
        display: "flex",
        boxSizing: "border-box",
        flexDirection: "column",
        flexGrow: 1,
        flexShrink: 1,
        maxWidth: 100,
    },
    title: {
        color: "#565C60",
        fontWeight: 600,
        fontSize: 14,
    },
    subTitle: {
        color: "#565C60",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "20px",
        height: 20,
    },
    acrtlbl: {
        display: "inline-block",
        cursor: "pointer",
        flex: "0 0 0",
        //ToDo: Check if functionality is needed of making button red if files are attached
        //backgroundColor: "red",
    },
    acrtbtn: {
        pointerEvents: "none",
    },
    acrtinp: {
        display: "none",
        visibility: "hidden",
    },
    nonProgressPopup: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        width: 640,
        height: 160,
    },
    nonProgressPopupContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "center",
    },
});

export function _onFormatDate(date: Date) {
    const locale = getCurrentLanguage();
    const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date && date.getTime() > 0 ? date.toLocaleDateString(locale, options) : "";
}

function getCalendarStrings() {
    const calendar = getIntlCalendar();

    // Fluent UI expects the translations as arrays, thus we have to transform them
    const strings = { ...calendar.strings };
    strings.days = [
        strings.days.sun,
        strings.days.mon,
        strings.days.tue,
        strings.days.wed,
        strings.days.thu,
        strings.days.fri,
        strings.days.sat,
    ];
    strings.shortDays = [
        strings.shortDays.sun,
        strings.shortDays.mon,
        strings.shortDays.tue,
        strings.shortDays.wed,
        strings.shortDays.thu,
        strings.shortDays.fri,
        strings.shortDays.sat,
    ];
    strings.months = [
        strings.months.jan,
        strings.months.feb,
        strings.months.mar,
        strings.months.apr,
        strings.months.may,
        strings.months.jun,
        strings.months.jul,
        strings.months.aug,
        strings.months.sep,
        strings.months.oct,
        strings.months.nov,
        strings.months.dec,
    ];
    strings.shortMonths = [
        strings.shortMonths.jan,
        strings.shortMonths.feb,
        strings.shortMonths.mar,
        strings.shortMonths.apr,
        strings.shortMonths.may,
        strings.shortMonths.jun,
        strings.shortMonths.jul,
        strings.shortMonths.aug,
        strings.shortMonths.sep,
        strings.shortMonths.oct,
        strings.shortMonths.nov,
        strings.shortMonths.dec,
    ];

    return strings;
}

export function getIntlCalendar() {
    const o = intl.getInitOptions();
    return (o.locales || {})[o.currentLocale]?.calendar || {};
}

let shortDays2;

export function generateCanvasCalendarIntl() {
    if (shortDays2 == undefined) {
        const calendar = getIntlCalendar();
        shortDays2 = {
            shortDays2: calendar?.strings?.shortDays2,
        };
    }
    return shortDays2;
}

// datepicker will show local time, but upon onSelectDate it will recalculate it to the corresponding UTC timestamp. I think it's a bit of a hack
export function UTCDatePicker(props: IDatePickerProps) {
    const calendar = getIntlCalendar();
    const strings = getCalendarStrings();

    function _onUTCFormatDate(date: Date) {
        const locale = getCurrentLanguage();
        const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };

        return date && date.getTime() > 0 ? date.toUTCFormatString(locale, options) : "";
    }

    function onSelect(date: Date) {
        props.onSelectDate(new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000));
    }

    return (
        <DatePicker
            {...props}
            onSelectDate={onSelect}
            formatDate={_onUTCFormatDate}
            openOnClick={false}
            firstDayOfWeek={calendar?.firstDayOfWeek || 0}
            firstWeekOfYear={calendar?.firstWeekOfYear || 0}
            strings={strings}
        />
    );
}

export function UTCDatePickerV2({
    onSelectDate,
    value,
    disabled,
    label,
    className,
    placeholder,
    onOpenChange,
}: IDatePickerProps & { onOpenChange?: (isOpen: boolean) => void }) {
    const [isCalendarOpen, setCalendarOpen] = useState(false);

    useEffect(() => {
        onOpenChange && onOpenChange(isCalendarOpen);
    }, [isCalendarOpen]);

    function _onUTCFormatDate(date: Date) {
        const locale = getCurrentLanguage();
        const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };

        return date && date.getTime() > 0 ? date.toUTCFormatString(locale, options) : "";
    }

    function onSelect(date: Date) {
        onSelectDate(new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000));
        setCalendarOpen(false);
    }

    return (
        <div className={cn("flex", "flex-col", "gap-1", className)}>
            <Label>{label}</Label>
            <Popover open={isCalendarOpen} modal={true}>
                <PopoverTrigger asChild>
                    <Button
                        onClick={() => {
                            setCalendarOpen((prevState) => !prevState);
                        }}
                        variant={"outline"}
                        disabled={disabled}
                        className={cn("w-full pl-3 text-left font-normal", !value && "text-muted-foreground")}
                    >
                        <span>{value ? _onUTCFormatDate(value) : placeholder || ""}</span>
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    className="w-auto p-0"
                    align="start"
                    onPointerDownOutside={() => setCalendarOpen(false)}
                >
                    <Calendar
                        mode="single"
                        selected={value}
                        onSelect={onSelect}
                        disabled={disabled}
                        initialFocus
                        required
                    />
                </PopoverContent>
            </Popover>
        </div>
    );
}

export function LocalizedDatePickerV1(
    props: IDatePickerProps & {
        readOnly?: boolean;
        selected?: Date;
    },
) {
    const calendar = getIntlCalendar();
    const strings = getCalendarStrings();

    return (
        <DatePicker
            {...props}
            formatDate={_onFormatDate}
            openOnClick={false}
            firstDayOfWeek={calendar?.firstDayOfWeek || 0}
            firstWeekOfYear={calendar?.firstWeekOfYear || 0}
            strings={strings}
        />
    );
}

export function LegacyStartEndDateControlV1(props: {
    defaultStart?: Date;
    defaultEnd?: Date;
    onChange?: ((startDate: Date, endDate: Date) => void) | null;
    disabledStart?: boolean;
    disabledEnd?: boolean;
    utc: boolean;
    duration?: number;
    durationUnit?: number;
    ellapsedUnit?: number;
}) {
    const [start, setStart] = useState(props.defaultStart);
    const [end, setEnd] = useState(props.defaultEnd);
    const cwStart = start ? weekNumber(start) : -1;
    const cwEnd = end ? weekNumber(end) : -1;
    const readOnly = null === props.onChange;
    const onChangeStart = readOnly
        ? null
        : useMemo(
              () => (date: Date) => {
                  if (props.utc) {
                      date = new Date(HelperDateToUTCEpochMS(date));
                  }
                  setStart(date);
                  if (props.onChange) {
                      props.onChange(date, end);
                  }
              },
              [props.utc, props.onChange, setStart, end],
          );
    const onChangeEnd = readOnly
        ? null
        : useMemo(
              () => (date: Date) => {
                  if (props.utc) {
                      date = new Date(HelperDateToUTCEpochMS(date));
                  }
                  setEnd(date);
                  if (props.onChange) {
                      props.onChange(start, date);
                  }
              },
              [props.utc, props.onChange, start, setEnd],
          );
    return (
        <div className={classNames.control}>
            <div className={classNames.container}>
                {readOnly ? (
                    <TextField
                        readOnly={true}
                        label={intl.get("fw.startEndDateControl.start.label")}
                        value={_onFormatDate(props.disabledStart ? null : start)}
                        borderless
                    />
                ) : (
                    <LocalizedDatePicker
                        label={intl.get("fw.startEndDateControl.start.text")}
                        placeholder={intl.get("fw.startEndDateControl.start.placeholder")}
                        value={props.disabledStart ? null : start}
                        onSelectDate={onChangeStart}
                        disabled={props.disabledStart}
                        calloutProps={{ doNotLayer: true }}
                    />
                )}
                <div className={classNames.subTitle}>
                    {!props.disabledStart && cwStart >= 0
                        ? intl.get("fw.startEndDateControl.cw", { cw: cwStart.toString(10) })
                        : null}
                </div>
            </div>
            {props.disabledEnd || null !== props.defaultEnd ? (
                <div className={classNames.container}>
                    {readOnly ? (
                        <TextField
                            readOnly={true}
                            label={intl.get("fw.startEndDateControl.end.label")}
                            value={_onFormatDate(props.disabledEnd ? null : end)}
                            borderless
                        />
                    ) : (
                        <LocalizedDatePicker
                            label={intl.get("fw.startEndDateControl.end.text")}
                            placeholder={intl.get("fw.startEndDateControl.end.placeholder")}
                            value={props.disabledEnd ? null : end}
                            onSelectDate={onChangeEnd}
                            disabled={props.disabledEnd}
                            calloutProps={{ doNotLayer: true }}
                        />
                    )}
                    <div className={classNames.subTitle}>
                        {!props.disabledEnd && cwEnd >= 0
                            ? intl.get("fw.startEndDateControl.cw", { cw: cwEnd.toString(10) })
                            : null}
                    </div>
                </div>
            ) : props.defaultStart && !props.defaultEnd && null === end ? (
                <div className={classNames.container}>
                    <TextField
                        readOnly={true}
                        label={intl.get("fw.startEndDateControl.end.time")}
                        type="time"
                        value={HelperDateTo24TimeStr(props.defaultStart.getTime())}
                        borderless
                    />
                </div>
            ) : null}
            {undefined !== props.duration && undefined !== props.durationUnit ? (
                <div className={classNames.containerDuration}>
                    <TextField
                        readOnly={true}
                        label={intl.get("fw.startEndDateControl.duration.label")}
                        type="number"
                        value={props.duration?.toString()}
                        suffix={intl.get(`UnitTypes.${UNIT_TYPES[props.durationUnit]}`, { value: props?.duration })}
                        borderless
                    />
                    <div className={classNames.subTitle}>
                        {!props.disabledStart && start && !props.disabledEnd && end && 10 === props.ellapsedUnit
                            ? [
                                  (EpochMStoEpochDays(end.getTime()) - EpochMStoEpochDays(start.getTime())).toString(
                                      10,
                                  ),
                                  intl.get(`UnitTypes.${UNIT_TYPES[props.ellapsedUnit]}`),
                              ].join(" ")
                            : null}
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export function UploadAttachmentControlV1(props: {
    card: { tid: number; aid: number; i: number };
    statusKey: string | number;
    onStatus?: (status: {}[]) => void;
    onFailed?: () => void;
    buttonId?: string;
    cb?: number;
}) {
    const LCMD = useParticleContext();
    const onChange = useMemo(
        () => (ev) => {
            //console.log("HTMLInputElement::onChange");
            const fileInput: HTMLInputElement = ev.target;
            if (fileInput.files.length > 0) {
                let cb = props.cb;
                if (
                    "number" !== typeof cb &&
                    "string" === typeof fileInput.dataset.fwAttachmentCb &&
                    (Number.parseInt(fileInput.dataset.fwAttachmentCb) || 0).toString() ===
                        fileInput.dataset.fwAttachmentCb
                ) {
                    cb = Number.parseInt(fileInput.dataset.fwAttachmentCb);
                }
                LCMD.uploadAttachments(props.card, fileInput.files, props.statusKey, cb);
            }
        },
        [LCMD, props.card, props.statusKey],
    );
    const onClick = useMemo(
        () => (ev) => {
            //console.log("HTMLInputElement::onClick");
            const fileInput: HTMLInputElement = ev.target;
            fileInput.value = null;
        },
        [],
    );
    return (
        <label className={classNames.acrtlbl}>
            <input
                id={props.buttonId}
                type="file"
                className={classNames.acrtinp}
                onChange={onChange}
                onClick={onClick}
                multiple={true}
            />
            <DefaultButton
                iconProps={{
                    iconName: "Attach",
                }}
                text={intl.get("fw.attachment.text")}
                allowDisabledFocus
                className={classNames.acrtbtn}
            />
        </label>
    );
}

export function LocalizedDatePicker(
    props: IDatePickerProps & {
        readOnly?: boolean;
        selected?: Date;
        required?: boolean;
        fromDate?: Date;
        toDate?: Date;
    },
) {
    const dateLocales = { "de-DE": de, "en-US": enUS, "fr-FR": fr };

    return (
        <LCMDDatepicker
            label={props.label}
            disabled={props.disabled}
            value={props.value}
            placeholder={props.placeholder}
            onSelectDate={props.onSelectDate}
            formatDate={_onFormatDate}
            locale={dateLocales[intl.options.currentLocale]}
            required={props.required}
            fromDate={props.fromDate}
            toDate={props.toDate}
        />
    );
}

export function LegacyStartEndDateControl(props: {
    defaultStart?: Date;
    defaultEnd?: Date;
    onChange?: ((startDate: Date, endDate: Date) => void) | null;
    disabledStart?: boolean;
    disabledEnd?: boolean;
    utc: boolean;
    duration?: number;
    durationUnit?: number;
    ellapsedUnit?: number;
}) {
    const [start, setStart] = useState(props.defaultStart);
    const [end, setEnd] = useState(props.defaultEnd);
    const cwStart = start ? weekNumber(start) : -1;
    const cwEnd = end ? weekNumber(end) : -1;
    const readOnly = null === props.onChange;
    const onChangeStart = (date: Date) => {
        if (readOnly) {
            return;
        }

        if (props.utc) {
            date = new Date(HelperDateToUTCEpochMS(date));
        }
        setStart(date);
        if (props.onChange) {
            props.onChange(date, end);
        }
    };

    const onChangeEnd = (date: Date) => {
        if (readOnly) {
            return;
        }

        if (props.utc) {
            date = new Date(HelperDateToUTCEpochMS(date));
        }
        setEnd(date);
        if (props.onChange) {
            props.onChange(start, date);
        }
    };
    return (
        <div className="mt-1.5 box-border flex flex-row justify-between gap-x-5 rounded bg-gray-200 px-5 py-4">
            <div className="box-border flex shrink grow flex-col">
                {readOnly ? (
                    <>
                        <Label htmlFor="startDateControl" className="block pb-1">
                            {intl.get("fw.startEndDateControl.start.label")}
                        </Label>
                        <Input
                            id="startDateControl"
                            value={_onFormatDate(props.disabledStart ? null : start)}
                            className="bg-white disabled:cursor-text disabled:bg-white disabled:opacity-100"
                            disabled
                        />
                    </>
                ) : (
                    <LocalizedDatePicker
                        label={intl.get("fw.startEndDateControl.start.text")}
                        placeholder={intl.get("fw.startEndDateControl.start.placeholder")}
                        value={props.disabledStart ? null : start}
                        onSelectDate={onChangeStart}
                        disabled={props.disabledStart}
                    />
                )}
                <div className="h-5 text-sm font-semibold leading-5 text-gray-700">
                    {!props.disabledStart && cwStart >= 0
                        ? intl.get("fw.startEndDateControl.cw", { cw: cwStart.toString(10) })
                        : null}
                </div>
            </div>
            {props.disabledEnd || null !== props.defaultEnd ? (
                <div className="box-border flex shrink grow flex-col">
                    {readOnly ? (
                        <>
                            <Label htmlFor="endDateControl" className="block pb-1">
                                {intl.get("fw.startEndDateControl.end.label")}
                            </Label>
                            <Input
                                id="endDateControl"
                                disabled
                                value={_onFormatDate(props.disabledEnd ? null : end)}
                                className="bg-white disabled:cursor-text disabled:bg-white disabled:opacity-100"
                            />
                        </>
                    ) : (
                        <LocalizedDatePicker
                            label={intl.get("fw.startEndDateControl.end.text")}
                            placeholder={intl.get("fw.startEndDateControl.end.placeholder")}
                            value={props.disabledEnd ? null : end}
                            onSelectDate={onChangeEnd}
                            disabled={props.disabledEnd}
                        />
                    )}
                    <div className="h-5 text-sm font-semibold leading-5 text-gray-700">
                        {!props.disabledEnd && cwEnd >= 0
                            ? intl.get("fw.startEndDateControl.cw", { cw: cwEnd.toString(10) })
                            : null}
                    </div>
                </div>
            ) : props.defaultStart && !props.defaultEnd && null === end ? (
                <div className="box-border flex shrink grow flex-col">
                    <Label htmlFor="endTime" className="block pb-1">
                        {intl.get("fw.startEndDateControl.end.time")}
                    </Label>
                    <Input
                        id="endTime"
                        type="date"
                        disabled
                        value={HelperDateTo24TimeStr(props.defaultStart.getTime())}
                        className="bg-white disabled:cursor-text disabled:bg-white disabled:opacity-100"
                    />
                </div>
            ) : null}
            {undefined !== props.duration && undefined !== props.durationUnit ? (
                <div className="box-border flex max-w-[100px] shrink grow flex-col">
                    <Label htmlFor="endTime" className="block pb-1">
                        {intl.get("fw.startEndDateControl.duration.label")}
                    </Label>
                    <Input
                        id="endTime"
                        disabled
                        suffix={intl.get(`UnitTypes.${UNIT_TYPES[props.durationUnit]}`, { value: props?.duration })}
                        value={props.duration?.toString()}
                        className="w-16 bg-white disabled:cursor-text disabled:bg-white disabled:opacity-100"
                        suffixClassName="bg-gray-200"
                    />
                    <div className="h-5 text-sm font-semibold leading-5 text-gray-700">
                        {!props.disabledStart && start && !props.disabledEnd && end && 10 === props.ellapsedUnit
                            ? [
                                  (EpochMStoEpochDays(end.getTime()) - EpochMStoEpochDays(start.getTime())).toString(
                                      10,
                                  ),
                                  intl.get(`UnitTypes.${UNIT_TYPES[props.ellapsedUnit]}`),
                              ].join(" ")
                            : null}
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export function UploadAttachmentControl(props: {
    card: { tid: number; aid: number; i: number };
    statusKey: string | number;
    onStatus?: (status: {}[]) => void;
    onFailed?: () => void;
    buttonId?: string;
    cb?: number;
}) {
    const LCMD = useParticleContext();
    const onChange = useMemo(
        () => (ev) => {
            //console.log("HTMLInputElement::onChange");
            const fileInput: HTMLInputElement = ev.target;
            if (fileInput.files.length > 0) {
                let cb = props.cb;
                if (
                    "number" !== typeof cb &&
                    "string" === typeof fileInput.dataset.fwAttachmentCb &&
                    (Number.parseInt(fileInput.dataset.fwAttachmentCb) || 0).toString() ===
                        fileInput.dataset.fwAttachmentCb
                ) {
                    cb = Number.parseInt(fileInput.dataset.fwAttachmentCb);
                }
                LCMD.uploadAttachments(props.card, fileInput.files, props.statusKey, cb);
            }
        },
        [LCMD, props.card, props.statusKey],
    );
    const onClick = useMemo(
        () => (ev) => {
            //console.log("HTMLInputElement::onClick");
            const fileInput: HTMLInputElement = ev.target;
            fileInput.value = null;
        },
        [],
    );
    return (
        <label className="cursor-pointer">
            <input
                id={props.buttonId}
                type="file"
                className="invisible hidden"
                onChange={onChange}
                onClick={onClick}
                multiple={true}
            />
            <Button variant="outline" className="pointer-events-none border-[#8a8886]">
                <Paperclip size={16} className="mr-2" />
                {intl.get("fw.attachment.text")}
            </Button>
        </label>
    );
}

function _menuUserSettingButtonClick(
    this: MainWorkerPipe,
    ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    item?: IContextualMenuItem,
): boolean | void {
    //console.log("CLICK "+JSON.stringify(item, null, 4));
    switch (item.key) {
        case "logout":
            {
                this.dispatchMessage(["framework", "logout"]);
            }
            break;
        case "accountManagement":
            {
                this.dispatchMessage(["toggle", "dialog.project.account", {}]);
            }
            break;
    }
}

export function UserSettingButton(props: { worker: MainWorkerPipe }) {
    const userSettingButtonOptions = [
        {
            key: "logout",
            icon: <LogOut className="mr-2 h-5 w-4 self-center" />,
            title: intl.get("fw.tooltip.logout"),
            text: intl.get("fw.contact.logout"),
            onClick: () => props.worker.dispatchMessage(["framework", "logout"]),
        },
        {
            key: "accountManagement",
            icon: <User className="mr-2 h-5 w-4 self-center" />,
            title: intl.get("fw.tooltip.accountManagement"),
            text: intl.get("fw.contact.accountManagement"),
            onClick: () => props.worker.dispatchMessage(["toggle", "dialog.project.account", {}]),
            //disabled: true
        },
        {
            key: "terms",
            icon: <ExternalLink className="mr-2 h-5 w-4 self-center" />,
            title: intl.get("fw.tooltip.terms"),
            text: intl.get("fw.contact.terms"),
            href: intl.get("links.termsURL"),
            target: "_blank",
        },
    ];
    // bloody hack, but the username wont change ...
    /*
    const sub=props.worker.auth.params.sub;
    if (null===menuUserSettingButton.items[0].key) {
        menuUserSettingButton.items[0].key=sub;
        menuUserSettingButton.items[0].text="TODO";
    }
    assert(sub===menuUserSettingButton.items[0].key); // changed? why? how?
    */
    return (
        <DropdownMenu>
            <LCMDTooltip text={intl.get("CommonUse.Profile")}>
                <DropdownMenuTrigger className="outline-none">
                    <IconButton
                        data-userflow-id="profil-menu"
                        className="mx-0.5 h-8 w-[36px] self-center rounded-md p-2 hover:bg-accent/20 hover:text-white"
                        icon={UserRound}
                        iconProps={{ size: 20 }}
                    />
                </DropdownMenuTrigger>
            </LCMDTooltip>

            <DropdownMenuContent className="left-1">
                <DropdownMenuGroup>
                    <ul>
                        {userSettingButtonOptions.map(({ key, title, text, icon, href, target, onClick }) => (
                            <li
                                key={key}
                                className="row-span-3"
                                data-userflow-id={key === "accountManagement" ? "profil-einstellungen" : null}
                            >
                                <DropdownMenuItem>
                                    <a
                                        className="flex h-full w-full cursor-pointer justify-items-center"
                                        onClick={onClick}
                                        title={title}
                                        href={href}
                                        target={target}
                                    >
                                        {icon}
                                        {text}
                                    </a>
                                </DropdownMenuItem>
                            </li>
                        ))}
                    </ul>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}

function onTextFieldChange(this: any, value0, setValue0, value, ev) {
    const v = (ev.target as HTMLInputElement).value;
    const nv = { ...(value ? value : value0), value: v };
    this(nv);
}

function onTextFieldBlur(this: any, value0, setValue0, value, onChange: (value: string) => void) {
    if (null !== value && value0 !== value) {
        setValue0(value);
        this(null);
        console.log("BLUR " + JSON.stringify(value));
        onChange(value);
    }
}

function onTextFieldKeypress(this: null, ev) {
    if (ev.key === "Enter" || ev.keyCode === 13) {
        ev.preventDefault();
        ev.target.blur();
    }
}

export function TextFieldWithCommitV1(props: {
    label?: string;
    value: string | { value: string; suffix?: string };
    ts: number;
    styles?: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
    onChange: (value: { type?: string; value: string; suffix?: string }) => void;
    multiline?: boolean;
    rows?: number;
    type?: string;
    suffix?: string | { dropdown?: string[] };
    dropdown?: string[];
    disabled?: boolean;
    min?: any;
    max?: any;
    placeholder?: any;
}) {
    const props0: { value: string; suffix?: string } = useMemo(
        () => (null !== props.value && "object" === typeof props.value ? (props.value as any) : { value: props.value }),
        [props.value],
    );
    const [value, setValue] = useState(null);
    const [value0, setValue0] = useState(props0);
    useEffect(() => {
        if (null === value && value0 !== props0) {
            //console.log("setValue0("+JSON.stringify(props.value)+")");
            setValue0(props0);
        }
    }, [/*value, value0,*/ props.ts, props0, setValue0]);
    const suffix = "string" === typeof props.suffix ? props.suffix : undefined;
    const onRenderSuffix = Array.isArray((props.suffix as any)?.dropdown)
        ? () => {
              return (
                  <Dropdown
                      selectedKey={value ? value?.suffix : value0?.suffix}
                      options={(props.suffix as any).dropdown.map((v, i_v) => ({
                          key: v,
                          text: v,
                      }))}
                      styles={{
                          root: {},
                          title: {
                              minWidth: (props.suffix as any)?.width || undefined,
                              backgroundColor: "transparent",
                              border: "none",
                          },
                      }}
                      onChange={(event, option) => {
                          const nv = { ...(value ? value : value0), suffix: option.key };
                          onTextFieldBlur.call(setValue, value0, setValue0, nv, props.onChange);
                      }}
                  />
              );
          }
        : undefined;
    return (
        <TextField
            label={props.label}
            styles={props.styles}
            suffix={suffix}
            onRenderSuffix={onRenderSuffix}
            value={null !== value ? value.value || "" : value0.value || ""}
            onChange={onTextFieldChange.bind(setValue, value0, setValue0, value)}
            onBlur={onTextFieldBlur.bind(setValue, value0, setValue0, value, props.onChange)}
            onKeyPress={onTextFieldKeypress}
            multiline={props.multiline}
            rows={props.rows}
            resizable={false}
            type={props.type}
            disabled={props.disabled}
            min={props.min}
            max={props.max}
            placeholder={props.placeholder}
        />
    );
}

export function DropdownWithCommitV1(props: {
    label?: string;
    value: string | { value: string };
    ts: number;
    styles?: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>;
    onChange: (value: { type?: string; value: string; suffix?: string }) => void;
    dropdown: (string | { key: string; text: string })[];
    multiSelect?: boolean;
    disabled?: boolean;
}) {
    const dropdownOptions = useMemo(
        () =>
            props.dropdown.map((v, i_v) =>
                "string" === typeof v
                    ? {
                          key: v,
                          text: v,
                      }
                    : v,
            ),
        [props.dropdown],
    );
    return (
        <Dropdown
            onChange={(event, option) => {
                props.onChange({
                    value: option.key as string,
                });
            }}
            label={props.label}
            styles={props.styles}
            options={dropdownOptions}
            selectedKey={(props.value as any)?.value || props.value}
            disabled={props.disabled}
        />
    );
}

export function ChoiceWithCommitV1(props: {
    label?: string;
    value: string | { value: string };
    ts: number;
    styles?: IStyleFunctionOrObject<IChoiceGroupStyleProps, IChoiceGroupStyles>;
    choices: any;
    onRenderField: (choice, i_choice, props, render) => any;
    onChange: (value: { value: string }) => void;
}) {
    const options = useMemo(
        () =>
            props.choices.map(
                (choice, i_choice) =>
                    ({
                        key: choice.id,
                        styles: {
                            choiceFieldWrapper: {
                                //background: 'green',
                                width: "100%",
                            },
                        },
                        onRenderField: props.onRenderField.bind(props, choice, i_choice), //(_props, _render)=>(<div style={{backgroundColor: "green"}}>{props.fields[i_choice]}</div>)
                    }) as IChoiceGroupOption,
            ),
        [props.choices],
    );
    return (
        <ChoiceGroup
            styles={props.styles}
            label={props.label}
            options={options}
            selectedKey={(props.value as any)?.value || props.value}
            onChange={(event, option) => {
                props.onChange({
                    value: option.key as string,
                });
            }}
        />
    );
}

export function TextFieldWithCommit(props: {
    id?: string;
    label?: string;
    value: string | { value: string; suffix?: string };
    ts: number;
    styles?: any;
    onChange: (value: { type?: string; value: string; suffix?: string }) => void;
    multiline?: boolean;
    rows?: number;
    type?: string;
    suffix?: string | { dropdown?: string[] };
    dropdown?: string[];
    disabled?: boolean;
    min?: any;
    max?: any;
    placeholder?: any;
}) {
    const props0: { value: string; suffix?: string } = useMemo(
        () => (null !== props.value && "object" === typeof props.value ? (props.value as any) : { value: props.value }),
        [props.value],
    );
    const [value, setValue] = useState(null);
    const [value0, setValue0] = useState(props0);

    useEffect(() => {
        if (null === value && value0 !== props0) {
            //console.log("setValue0("+JSON.stringify(props.value)+")");
            setValue0(props0);
        }
    }, [/*value, value0,*/ props.ts, props0, setValue0]);

    const suffix = "string" === typeof props.suffix ? props.suffix : undefined;
    const suffixValue = value ? value?.suffix : value0?.suffix;

    const InputDropdown = Array.isArray((props.suffix as any)?.dropdown) ? (
        <DropdownMenu>
            <DropdownMenuTrigger className="flex h-7 py-1.5 outline-none">
                <ChevronDown size={16} className="opacity-50" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="left-1">
                <DropdownMenuGroup>
                    <ul>
                        {(props.suffix as any).dropdown.map((option) => (
                            <li key={option} className="row-span-3">
                                <DropdownMenuItem
                                    onChange={() => {
                                        const nv = {
                                            ...(value ? value : value0),
                                            suffix: option.key,
                                        };
                                        onTextFieldBlur.call(setValue, value0, setValue0, nv, props.onChange);
                                    }}
                                >
                                    <p className="mx-1.5 break-words text-sm font-normal leading-5 tracking-tighter text-[#565C60]">
                                        {option}
                                    </p>
                                </DropdownMenuItem>
                            </li>
                        ))}
                    </ul>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    ) : null;

    return (
        <div style={props.styles?.wrapper}>
            <Label htmlFor={props.id} className="block py-1">
                {props.label}
            </Label>
            {props.multiline ? (
                <Textarea
                    id={props.id}
                    value={null !== value ? value.value || "" : value0.value || ""}
                    onChange={onTextFieldChange.bind(setValue, value0, setValue0, value)}
                    onBlur={onTextFieldBlur.bind(setValue, value0, setValue0, value, props.onChange)}
                    style={props.styles?.fieldGroup}
                    rows={props.rows}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                />
            ) : (
                <Input
                    id={props.id}
                    value={suffixValue || null !== value ? value.value || "" : value0.value || ""}
                    onChange={onTextFieldChange.bind(setValue, value0, setValue0, value)}
                    onBlur={onTextFieldBlur.bind(setValue, value0, setValue0, value, props.onChange)}
                    style={props.styles?.fieldGroup}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    type={props.type}
                    suffix={suffix}
                    dropdown={InputDropdown}
                    min={props.min}
                    max={props.max}
                />
            )}
        </div>
    );
}

export function DropdownWithCommit(props: {
    label?: string;
    value: string | { value: string };
    ts: number;
    styles?: any;
    onChange: (value: { type?: string; value: string; suffix?: string }) => void;
    dropdown: (string | { key: string; text: string })[];
    disabled?: boolean;
}) {
    const dropdownOptions = useMemo(
        () =>
            props.dropdown.map((v, i_v) =>
                "string" === typeof v && !!v
                    ? {
                          key: v,
                          text: v,
                      }
                    : v,
            ),
        [props.dropdown],
    );

    return (
        <div style={props.styles.root}>
            <Label style={props.styles.label}>{props.label}</Label>
            <Select
                value={(props.value as any)?.value || props.value}
                disabled={props.disabled}
                onValueChange={(option) => {
                    props.onChange({
                        value: option as string,
                    });
                }}
            >
                <SelectTrigger style={props.styles.dropdown}>
                    <SelectValue />
                </SelectTrigger>
                <SelectContent>
                    {dropdownOptions.map((option, index) => (
                        <SelectItem key={index} value={(option as any)?.key}>
                            {(option as any)?.text}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    );
}

export function ChoiceWithCommit(props: {
    label?: string;
    value: string | { value: string };
    ts: number;
    styles?: IStyleFunctionOrObject<IChoiceGroupStyleProps, IChoiceGroupStyles>;
    choices: any;
    onRenderField: (choice, props) => any;
    onChange: (value: { value: string }) => void;
}) {
    return (
        <>
            <Label className="mb-3 block font-semibold">{props.label}</Label>
            <RadioGroup
                defaultValue={(props.value as any)?.value || props.value}
                onValueChange={(value) => props.onChange((value as any)?.value || value)}
            >
                {props.choices.map((option) => {
                    return (
                        <div key={option.id} className="flex flex-grow flex-row items-center justify-between gap-2">
                            <RadioGroupItem id={option.id} value={option.id} />
                            <div className="w-full">{props.onRenderField(option, props)}</div>
                        </div>
                    );
                })}
            </RadioGroup>
        </>
    );
}

const moreIcon: IIconProps = {
    iconName: "MoreVertical",
};

function onRenderMore(
    menuProps: IContextualMenuProps,
    selection: Selection,
    onContextMenu: (selection: Selection, ev, action) => void,
    item: any,
) {
    return (
        <FluentUIIconButton
            styles={{
                root: {
                    height: "100%",
                },
            }}
            menuProps={menuProps}
            iconProps={moreIcon}
            data-selection-disabled={true}
            data-is-focusable={false}
            split={false}
            onRenderMenuIcon={() => null}
            menuAs={(menuProps: IContextualMenuProps) => {
                const menuPropsBound = Object.assign({}, menuProps, {
                    items: menuProps.items.map((entry) =>
                        Object.assign({}, entry, {
                            onClick: onContextMenu.bind(this, selection, item),
                        }),
                    ),
                });
                return <ContextualMenu {...menuPropsBound} />;
            }}
        />
    );
}

export function generateMoreColumn(
    menuProps: IContextualMenuProps,
    selection: Selection,
    onContextMenu: (selection: Selection, ev, action) => void,
) {
    return {
        key: "more",
        name: "",
        fieldName: null,
        minWidth: 15,
        maxWidth: 15,
        onRender: onRenderMore.bind(this, menuProps, selection, onContextMenu),
    };
}

export function roundRectPath(ctx: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, r: number) {
    r = Math.min(r, w / 2, h / 2);
    ctx.beginPath();
    ctx.moveTo(x + r, y);
    ctx.lineTo(x + w - r, y);
    ctx.quadraticCurveTo(x + w, y, x + w, y + r);
    ctx.lineTo(x + w, y + h - r);
    ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
    ctx.lineTo(x + r, y + h);
    ctx.quadraticCurveTo(x, y + h, x, y + h - r);
    ctx.lineTo(x, y + r);
    ctx.quadraticCurveTo(x, y, x + r, y);
    ctx.closePath();
}

export function NonProgressPopup(props: { startTime: number; label: string }) {
    const [timer, setTimer] = useState(props.startTime);
    useEffect(() => {
        if (props.startTime) {
            const timerId = setInterval(() => {
                setTimer(Date.now());
            }, 1000);
            return () => {
                clearInterval(timerId);
            };
        }
    }, [setTimer, props.startTime]);
    return (
        <Modal
            isBlocking={true}
            containerClassName={classNames.nonProgressPopup}
            isOpen={!!props.startTime}
            scrollableContentClassName={classNames.nonProgressPopupContent}
        >
            <NonProgressBar timer0={props.startTime} timer={timer} timerLabel={props.label} />
        </Modal>
    );
}

export const NonProgressBar = function (props: { timer0: number; timer: number; timerLabel?: string }) {
    const t_max = 5 * 60 * 1000;
    const t = Math.min(Math.max(0, props.timer - props.timer0), t_max);
    let variant = undefined;
    let p = 0;
    let text = undefined;
    if (t > 2 * 60 * 1000) {
        // more than 2 min
        variant = "red";
        p = Math.min(Math.max(Math.ceil((t / t_max) * 100), 0), 100);
        text = "This operation takes longer than usual...";
    } else if (t > 1 * 60 * 1000) {
        // more than 1 min
        variant = "yellow";
        p = Math.min(Math.max(Math.ceil((t / (2 * 60 * 1000)) * 100), 0), 100);
        text = "This operation takes longer than usual...";
    } else {
        p = Math.min(Math.max(Math.ceil((t / (1 * 60 * 1000)) * 100), 0), 100);
    }
    const m = Math.floor(t / (60 * 1000));
    const s = Math.ceil((t - m * (60 * 1000)) / 1000);
    const f = m + "m:" + s + "s" + (text ? " (" + text + ")" : "");
    return (
        <div className="mt-3 px-3">
            <div className="text-center">
                {m > 0 || s > 10
                    ? f
                    : props.timerLabel || (intl?.getInitOptions().currentLocale ? intl.get("fw.connecting") : "...")}
            </div>
            <Progress value={p} indicatorColor={variant} className="h-1" />
        </div>
    );
};
