import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "@/components/ui/data-table";
import { StabilityCriteriaDeleteDialog } from "./DeleteStabilityCriteriaDialog";
import { SettingsModal } from "./SettingsModal";
import { intl } from "@/legacy/GlobalHelperReact";
import { CoreExtensions } from "@/extensions/core.extensions";
import { useLCMD } from "@/app/LCMContext";
import { useT3RolesHook } from "../../hooks/useT3Roles.hook";
import { useCanCreateStabilityCriteria } from "../../hooks/useCanCreateStabilityCriteria.hook";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { SearchBar } from "@/components/common/SearchBar/SearchBar";
import { Button } from "@/components/ui/button";
import { ArrowUpDown, Plus, Pencil, Trash2 } from "lucide-react";
import { MoreButtonDropdown, MenuMoreProps } from "@/components/MoreButtonDropdown/MoreButtonDropdown";
import { copyAndSort } from "../../Utils";

type StabilityCriteriaItem = {
    key: string;
    name: string;
    leadTimeValue: number;
    trades: any[];
};

type StabilityCriteriaExtensionFields = {
    criteria: {
        label: string;
        dropdown: string[];
        "stability.stable": string[];
        "stability.lead": number;
        width: number;
        "ui.index": number;
        "stability.trades": string[];
    };
};

type DeleteLCMDExtensionCommand = Pick<LCMDExtension, "lcmdx" | "id">;

interface LCMDExtension<T = void> {
    lcmdx: number;
    id: string;
    patch: {
        prio?: number;
        process?: T;
    };
}

interface LCMDProcessExtension<T> {
    fields: T;
}

type ProcessExtensionFields = {
    label: string;
    dropdown: string[];
    "stability.stable": string[];
    "stability.lead": number;
    width: number;
    "ui.index": number;
    id: string;
    ext: string;
    prio: number;
    key: string;
};

type LCMDStabilityCriteriaExtension = LCMDExtension<LCMDProcessExtension<StabilityCriteriaExtensionFields>>;

function putExtensionHelper(LCMD, ext) {
    const criteria = ext.patch.process.fields.criteria;

    if (criteria["stability.trades"]) {
        (LCMD as any).getSafeIDs({ trids: criteria["stability.trades"] }, (error, safeIDs) => {
            if (error) {
                console.warn(error);
            } else {
                criteria["stability.trades"] = safeIDs.trids;
                LCMD.putExtension(ext);
            }
        });
    } else {
        delete criteria["stability.trades"];
        LCMD.putExtension(ext);
    }
}

let allItems: StabilityCriteriaItem[] = [];

export function SettingsList() {
    const LCMD = useLCMD();
    const [items, setItems] = useState<StabilityCriteriaItem[]>([]);
    const [sortAscending, setSortAscending] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showStabilityCriteriaDeleteDialog, setShowStabilityCriteriaDeleteDialog] = useState(false);
    const [stabilityCriteriaToChange, setStabilityCriteriaToChange] = useState<StabilityCriteriaItem>(null);
    const [allTrades, setAllTrades] = useState(new Map());
    const [filterState, setFilterState] = useState("");
    const canT3CreateOrEditStability = useT3RolesHook();
    const canCreateOrEditStability = useCanCreateStabilityCriteria();
    const isAllowed = canT3CreateOrEditStability && canCreateOrEditStability;

    const stabilityCriteriaExtensionId = CoreExtensions.STABILITY_CRITERIA_SETTINGS;

    const idRegExp = new RegExp(`^${stabilityCriteriaExtensionId}`);

    const generateExtensionId = () => {
        return `${stabilityCriteriaExtensionId}.${LCMD.generateId()}`;
    };

    const deleteStabilityCriteria = (extensionKey: string) => {
        const deleteStabilityCriteriaCommand: DeleteLCMDExtensionCommand = {
            lcmdx: 1.0,
            id: extensionKey,
        };
        LCMD.putExtension(deleteStabilityCriteriaCommand);
    };

    useEffect(() => {
        WebAppTelemetryFactory.trackEvent("settings-stability-criteria-opened");
    }, []);
    LCMD.useTradesEffect((error, data) => {
        const tradeCache = new Map();
        if (error) {
            console.error(error);
        }
        let t = [];
        Object.keys(data).forEach((projectId) => {
            t = t.concat(data[projectId]);
        });
        t.forEach((trade) => {
            tradeCache.set(trade.id, trade);
        });

        setAllTrades(tradeCache);
    }, []);

    async function getUnsafeIds(trades: string[]): Promise<string[]> {
        return new Promise((resolve, reject) => {
            (LCMD as any).getUnsafeIDs({ trids: trades }, (error, data) => {
                if (error) {
                    reject();
                    return;
                }
                resolve(data.trids);
            });
        });
    }

    function getAllItemsFromApi() {
        LCMD.getExtensions(async (error, extensions: { process: ProcessExtensionFields[] }) => {
            if (error) {
                console.error(error);
            } else {
                allItems = [];
                if (extensions?.process) {
                    for (const processExtension of extensions.process) {
                        if (idRegExp.test(processExtension.ext)) {
                            const stabilityCriteriaItem: StabilityCriteriaItem = {
                                key: processExtension.ext,
                                name: processExtension.label ? processExtension.label : "",
                                leadTimeValue: processExtension["stability.lead"],
                                trades:
                                    allTrades.size > 0
                                        ? ((await getUnsafeIds(processExtension["stability.trades"])) || []).map((t) =>
                                              allTrades.get(t),
                                          )
                                        : [],
                            };
                            const checkUndefined = stabilityCriteriaItem.trades.indexOf(undefined);
                            if (checkUndefined == -1) {
                                allItems.push(stabilityCriteriaItem);
                            } else {
                                stabilityCriteriaItem.trades.splice(checkUndefined, 1);
                                allItems.push(stabilityCriteriaItem);
                            }
                        }
                    }
                }
                setItems(allItems);
            }
        });
    }

    useEffect(() => {
        getAllItemsFromApi();
    }, [LCMD, allTrades]);

    useEffect(() => {
        if (filterState) {
            const res = filterState
                ? allItems.filter((i) => {
                      return stringToLowerCase(i.name).indexOf(stringToLowerCase(filterState)) > -1;
                  })
                : allItems;
            setItems(res);
        } else {
            setItems([...allItems]);
        }
    }, [allItems, filterState]);

    function stringToLowerCase(string: string) {
        return string ? string.toString().toLowerCase() : "";
    }

    function sortByColumn(columnKey: string) {
        setItems(copyAndSort([...items], columnKey, sortAscending));
        setSortAscending(!sortAscending);
    }

    function getDefaultColumns() {
        return [
            {
                accessorKey: "name",
                header: ({ column }) => (
                    <Button variant="ghost" className="p-0" onClick={() => sortByColumn(column.id)}>
                        {intl.get("StabilityCriteria.SettingsList.NameColumn")}
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                ),
                size: 200,
            },
            {
                id: "actions",
                meta: {
                    cellProps: {
                        style: {
                            display: "flex",
                            justifyContent: "flex-end",
                        },
                    },
                },
                cell: ({ row }) => {
                    const menuProps: MenuMoreProps[] = [
                        {
                            key: "edit",
                            text: intl.get("StabilityCriteria.SettingsList.Actions.Edit"),
                            icon: <Pencil size={16} color="#666666" />,
                            onClick: () => {
                                setStabilityCriteriaToChange(row.original);
                                setShowEditModal(true);
                            },
                        },
                        {
                            key: "delete",
                            text: intl.get("StabilityCriteria.SettingsList.Actions.Delete"),
                            icon: <Trash2 size={16} color="#666666" />,
                            onClick: () => {
                                setStabilityCriteriaToChange(row.original);
                                setShowStabilityCriteriaDeleteDialog(true);
                            },
                        },
                    ];
                    return <MoreButtonDropdown menuItems={menuProps} />;
                },
            },
            {
                accessorKey: "leadTimeValue",
                header: ({ column }) => (
                    <Button variant="ghost" className="p-0" onClick={() => sortByColumn(column.id)}>
                        {intl.get("StabilityCriteria.SettingsList.LeadTimeColumn")}
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                ),
                size: 200,
                cell: ({ row }) => (
                    <span>
                        {intl.get("StabilityCriteria.SettingsList.WorkingDays", { num: row.getValue("leadTimeValue") })}
                    </span>
                ),
            },
            {
                accessorKey: "trades",
                header: intl.get("StabilityCriteria.SettingsList.InvokesToColumn"),
                size: 200,
                cell: ({ row }) => (
                    <span>
                        {intl.get("StabilityCriteria.SettingsList.Trades", { num: row.getValue("trades").length })}
                    </span>
                ),
            },
        ];
    }

    const editModal = useMemo(() => {
        const onClose = (editedStabilityCriteria) => {
            if (editedStabilityCriteria.key) {
                const originalStabilityCriteria = allItems.find((item) => {
                    return item.key === editedStabilityCriteria.key;
                });
                if (originalStabilityCriteria) {
                    originalStabilityCriteria.name = editedStabilityCriteria.name;
                    originalStabilityCriteria.trades = editedStabilityCriteria.trades;
                    originalStabilityCriteria.leadTimeValue = editedStabilityCriteria.leadTimeValue;

                    const patchedExtension: LCMDStabilityCriteriaExtension = {
                        lcmdx: 1.0,
                        id: originalStabilityCriteria.key,
                        patch: {
                            prio: 0,
                            process: {
                                fields: {
                                    criteria: {
                                        label: originalStabilityCriteria.name,
                                        "stability.lead": originalStabilityCriteria.leadTimeValue,
                                        "stability.stable": ["done"],
                                        "stability.trades": (originalStabilityCriteria.trades || []).map(
                                            (trade) => trade.id,
                                        ),
                                        width: 250,
                                        "ui.index": 0,
                                        dropdown: ["open", "done"],
                                    },
                                },
                            },
                        },
                    };
                    putExtensionHelper(LCMD, patchedExtension);
                }
                setItems([...allItems]);
                setStabilityCriteriaToChange(null);
            }
            hideModal();
        };

        const hideModal = () => {
            setShowEditModal(false);
        };

        return showEditModal ? (
            <SettingsModal
                allTrades={[...allTrades.values()]}
                onCancel={hideModal}
                showDialog={showEditModal}
                onAccept={onClose}
                stabilityCriteriaToEdit={stabilityCriteriaToChange}
            />
        ) : undefined;
    }, [showEditModal, stabilityCriteriaToChange]);

    const createModal = useMemo(() => {
        return showCreateModal ? (
            <SettingsModal
                allTrades={[...allTrades.values()]}
                showDialog={showCreateModal}
                onCancel={() => {
                    setShowCreateModal(false);
                }}
                onAccept={(newStabilityCriteria: StabilityCriteriaItem) => {
                    const extensionId = generateExtensionId();
                    const newExtension: LCMDStabilityCriteriaExtension = {
                        lcmdx: 1.0,
                        id: extensionId,
                        patch: {
                            prio: 0,
                            process: {
                                fields: {
                                    criteria: {
                                        label: newStabilityCriteria.name,
                                        "stability.lead": newStabilityCriteria.leadTimeValue,
                                        "stability.stable": ["done"],
                                        "stability.trades": (newStabilityCriteria.trades || []).map(
                                            (trade) => trade.id,
                                        ),
                                        width: 250,
                                        "ui.index": 0,
                                        dropdown: ["open", "done"],
                                    },
                                },
                            },
                        },
                    };
                    putExtensionHelper(LCMD, newExtension);
                    allItems.push(newStabilityCriteria);

                    if (!newStabilityCriteria.key) {
                        newStabilityCriteria.key = extensionId;
                        setItems([...allItems]);
                        setShowCreateModal(false);
                    }
                }}
            />
        ) : undefined;
    }, [showCreateModal]);

    const _onFilter = (text: string): void => {
        setFilterState(text);
    };

    return (
        <div className="w-[calc(100%-50px)] px-5 py-0">
            <div className="py-2.5 pl-0 pr-6">
                <div className="mb-1 flex justify-between">
                    <SearchBar
                        className="w-100 relative"
                        value={filterState}
                        onSearch={(value) => _onFilter(value || "")}
                        onChange={(event, newValue) => _onFilter(newValue)}
                        placeholder={intl.get("StabilityCriteria.SettingsList.SearchFieldText")}
                    />
                    <Button
                        disabled={!isAllowed}
                        onClick={() => {
                            setShowCreateModal(true);
                            WebAppTelemetryFactory.trackEvent("settings-new-stability-criteria-clicked");
                        }}
                    >
                        <Plus size={16} className="mr-2" />
                        {intl.get("StabilityCriteria.SettingsList.AddStabilityCriteriaButtonText")}
                    </Button>
                    {createModal}
                </div>
                <DataTable columns={getDefaultColumns()} data={items} />
                {editModal}
            </div>
            {showStabilityCriteriaDeleteDialog && stabilityCriteriaToChange && (
                <StabilityCriteriaDeleteDialog
                    onCancel={() => {
                        setShowStabilityCriteriaDeleteDialog(false);
                    }}
                    onDelete={() => {
                        deleteStabilityCriteria(stabilityCriteriaToChange.key);
                        allItems = allItems.filter((currentElement) => {
                            return stabilityCriteriaToChange.key != currentElement.key;
                        });
                        setItems([...allItems]);
                        getAllItemsFromApi();
                        setShowStabilityCriteriaDeleteDialog(false);
                        setStabilityCriteriaToChange(null);
                    }}
                    stabilityTitle={stabilityCriteriaToChange?.name}
                />
            )}
        </div>
    );
}
