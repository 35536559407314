import { Label } from "@/components/ui/label";
import { LegacyStartEndDateControl } from "@/legacy/GlobalHelperFluentUI";
import { intl } from "@/legacy/GlobalHelperReact";
import { defaultProjectPeriod, useProjectPeriodStore } from "../../store/projectPeriodStore";

type Details2ViewProps = {
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
};

export const Details2View = ({ onSubmit }: Details2ViewProps) => {
    const { startDate, endDate, setProjectPeriod } = useProjectPeriodStore((state) => state);

    return (
        <div className="w-full">
            <form onSubmit={onSubmit}>
                <input name="startDate" type="hidden" value={startDate.toISOString()} />
                <input name="endDate" type="hidden" value={endDate.toISOString()} />
                <div>
                    <Label htmlFor="formStartEnd">{intl.get("legacyProjectDialog.details2.label")}</Label>
                    <LegacyStartEndDateControl
                        utc={false}
                        defaultStart={defaultProjectPeriod.startDate}
                        defaultEnd={defaultProjectPeriod.endDate}
                        data-userpilot-id="fileUploadPopup-projectStartDate"
                        onChange={(start: Date, end: Date) => setProjectPeriod(new Date(start), new Date(end))}
                    />
                </div>
            </form>
        </div>
    );
};

Details2View.displayName = "Details2View";
