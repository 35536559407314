import * as React from "react";
import { useEffect, useState } from "react";
import { Calendar, CheckCircle, ChevronDown, ChevronRight, Globe, Pencil, Save, Search, Trash, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { LcmdModal2, ModalSizeTypes } from "@/components/common/LcmModal2";
import { assert, darkenColor, needsWhiteColor, ProcessStatuses } from "@/model/GlobalHelper";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import {
    DataModelFilter,
    DataModelFilterSavedAs,
    DigitalPlanningBoardTradeDetails,
    intl,
    VCONST0,
} from "lcmd2framework";
import { copyAndSort } from "@/components/Utils";
import { useLCMD } from "@/app/LCMContext";
import { CheckedState } from "@radix-ui/react-checkbox";
import { tradeImagesMap } from "@/components/Sidebar/TradeImages";
import { decimalToHex } from "../../utils/color/decimal-to-hex";
import { IconButton } from "../../common/IconButton/IconButton";
import { DialogTitle } from "@/components/ui/dialog";
import { SaveFilter } from "@/components/Filter/FilterV2/SaveFilter";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useProcessPlanTimestamp } from "@/app/store/canvasStore";
import { Card } from "@/components/ui/card";
import { useFilterCollection } from "@/components/Filter/data-access-hooks/use-filter-collection";
import { FilterItem } from "@/components/Filter/data-access-hooks/use-filter-data";
import { Skeleton } from "@/components/ui/skeleton";
import { _onFormatDate } from "@/legacy/GlobalHelperFluentUI";
import { TimeFrameSelectionModal } from "@/components/Filter/FilterV2/modals/TimeFrameSelectionModal";
import { Badge } from "@/components/ui/badge";
import { EGlobeState } from "@/components/common/globeField";
import { DeleteConfirmModal } from "@/components/Filter/FilterV2/modals/DeleteConfirmModal";
import { InputWithGlobeField } from "@/components/Filter/FilterV2/InputWithGlobeField";
import { getDateAndTime } from "@/components/utils/date/dateAndTime";
import { LCMDTooltip } from "@/components/common/Tooltip/LCMDTooltip";
import { Spinner } from "@fluentui/react";

function FilterHeadline({ children }: { children: string }) {
    return <div className="mb-4 text-base font-semibold">{children}</div>;
}

const ProcessStatusesEnumValues: any = Object.values(ProcessStatuses).filter((value) => typeof value === "number");

const timeframes = ["every", "4weeks", "6weeks", "4months", "custom"] as const;
const today = getDateAndTime(new Date().getTime())?.toString() || "";

function getAllChildren(parent, allItems) {
    const ret = [];
    const parentIndex = allItems.findIndex((element) => element.tid === parent.tid);
    for (let i = parentIndex + 1; i < allItems.length; i++) {
        if (parent.l === allItems[i].l) {
            break;
        }
        ret.push(allItems[i]);
    }

    return ret;
}

function StatusCard({
    status,
    onClick,
    isSelected,
}: {
    status: ProcessStatuses;
    onClick: (status: ProcessStatuses) => void;
    isSelected: boolean;
}) {
    const statusColorProperties = {
        [ProcessStatuses.open]: {
            backgroundColor: "bg-slate-400",
            text: intl.get("charts.statusTracking.labels.open"),
        },
        [ProcessStatuses.inProgress]: {
            backgroundColor: "bg-blue-500",
            text: intl.get("charts.statusTracking.labels.inProgress"),
        },
        [ProcessStatuses.done]: {
            backgroundColor: "bg-green-600",
            text: intl.get("charts.statusTracking.labels.done"),
        },
        [ProcessStatuses.late]: {
            backgroundColor: "bg-orange-500",
            text: intl.get("charts.statusTracking.labels.late"),
        },
        [ProcessStatuses.overdue]: {
            backgroundColor: "bg-red-600",
            text: intl.get("charts.statusTracking.labels.overdue"),
        },
    };

    const { backgroundColor, text } = statusColorProperties[status] || {};
    return (
        <Button
            key={"status-" + status}
            variant={"outline"}
            onClick={() => {
                onClick(status);
            }}
            className={cn(isSelected && "bg-slate-100")}
        >
            <div className={cn(backgroundColor, "mr-3 h-2 w-2 rounded-full")}></div>
            {text}
        </Button>
    );
}

export type Duration = {
    start?: Date;
    end?: Date;
};

// todo: mit willi reden ob wir types in ne extra datei packen
export type FilterDuration = {
    start?: number;
    end?: number;
    formattedStart: Date;
    formattedEnd: Date;
    selection: (typeof timeframes)[number];
};

type Filter = {
    localAreas: any[];
    localTrades: any[];
    filterDuration: FilterDuration;
    status: ProcessStatuses[];
};

const emptyFilter: Filter = {
    localAreas: [],
    localTrades: [],
    filterDuration: {
        start: undefined,
        end: undefined,
        formattedStart: undefined,
        formattedEnd: undefined,
        selection: "every",
    },
    status: [],
};

export function FilterModal({
    isOpen,
    onDismiss,
    tab,
}: {
    isOpen: boolean;
    onDismiss: () => void;
    tab?: "filter" | "myFilters";
}) {
    const LCMD = useLCMD();
    const processPlanTimestamp = useProcessPlanTimestamp();

    const [isSaveFilterOpen, setIsSaveFilterOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState<"filter" | "myFilters">(tab || "filter");
    const [currentlySelectedFilters, setCurrentlySelectedFilters] = useState<Filter>(emptyFilter);
    const [isAnySubModalOpen, setIsAnySubModalOpen] = useState(false);
    const [currentEditFilter, setCurrentEditFilter] = useState(null);
    const [allAreas, setAllAreas] = useState([]);
    const [allTrades, setAllTrades] = useState([]);
    const [editName, setEditName] = useState("");
    const [editGlobal, setEditGlobal] = useState<EGlobeState>(EGlobeState.Private);
    const [deleteFilter, setDeleteFilter] = useState<DataModelFilterSavedAs>(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [projectDuration, setProjectDuration] = useState<Duration>({
        start: undefined,
        end: undefined,
    });

    useEffect(() => {
        setCurrentTab(tab);
    }, [tab]);

    useEffect(() => {
        const openDeleteDialog = deleteFilter !== null;
        setShowDeleteDialog(openDeleteDialog);
        setTimeout(() => {
            handleOpenSubModalChanged(openDeleteDialog);
        });
    }, [deleteFilter]);

    useEffect(() => {
        return LCMD.loadFilterData((data) => {
            const wbs: any[] = data?.wbs || [];

            const filterEntity = data?.filterCollection.find(
                (filter) =>
                    currentEditFilter &&
                    filter._hid === currentEditFilter?._hid &&
                    filter.global === currentEditFilter?.global,
            );

            const filter: DataModelFilterSavedAs = filterEntity ? filterEntity._filter : data?.filter;

            const filteredAreas = filter?.tz || [];
            const selectedAreas = filteredAreas.map((selected) => wbs.find((area) => area.tid == selected)) || [];
            setAllAreas(wbs);
            const trades: DigitalPlanningBoardTradeDetails[] = data?.trades || [];
            const sortedTrades = copyAndSort(trades, "label");
            const filteredTrades = filter?.trade || [];
            const selectedTrades = filteredTrades.map((selected) => sortedTrades.find((trade) => trade.id == selected));
            const projectStartDate = new Date(data.startDate);
            const projectEndDate = new Date(data.endDate);

            setProjectDuration({
                start: projectStartDate,
                end: projectEndDate,
            });

            const startDate = filter?.d?.at(0) !== undefined ? filter?.d[0] : undefined;

            let endDate: number = undefined;
            let selection: FilterDuration["selection"] = "every";
            let formattedEndTimestamp: number = projectEndDate.getTime();
            if (filter?.d?.at(1)) {
                endDate = filter?.d?.at(1);
                const now = new Date();
                if (filter.d[1] == 4) {
                    formattedEndTimestamp = new Date().setDate(now.getDate() + 28);
                    selection = "4weeks";
                } else if (filter.d[1] == 6) {
                    formattedEndTimestamp = new Date().setDate(now.getDate() + 42);
                    selection = "6weeks";
                } else if (filter.d[1] == 260) {
                    formattedEndTimestamp = new Date().setMonth(now.getMonth() + 4);
                    selection = "4months";
                } else {
                    formattedEndTimestamp = filter.d[1];
                    selection = "custom";
                }
            }

            let formattedStart: Date;
            if (startDate === undefined) {
                formattedStart = projectStartDate;
            } else if (startDate === 0) {
                formattedStart = new Date();
            } else {
                formattedStart = new Date(startDate);
            }

            const formattedEnd = new Date(formattedEndTimestamp);

            const filterDuration = {
                start: startDate,
                end: endDate,
                selection,
                formattedStart,
                formattedEnd,
            };

            setAllTrades(sortedTrades);
            setCurrentlySelectedFilters({
                localAreas: selectedAreas,
                localTrades: selectedTrades,
                status: filter?.status ? [...filter.status.values()] : [],
                filterDuration: filterDuration,
            });
        });
    }, [LCMD, setCurrentlySelectedFilters, setAllAreas, setAllTrades, processPlanTimestamp, currentEditFilter]);

    function toLCMDFilter(tzFilter, tradesFilter, dateFilter, statusFilter): DataModelFilter {
        return {
            tz: tzFilter.length > 0 ? tzFilter.map((tz) => Number.parseInt(tz.tid)) : undefined,
            trade: tradesFilter.length > 0 ? tradesFilter.map((trade) => Number.parseInt(trade.id)) : undefined,
            d: dateFilter,
            status: new Set<ProcessStatuses>(statusFilter),
        };
    }

    function onFilterChanged(newFilter) {
        setCurrentlySelectedFilters(newFilter);
    }

    const handleSetFilter = (saveFilterData: { name?: string; global?: boolean; pos?: number; hid?: number }) => {
        const { localAreas, localTrades, filterDuration, status } = currentlySelectedFilters;
        const dateFilter =
            (filterDuration?.start === 0 || filterDuration?.start) && filterDuration?.end
                ? [filterDuration.start, filterDuration.end]
                : null;
        const saveAs = saveFilterData && {
            name: saveFilterData.name,
            global: saveFilterData.global,
            pos: saveFilterData.pos,
            hid: saveFilterData.hid,
        };
        let filter: DataModelFilterSavedAs = {
            ...toLCMDFilter(localAreas, localTrades, dateFilter, status),
        };
        if (saveAs) {
            filter = { ...filter, saveAs: saveAs };
        }

        LCMD.setFilter(filter);
    };

    function handleFilterEdit(filterItem) {
        setCurrentTab("filter");
        setEditName(filterItem?.name !== undefined && filterItem.name !== "" ? filterItem.name : today);
        setEditGlobal(Number(filterItem.global));
        setCurrentEditFilter(filterItem);
    }

    function handleFilterDelete(filterItem: DataModelFilterSavedAs) {
        setCurrentTab("myFilters");
        LCMD.setFilter(filterItem);
        setDeleteFilter(null);
    }

    function isFilterSavable() {
        if (currentEditFilter !== null) {
            return false;
        }
        const { localAreas, localTrades, filterDuration, status } = currentlySelectedFilters;
        const isStatusInitial = status.length == 0;
        const isTradeInitial = localTrades.length == 0;
        const isAreasInitial = localAreas.length == 0;
        const isTimeframeInitial =
            filterDuration?.formattedEnd?.getTime() === projectDuration?.end?.getTime() &&
            filterDuration?.formattedStart?.getTime() === projectDuration?.start?.getTime();
        return !(isStatusInitial && isAreasInitial && isTradeInitial && isTimeframeInitial);
    }

    const modalHeader = (
        <div className={"flex h-full justify-between pt-1.5 align-middle"}>
            <DialogTitle className="overflow-hidden overflow-ellipsis whitespace-nowrap pb-2">
                <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="filter">{intl.get("canvas.cmd.filter.text")}</TabsTrigger>
                    <TabsTrigger value="myFilters">{intl.get("filter.myFilters")}</TabsTrigger>
                </TabsList>
            </DialogTitle>

            {currentTab === "filter" && (
                <div className={"mr-8 border-r-2 border-slate-200 p-2 pr-4 pt-1"}>
                    {currentEditFilter === null && (
                        <LCMDTooltip text={intl.get("filter.main.save.title")}>
                            <Save
                                size={16}
                                className={isFilterSavable() ? "font-black" : "text-slate-200"}
                                onClick={() => {
                                    if (!isFilterSavable()) {
                                        return;
                                    }
                                    saveFilter();
                                }}
                            ></Save>
                        </LCMDTooltip>
                    )}
                    {currentEditFilter !== null && (
                        <LCMDTooltip text={intl.get("filter.manage.cmd.delete.text")}>
                            <Trash
                                size={16}
                                onClick={() => {
                                    setDeleteFilter({
                                        saveAs: {
                                            hid: currentEditFilter._hid,
                                            name: currentEditFilter.name,
                                            global: currentEditFilter.global,
                                            pos: currentEditFilter.pos,
                                        },
                                    });
                                    setCurrentEditFilter(null);
                                }}
                            ></Trash>
                        </LCMDTooltip>
                    )}
                </div>
            )}
        </div>
    );

    function handleOpenSubModalChanged(isAnySubModalOpen: boolean) {
        setIsAnySubModalOpen(isAnySubModalOpen);
    }

    function saveFilter() {
        handleOpenSubModalChanged(true);
        setIsSaveFilterOpen(true);
    }

    const modalButtons =
        currentTab === "filter"
            ? [
                  currentEditFilter === null && (
                      <Button
                          key={"resetFilter"}
                          variant="outline"
                          onClick={() => {
                              LCMD.setFilter(null);
                          }}
                      >
                          {intl.get("filter.main.clear.text")}
                      </Button>
                  ),
                  <Button
                      key={"setFilter"}
                      onClick={() => {
                          if (currentEditFilter === null) {
                              handleSetFilter({});
                              onDismiss();
                          } else {
                              handleSetFilter({
                                  name: editName !== "" ? editName : today,
                                  global: Boolean(editGlobal),
                                  pos: currentEditFilter._pos,
                                  hid: currentEditFilter._hid,
                              });
                              setCurrentEditFilter(null);
                              setCurrentTab("myFilters");
                          }
                      }}
                  >
                      {intl.get("filter.manage.cmd.apply.text")}
                  </Button>,
              ]
            : [];

    return (
        <>
            {showDeleteDialog && (
                <DeleteConfirmModal
                    onOk={() => handleFilterDelete({ saveAs: { ...deleteFilter.saveAs, name: null } })}
                    onCancel={() => setDeleteFilter(null)}
                    filterName={deleteFilter?.saveAs?.name}
                />
            )}
            {isSaveFilterOpen && (
                <SaveFilter
                    isOpen={isSaveFilterOpen}
                    onClose={() => {
                        setIsSaveFilterOpen(false);
                        handleOpenSubModalChanged(false);
                    }}
                    onSave={(saveData) => {
                        handleSetFilter(saveData);
                        handleOpenSubModalChanged(false);
                        setIsSaveFilterOpen(false);
                    }}
                ></SaveFilter>
            )}
            <Tabs
                className={cn(isAnySubModalOpen && "cursor-default")}
                value={currentTab}
                onValueChange={(value: "filter" | "myFilters") => {
                    if (isAnySubModalOpen) {
                        return;
                    }
                    setCurrentTab(value);
                }}
            >
                <LcmdModal2
                    open={isOpen}
                    size={ModalSizeTypes.l}
                    header={modalHeader}
                    onOpenChange={() => {
                        if (isAnySubModalOpen) {
                            return;
                        }
                        setCurrentEditFilter(null);
                        onDismiss();
                    }}
                    buttons={modalButtons}
                >
                    <div
                        className={cn("overflow-y-auto", isAnySubModalOpen && "pointer-events-none")}
                        onClick={(e) => {
                            if (isAnySubModalOpen) {
                                e.preventDefault();
                            }
                        }}
                    >
                        <TabsContent value="filter">
                            {currentEditFilter !== null && (
                                <div className={"p-2"}>
                                    <InputWithGlobeField
                                        placeholder={today}
                                        initialGlobeState={editGlobal}
                                        onGlobalStateChange={(globalState) => {
                                            setEditGlobal(globalState);
                                        }}
                                        value={editName}
                                        onInputValueChange={(value) => setEditName(value)}
                                    ></InputWithGlobeField>
                                </div>
                            )}
                            <FilterTabView
                                onFilterChanged={onFilterChanged}
                                currentFilter={currentlySelectedFilters}
                                onOpenSubModalChanged={handleOpenSubModalChanged}
                                allAreas={allAreas}
                                allTrades={allTrades}
                                projectDuration={projectDuration}
                            />
                        </TabsContent>
                        <TabsContent value={"myFilters"}>
                            <MyFilterTabView
                                onFilterSelect={onDismiss}
                                onFilterEdit={handleFilterEdit}
                                onFilterDelete={setDeleteFilter}
                            />
                        </TabsContent>
                    </div>
                </LcmdModal2>
            </Tabs>
        </>
    );
}

function FilterTabView({
    onFilterChanged,
    onOpenSubModalChanged,
    currentFilter: defaultFilter,
    allTrades,
    allAreas,
    projectDuration,
}: {
    onFilterChanged: (filter) => void;
    onOpenSubModalChanged: (isAnySubModalOpen: boolean) => void;
    currentFilter: any;
    allTrades: any[];
    allAreas: any[];
    projectDuration: Duration;
}) {
    const LCMD = useLCMD();
    const [isAreasExpanded, setIsAreasExpanded] = useState(false);
    const [isTradesExpanded, setIsTradesExpanded] = useState(false);
    const [isAreasDialogOpen, setIsAreasDialogOpen] = useState(false);
    const [isTradesDialogOpen, setIsTradesDialogOpen] = useState(false);
    const [isTimeframeDialogOpen, setIsTimeframeDialogOpen] = useState(false);
    const [currentFilter, setCurrentFilter] = useState<Filter>(defaultFilter ?? emptyFilter);

    const [filterDateString, setFilterDateString] = useState(intl.get("filter.main.date.add.text"));

    useEffect(() => {
        if (!defaultFilter) {
            return;
        }
        setCurrentFilter(defaultFilter);
    }, [defaultFilter]);

    useEffect(() => {
        onOpenSubModalChanged(isTimeframeDialogOpen);
    }, [isTimeframeDialogOpen]);

    useEffect(() => {
        if (!currentFilter?.filterDuration?.formattedStart || !currentFilter?.filterDuration?.formattedEnd) {
            setFilterDateString(intl.get("filter.main.date.add.text"));
            return;
        }

        setFilterDateString(
            currentFilter?.filterDuration?.formattedEnd?.getTime() !== projectDuration?.end?.getTime() ||
                currentFilter?.filterDuration?.formattedStart?.getTime() !== projectDuration?.start?.getTime()
                ? `${_onFormatDate(currentFilter.filterDuration?.formattedStart)} - ${_onFormatDate(currentFilter.filterDuration.formattedEnd)}`
                : intl.get("filter.main.date.add.text"),
        );
    }, [currentFilter]);

    useEffect(() => {
        onFilterChanged(currentFilter);
    }, [currentFilter]);

    const handleStatusToggle = (statusItem: ProcessStatuses) => {
        let newStatus;
        if (currentFilter.status.includes(statusItem)) {
            currentFilter.status.splice(currentFilter.status.indexOf(statusItem), 1);
            newStatus = [...currentFilter.status];
        } else {
            newStatus = [...currentFilter.status, statusItem];
        }
        setCurrentFilter({ ...currentFilter, status: newStatus });
    };

    return (
        <div className={"relative flex max-h-96 w-full flex-col gap-8"}>
            <div>
                <FilterHeadline>{intl.get("filter.main.date.label")}</FilterHeadline>
                <Button
                    variant="outline"
                    className="w-full justify-start"
                    onClick={() => {
                        setIsTimeframeDialogOpen(true);
                    }}
                >
                    <Calendar className="mr-2 h-4 w-4" />
                    {filterDateString}
                </Button>
            </div>
            <TimeFrameSelectionModal
                isOpen={isTimeframeDialogOpen}
                filterDuration={currentFilter.filterDuration}
                projectDuration={projectDuration}
                onClose={() => setIsTimeframeDialogOpen(false)}
                onTimeFrameSave={(filterDuration) => {
                    setCurrentFilter({ ...currentFilter, filterDuration });
                    setIsTimeframeDialogOpen(false);
                }}
            />
            <div>
                <FilterHeadline>{intl.get("filter.main.status.label")}</FilterHeadline>
                <div className="flex flex-wrap gap-2">
                    {ProcessStatusesEnumValues.map((statusItem) => (
                        <StatusCard
                            key={"statuscard-" + statusItem}
                            onClick={(selectStatus) => handleStatusToggle(selectStatus)}
                            status={statusItem}
                            isSelected={currentFilter.status.includes(statusItem)}
                        ></StatusCard>
                    ))}
                </div>
            </div>
            <FilterSection
                title={intl.get("filter.main.tz.label")}
                selectedItems={currentFilter.localAreas}
                onSelectedItemsChanged={(localAreas) => setCurrentFilter({ ...currentFilter, localAreas })}
                isExpanded={isAreasExpanded}
                onShowList={() => {
                    setIsAreasDialogOpen(true);
                }}
                setIsExpanded={setIsAreasExpanded}
            />
            <FilterSection
                title={intl.get("filter.main.trades.label")}
                selectedItems={currentFilter.localTrades}
                onSelectedItemsChanged={(localTrades) => {
                    setCurrentFilter({ ...currentFilter, localTrades });
                }}
                isExpanded={isTradesExpanded}
                setIsExpanded={setIsTradesExpanded}
                onShowList={() => {
                    setIsTradesDialogOpen(true);
                }}
                isTrade
            />
            <TradeModal
                isDialogOpen={isTradesDialogOpen}
                onOpenChange={() => {
                    setIsTradesDialogOpen(false);
                }}
                title={intl.get("filter.main.trades.label")}
                selectedItems={currentFilter.localTrades}
                allItems={allTrades}
                onItemSelectionChanged={(localTrades) => {
                    setCurrentFilter({ ...currentFilter, localTrades });
                }}
            ></TradeModal>
            <AreaModal
                isDialogOpen={isAreasDialogOpen}
                onOpenChange={() => {
                    setIsAreasDialogOpen(false);
                }}
                title={intl.get("filter.main.tz.label")}
                selectedItems={currentFilter.localAreas}
                allItems={allAreas}
                onItemSelectionChanged={(localAreas) => {
                    setCurrentFilter({ ...currentFilter, localAreas });
                }}
            ></AreaModal>
        </div>
    );
}

function FilterSection({
    title,
    selectedItems,
    onSelectedItemsChanged,
    isExpanded,
    setIsExpanded,
    isTrade = false,
    onShowList,
}: {
    title: string;
    selectedItems: any[];
    onSelectedItemsChanged: (items: any[]) => void;
    isExpanded: boolean;
    setIsExpanded: (isExpanded: boolean) => void;
    onShowList: () => void;
    isTrade?: boolean;
}) {
    const visibleItems = isExpanded ? selectedItems : selectedItems.slice(0, 4);
    const searchVocabulary = isTrade ? "filter.main.trades.add.search" : "filter.main.tz.add.search";

    return (
        <div
            className={`space-y-2 rounded-2xl p-4 ${!isTrade && "bg-slate-100"} ${isTrade && "border border-slate-100"}`}
        >
            <div className="mb-4 flex items-center justify-between">
                <div>
                    <div className={"text-2xl font-semibold"}>{title}</div>
                    <div>
                        <span className="text-sm text-gray-500">
                            {selectedItems.length} {intl.get("filter.filterSection.selected")}
                        </span>
                    </div>
                </div>
                <div>
                    <Button variant="link" className={"flex items-center"} size="sm" onClick={() => onShowList()}>
                        {intl.get(searchVocabulary)}
                        <Search className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            </div>
            <div className="flex flex-wrap gap-2">
                {visibleItems.map((item, index) => {
                    return (
                        <div key={index} className="flex h-[30px] items-center gap-1 space-x-1 rounded-full p-1">
                            {!isTrade && item && (
                                <AreaCard
                                    name={item?.n || ""}
                                    index={index}
                                    onDelete={(name) => {
                                        const newItems = selectedItems.filter((i) => i.n !== name);
                                        onSelectedItemsChanged(newItems);
                                    }}
                                />
                            )}

                            {isTrade && item && (
                                <TradeCard
                                    id={item.id}
                                    name={item.name}
                                    color={item.color}
                                    index={index}
                                    icon={item.icon}
                                    onClick={(selectedItem) => {
                                        const newItems = selectedItems.filter((item) => item.id !== selectedItem);
                                        onSelectedItemsChanged(newItems);
                                    }}
                                ></TradeCard>
                            )}
                        </div>
                    );
                })}
            </div>
            {selectedItems.length > 4 && (
                <div className={"flex justify-center"}>
                    <Button variant="ghost" size="sm" onClick={() => setIsExpanded(!isExpanded)}>
                        <ChevronDown className={cn("h-4 w-4 transition-transform", isExpanded && "rotate-180")} />
                    </Button>
                </div>
            )}
        </div>
    );
}

function TradeModal({
    isDialogOpen,
    onOpenChange,
    title,
    allItems = [],
    onItemSelectionChanged,
    selectedItems = [],
}: any) {
    const [searchTerm, setSearchTerm] = useState("");
    const [selected, setSelected] = useState(selectedItems);

    const filteredItems = allItems.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

    const isAllSelected: CheckedState =
        selected.length == filteredItems.length ? true : selected.length >= 1 ? "indeterminate" : false;

    useEffect(() => {
        if (!selectedItems) {
            return;
        }
        setSelected(selectedItems);
    }, [selectedItems]);

    function handleOkClick() {
        onItemSelectionChanged(selected);
        onOpenChange();
    }

    function handleCancelClick() {
        setSelected([...selectedItems]);
        onOpenChange();
    }

    return (
        <LcmdModal2
            open={isDialogOpen}
            onOpenChange={() => {
                onOpenChange();
            }}
            header={{ title: title }}
            size={ModalSizeTypes.m}
            buttons={[
                <Button key="cancel" variant="outline" onClick={handleCancelClick}>
                    {intl.get("fw.cancel")}
                </Button>,
                <Button key="ok" onClick={handleOkClick}>
                    {intl.get("fw.ok")}
                </Button>,
            ]}
        >
            <div className="space-y-4">
                <Input
                    placeholder={intl.get("filter.main.trades.add.search")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="h-[300px] space-y-2 overflow-y-auto">
                    <Checkbox
                        checked={isAllSelected}
                        onClick={() => {
                            const newCheckedState: CheckedState = isAllSelected == "indeterminate" || !isAllSelected;
                            setSelected(newCheckedState ? filteredItems : []);
                        }}
                    />
                    {filteredItems.map((item, index) => (
                        <div key={index} className="flex items-center space-x-2">
                            <Checkbox
                                id={`${title}-${index}`}
                                key={`${title}-${index}`}
                                checked={!!selected.find((select) => select.id === item.id)}
                                onCheckedChange={() => {
                                    const newItems = selected.includes(item)
                                        ? selected.filter((i) => i !== item)
                                        : [...selected, item];
                                    setSelected(newItems);
                                }}
                            />
                            <Label htmlFor={`${title}-${index}`}>{item.name}</Label>
                        </div>
                    ))}
                </div>
            </div>
        </LcmdModal2>
    );
}

function TradeCard({
    id,
    color,
    icon,
    name,
    index = 0,
    onClick,
}: {
    id: number;
    color: number;
    icon: string;
    name: string;
    index: number;
    onClick: (element) => void;
}) {
    const bGColorHex = color !== undefined ? decimalToHex(color) : "white";
    const textColor = color !== undefined && needsWhiteColor(color) ? "text-white" : "text-black";
    const darkerBgColorHex = color !== undefined ? darkenColor(bGColorHex, 0.2) : "white";
    const svgIcon = tradeImagesMap.get(icon || VCONST0.tradeDefaultIcon);

    return (
        <div
            key={index}
            className="flex h-[30px] items-center gap-1 space-x-1 rounded-full p-1"
            style={{ backgroundColor: bGColorHex }}
        >
            <div
                className="flex items-center justify-center rounded-full"
                style={{
                    width: "22px",
                    height: "22px",
                    backgroundColor: darkerBgColorHex,
                }}
            >
                <img
                    src={svgIcon.img}
                    title={svgIcon.title}
                    className="pointer-events-none h-[14px] w-[14px] contrast-125"
                />
            </div>
            <span className={cn("text-xs font-medium leading-5", textColor)}>{name}</span>
            <IconButton
                key={"tradeBtn-" + index}
                icon={X}
                className="h-5 w-5 text-white contrast-125 hover:text-white"
                style={{ backgroundColor: darkerBgColorHex }}
                iconProps={{ size: 14 }}
                onClick={() => {
                    onClick(id);
                }}
            />
        </div>
    );
}

function searchWBS(wbs: any[], search: string) {
    const terms = [search.trim().toLocaleLowerCase()];
    const n_terms = terms.length;
    const path = [];
    const n = wbs.length;
    for (let i = 0; i < n; i++) {
        const item = wbs[i];
        while (item.l < path.length) {
            path.pop();
        }
        assert(item.l === path.length);
        const name: string = (item.n || "").trim().toLocaleLowerCase();
        let hit = false;
        for (let i_term = 0; i_term < n_terms; i_term++) {
            hit = hit || name.indexOf(terms[i_term]) >= 0;
        }
        if (hit) {
            // mark path
            const n_path = path.length;
            for (let i_path = 0; i_path < n_path; i_path++) {
                path[i_path]._hit |= 0x1;
            }
            item._hit = 0x3;
        } else {
            item._hit = 0; // clear flag...
        }
        path.push(item);
    }
}

function filterWBS(allItems, collapsed: any, selected: any, search: string) {
    const ret = [];

    if (search) {
        searchWBS(allItems, search);
    }

    const n = allItems.length;
    for (let i = 0; i < n; ) {
        const sel = allItems[i].tid in selected;
        if (allItems[i].tid in collapsed) {
            ret.push({ ...allItems[i], s: 2, sel: sel });
            let j = i + 1;
            while (j < n && allItems[j].l > allItems[i].l) j++;
            i = j;
        } else if (!search || allItems[i]._hit & 0x1) {
            const _hit = search ? allItems[i]._hit : 0;
            if (sel) {
                ret.push({ ...allItems[i++], sel: sel, hit: _hit });
            } else if (_hit) {
                ret.push({ ...allItems[i++], hit: _hit });
            } else {
                ret.push(allItems[i++]);
            }
        } else {
            i++; // skip...
        }
    }
    return ret;
}

function AreaModal({
    isDialogOpen,
    onOpenChange,
    title,
    allItems = [],
    onItemSelectionChanged,
    selectedItems = [],
}: any) {
    const [searchTerm, setSearchTerm] = useState("");
    const [collapsed, setCollapsed] = useState({});
    const [selected, setSelected] = useState(selectedItems);

    function handleSelectChange(item) {
        let newItems, newSelection;

        if (selected.find((select) => select.tid === item.tid)) {
            newItems = selected.filter((i) => i.tid !== item.tid);
            newSelection = unselectChildren(item, newItems);
            setSelected(newSelection);
        } else {
            newItems = [...selected, item];
            const itemChildren = getAllChildren(item, allItems);
            newSelection = newItems.concat(itemChildren);
            setSelected(newSelection);
        }
    }

    function unselectChildren(parent, currentSelection) {
        const children = getAllChildren(parent, allItems);
        if (children.length === 0) {
            return currentSelection;
        }
        const childrenIds = new Set(children.map((child) => child.tid));
        return currentSelection.filter((item) => !childrenIds.has(item.tid));
    }

    useEffect(() => {
        if (!selectedItems) {
            return;
        }
        setSelected(selectedItems);
    }, [selectedItems]);

    const filteredItems = filterWBS(allItems, collapsed, selected, searchTerm);

    const isAllSelected: CheckedState =
        selected.length == filteredItems.length ? true : selected.length >= 1 ? "indeterminate" : false;

    function onCollapseClick(item: any) {
        if (2 === item.s || 1 === item.s) {
            const collapse = 1 === item.s;
            setCollapsed((collapsed) => {
                const ret = Object.assign({}, collapsed);
                if (collapse) {
                    ret[item.tid] = true;
                } else {
                    delete ret[item.tid];
                }
                return ret;
            });
        }
    }

    function handleOkClick() {
        onItemSelectionChanged(selected);
        onOpenChange();
    }

    function handleCancelClick() {
        setSelected([...selectedItems]);
        onOpenChange();
    }

    return (
        <LcmdModal2
            open={isDialogOpen}
            onOpenChange={handleCancelClick}
            header={{ title: title }}
            size={ModalSizeTypes.m}
            buttons={[
                <Button key="cancel" variant="outline" onClick={handleCancelClick}>
                    {intl.get("fw.cancel")}
                </Button>,
                <Button key="ok" onClick={handleOkClick}>
                    {intl.get("fw.ok")}
                </Button>,
            ]}
        >
            <div className="space-y-4">
                <Input
                    placeholder={intl.get("filter.main.tz.add.search")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="h-[300px] space-y-2 overflow-y-auto">
                    <Checkbox
                        checked={isAllSelected}
                        onClick={() => {
                            const newCheckedState: CheckedState = isAllSelected === "indeterminate" || !isAllSelected;
                            setSelected(newCheckedState ? filteredItems : []);
                        }}
                    />
                    {filteredItems.map((item, index) => (
                        <div
                            key={index}
                            className="flex items-center space-x-2"
                            style={{ paddingLeft: 16 * item.l + "px" }}
                        >
                            {collapsed[item.tid] ? (
                                <ChevronDown onClick={() => onCollapseClick(item)} />
                            ) : (
                                <ChevronRight onClick={() => onCollapseClick(item)} />
                            )}

                            <Checkbox
                                id={`${title}-${index}`}
                                key={`${title}-${index}`}
                                checked={!!selected.find((select) => select.tid === item.tid)}
                                onCheckedChange={() => {
                                    handleSelectChange(item);
                                }}
                            />
                            <Label htmlFor={`${title}-${index}`}>{item.n}</Label>
                        </div>
                    ))}
                </div>
            </div>
        </LcmdModal2>
    );
}

function AreaCard({ name, index, onDelete }: { name: string; index: number; onDelete: (element) => void }) {
    return (
        <Button key={index} variant="outline" size="sm" className={cn("flex cursor-default items-center space-x-1")}>
            <span>{name}</span>

            <X
                className="h-3 w-3 cursor-pointer"
                onClick={() => {
                    onDelete(name);
                }}
            />
        </Button>
    );
}

function MyFilterTabView({
    onFilterSelect,
    onFilterEdit,
    onFilterDelete,
}: {
    onFilterSelect: () => void;
    onFilterEdit: (item: FilterItem) => void;
    onFilterDelete: (item: DataModelFilterSavedAs) => void;
}) {
    const {
        status: { loading, loaded },
        refresh,
        selectedFilterId,
        items: filterItems,
    } = useFilterCollection();
    const LCMD = useLCMD();
    const { isLoading: isUserLoading, data: currentUserData } = LCMD.useCurrentUserId();

    if (isUserLoading) {
        return <Spinner></Spinner>;
    }

    return (
        <div className="relative flex h-[450px] w-full flex-col gap-8">
            {loading &&
                Array.from({ length: 3 }, (_, index) => (
                    <Skeleton key={`Skeleton-${index}`} className={"mb-2 h-24 w-full"}></Skeleton>
                ))}
            {loaded &&
                filterItems.map((item) => {
                    return (
                        <FilterCard
                            key={`${item.global}-${item._hid}`}
                            item={item}
                            currentUser={currentUserData.sub}
                            selected={selectedFilterId && selectedFilterId == item._hid}
                            onFilterSelect={onFilterSelect}
                            onFilterEdit={onFilterEdit}
                            onFilterDelete={onFilterDelete}
                        />
                    );
                })}
        </div>
    );
}

function FilterCard({
    item,
    selected,
    currentUser,
    onFilterSelect,
    onFilterEdit,
    onFilterDelete,
}: {
    item: FilterItem;
    selected: boolean;
    currentUser: string;
    onFilterSelect: () => void;
    onFilterEdit: (item: FilterItem) => void;
    onFilterDelete: (item: DataModelFilterSavedAs) => void;
}) {
    const LCMD = useLCMD();
    return (
        <Card className={"h-[100px] rounded-2xl border-2 border-gray-200 bg-slate-50 p-4"}>
            <div>{item.name}</div>
            <div className={"flex items-center justify-end gap-2"}>
                {item.global && (
                    <div className={"p-2"}>
                        <LCMDTooltip text={intl.get("filter.manage.cmd.global.text")}>
                            <Globe
                                size={16}
                                className="outline-violet-600] rounded-full bg-violet-600 stroke-white outline-1"
                            />
                        </LCMDTooltip>
                    </div>
                )}
                {currentUser === item.owner ? (
                    <>
                        <LCMDTooltip text={intl.get("filter.manage.cmd.edit.text")}>
                            <IconButton
                                icon={Pencil}
                                iconProps={{ size: 16 }}
                                className={"text-zinc-500"}
                                onClick={() => onFilterEdit(item)}
                            ></IconButton>
                        </LCMDTooltip>
                        <LCMDTooltip text={intl.get("filter.manage.cmd.delete.text")}>
                            <IconButton
                                icon={Trash}
                                className={"text-zinc-500"}
                                iconProps={{ size: 16 }}
                                onClick={() => {
                                    onFilterDelete({
                                        saveAs: {
                                            hid: parseInt(item._hid),
                                            pos: item._pos,
                                            name: item.name,
                                            global: item.global,
                                        },
                                    });
                                }}
                            ></IconButton>
                        </LCMDTooltip>
                    </>
                ) : (
                    <LCMDTooltip text={intl.get("filter.manage.cmd.notOwner.text")}>
                        <div className={"flex items-center justify-end gap-2"}>
                            <div className={"p-2"}>
                                <Pencil className={"text-zinc-300"} size={16}></Pencil>
                            </div>
                            <div className={"p-2"}>
                                <Trash className={"text-zinc-300"} size={16}></Trash>
                            </div>
                        </div>
                    </LCMDTooltip>
                )}
                <div>
                    {selected && (
                        <Badge className="flex h-10 items-center rounded bg-green-600 px-3 hover:bg-green-600">
                            <CheckCircle size={16} className="mr-2" />
                            <div className="text-sm font-medium">{intl.get("baselines.active")}</div>
                        </Badge>
                    )}
                    {!selected && (
                        <Button
                            variant={"secondary"}
                            onClick={() => {
                                LCMD.setFilter({ filterId: item._hid, global: item.global });
                                onFilterSelect();
                            }}
                        >
                            {intl.get("filter.manage.cmd.apply.text")}
                        </Button>
                    )}
                </div>
            </div>
        </Card>
    );
}
