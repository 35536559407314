import React, { useState, useEffect, useCallback } from "react";
import { WorkerSession } from "@/model/DataModel";
import { getLog, getProject, getSub } from "@/model/ApiHelper";
import { DialogType, Dialog } from "@fluentui/react";
import { assert } from "@/model/GlobalHelper";
import { intl } from "@/legacy/GlobalHelperReact";
import DigitalPlanningBoard from "@/legacy/components/DigitalPlanningBoard";
import { MainWorkerMessage, MainWorkerPipe } from "@/legacy/MainWorkerPipe";
import { Card } from "@/components/ui/card";
import { CONST } from "@/legacy/settings";
import { FilesOverlay as FilesOverlayComponent } from "@/components/FilesOverlay";
import { useLCMD } from "@/app/LCMContext";

function OpenDialog({ onDismiss }: { onDismiss: () => void }) {
    return (
        <Dialog
            hidden={false}
            onDismiss={() => onDismiss()}
            dialogContentProps={{
                type: DialogType.normal,
                title: intl.get("projects.open.title"),
                subText: intl.get("projects.open.subText"),
            }}
            modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 450 } },
            }}
        />
    );
}

type FilesOverlayProps = {
    visible: boolean;
    hasCloseButton: boolean;
};

const FilesOverlay = (props: FilesOverlayProps) => {
    const { visible } = props;
    const { worker } = useLCMD();
    const [projects, setProjects] = useState<any[]>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [tsKey, setTsKey] = useState(null);

    const setProjectState = useCallback((item: any, i_item: number, projectState: any, cb?: () => void) => {
        setProjects((prevProjects) => {
            let localProjects = prevProjects || [];
            if (localProjects[i_item] === item) {
                localProjects = localProjects.slice();
                localProjects[i_item] = Object.assign({}, localProjects[i_item], projectState);
                if (worker.nav.project === localProjects[i_item].pid) {
                    onCellClick(localProjects[i_item]);
                    setOpen(false);
                }
                // cb?.(); // TODO check this stuff
                return localProjects;
            } else {
                // cb?.();
                return null;
            }
        });
    }, []);

    const getTSKey = () => {
        const now = Date.now();
        const newTsKey = Math.max((tsKey || 0) + 1, now);
        assert((tsKey || 0) < newTsKey);
        setTsKey(newTsKey);
        return newTsKey;
    };

    const _fetchProjects = (projects) => {
        // const resolved_ctx = {
        //     n: projects.length,
        //     i: 0,
        //     p: null,
        // };
        // const resolve = (p, i) => {
        //     if (worker.nav.project === p?.pid) {
        //         resolved_ctx.p = i;
        //     }
        //     resolved_ctx.i++;
        //     if (resolved_ctx.n === resolved_ctx.i) {
        //         if (worker.nav.project) {
        //             // setOpen(null);
        //             let _p = null;
        //             if (null !== resolved_ctx.p) {
        //                 _p = projects[resolved_ctx.p];
        //             }
        //             if (_p) {
        //                 onCellClick(_p);
        //             } else {
        //                 // not found
        //             }
        //         }
        //     }
        // };
        projects.forEach((item: any, i_item: number) => {
            if (!item.project) {
                item.project = { loading: true };
                getProject(worker.auth.auth_token, item.pid, (error, project) => {
                    if (error) {
                        setProjectState(item, i_item, { loading: false, error });
                    } else {
                        const pid_ts = project.project.log[project.project.log.length - 1];
                        getLog(project.project_token, project.pid, pid_ts, (logError, log) => {
                            if (logError) {
                                setProjectState(item, i_item, { loading: false, error: logError });
                            } else {
                                setProjectState(item, i_item, {
                                    loading: false,
                                    error: null,
                                    project,
                                    log,
                                    pid_ts,
                                    ts: getTSKey(),
                                });
                            }
                        });
                    }
                });
            }
        });
    };

    const fetchProjects = () => {
        if (worker.auth?.auth_token) {
            getSub(worker.auth.auth_token, null, (error, result) => {
                if (error) {
                    // TODO
                } else {
                    const helper = (projects || []).reduce((ret, item) => {
                        ret[item.pid] = item;
                        return ret;
                    }, {});
                    const newProjects = (result?.result?.projects || []).map((pid: string) => helper[pid] || { pid });
                    setProjects(newProjects);
                    _fetchProjects(newProjects);
                }
            });
        }
    };

    const onWorkerMsg = useCallback(
        (msg: MainWorkerMessage) => {
            switch (msg[0]) {
                case "framework": {
                    switch (msg[1]) {
                        case "clone":
                            {
                                const opt = msg[2];
                                const file = opt.data ? new File([opt.data], "", { type: opt.mime }) : null;
                                worker.dispatchMessage([
                                    "toggle",
                                    "dialog.project.clone",
                                    {
                                        key: getTSKey(),
                                        view: "upload",
                                        onDone: (data) => {
                                            onUploadDone(data);
                                            worker.dispatchMessage(["toggle", "dialog.project.clone", null]);
                                        },
                                        viewOptions: {
                                            clone: opt,
                                            files: file ? [file] : undefined,
                                        },
                                        auth_token: worker.auth?.auth_token || null,
                                        auth_warmup: worker.warmup_result?.req ? worker.warmup_result : null,
                                        project: null,
                                    },
                                ]);
                            }
                            break;
                        case "reload": {
                            const opt = msg[2];
                            if ("string" === typeof opt?.pid) {
                                const i = projects?.findIndex((p) => p.pid === opt.pid);
                                if (i >= 0) {
                                    const updatedProjects = [...projects];
                                    updatedProjects[i] = { ...updatedProjects[i], project: null };
                                    setProjects(updatedProjects);
                                    fetchProjects();
                                }
                            } else {
                                fetchProjects();
                            }
                        }
                    }
                }
            }
        },
        [worker, projects, fetchProjects],
    );

    const openProject = (worker: MainWorkerPipe, session: WorkerSession, dailyBoard: boolean) => {
        worker.dispatchMessage(["framework", "files", false]);
        DigitalPlanningBoard.openProject(worker, session, dailyBoard);
    };

    const onCellClick = (item: any) => {
        if ("number" === typeof item?.pid_ts) {
            const session: WorkerSession = {
                pid: item.project.pid,
                pid_ts: item.pid_ts,
                project_token: item.project.project_token,
                resource: item.log?.file,
                master_token: item.log.master,
                sandbox: item.ts,
            };
            delete worker.nav.project;
            openProject(worker, session, false);
        }
    };

    const onClose = () => {
        worker.dispatchMessage(["framework", "files", false]);
    };

    const onProjectEntryClick = (item) => {
        return onCellClick(item);
    };

    const onUploadDone = (data) => {
        if (data) {
            fetchProjects();
        }
    };

    useEffect(() => {
        if (worker.nav.project) {
            setOpen(true);
        }
        worker.registerHandler(onWorkerMsg);
        fetchProjects();

        return () => {
            worker.unregisterHandler(onWorkerMsg);
        };
    }, []);

    return (
        <>
            <Card
                style={{
                    position: "fixed",
                    top: 50,
                    left: 10,
                    bottom: 50,
                    width: CONST.filesOverlayWidth,
                    zIndex: 200,
                    backgroundColor: "white",
                    boxShadow: "rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px",
                    overflow: "hidden",
                    display: visible ? undefined : "none",
                }}
            >
                <FilesOverlayComponent
                    {...props}
                    projects={projects || []}
                    onClose={onClose}
                    onUploadDone={onUploadDone}
                    onProjectEntryClick={onProjectEntryClick}
                />
            </Card>
            {open && (
                <OpenDialog
                    onDismiss={() => {
                        setOpen(false);
                    }}
                />
            )}
        </>
    );
};

export { FilesOverlay };
