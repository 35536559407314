import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { LcmdModal2, ModalSizeTypes } from "@/components/common/LcmModal2";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Calendar } from "lucide-react";
import { UTCDatePickerV2 } from "@/legacy/GlobalHelperFluentUI";
import type { FilterDuration, Duration } from "@/components/Filter/FilterV2/FilterModal";
import { useEffect, useState } from "react";
import { FILTER_SPECIAL_VALUE_MASK } from "@/model/DataModelTypes";

type TimeFrameSelectionModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onTimeFrameSave: (filterDuration: FilterDuration) => void;
    filterDuration: FilterDuration;
    projectDuration: Duration;
};

export function TimeFrameSelectionModal({
    isOpen,
    onClose: handleClose,
    onTimeFrameSave: handleTimeFrameSave,
    filterDuration,
    projectDuration,
}: TimeFrameSelectionModalProps) {
    const timeframes = ["every", "4weeks", "6weeks", "4months", "custom"] as const;
    const [localFilterDuration, setLocalFilterDuration] = useState<FilterDuration>(filterDuration);
    const [isEndDateReadonly, setIsEndDateReadonly] = useState(true);
    const [isOneCalendarOpen, setIsOneCalendarOpen] = useState(false);

    useEffect(() => {
        if (!filterDuration) {
            return;
        }
        setLocalFilterDuration(filterDuration);
    }, [filterDuration, isOpen]);

    const handleTimeframeSelect = (option: string) => {
        const now = new Date();
        let end = 0;
        let newFilterDuration: FilterDuration = {
            ...localFilterDuration,
            selection: "custom",
        };
        let newIsEndDateReadonly = false;

        switch (option) {
            case "every":
                newFilterDuration = {
                    start: undefined,
                    end: undefined,
                    selection: "every",
                    formattedStart: projectDuration.start,
                    formattedEnd: projectDuration.end,
                };
                newIsEndDateReadonly = true;

                break;
            case "4weeks":
                end = new Date().setUTCDate(now.getUTCDate() + 28);
                newFilterDuration = {
                    start: 0,
                    end: 4,
                    selection: "4weeks",
                    formattedStart: now,
                    formattedEnd: new Date(end),
                };
                newIsEndDateReadonly = true;
                break;
            case "6weeks":
                end = new Date().setUTCDate(now.getUTCDate() + 42);
                newFilterDuration = {
                    start: 0,
                    end: 6,
                    selection: "6weeks",
                    formattedStart: now,
                    formattedEnd: new Date(end),
                };
                newIsEndDateReadonly = true;

                break;
            case "4months":
                end = new Date().setUTCMonth(now.getUTCMonth() + 4);
                newFilterDuration = {
                    start: 0,
                    end: 260,
                    selection: "4months",
                    formattedStart: now,
                    formattedEnd: new Date(end),
                };
                newIsEndDateReadonly = true;
                break;
            case "custom":
                newFilterDuration.start = new Date().setUTCHours(0, 0, 0, 0);
                newFilterDuration.end = newFilterDuration.formattedEnd.getTime();
                newFilterDuration.formattedStart = new Date(newFilterDuration.start);
                break;
        }

        setLocalFilterDuration(newFilterDuration);
        setIsEndDateReadonly(newIsEndDateReadonly);
    };

    return (
        <LcmdModal2
            header={{ title: intl.get("filter.main.date.label") }}
            open={isOpen}
            onClickX={handleClose}
            size={ModalSizeTypes.l}
            onOpenChange={() => {
                if (isOneCalendarOpen) {
                    return;
                }
                handleClose();
            }}
            buttons={[
                <Button
                    key={"saveTimeframe"}
                    className="mt-4 w-full"
                    onClick={() => {
                        handleTimeFrameSave(localFilterDuration);
                    }}
                >
                    {intl.get("fw.ok")}
                </Button>,
            ]}
            closable={true}
        >
            <div className="mb-4 grid grid-cols-5 gap-2">
                {timeframes.map((option) => (
                    <Button
                        key={option}
                        variant="outline"
                        size="sm"
                        className={cn(
                            "flex h-20 flex-col items-center justify-center ",
                            option === localFilterDuration.selection && "bg-slate-400 hover:bg-slate-400",
                        )}
                        onClick={() => {
                            handleTimeframeSelect(option);
                        }}
                    >
                        <Calendar className="mb-1 h-6 w-6" />
                        <span className="text-xs">{intl.get(`filter.date.${option}.text`)}</span>
                    </Button>
                ))}
            </div>
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <UTCDatePickerV2
                        onOpenChange={setIsOneCalendarOpen}
                        label={intl.get("fw.startEndDateControl.start.label")}
                        disabled={localFilterDuration.selection === "every"}
                        value={localFilterDuration.formattedStart}
                        onSelectDate={(value) => {
                            const changedFilterDuration = {
                                ...localFilterDuration,
                                start: value.getTime(),
                                formattedStart: value,
                            };

                            if (localFilterDuration.selection != "custom" && localFilterDuration.selection != "every") {
                                const newEndDate: Date = new Date(value.valueOf());
                                if (localFilterDuration.selection.includes("weeks")) {
                                    const daysToAdd = localFilterDuration.end * 7;
                                    newEndDate.setUTCDate(value.getUTCDate() + daysToAdd);
                                }

                                if (localFilterDuration.selection.includes("months")) {
                                    const monthsToAdd = localFilterDuration.end - FILTER_SPECIAL_VALUE_MASK - 1;
                                    newEndDate.setUTCMonth(value.getUTCMonth() + monthsToAdd);
                                }

                                changedFilterDuration.formattedEnd = newEndDate;
                            }

                            setLocalFilterDuration(changedFilterDuration);
                        }}
                    />
                </div>
                <div>
                    <UTCDatePickerV2
                        onOpenChange={setIsOneCalendarOpen}
                        label={intl.get("fw.startEndDateControl.end.label")}
                        disabled={isEndDateReadonly}
                        value={localFilterDuration.formattedEnd}
                        onSelectDate={(value) => {
                            setLocalFilterDuration({
                                ...localFilterDuration,
                                end: value.getTime(),
                                formattedEnd: value,
                            });
                        }}
                    />
                </div>
            </div>
        </LcmdModal2>
    );
}
