import * as React from "react";
import { Button } from "../../ui/button";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import { useEffect, useMemo, useState } from "react";
import { useFilterCollection } from "@/components/Filter/data-access-hooks/use-filter-collection";
import { intl } from "@/legacy/GlobalHelperReact";
import { Input } from "@/components/ui/input";
import { useLCMD } from "@/app/LCMContext";
import { Separator } from "@/components/ui/separator";
import { ArrowDown, Globe, Pencil, RefreshCcw, Settings2 } from "lucide-react";
import { DropdownMenuItemProps } from "@radix-ui/react-dropdown-menu";
import { cn } from "@/lib/utils";
import { FilterItem } from "../../Filter/data-access-hooks/use-filter-data";
import { Status } from "../../hooks/use-loading-status";
import { FilterModal } from "@/components/Filter/FilterV2/FilterModal";
import { LCMDTooltip } from "@/components/common/Tooltip/LCMDTooltip";

/* content menu */
//TODO: Build to shadCN
// const noFilterFoundItem: DropdownMenuItemProps = {
//     key: "noFiltersFound",
//     onRender: NotFoundFilters,
// };

type FilterSelectedProps = { filterId: string; global: boolean };

type DropdownMenuItemPropsExtended = DropdownMenuItemProps & {
    text: string;
    global: boolean;
    handleSelect: (selectedFilter: FilterSelectedProps) => void;
};

export type ContextualMenuProps = {
    onSelect: (selectedFilter: FilterSelectedProps) => void;
    onClear: () => void;
    onManage: () => void;
    items: FilterItem[];
    selectedFilterId: string;
    refresh: () => void;
    status: Status;
};

function normalizeFilterText(text: string) {
    return text.trim().toLowerCase();
}

function ContextualMenu(props: ContextualMenuProps) {
    const [searchInput, setSearchInput] = useState("");
    const [loadingFiltersItem, setLoadingFiltersItem] = useState(null);
    const { items: filterItems, selectedFilterId, status, refresh, onSelect: handleSelect } = props;

    useEffect(() => {
        setLoadingFiltersItem(
            !status.loaded
                ? {
                      key: "loading",
                      text: intl.get("fw.loading"),
                      disabled: true,
                  }
                : null,
        );
    }, [status.loaded]);

    const filterOptions: DropdownMenuItemPropsExtended[] = useMemo(() => {
        return filterItems.map((filterItem) => {
            return {
                key: filterItem._hid.toString(),
                text: filterItem.name || "?",
                canCheck: true,
                checked: selectedFilterId === filterItem._hid,
                handleSelect,
                global: filterItem.global,
            };
        });
    }, [filterItems, selectedFilterId, handleSelect]);

    const filteredOptions = useMemo(() => {
        const filterByText = normalizeFilterText(searchInput);

        // when search input empty
        if (!filterByText) {
            return filterOptions;
        }

        const filteredOptions: DropdownMenuItemPropsExtended[] = filterOptions.filter(
            (option: DropdownMenuItemPropsExtended) => normalizeFilterText(option.text).includes(filterByText),
        );

        return filteredOptions.length
            ? filteredOptions
            : [{ key: "noFiltersFound", text: intl.get("canvas.cmd.filter.cmd.search.notFoundText") }];
    }, [filterOptions, searchInput]);

    const menuItems: DropdownMenuItemPropsExtended[] = useMemo(() => {
        return loadingFiltersItem ? [loadingFiltersItem] : filteredOptions;
    }, [loadingFiltersItem, filteredOptions]);

    return (
        <DropdownMenuContent className="w-56">
            <Input
                placeholder={intl.get("canvas.cmd.filter.cmd.search.placeholder")}
                value={searchInput}
                onChange={(event) => {
                    event.preventDefault();
                    setSearchInput(event.target.value);
                    setTimeout(() => {
                        event.target.focus();
                    }, 0);
                }}
            />
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
                <DropdownMenuItem className={"flex items-center justify-between"}>
                    <span>{intl.get("canvas.cmd.filter.title")}</span>
                    <span>
                        <Pencil
                            onClick={() => {
                                setTimeout(() => {
                                    props.onManage();
                                    refresh();
                                });
                            }}
                            className={"h-3 w-3"}
                            data-userflow-id="pp-filter-bearbeiten"
                        />
                    </span>
                </DropdownMenuItem>
                {menuItems.length > 0 &&
                    menuItems.map(({ key, global, handleSelect, text }) => (
                        <DropdownMenuCheckboxItem
                            checked={selectedFilterId?.toString() === key}
                            key={key}
                            onClick={() => {
                                handleSelect({ filterId: key as string, global: global });
                                refresh();
                            }}
                        >
                            <div className={"flex w-full justify-between"}>
                                <LCMDTooltip text={text}>
                                    <div className={"w-28 overflow-hidden truncate text-ellipsis text-left"}>{text}</div>
                                </LCMDTooltip>
                                <div>
                                    {global && (
                                        <Globe className="h-4 w-4 rounded-full bg-[#4F3AB1] stroke-white outline-1 outline-[#4F3AB1]" />
                                    )}
                                </div>
                            </div>
                        </DropdownMenuCheckboxItem>
                    ))}
                <DropdownMenuSeparator />
                <DropdownMenuItem
                    key={"clear"}
                    onClick={() => {
                        props.onClear();
                        refresh();
                    }}
                    data-userflow-id="pp-filter-zurueksetzen"
                >
                    <RefreshCcw size={20} className={"m-2"} />
                    {intl.get("canvas.cmd.filter.cmd.clear.text")}
                </DropdownMenuItem>
            </DropdownMenuGroup>
        </DropdownMenuContent>
    );
}

export function FilterButton({
    checked,
    userflowId,
    userflowId2,
}: {
    checked: boolean;
    userflowId?: string;
    userflowId2?: string;
}) {
    const LCMD = useLCMD();
    const filterCollection = useFilterCollection();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTab, setModalTab] = useState<"filter" | "myFilters">("filter");

    function handleOpenFilter(tab) {
        setModalTab(tab);
        setIsModalOpen(true);
    }

    function handleDismissFilter() {
        setIsModalOpen(false);
        setModalTab(undefined);
    }

    return (
        <div className="group flex items-center rounded-md bg-secondary text-secondary-foreground duration-150 hover:bg-zinc-300 hover:duration-150">
            <Button
                variant="secondary"
                className={cn(
                    checked && "bg-gray-200 hover:bg-gray-200",
                    "flex h-8 items-center rounded-r-none bg-zinc-100 px-3 duration-150 hover:duration-150 group-hover:bg-zinc-300",
                )}
                onClick={() => handleOpenFilter("filter")}
                userflowId={userflowId2}
            >
                <Settings2 className="mr-2" />
                <div>{intl.get("canvas.cmd.filter.text")}</div>
            </Button>
            <FilterModal isOpen={isModalOpen} onDismiss={handleDismissFilter} tab={modalTab} />
            <Separator
                orientation="vertical"
                decorative
                className={cn(
                    "h-4 bg-[#C1C5C7] transition-opacity duration-150",
                    checked ? "opacity-0 group-hover:opacity-100" : "opacity-100",
                )}
            />
            <DropdownMenu onOpenChange={filterCollection.refresh}>
                <DropdownMenuTrigger asChild>
                    <Button
                        variant="secondary"
                        className={cn(
                            checked && "bg-zinc-300 text-secondary-foreground hover:bg-zinc-300",
                            "h-8 rounded-l-none px-2 duration-150 hover:duration-150 group-hover:bg-zinc-300",
                        )}
                        userflowId={userflowId}
                    >
                        <ArrowDown size={16} />
                    </Button>
                </DropdownMenuTrigger>
                <ContextualMenu
                    onSelect={(selectedFilter) => LCMD.setFilter(selectedFilter)}
                    onClear={() => LCMD.setFilter(null)}
                    onManage={() => {
                        handleOpenFilter("myFilters");
                    }}
                    {...filterCollection}
                />
            </DropdownMenu>
        </div>
    );
}
