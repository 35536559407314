import { ReactElement, useEffect, useState } from "react";
import { LcmdModal2 } from "@/components/common/LcmModal2";
import { Label } from "@/components/ui/label";
import { intl, CheckForUpdatesResult } from "lcmd2framework";
import { Button } from "@/components/ui/button";
import generalErrorImage from "@/assets/img/errorNotifications/GeneralError.svg";

type GeneralError = {
    onReload: () => void;
    image?: string;
    headline?: string;
    text?: string;
    buttonText?: string;
    checkForUpdates?: () => Promise<CheckForUpdatesResult>;
    forcedUpdate: boolean;
    buildInfo?: string;
    projectId: string;
};

export function GeneralError(props: GeneralError): ReactElement {
    const buttonText = props.buttonText ? props.buttonText : intl.get("GeneralError.buttonText");
    const image = props.image ? props.image : generalErrorImage;
    const headline = props.headline ? props.headline : intl.get("GeneralError.headline");
    const text = props.text ? props.text : intl.getHTML("GeneralError.text", { projectId: props.projectId });

    const [updateState, setUpdateState] = useState("GeneralError.update");
    useEffect(() => {
        if (props.checkForUpdates) {
            props
                .checkForUpdates()
                .then((ret) => {
                    console.log(JSON.stringify(ret));
                    if (ret?.updateAvailable) {
                        setUpdateState(null); // update available
                    } else {
                        setUpdateState(null); // no update needed
                    }
                })
                .catch((e) => {
                    console.error(e);
                    setUpdateState(null); // failed to get updates...
                });
        } else {
            setUpdateState(null);
        }
    }, [props.checkForUpdates, setUpdateState]);

    return (
        <LcmdModal2 open={true} size="m" onOpenChange={props.onReload}>
            <div className="flex flex-col flex-nowrap items-stretch p-6">
                <div className="flex flex-auto justify-center">
                    <img alt="errorImage" src={image} />
                </div>
                <div className="mb-2.5 text-center text-3xl font-semibold">{headline}</div>
                <p className="mb-2.5 text-center text-xs text-gray-500">{text}</p>
                <div className="flex flex-col items-center justify-between">
                    {updateState ? (
                        <Label className="font-semibold">{intl.get(updateState)}</Label>
                    ) : (
                        <Button className="h-8" variant="darkHover" onClick={props.onReload} autoFocus>
                            {buttonText}
                        </Button>
                    )}
                </div>
            </div>
        </LcmdModal2>
    );
}
