import { LcmdModal2, ModalSizeTypes } from "../../common/LcmModal2";
import { Input } from "../../ui/input";
import { GlobeField } from "../../common/globeField";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { intl } from "lcmd2framework";
import { getDateAndTime } from "@/components/utils/date/dateAndTime";
import { InputWithGlobeField } from "@/components/Filter/FilterV2/InputWithGlobeField";

type saveData = {
    filterName: string;
    isGlobal: boolean;
};

const today = getDateAndTime(new Date().getTime())?.toString() || "";

export function SaveFilter({
    isOpen,
    onClose,
    onSave,
    global = false,
    initialFilterName,
}: {
    isOpen: boolean;
    onClose: () => void;
    onSave: (saveData) => void;
    global?: boolean;
    initialFilterName?: string;
}) {
    const [isGlobal, setIsGlobal] = useState<boolean>(Boolean(global));
    const [filterName, setFilterName] = useState(initialFilterName !== undefined ? initialFilterName : today);

    function handleSaveNameClick() {
        onSave({
            name: filterName !== "" ? filterName : today,
            global: isGlobal,
        });
    }
    return (
        <LcmdModal2
            open={isOpen}
            onOpenChange={onClose}
            header={{ title: intl.get("filter.save.ok") }}
            size={ModalSizeTypes.m}
            buttons={[
                <Button key={"saveFilter"} onClick={handleSaveNameClick}>
                    {intl.get("filter.save.ok")}
                </Button>,
            ]}
        >
            <div className={"text-xs font-normal text-gray-800"}>{intl.get("filter.save.name.description")}</div>
            <InputWithGlobeField
                value={filterName}
                placeholder={today}
                onGlobalStateChange={(globalState) => setIsGlobal(Boolean(globalState))}
                onInputValueChange={(value) => setFilterName(value)}
            ></InputWithGlobeField>
        </LcmdModal2>
    );
}
