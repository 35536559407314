﻿import * as React from "react";
import { isMobile } from "is-mobile";
import { CanvasTaskData, CanvasViewConst, DataModel, WorkerSession } from "../../../model/DataModel";
import { getParticleContext } from "../../api/ParticleContext";
import { CONST } from "../../settings";
import * as fluentui from "@fluentui/react";
import * as detectIt from "detect-it";
import { assert, gfu } from "../../../model/GlobalHelper";
import { unlink_project } from "../../../model/ApiHelper";
import { CoreEventNames, CoreEventsCallbackData } from "../../../app/types/CoreEvents";
import { useCanvasStore } from "../../../app/store/canvasStore";
import { CanvasDashboard } from "./CanvasDashboard";
import { CanvasDailyBoardHost } from "./CanvasDailyBoardHost";
import { CanvasTabHost } from "./CanvasTabHost";
import { CanvasAddPanel } from "./CanvasAddPanel";
import { CanvasChangesPanel } from "./CanvasChangesPanel";
import { ReasonDisturbance } from "../../../components/common/ErrorNotifications/ReasonDisturbance/ReasonDisturbance";
import { CanvasHostContainer } from "./CanvasHostContainer";
import { CanvasSidebarHost } from "./CanvasSidebarHost";
import { CanvasDragOverlay } from "./CanvasDragOverlay";
import { CanvasStatusHeader } from "./CanvasStatusHeaderV2";
import { CanvasOverlay } from "./CanvasOverlay";
import { CanvasPopup } from "./CanvasPopup";
import { DigitalPlanningBoardMetaData, DigitalPlanningBoardViewKey, intl } from "lcmd2framework";
import { MainWorkerMessage, MainWorkerPipe } from "../../MainWorkerPipe";
import { AreaListPopup } from "../../../components/canvas/areas/areaListPopup";
import { CanvasSizeHelper } from "./Shared/CanvasSizeHelper";
import { ProcessPlanToolbar } from "@/components/common/ProcessPlanToolbar/ProcessPlanToolbar";
import { EmbeddedNPSModal } from "@/components/common/embeddedNPSModal";
import { CopyToaster } from "@/app/components/UtilsContainer";
import { downloadBuffer } from "@/utils/GeneralUtils";

type CanvasProps = CanvasCommonProps & {
    //readonly: boolean,
    sidebar: {
        selected: CanvasViewKey;
        subView?: string;
    };
    filesOverlay: boolean;
};

type CanvasState = CanvasCommonState & {
    filter: { manage?: boolean } | null;
    trade: { tid: number; trades: number[] } | null;
    user: boolean;
    addPanel: boolean;
    taktzones: boolean;
    card: any | null;
    mode: string;
    meta: CanvasMeta | null;
    footer: any;
    wb: any; // whiteboards
    constWB: CanvasViewConst; // active whiteboard const,
    showReasonCodePopUp: boolean;
    reasonCodeData: any;
    taktzoneModal?: {
        isOpen: boolean;
        selectedTaktzone: number;
    };
    showCircularDependencyToast: boolean;
};

type CanvasMouseDownInfo = {
    target: HTMLDivElement;
    task: CanvasTaskData;
    templateId: number | null;
    setCaret: boolean | null;
    _clientX: number;
    _clientY: number;
    dragX: number | null;
    dragY: number | null;
    mousemove: boolean;
    shiftKey: boolean;
    ctrlKey: boolean;
};

/**
 * @public
 */

export type CanvasViewKey = DigitalPlanningBoardViewKey;

export type CanvasViewPinchState = {
    scale: number;
    left: number;
    top: number;
    colHeaderHeight: number;
    rowHeaderWidth: number;
    ev: any;
    clientX0: number;
    clientY0: number;
    clientX1: number;
    clientY1: number;
    _ml: number;
    _mx: number;
    _my: number;
    ml: number;
    mx: number;
    my: number;
    _s: number;
};

export type CanvasViewPort = {
    mobile: boolean;
    //    readonly: boolean,
    scaling_lock: number;
    target_zoom: {
        clientX: number;
        clientY: number;
        scale: number;
    } | null;
    scale: number;
    colHeaderHeight: number;
    rowHeaderWidth: number;
    left: number;
    top: number;
    width: number;
    height: number;
    mouseDown: CanvasMouseDownInfo | null;
    clientZoomX: number | null;
    clientZoomY: number | null;
    forceShiftKey: boolean;
    _caretX: number | null;
    _caretY: number | null;
    selected: { [taskId: number]: true };
    clipboard: CanvasTaskData[] | number[];
    sync: {
        commited: number;
        syncCommited: number;
        tasksCommited: number;
    };
    today: number;
    availHeight: number;
    availWidth: number;
    pinch: CanvasViewPinchState;
    inf2Rows: number;
    inf2ColsBefore: number; // REMOVE ME!
    inf2ColsAfter: number; // REMOVE ME!
};

export type CanvasMeta = DigitalPlanningBoardMetaData & {
    /** @internal */
    session: WorkerSession;
    /** @beta */
    procore?: any;
};

export type CanvasViewProps = {
    view: CanvasViewPort;
    meta: CanvasMeta;
};

export type CanvasCommonProps = {
    worker: MainWorkerPipe;
    projectId: string;
    projectSandbox: string | WorkerSession;
};

export type CanvasCommonState = {
    scale: number;
    const: CanvasViewConst;
    rows: number;
    cols: number;
    l_min: number;
    l_max: number;
};

export class Canvas extends React.Component<CanvasProps, CanvasState> {
    state = {
        scale: 1.0,
        const: this.props.worker.canvas.viewConst,
        rows: 0,
        cols: 0,
        l_min: 0,
        l_max: 0,
        filter: null,
        trade: null,
        user: false,
        addPanel: false,
        taktzones: false,
        card: null,
        mode: null,
        meta: null,
        footer: null,
        wb: null,
        constWB: this.props.worker.wb?.viewConst || null,
        showReasonCodePopUp: false,
        reasonCodeData: null,
        taktzoneModal: {
            isOpen: false,
            selectedTaktzone: 0,
        },
        loaded: false,
        showCircularDependencyToast: false,
    };

    private divRefs: {
        body: HTMLBodyElement;
        root: HTMLDivElement;
        viewport: HTMLMetaElement;
        CV: HTMLDivElement;
        CO: HTMLDivElement;
        CH: HTMLDivElement;
        RH: HTMLDivElement;
        AD: HTMLDivElement;
        RHC: HTMLDivElement[];
        CC_S: HTMLDivElement;
        SH: HTMLDivElement;
        DEBUG: HTMLDivElement;
        CC: HTMLDivElement;
    } = {
        body: document.body as HTMLBodyElement,
        root: document.getElementById("root") as HTMLDivElement,
        viewport: document.getElementById("viewport") as HTMLMetaElement,
        CV: null,
        CO: null,
        CH: null,
        RH: null,
        AD: null,
        RHC: [],
        CC_S: null,
        SH: null,
        DEBUG: null,
        CC: null,
    };

    private viewPort: CanvasViewPort = {
        mobile:
            false &&
            isMobile({
                tablet: true,
            }),
        //readonly: false || this.props.readonly /*&& ("development"!==process.env.NODE_ENV || false)*/,
        scaling_lock: 0,
        target_zoom: null,
        scale: 1,
        colHeaderHeight: 0,
        rowHeaderWidth: 0,
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        mouseDown: null,
        clientZoomX: null,
        clientZoomY: null,
        forceShiftKey: false,
        _caretX: null,
        _caretY: null,
        selected: {},
        clipboard: [],
        sync: {
            commited: 0,
            syncCommited: 0,
            tasksCommited: 0,
        },
        today: DataModel.EpochMSRoundDays(Date.now()),
        availHeight: window.screen.availHeight,
        availWidth: window.screen.availWidth,
        pinch: null,
        inf2Rows: 0, // REMOVE ME?!
        inf2ColsBefore: 0, // REMOVE ME?!
        inf2ColsAfter: 0, // REMOVE ME?!
    };

    private unsubscribeCanvasStore;
    private isCanvasRendered = false;

    onWorkerMsg = function (this: Canvas, msg: MainWorkerMessage) {
        switch (msg[0]) {
            case "canvas":
                {
                    const canvas = msg[1];
                    this.setState(this._updateView.bind(null, canvas, null, this));
                }
                break;
            case "view":
                {
                    const view = msg[1];
                    this.setState(this._updateView.bind(null, null, view, this));
                }
                break;
            case "wb":
                {
                    const wb = msg[1];
                    const constWB = wb.viewConst || this.state.constWB;
                    if (constWB !== this.state.constWB) {
                        this.setState({
                            constWB,
                        });
                    }
                }
                break;
            case "import":
                {
                    const todos = msg[1];
                    if (todos?.error?.meta) {
                        this.setState({
                            meta: todos.error.meta,
                        });
                    }
                }
                break;
            case "zoom":
                {
                    const zoom = msg[1];
                    assert(this.viewPort.scaling_lock > 0);
                    this.viewPort.scale = zoom.scale;
                    this.forceUpdate(() => {
                        assert(this.viewPort.scaling_lock > 0);
                        this.viewPort.scaling_lock--;
                        const left = Math.round(zoom.left);
                        const top = Math.round(zoom.top);
                        window.scrollTo(left, top);
                        assert(this.viewPort.scale === zoom.scale);
                        if (null !== this.viewPort.target_zoom) {
                            setImmediate(() => {
                                Canvas._ensureTargetZoom(
                                    this.viewPort,
                                    this.props.worker,
                                    this.state,
                                    this.viewPort.target_zoom,
                                );
                            });
                        } else {
                            this.updateViewport(true);
                        }
                    });
                }
                break;
            case "toggle":
                {
                    switch (msg[1]) {
                        case "filter":
                            {
                                this.setState({
                                    filter: msg[2] || null,
                                });
                            }
                            break;
                        case "user":
                            {
                                this.setState({
                                    user: true === msg[2] || false === msg[2] ? msg[2] : !this.state.user,
                                });
                            }
                            break;
                        case "taktzones":
                            {
                                this.setState({
                                    taktzones: msg[2] ? true : false,
                                });
                            }
                            break;
                        case "zoom":
                            {
                                this.viewPort.clientZoomX = Math.floor(this.viewPort.availWidth / 2);
                                this.viewPort.clientZoomY = Math.floor(this.viewPort.availHeight / 2);
                                this.zoom(msg[2] || 0, 100);
                            }
                            break;
                        case "footer":
                            {
                                this.setState({
                                    footer: msg[2],
                                });
                            }
                            break;
                    }
                }
                break;
            case "framework":
                {
                    switch (msg[1]) {
                        case "card":
                            {
                                const card = msg[2];
                                this.setState({
                                    card: card,
                                });
                            }
                            break;
                        case "trade":
                            {
                                const opt = msg[2];
                                this.setState({
                                    trade: opt,
                                });
                            }
                            break;
                        case "unlink":
                            {
                                const subs = msg[2].subs;
                                const projectSandbox = this.props.projectSandbox as WorkerSession;
                                if (projectSandbox.project_token) {
                                    unlink_project(projectSandbox.project_token, msg[2].cb, projectSandbox.pid, subs);
                                }
                            }
                            break;
                        case "worker": {
                            if (true === msg[2]) {
                                this._unregisterEventHandler();
                            } else if (false === msg[2]) {
                                this._registerEventHandler();
                            }
                        }
                    }
                }
                break;
        }
    }.bind(this);

    private _CTRL_HACK = false;

    private onKeyDown = function (this: Canvas, event: KeyboardEvent) {
        this._CTRL_HACK = event.ctrlKey;
        //console.log(JSON.stringify(event.key));
        if (this.props.worker.nav.view == "pp") {
            switch (event.key) {
                case "z":
                    if (event.ctrlKey || event.metaKey) {
                        this.props.worker.postMessage(["undo", {}]);
                        event.preventDefault();
                    }
                    break;
                case "y":
                    if (event.ctrlKey || event.metaKey) {
                        this.props.worker.postMessage(["redo", {}]);
                        event.preventDefault();
                    }
                    break;
            }
        }
        if (
            (!event.defaultPrevented && !document.activeElement) ||
            document.activeElement === document.body ||
            this.isCanvasButton(document.activeElement)
        ) {
            if (
                this.props.worker.config?.onKeyDown &&
                this.props.worker.config?.onKeyDown(getParticleContext(), event)
            ) {
                // handled
            } else
                switch (event.key) {
                    case "q":
                        if ("development" === process.env.NODE_ENV && event.ctrlKey) {
                            this.props.worker.postMessage([
                                "QA",
                                {
                                    view: this.viewPort,
                                },
                            ]);
                        } else {
                            this.zoom(100, 100);
                        }
                        event.preventDefault();
                        break;
                    case "w":
                        event.preventDefault();
                        this.zoom(-100, 100);
                        break;
                    case "s": // CTRL+s
                        if (false && (event.ctrlKey || event.metaKey)) {
                            // disabled...
                            if (!this.state.const.readonly) {
                                this.props.worker.dispatchMessage([
                                    "cmd",
                                    {
                                        action: "stripeOverride",
                                    },
                                ]);
                            }
                            event.preventDefault();
                        }
                        break;
                    case "g": // CTRL+g
                        if (event.ctrlKey || event.metaKey) {
                            this.props.worker.dispatchMessage(["goto", "today"]);
                            event.preventDefault();
                        }
                        break;
                    case "b": // CTRL+b
                        if (event.ctrlKey || event.metaKey) {
                            this.props.worker.postMessage([
                                "view",
                                "status",
                                {
                                    view: null,
                                    options: {
                                        taktZoneImages: this.state.const.sidebarColImage > 0 ? false : true,
                                    },
                                },
                            ]);
                            event.preventDefault();
                        }
                        break;
                    case "5": // CTRL+5
                    case "6": // CTRL+6
                    case "7": // CTRL+7
                        if (event.ctrlKey || event.metaKey) {
                            this.props.worker.postMessage(["calendar", Number.parseInt(event.key)]);
                            event.preventDefault();
                        }
                        break;
                    case "k": // CTRL+k
                        if (event.ctrlKey || event.metaKey) {
                            this.props.worker.dispatchMessage(["toggle", "footer", { name: "canvas.kappa" }]);
                            event.preventDefault();
                        }
                        break;
                    case "m": // CTRL+m
                        if (event.ctrlKey || event.metaKey) {
                            this.props.worker.dispatchMessage(["toggle", "footer", { name: "canvas.milestones" }]);
                            event.preventDefault();
                        }
                        break;
                    case "Delete":
                        if (!this.state.const.readonly) {
                            this.props.worker.dispatchMessage([
                                "cmd",
                                {
                                    action: "delete",
                                },
                            ]);
                        }
                        event.preventDefault();
                        break;
                    case "d":
                        if (event.ctrlKey || event.metaKey) {
                            if (!this.state.const.readonly) {
                                this.props.worker.dispatchMessage([
                                    "cmd",
                                    {
                                        action: "dependency",
                                    },
                                ]);
                            }
                            event.preventDefault();
                        }
                        break;
                    case "x":
                        if (event.ctrlKey || event.metaKey) {
                            if (!this.state.const.readonly) {
                                this.props.worker.dispatchMessage([
                                    "cmd",
                                    {
                                        action: "cut",
                                    },
                                ]);
                            }
                            event.preventDefault();
                        }
                        break;
                    case "c":
                        if (event.ctrlKey || event.metaKey) {
                            this.props.worker.dispatchMessage([
                                "cmd",
                                {
                                    action: "copy",
                                },
                            ]);
                            event.preventDefault();
                        }
                        break;
                    case "v":
                        if (event.ctrlKey || event.metaKey) {
                            if (null !== this.viewPort._caretX && null !== this.viewPort._caretY) {
                                if (!this.state.const.readonly) {
                                    this.props.worker.dispatchMessage([
                                        "cmd",
                                        {
                                            action: "paste",
                                            caretX: this.viewPort._caretX,
                                            caretY: this.viewPort._caretY,
                                        },
                                    ]);
                                }
                            }
                            event.preventDefault();
                        }
                        break;
                    case "E":
                        if (event.ctrlKey && event.shiftKey && event.metaKey) {
                            this.props.worker.postMessage([
                                "export",
                                {
                                    target: "raw",
                                },
                            ]);
                            event.preventDefault();
                        }
                        break;
                    case "L":
                        if (event.ctrlKey && event.shiftKey && event.metaKey) {
                            console.log("Export log");
                            this.props.worker.postMessage([
                                "export",
                                {
                                    target: "log",
                                    cb: this.props.worker.registerCallback((data) => {
                                        downloadBuffer(data?.data, "LogExport.xlsx")
                                    })
                                }

                            ]);
                            event.preventDefault();
                        }
                        break;
                    case "C":
                        if (event.ctrlKey && event.shiftKey && event.metaKey) {
                            throw new Error("crash test");
                        }
                        break;
                }
            /*
            switch(event.keyCode) {
                case 81: // Q
                    event.preventDefault();
                    this.zoom(1);
                break;
                case 87: // W
                    event.preventDefault();
                    this.zoom(-1);
                break;
                case 37: // ArrowLeft
                    if (false && "development"!==process.env.NODE_ENV) { // not enabled yet...
                        this.props.worker.dispatchMessage(["key", {code: "left"}]);
                        event.preventDefault();
                    }
                    break;
                case 38: // ArrowUp
                    if (false && "development"!==process.env.NODE_ENV) { // not enabled yet...
                        this.props.worker.dispatchMessage(["key", {code: "up"}]);
                        event.preventDefault();
                    }
                    break;
                case 39: // ArrowRight
                    if (false && "development"!==process.env.NODE_ENV) { // not enabled yet...
                        this.props.worker.dispatchMessage(["key", {code: "right"}]);
                        event.preventDefault();
                    }
                    break;
                case 40: // ArrowDown
                    if (false && "development"!==process.env.NODE_ENV) { // not enabled yet...
                        this.props.worker.dispatchMessage(["key", {code: "down"}]);
                        event.preventDefault();
                    }
                    break;
            }
            */
        }
        if ("Control" !== event.key && (null !== this.viewPort._caretX || null !== this.viewPort._caretY)) {
            this.viewPort._caretX = null;
            this.viewPort._caretY = null;
            if (this.divRefs.SH) {
                this.divRefs.SH.style.visibility = null; // default from style
            }
        }
    }.bind(this);

    private onKeyUp = function (this: Canvas, event: KeyboardEvent) {
        if (!event.ctrlKey && this._CTRL_HACK) {
            /*
            if (0!==this.viewPort.translateX || 0!==this.viewPort.translateY) {
                //const left=-this.viewPort.translateX;
                //const top=-this.viewPort.translateY;
                this.viewPort.translateX=0;
                this.viewPort.translateY=0;
                this.viewPort.target_zoom=null;
                this.divRefs.CV.style.transform=null; // "translate("+this.viewPort.translateX+"px, "+this.viewPort.translateY+"px)";
                this.divRefs.CV.style.paddingLeft="0px";
                this.divRefs.CV.style.paddingTop="0px";
                //window.scrollTo(left, top);
                this.updateViewport();
            }
            */
        }
        this._CTRL_HACK = event.ctrlKey;
    }.bind(this);

    private _onWheel = function (this: Canvas, e: WheelEvent) {
        if (e.ctrlKey) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }.bind(this);
    private onViewChange = function (
        event: React.FormEvent<HTMLDivElement>,
        option?: fluentui.IDropdownOption<any>,
        index?: number,
    ) {
        if ("number" === typeof index) {
            this.props.worker.postMessage(["view", "select", index]);
        }
    }.bind(this);

    private onScroll = this.onViewChanged.bind(this, false);
    private onResize = this.onViewChanged.bind(this, true);
    private primaryTouch: number | null = null;
    private secondaryTouch: number | null = null;
    private _ahhh = [];

    private onTouchStart = function (ev) {
        if (2 === ev.touches.length) {
            ev.preventDefault();
        }
    }.bind(this);

    private onTouchMove = function (this: Canvas, ev: any) {
        this.onViewChanged(true, ev);
        if (2 === ev.touches.length) {
            ev.preventDefault();
        }
    }.bind(this);

    private onTouchEnd = function (ev) {
        /*
        if (2===ev.touches.length) {
            ev.preventDefault();
        }
        */
    }.bind(this);

    private onWheel = function (event) {
        if (event.ctrlKey) {
            if (this._CTRL_HACK) {
                //const dy=Math.sign(event.deltaY)*Math.max(Math.abs(event.deltaY/100), 1);
                //const step=Math.round(dy);
                const step = event.deltaY;
                this._clientZoomX = event.clientX;
                this._clientZoomY = event.clientY;
                this.zoom(step, 100);
            }
            event.stopPropagation();
            event.preventDefault();
        }
    }.bind(this);

    private onCoreEvent = function (
        this: Canvas,
        [messageType, eventData, coreEventName]: [string, CoreEventsCallbackData, CoreEventNames],
    ) {
        if (messageType !== "core::event") {
            return;
        }

        const isTakzzone = "taktzone" === this.props.sidebar.selected || "processview" === this.props.sidebar.selected;
        const isCanvasHost = "workshop" === this.props.sidebar.selected;
        const isDailyBoardHost = "dailyboard" === this.props.sidebar.selected;
        const isTabHost = "todo" === this.props.sidebar.selected || "settings" === this.props.sidebar.selected;
        const isDashboard = "dashboard" === this.props.sidebar.selected || this.state.meta?.error;
        const isProject = !(isTakzzone || isDashboard || isCanvasHost || isDailyBoardHost || isTabHost);

        if (
            coreEventName === "processes::startDateChanged::negativeEffect" ||
            coreEventName === "processes::durationChanged::negativeEffect"
        ) {
            if (isProject) {
                this.setState({
                    showReasonCodePopUp: true,
                });
                useCanvasStore.setState({ canvasEvent: { eventData: eventData, coreEventName } });
            }
        }

        if (coreEventName === "process::dependencyCreated::circularDependency") {
            this.setState({
                showCircularDependencyToast: true,
            });
            useCanvasStore.setState({ canvasEvent: { eventData: eventData, coreEventName } });
        }
    }.bind(this);
    private _onEditing = function (this: Canvas) {
        /*
        if (this.props.worker.isLicTrial()){
            this.props.worker.dispatchMessage(["framework", "betaPopup"]);
        } else {
            const session:WorkerSession=this.props.projectSandbox as any;
            if (session?.master_token || session?.resource?.token) {
                this.props.worker.dispatchMessage(["framework", "sandboxPopup", {
                    session: session,
                    view: 0
                } as SandboxPopupOptions]);
            }
        }
        */
    }.bind(this);
    private _onFilterClose = function (this: Canvas) {
        this.setState({
            filter: null,
        });
    }.bind(this);
    private _onTradeClose = function (this: Canvas) {
        this.setState({
            trade: null,
        });
    }.bind(this);
    private _onUserClose = function (this: Canvas) {
        this.setState({
            user: false,
        });
    }.bind(this);
    private _onAddPanelOpen = function (this: Canvas) {
        this.setState({
            addPanel: true,
        });
    }.bind(this);
    private _onAddPanelClose = function (this: Canvas) {
        this.setState({
            addPanel: false,
        });
    }.bind(this);
    private _onTaktZonesClose = function (this: Canvas) {
        this.setState({
            taktzones: false,
        });
    }.bind(this);
    private _onCardClose = function (this: Canvas) {
        this.setState({
            card: null,
        });
    }.bind(this);
    /*
    private onGestureStart=function(ev) {
        if (true) {
            const hack=document.getElementById("hack");
            hack.innerText="start";
        }
        if (true && null!==this.secondaryTouch) {
            ev.stopPropagation();
            ev.preventDefault();
        }
    }.bind(this)
    private onGestureChange=function(ev) {
        if (true) {
            const hack=document.getElementById("hack");
            hack.innerText="change";
        }
        if (true && null!==this.secondaryTouch) {
            ev.stopPropagation();
            ev.preventDefault();
        }
    }.bind(this)
    private onGestureEnd=function(ev) {
        if (true) {
            const hack=document.getElementById("hack");
            hack.innerText="end";
        }
        if (true && null!==this.secondaryTouch) {
            ev.stopPropagation();
            ev.preventDefault();
        }
    }.bind(this)
    */
    private _refCV = function (ref: HTMLDivElement) {
        this.divRefs.CV = ref;
        if (ref) this.updateViewport();
    }.bind(this);
    private _refSH = function (ref: HTMLDivElement) {
        this.divRefs.SH = ref;
    }.bind(this);

    /*
    private _hackTouchStartCount=0;
    private onTouchStart=function(ev) {
        assert(ev.targetTouches.length>0);
        this._putTouches(ev.targetTouches);
        if (false && null!==this.secondaryTouch) {
            ev.stopPropagation();
            ev.preventDefault();
        }
        this._updateHack();
        const hack=document.getElementById("hack");
        if (hack) {
            this._hackTouchStartCount++;
            hack.innerText=JSON.stringify([this._hackTouchStartCount, null!==this.secondaryTouch]);
        }
    }.bind(this)
    */

    /*
    private onTouchMove=function(ev) {
        //this._updateHack("move", ev.changedTouches.length);
        this.onViewChanged(true);
        this._putTouches(ev.changedTouches);
        if (false && null!==this.secondaryTouch) {
            ev.stopPropagation();
            ev.preventDefault();
        }
        this._updateHack();
    }.bind(this)
    */

    /*
    private onTouchEnd=function(ev) {
        const n=ev.changedTouches.length;
        let pinchEnded=false;
        for(let i=0;i<n;i++) {
            const t=ev.changedTouches[i];
            const tid=t.identifier;
            this._ahhh.push("end("+tid+")");
            if (tid===this.primaryTouch) {
                this.primaryTouch=null;
                if (null!==this.secondaryTouch) {
                    this.primaryTouch=this.secondaryTouch;
                    this.secondaryTouch=null;
                    pinchEnded=true;
                }
            } else if (tid===this.secondaryTouch) {
                assert(null!==this.primaryTouch);
                this.secondaryTouch=null;
                pinchEnded=true;
            } else {
                // more than 2 fingers?!
            }
        }
        if (false) {
            const vp=this.divRefs.viewport;
            vp.setAttribute("content", "width=device-width, height=device-height, initial-scale=1, maximum-scale=2, minimum-scale=0.5, user-scalable=yes");
        }
        this._updateHack(pinchEnded);
        if (false && null!==this.secondaryTouch) {
            ev.stopPropagation();
            ev.preventDefault();
        }
    }.bind(this)
*/

    public static updateViewSync(
        view: CanvasViewPort,
        sync: {
            syncCommited: number;
            commited: number;
        },
    ) {
        const _sync = view.sync;
        _sync.commited = Math.max(_sync.commited, sync.commited);
        _sync.syncCommited = Math.max(_sync.syncCommited, sync.syncCommited);
    }

    public static _ensureTargetZoom(
        view: CanvasViewPort,
        worker: MainWorkerPipe,
        state: {
            const: CanvasViewConst;
            l_min: number;
            l_max: number;
        },
        targetZoom: {
            clientX: number;
            clientY: number;
            scale: number;
        },
    ) {
        if (0 === view.scaling_lock) {
            view.target_zoom = null;
            if (targetZoom) {
                view.scaling_lock++;
                const C = state.const;
                const scale0 = view.scale;
                const scale = targetZoom.scale;
                const cx = targetZoom.clientX - view.rowHeaderWidth;
                const cy = targetZoom.clientY - view.colHeaderHeight;
                const cx_ = cx;
                const cy_ = cy;
                const x = (view.left + targetZoom.clientX - view.rowHeaderWidth) / scale0;
                const y = (view.top + targetZoom.clientY - view.colHeaderHeight) / scale0;
                const x_ = x * scale - cx;
                const y_ = y * scale - cy;
                worker.dispatchMessage([
                    "zoom",
                    {
                        scale: scale,
                        left: x_,
                        top: y_,
                    },
                ]);
            }
        } else {
            view.target_zoom = targetZoom;
        }
    }

    _registerEventHandler(this: Canvas) {
        window.addEventListener("scroll", this.onScroll, detectIt.supportsPassiveEvents ? { passive: true } : false);
        window.addEventListener("resize", this.onResize, detectIt.supportsPassiveEvents ? { passive: true } : false);
        window.addEventListener(
            "touchstart",
            this.onTouchStart,
            detectIt.supportsPassiveEvents ? { passive: false } : false,
        );
        window.addEventListener(
            "touchcancel",
            this.onTouchEnd,
            detectIt.supportsPassiveEvents ? { passive: false } : false,
        );
        window.addEventListener(
            "touchend",
            this.onTouchEnd,
            detectIt.supportsPassiveEvents ? { passive: false } : false,
        );
        window.addEventListener(
            "touchmove",
            this.onTouchMove,
            detectIt.supportsPassiveEvents ? { passive: false } : false,
        );
        document.body.addEventListener("keydown", this.onKeyDown);
        document.body.addEventListener("keyup", this.onKeyUp);

        /*
        window.addEventListener('gesturestart', this.onGestureStart, {passive:false});
        window.addEventListener('gesturechange', this.onGestureChange, {passive:false});
        window.addEventListener('gestureend', this.onGestureEnd, {passive:false});
        */
        window.addEventListener("wheel", this._onWheel);
        document.addEventListener("wheel", this._onWheel, detectIt.supportsPassiveEvents ? { passive: false } : false);
        document.body.addEventListener(
             "wheel",
            this._onWheel,
            detectIt.supportsPassiveEvents ? { passive: false } : false,
        );
        this.divRefs.root.addEventListener("wheel", this.onWheel);

        this.unsubscribeCanvasStore = useCanvasStore.subscribe((state) => {
            if (!this.isCanvasRendered && state.initiallyRendered) {
                this.isCanvasRendered = true;

                this.props.worker.dispatchMessage([
                    "goto",
                    {
                        pid: state.processId,
                        scale: 1,
                    },
                ]);
            }
        });
    }

    _unregisterEventHandler(this: Canvas) {
        this.divRefs.root.removeEventListener("wheel", this.onWheel);
        document.body.removeEventListener("wheel", this._onWheel);
        document.removeEventListener("wheel", this._onWheel);
        window.removeEventListener("wheel", this._onWheel);
        document.body.removeEventListener("keyup", this.onKeyUp);
        document.body.removeEventListener("keydown", this.onKeyDown);

        window.removeEventListener("touchmove", this.onTouchMove);
        window.removeEventListener("touchend", this.onTouchEnd);
        window.removeEventListener("touchcancel", this.onTouchEnd);
        window.removeEventListener("touchstart", this.onTouchStart);
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("scroll", this.onScroll);

        // this.unsubscribeCanvasStore();
        // this.isCanvasRendered = false;
    }

    componentDidMount(this: Canvas) {
        (async () => {
            this.props.worker.registerHandler(this.onWorkerMsg);
            this.props.worker.registerHandler(this.onCoreEvent);

            await this.props.worker.loadProject(this.props.projectId, this.props.projectSandbox);
            this._registerEventHandler();
            if ((window as any).lcmdigital) {
                // expose API
                (window as any).lcmdigital.canvas = {
                    view: this.viewPort,
                };
            }
            this.updateViewport();
            this.props.worker.postMessage(["canvas", {}]);
        })()
    }

    componentDidUpdate(
        this: Canvas,
        prevProps: Readonly<CanvasProps>,
        prevState: Readonly<CanvasState>,
        snapshot?: any,
    ) {
        this.updateViewport();

        if (this.props.sidebar.selected !== "project" && prevProps.sidebar.selected === "project") {
            this.setState({
                showReasonCodePopUp: false,
            });
        }
        if (this.state.showCircularDependencyToast) {
            this.setState({
                showCircularDependencyToast: false,
            });
        }
    }

    componentWillUnmount(this: Canvas) {
        this._unregisterEventHandler();
        this.props.worker.unregisterHandler(this.onWorkerMsg);
        this.props.worker.unregisterHandler(this.onCoreEvent);
        this.props.worker.shutdownWorker();
    }

    onViewChanged(this: Canvas, resize: boolean, event: any) {
        /*
        console.log("onViewChanged");
        const hack=document.getElementById("hack");
        if (hack) {
            this._onViewChangedCounter++;
            hack.innerText=window.innerWidth+" "+window.innerHeight+"["+this._onViewChangedCounter+"]"+"\n"+
                           window.outerWidth+" "+window.outerHeight+"\n"+
                           (window as any).visualViewport.width+" "+(window as any).visualViewport.height+"\n"+
                           document.body.clientWidth+" "+document.body.clientHeight;
        }
        */
        if (document.activeElement && document.activeElement.parentElement?.parentElement?.id?.startsWith("_T")) {
            (document.activeElement as HTMLElement).blur();
        }
        this.updateViewport();
    }

    render(this: Canvas) {
        const state = this.state;
        const scale = this.viewPort.scale;
        const C = this.state.const;
        const cols = state?.cols || 0;
        const rows = state?.rows || 0;
        const width = cols * C.colPx;
        const height = rows * C.rowPx;
        const _width =
            width * scale +
            CanvasSizeHelper.getHeaderWidth(C, this.state.l_min, this.state.l_max) +
            (this.viewPort.inf2ColsBefore + this.viewPort.inf2ColsAfter) * C.colPx * scale;
        const _height =
            height * scale + CanvasSizeHelper.getHeaderHeight(C) + this.viewPort.inf2Rows * C.rowPx * 2 * scale;
        const isTakzzone = "taktzone" === this.props.sidebar.selected || "processview" === this.props.sidebar.selected;
        const isCanvasHost = "workshop" === this.props.sidebar.selected;
        const isDailyBoardHost = "dailyboard" === this.props.sidebar.selected;
        const isTabHost = "todo" === this.props.sidebar.selected || "settings" === this.props.sidebar.selected;
        const isDashboard = "dashboard" === this.props.sidebar.selected || this.state.meta?.error;
        const isProject = !(isTakzzone || isDashboard || isCanvasHost || isDailyBoardHost || isTabHost);

        return (
            this.state.loaded ? <>
                <CanvasHostContainer
                    key="pphost"
                    mode="canvas"
                    view={this.viewPort}
                    viewConst={this.state.const}
                    meta={this.state.meta}
                    width={_width}
                    height={_height}
                    {...this.props}
                    wb={this.state.wb}
                    hidden={!isProject}
                    onCanvasTaktzoneClicked={this.onCanvasTaktzoneClicked}
                    loaded={this.state.loaded}
                />
                {!isProject ? (
                isDashboard ? (
                <CanvasDashboard key="dashboard" {...this.props} view={this.viewPort} meta={this.state.meta} />
                ) : isTakzzone ? (
                <CanvasHostContainer
                    key="processview"
                    mode="tz"
                    view={this.viewPort}
                    viewConst={this.state.const}
                    meta={this.state.meta}
                    width={_width}
                    height={_height}
                    {...this.props}
                    wb={this.state.wb}
                    onCanvasTaktzoneClicked={this.onCanvasTaktzoneClicked}
                    loaded={this.state.loaded}
                />
                ) : isCanvasHost ? (
                <CanvasHostContainer
                    key="host"
                    mode="wb"
                    view={this.viewPort}
                    viewConst={this.state.constWB}
                    meta={this.state.meta}
                    width={_width}
                    height={_height}
                    {...this.props}
                    wb={this.state.wb}
                    onCanvasTaktzoneClicked={this.onCanvasTaktzoneClicked}
                    loaded={this.state.loaded}
                        />
                    ) : null
                ) : null}
                {
                    <CanvasDailyBoardHost
                        key="db"
                        view={this.viewPort}
                        meta={this.state.meta}
                        width={_width}
                        height={_height}
                        {...this.props}
                        visible={isDailyBoardHost}
                    />
                }
                {isTabHost ? (
                    <CanvasTabHost
                        key={this.props.sidebar.selected}
                        view={this.viewPort}
                        meta={this.state.meta}
                        width={_width}
                        height={_height}
                        {...this.props}
                        visible={isTabHost}
                    />
                ) : null}
                <CanvasStatusHeader
                    key={1}
                    view={this.viewPort}
                    meta={this.state.meta}
                    {...this.props}
                    onEditing={this._onEditing}
                    hidden={!isProject}
                />
                <CanvasSidebarHost
                    key="CSC"
                    view={this.viewPort}
                    meta={this.state.meta}
                    wb={this.state.wb}
                    divRefs={this.divRefs}
                    {...this.props}
                    hidden={!("project" === this.props.sidebar.selected || "workshop" === this.props.sidebar.selected)}
                >
                    <CanvasOverlay
                        key="AO"
                        view={this.viewPort}
                        meta={this.state.meta}
                        {...this.props}
                        handleAddPanelOpen={this._onAddPanelOpen}
                    />
                    {/*<CanvasTasksSidebar id="task" y={5} h={100} tab={intl.get("canvas.tab.process.text")} {...this.props} view={this.viewPort} divRefs={this.divRefs}/>*/}
                    {/*<CanvasProgressSidebar id="progress" y={5+1*100} h={100} tab="Progress" {...this.props} view={this.viewPort} divRefs={this.divRefs}/>*/}
                    {/*<CanvasTaskHistorySidebar id="history" y={5+1*100} h={100} tab={intl.get("canvas.tab.history.text")} {...this.props} view={this.viewPort} divRefs={this.divRefs}/>*/}
                </CanvasSidebarHost>
                <CanvasAddPanel
                    key="CAP"
                    {...this.props}
                    isOpen={!isDailyBoardHost && !isDashboard && !isTabHost && this.state.addPanel}
                    onClose={this._onAddPanelClose}
                />
                <CanvasChangesPanel key="CXP" view={this.viewPort} {...this.props} divRefs={this.divRefs} />
                {/*//"development"!==process.env.NODE_ENV?<CanvasCollaboratorsOverlay key="UO"*/}
                {/*                                                                   view={this.viewPort} {...this.props}*/}
                {/*                                                                   divRefs={this.divRefs} />:null,*/}
                {/*/!*<CanvasSidebarOverlay key="SO" view={this.viewPort} {...this.props} divRefs={this.divRefs} />,*!/*/}
                {/*//<CanvasHistoryOverlay key="HO" view={this.viewPort} {...this.props} divRefs={this.divRefs} />,*/}
                <CanvasDragOverlay
                    key="CO"
                    view={this.viewPort}
                    meta={this.state.meta}
                    {...this.props}
                    divRefs={this.divRefs}
                    hidden={!isProject}
                />
                {/*//<REMOVE_ME_CanvasCardsPopup key="CCP" {...this.props} />,*/}
                {/*//<CanvasTasksPopup key="CTP" {...this.props} />,*/}
                {/*//<CanvasCommitPopup key="CMP" {...this.props} />,*/}
                {/*//<CanvasDependencyPopup key="CDP" {...this.props} />,*/}
                {/*//<CanvasNotificationsPopup key="CNP" {...this.props} />,*/}
                <CanvasPopup
                    key="CP"
                    view={this.viewPort}
                    meta={this.state.meta}
                    {...this.props}
                    divRefs={this.divRefs}
                />
                {isProject && this.state.showReasonCodePopUp ? (
                    <ReasonDisturbance
                        onUndo={() => this.props.worker.postMessage(["undo", {}])}
                        onClose={() => this.setState({ showReasonCodePopUp: false })}
                        show={state.showReasonCodePopUp}
                    />
                ) : null}
                {isProject && this.state.showCircularDependencyToast
                    ? CopyToaster(
                          {
                              title: intl.get("DependencyDetails.circularDependency.title"),
                              text: intl.getHTML("DependencyDetails.circularDependency.text"),
                              // link: "https://lcmdigital.zendesk.com/hc/de/articles/12420163695132"
                          },
                          {
                              closeButton: true,
                              position: "bottom-right",
                              autoClose: 5000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: false,
                              theme: "light",
                          },
                      )
                    : null}
                {isProject && this.state.loaded && <EmbeddedNPSModal />}
                {isProject && <ProcessPlanToolbar />}
                {/*//<CanvasModalStripeFilter key="CMSF" {...this.props} view={this.viewPort} divRefs={this.divRefs} />,*/}
                {this.state.user ? (
                    "function" === typeof this.props.worker.config?.ShareDialog ? (
                        this.state.user ? (
                            <this.props.worker.config.ShareDialog
                                key="CUD"
                                {...this.props}
                                onClose={this._onUserClose}
                                meta={this.state.meta}
                            />
                        ) : null
                    ) : null
                ) : null}
                {"function" === typeof this.props.worker.config?.filterDialog ? (
                    <this.props.worker.config.filterDialog
                        key="NFD"
                        isOpen={this.state.filter}
                        onClose={this._onFilterClose}
                    />
                ) : null}
                {"function" === typeof this.props.worker.config?.TradesSelectionDialog ? (
                    <this.props.worker.config.TradesSelectionDialog
                        key="TSD"
                        isOpen={this.state.trade}
                        onClose={this._onTradeClose}
                    />
                ) : null}
                {this.state.taktzones ? (
                    "function" === typeof this.props.worker.config?.ProcessViewDialog ? (
                        <this.props.worker.config.ProcessViewDialog
                            key="PVD"
                            isOpen={this.state.taktzones}
                            {...this.props}
                            onClose={this._onTaktZonesClose}
                            readonly={this.state.const.readonly}
                        />
                    ) : null
                ) : null}
                {"function" === typeof this.props.worker.config?.CardDialog ? (
                    <this.props.worker.config.CardDialog key="CCD" onClose={this._onCardClose} card={this.state.card} />
                ) : null}
                {isProject && this.state?.taktzoneModal?.isOpen && (
                    <AreaListPopup
                        isOpen={this.state?.taktzoneModal?.isOpen}
                        taktzone={
                            this.state.taktzoneModal && this.state.taktzoneModal.selectedTaktzone
                                ? this.state.taktzoneModal.selectedTaktzone
                                : 0
                        }
                        onClose={this.onCloseCanvasAreaListModal}
                    />
                )}
            </> : <>                <CanvasPopup key="CP" view={this.viewPort} meta={this.state.meta} {...this.props}
                                                 divRefs={this.divRefs} /></>);
    }

    private onCanvasTaktzoneClicked = (taktzone: number) => {
        console.log("canvas taktzoneclicked", taktzone);
        this.setState({
            taktzoneModal: {
                isOpen: true,
                selectedTaktzone: taktzone,
            },
        });
    };

    private onCloseCanvasAreaListModal = () => {
        this.setState({
            taktzoneModal: {
                isOpen: false,
                selectedTaktzone: 0,
            },
        });
    };

    private isCanvasButton(e: Element) {
        if (e && Node.ELEMENT_NODE === e.nodeType && "BUTTON" === e.nodeName.toUpperCase()) {
            return true;
        } else {
            return false;
        }
    }

    private updateViewport(this: Canvas, sendMessage?: boolean): void {
        let ret = false;
        const viewPort = this.viewPort;
        if (this.divRefs.CV /*&& this.divRefs.RH && this.divRefs.CH */) {
            const colHeaderHeight = this.divRefs.CH ? this.divRefs.CH.getBoundingClientRect().height : 0;
            //const rh=this.divRefs.RH.getBoundingClientRect();
            //const colHeaderHeight=ch.height;
            const rowHeaderWidth = CanvasSizeHelper.getHeaderWidth(
                this.state.const,
                this.state.l_min,
                this.state.l_max,
            ); //@TODO use DIV and getBoundingClientRect
            const r = this.divRefs.CV.getBoundingClientRect();
            const left = -r.left;
            const top = -r.top;
            /*
            const screen_width=this.divRefs.body.clientWidth;
            const screen_height=this.divRefs.body.clientHeight;
            */
            /*
             const ratio = window.devicePixelRatio || 1;
             const screen_width = Math.ceil(screen.width / ratio);
             const screen_height = Math.ceil(screen.height / ratio);
             */
            /*
             const b=this.divRefs.body.getBoundingClientRect();
             const screen_width=b.width;
             const screen_height=b.height;
             */
            /*
            const screen_width=window.innerWidth;
            const screen_height=window.innerHeight;
            */
            const vp = (window as any)?.visualViewport;
            const screen_width = vp?.width || document.body.clientWidth;
            const screen_height = vp?.height || document.body.clientHeight;
            const width = screen_width - rowHeaderWidth;
            const height = screen_height - colHeaderHeight;

            if (
                viewPort.colHeaderHeight !== colHeaderHeight ||
                viewPort.rowHeaderWidth !== rowHeaderWidth ||
                viewPort.left !== left ||
                viewPort.top !== top ||
                viewPort.width !== width ||
                viewPort.height !== height
            ) {
                viewPort.colHeaderHeight = colHeaderHeight;
                viewPort.rowHeaderWidth = rowHeaderWidth;
                viewPort.left = left;
                viewPort.top = top;
                viewPort.width = width;
                viewPort.height = height;
                ret = true;
            }
        } else {
            if (
                viewPort.colHeaderHeight !== 0 ||
                viewPort.rowHeaderWidth !== 0 ||
                viewPort.left !== 0 ||
                viewPort.top !== 0 ||
                viewPort.width !== 0 ||
                viewPort.height !== 0
            ) {
                viewPort.colHeaderHeight = 0;
                viewPort.rowHeaderWidth = 0;
                viewPort.left = 0;
                viewPort.top = 0;
                viewPort.width = 0;
                viewPort.height = 0;
                ret = true;
            }
        }
        if (false && this.viewPort.mouseDown?.task && this.divRefs.DEBUG) {
            const r = this.viewPort.mouseDown.target;
            const t = this.viewPort.mouseDown.task;
            assert("fixed" === r.style.position);
            const left = Number.parseFloat(r.style.left);
            const top = Number.parseFloat(r.style.top);
            const w = t.right - t.left;
            t.left = this.viewPort.left + left - this.viewPort.rowHeaderWidth;
            t.right = t.left + w;
            t.top = this.viewPort.top + top - this.viewPort.colHeaderHeight;
            if (false && this.divRefs.DEBUG) {
                const C = this.state.const;
                const debug = this.divRefs.DEBUG;
                debug.style.display = "block";
                debug.style.position = "absolute";
                debug.style.left = t.left + "px";
                debug.style.top = t.top + "px";
                debug.style.width = t.right - t.left + "px";
                debug.style.height = C.rowPx + "px";
                debug.style.backgroundColor = "yellow";
            }
        }
        if ((ret && false !== sendMessage) || true === sendMessage) {
            this.props.worker.dispatchMessage(["view", viewPort]);
        }
    }

    private _putTouches(touches) {
        const n = touches.length;
        for (let i = 0; i < n; i++) {
            const t = touches[i];
            const tid = t.identifier;
            this._ahhh.push("start(" + tid + ")");
            if (tid === this.primaryTouch) {
                // nothing
            } else if (tid === this.secondaryTouch) {
                assert(null !== this.primaryTouch);
                // nothing
            } else if (null === this.primaryTouch) {
                this.primaryTouch = tid;
            } else if (null === this.secondaryTouch) {
                assert(null !== this.primaryTouch);
                this.secondaryTouch = tid;
            } else {
                // more than 2 touches?...
            }
        }
    }

    private zoom(this: Canvas, step: number, maxSteps: number) {
        const clientX = this.viewPort.clientZoomX;
        const clientY = this.viewPort.clientZoomY;
        if (null !== clientX && null !== clientY) {
            //const scale0=this.viewPort.scale;
            //const target_scale_step=this.viewPort.target_zoom?this.viewPort.target_zoom.scale_step:this.viewPort.scale_step;
            //const scale_step=target_scale_step-step;
            //const scale=Canvas.calcZoom(scale_step);
            const _scale = this.viewPort.target_zoom ? this.viewPort.target_zoom.scale : this.viewPort.scale;
            //assert(100===maxSteps);
            const scale = Math.max(
                CONST.canvasZoomMin,
                Math.round(_scale * 1000) / 1000 - (_scale > 1 ? 0.25 : 0.1) * (step / maxSteps),
            );
            if (_scale !== scale) {
                Canvas._ensureTargetZoom(this.viewPort, this.props.worker, this.state, {
                    clientX: clientX,
                    clientY: clientY,
                    scale: scale,
                });
            }
        }
    }

    private _updateView(this: null, canvas: any, viewPort: CanvasViewPort | null, target: Canvas, state: CanvasState) {
        viewPort = viewPort || target.viewPort;
        const scale = viewPort.scale || state.scale || 1;
        const C = canvas?.viewConst || state.const;
        const cols = gfu(canvas?.cols, state.cols, 0);
        const rows = gfu(canvas?.rows, state.rows, 0);
        const l_min = gfu(canvas?.l_min, state.l_min, 0);
        const l_max = gfu(canvas?.l_max, state.l_max, 0);
        const mode = state.mode || "month";
        const meta = canvas?.meta || state.meta;
        const wb = canvas?.wb || state.wb;
        if (
            scale !== state.scale ||
            cols !== state.cols ||
            rows !== state.rows ||
            l_min !== state.l_min ||
            l_max !== state.l_max ||
            C !== state.const ||
            meta !== state.meta ||
            wb !== state.wb
        ) {
            return {
                scale: scale,
                const: C,
                cols: cols,
                rows: rows,
                l_min: l_min,
                l_max: l_max,
                mode: mode,
                meta: meta,
                wb: wb,
                loaded: true,
            };
        } else {
            return null;
        }
    }
}
