import { useEffect, useState } from "react";
import {
    SidebarProvider,
    Sidebar,
    SidebarContent,
    SidebarMenu,
    SidebarMenuItem,
    SidebarMenuButton,
} from "@/components/ui/sidebar";
import * as React from "react";
import { CalendarSettingsView } from "./settings/calendar-settings/CalendarSettings.view.component";
import { StabilityCriteriaSettingsView } from "./settings/stability-criteria";
import { PdfSettingsView } from "./settings/pdf-settings/PdfSettings.view.component";
import { intl } from "lcmd2framework";
import { ICustomizedNavLink, ICustomizedNavLinkGroup } from "./interface";
import { ChangesSettings } from "./settings/changes-settings/changes-settings";
import { useCalendarStore } from "@/app/store/saveCalendarSettings";
import { saveSettings } from "@/utils/SettingsUtils";
import { cn } from "@/lib/utils";

export function Settings({
    navigationOptions,
    subView = "lcmd.settings.pdf",
}: {
    navigationOptions?: ICustomizedNavLinkGroup[] | undefined;
    subView?: string;
}) {
    const calendarSettingsStore = useCalendarStore();
    if (navigationOptions === undefined) {
        navigationOptions = [
            {
                links: [
                    {
                        name: intl.get("Settings.PDFExport"),
                        key: "lcmd.settings.pdf",
                        url: "#",
                        view: <PdfSettingsView />,
                    },
                    {
                        name: intl.get("Settings.Calender"),
                        key: "lcmd.settings.calendar",
                        //TODO: make static text variable
                        url: "#",
                        view: <CalendarSettingsView />,
                    },
                    {
                        name: intl.get("Settings.StabilityCriteria"),
                        key: "lcmd.settings.stability-criteria",
                        url: "#",
                        view: <StabilityCriteriaSettingsView />,
                    },
                    {
                        name: intl.get("ReasonCodesSettings.headline"),
                        key: "lcmd.settings.changes-settings",
                        url: "#",
                        view: <ChangesSettings />,
                    },
                ],
            },
        ];
    }
    const selectedIndex = (naviOptions: ICustomizedNavLinkGroup[], subViewName: string): ICustomizedNavLink => {
        const view = naviOptions[0].links.find((l) => l.key === subViewName);
        if (!view) {
            console.error(`subView doesn't exist redirect to '${subView}'!`);
        }
        return view ? view : naviOptions[0].links[0];
    };

    const [activeSetting, setActiveSetting] = useState(selectedIndex(navigationOptions, subView));
    useEffect(() => {
        setActiveSetting(selectedIndex(navigationOptions, subView));
    }, [subView]);

    const navItemSelected = (item) => {
        if (activeSetting.key == "lcmd.settings.calendar") {
            const calendarSettings = calendarSettingsStore.calendarSettings;
            saveSettings(calendarSettings);
        }
        setActiveSetting(item);
    };

    return (
        <>
            <div className="flex w-full flex-row">
                <div>
                    <SidebarProvider>
                        <Sidebar>
                            <SidebarContent className="box-border h-screen min-w-[240px] overflow-y-auto border border-solid border-gray-200 bg-white p-2.5 pt-14">
                                <SidebarMenu>
                                    {navigationOptions[0].links.map((item) => (
                                        <SidebarMenuItem
                                            key={item.key}
                                            className={activeSetting.key === item.key && "rounded-[6px] bg-sky-200"}
                                        >
                                            <SidebarMenuButton asChild className="rounded-[6px]">
                                                <a href={item.url} onClick={() => navItemSelected(item)}>
                                                    <span>{item.name}</span>
                                                </a>
                                            </SidebarMenuButton>
                                        </SidebarMenuItem>
                                    ))}
                                </SidebarMenu>
                            </SidebarContent>
                        </Sidebar>
                    </SidebarProvider>
                </div>

                <div className={cn("relative", "w-full", activeSetting.description?.length > 0 && "my-2.5")}>
                    <div
                        className={cn(
                            "flex",
                            "border-b",
                            "border-solid",
                            "border-gray-200",
                            "min-h-16",
                            "px-5",
                            !activeSetting.description && "items-center",
                        )}
                    >
                        <div className="flex flex-row align-middle font-semibold">
                            <p className="text-xl">{activeSetting.name}</p>
                        </div>
                        {activeSetting.description?.length > 0 && (
                            <p className="max-w-[580px] pb-5">{activeSetting.description}</p>
                        )}
                    </div>
                    {activeSetting.view}
                </div>
            </div>
        </>
    );
}
