import * as React from "react";
import * as ReactDOM from "react-dom";
import { matchPath } from "react-router";
import { Canvas } from "./components/Canvas/Canvas";
import { WorkerSession } from "../model/DataModel";
import { intl } from "./GlobalHelperReact";
import * as queryString from "query-string";
import { CONST } from "./settings";
import { initServices, SERVICES } from "../model/services";
import { PartialTheme, ThemeProvider } from "@fluentui/react";
import { DigitalPlanningBoardConfig } from "lcmd2framework";
import { _setParticleContext, getParticleContext } from "./api/ParticleContext";
import DigitalPlanningBoard from "./components/DigitalPlanningBoard";
import { MainWorkerPipe } from "./MainWorkerPipe";
import { showScreenOfDeath } from "./ScreenOfDeath";
import { UtilsContainer } from "../app/components/UtilsContainer";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

export async function initIntl(
    config: { locales: { [locale: string]: any } },
    nav?: {
        currentLocale: string;
        fallbackLocale: string;
    },
): Promise<true> {
    const requestedLocale = nav?.currentLocale || (navigator?.languages || [])[0] || "en-US";
    const requestLang = requestedLocale.split("-")[0];
    const _rawProfile = {};
    const locales = { ...(config?.locales || {}), debug: _rawProfile };
    const currentLocale = Object.getOwnPropertyNames(locales).find((l) => l.startsWith(requestLang)) || "en-US";
    console.log("LOCALE " + currentLocale + " (" + requestedLocale + " requested)");
    const fallbackLocale = nav?.fallbackLocale || ("development" === process.env.NODE_ENV ? "debug" : "en-US");
    await intl.init({
        currentLocale: currentLocale,
        locales: locales,
        fallbackLocale: fallbackLocale,
        /*intlGetHook: (key, locale)=>{
            return key;
        }*/
    } as any);
    if ("debug" === currentLocale || "debug" === fallbackLocale) {
        const getDescendantProp = (intl as any).getDescendantProp;
        (intl as any).getDescendantProp = function (locale, key) {
            if (_rawProfile === locale) {
                return "'{'" + key + "'}'";
            } else {
                const ret = getDescendantProp(locale, key);
                return ret && ret.length > 0 ? ret : null;
            }
        };
    }
    return true;
}

export function initExtensions(config: any, nav: any, auth_warmup: any, cb: (e: Error) => void) {
    (async () => {
        try {
            const item = localStorage.getItem("lcm.dev.sso");
            if ("string" === typeof item) {
                console.log("lcm.dev.sso=" + item);
                const value = JSON.parse(item);
                if (value?.domain && auth_warmup) {
                    auth_warmup.sso = value;
                }
            }
        } catch (e) {
            console.warn(e);
        }
        // init i18n
        await initIntl(config, nav);
    })()
        .then(() => {
            cb(null);
        })
        .catch((e) => {
            cb(e);
        });
}

function initReactRoot(ctx: { nav: any; pipe: MainWorkerPipe }) {
    const target = document.getElementById("root");
    if (target) {
        //console.log("initReactRoot "+JSON.stringify(ctx));

        if (ctx.nav.procore) {
            CONST.titleHeight = 0; //HACK => remove titleBar...
            const session: WorkerSession & any = {
                pid: -1, // "00000000-0000-0000-0000-000000000000",
                procore: {
                    //email: "florian.reuter@lcmdigital.com",
                    project_id: ctx.nav.project_id,
                    company_id: ctx.nav.company_id,
                },
            };
            initExtensions(ctx.pipe.config, ctx.nav, {}, (e) => {
                if (e) {
                    this.setState({
                        error: "Error initializing locales.",
                    });
                } else {
                    const canvas = (
                        <Canvas
                            key="canvas"
                            worker={ctx.pipe}
                            projectId={session.pid}
                            projectSandbox={session}
                            /*readonly={nav.session.sandbox && !this.props.worker.isLicTrial()?0!==(nav.session?.sandbox_ts||0):true}*/ sidebar={{
                                selected: "project",
                            }}
                            filesOverlay={false}
                        />
                    );
                    //ReactDOM.unstable_createRoot(target).render(canvas);
                    ReactDOM.render(canvas, target);
                }
            });
        } else {
            const theme: PartialTheme = {
                components: {
                    Modal: {
                        styles: {
                            main: {
                                borderRadius: "8px",
                            },
                            scrollableContent: {
                                selectors: {
                                    "& .modalBody": {
                                        padding: "0px",
                                    },
                                },
                            },
                        },
                    },
                    Dialog: {
                        styles: {
                            main: {
                                borderRadius: "8px",
                            },
                            scrollableContent: {
                                padding: "16px 32px",
                            },
                        },
                    },
                },
            };

            ReactDOM.render(
                <ThemeProvider theme={theme}>
                    <DigitalPlanningBoard nav={ctx.nav} worker={ctx.pipe} />
                    <UtilsContainer />
                </ThemeProvider>,
                target,
            );
            /*if (false && null!==ctx.puid) {
                ctx.pipe.postMessage(["init", {
                    puid:ctx.puid,
                    key:ctx.key,
                    rev:ctx.rev
                }]);
            }*/
        }
    }
}

function initServiceWorker(cb: () => void) {
    /* @TODO: updgrade kill old service worker!
    navigator.serviceWorker.register('/serviceWorker.js').then((reg)=>{
        const serviceWorker=reg.installing || reg.waiting || reg.active;
        if (serviceWorker) {
            if ("activated"===serviceWorker.state) {
                cb();
            } else {
                const ctx={
                    cb,
                    self:null as any
                };
                const onStateChange=()=>{
                    if ("activated"===serviceWorker.state) {
                        if (ctx.self) {
                            serviceWorker.removeEventListener("statechange", ctx.self);
                            ctx.self=null;
                        }
                        if (ctx.cb) {
                            const cb=ctx.cb;
                            ctx.cb=null;
                            cb();
                        }
                    }
                };
                ctx.self=onStateChange;
                serviceWorker.addEventListener("statechange", ctx.self);
                onStateChange();
            }
        } else {
            console.error("no service worker");
        }
    }).catch((e)=>{
        console.error(e);
    });
    */
    cb();
}

export function init(
    config: {
        _canary?: boolean | "dev";
    } & DigitalPlanningBoardConfig<any>,
) {
    window.addEventListener(
        "error",
        function (event) {
            console.error(event);
            showScreenOfDeath(event.error, event);
            event.preventDefault();
        },
        false,
    );

    window.addEventListener(
        "unhandledrejection",
        function (event) {
            console.error(event);
            showScreenOfDeath(event.reason, event);
            event.preventDefault();
        },
        false,
    );
    initServiceWorker(() => {
        if (false && "serviceWorker" in navigator) {
            /*
            navigator.serviceWorker.register('/worker.js').then(function (registration) {
                // Registration was successful
                console.debug('ServiceWorker registration successful with scope: ', registration.scope);
                navigator.serviceWorker.ready.then( registration => {
                    const target=document.getElementById("root");
                    if (target) {
                        const pipe=new MainWorkerPipe(registration.active as ServiceWorker);
                        pipe.postMessage(["init", {}]);
                        ReactDOM.render(<DigitalPlanningBoard worker={pipe}/>, target);
                    }
                });
            }, function (err) {
                // registration failed :(
                console.error('ServiceWorker registration failed: ', err);
            });
            */
        } else if (true && window.Worker) {
            const props: any = {
                FORCE_CANARY: config._canary,
                FORCE_PROD: false, // probably never used, check if can be removed
            };
            console.log({ info: "INIT_SERVICE", props, _canary: config?._canary });
            {
                console.log(window.location);
                const location = window.location;

                if (location.pathname.startsWith("/workshop-")) {
                    props["promo"] = location.pathname.substring(10);
                    console.log(props);
                }

                let href = location.href;
                const i_query = href.indexOf("?");
                const isLoginComponent = location.hash.startsWith("#/auth"); // keep query parameters for login component
                
                if (location.hash.includes("signUp=")){
                    WebAppTelemetryFactory.trackEvent("invite-link-clicked");
                }

                if (i_query >= 0 && !isLoginComponent) {
                    const i_hash = href.indexOf("#");
                    href =
                        href.substring(0, i_query) +
                        "#" +
                        href.substring(i_query + 1, i_hash >= 0 ? i_hash : href.length);
                    window.history.replaceState({}, null, href);
                }

                const i_hash = href.indexOf("#");
                if (i_hash >= 0 && !isLoginComponent) {
                    // old signup query. Most likely legacy
                    const hash = href.substring(i_hash + 1);
                    Object.assign(props, queryString.parse(hash));
                    href = href.substring(0, i_hash);
                    window.history.replaceState({}, null, href);
                } else if (location.hash.startsWith("#/auth/register/")) {
                    // new query signup email format. But keep backwards compatible format
                    // http://localhost:3000/#/auth/register/sina.schirmer%2Btest1%40lcmd.io
                    const match = matchPath("/auth/register/:email", location.hash.split("#")[1]);
                    if (match && match.params.email) {
                        props.signUp = match.params.email;
                    }
                }
            }

            initServices(props);
            const pipe = new MainWorkerPipe(config);
            const ctx = {
                nav: props,
                pipe: pipe,
                config: config,
            };
            _setParticleContext(
                "function" === typeof config.getParticleContext ? config.getParticleContext() : null,
                pipe,
            );
            //_setLCMDWorker(pipe);
            if ("lcmdigital" === props.puppeteer) {
                // wait for puppeteer
                (globalThis as any).lcmdigital = {
                    canvas: null,
                    puppeteerInit: function (ctx: any) {
                        (self as any).lcmdigital.puppeteerInit = null; // never call twice...
                        initReactRoot(Object.assign(this, ctx));
                    }.bind(ctx),
                    pipe: pipe,
                    services: SERVICES,
                    qa: {},
                    pc: getParticleContext,
                };
                console.log("WAIT FOR PUPPETEER...");
            } else {
                (globalThis as any).lcmdigital = {
                    canvas: null,
                    pipe: pipe,
                    services: SERVICES,
                    qa: {},
                    pc: getParticleContext,
                };
                initReactRoot(ctx);
            }
        }
    });
}
