import * as React from "react";
import {
    DefaultButton,
    PrimaryButton,
    Stack,
    StackItem,
    DetailsList,
    IColumn,
    IconButton,
    IIconProps,
    Icon,
    mergeStyleSets,
    SelectionMode,
    ScrollablePane,
    ConstrainMode,
    ScrollbarVisibility,
    StickyPositionType,
    Sticky,
    IContextualMenuProps,
    ContextualMenu,
    IDragDropContext,
    Selection,
    CheckboxVisibility,
    DetailsListLayoutMode,
    SelectionZone,
    DetailsRow,
    DetailsRowCheck,
} from "@fluentui/react";
import { intl, useProcessView } from "lcmd2framework";
import { useLCMD, LCMDContextType } from "../app/LCMContext";
import { useBoolean } from "@fluentui/react-hooks";
import { LcmdModal } from "./common/LcmModal";
import { useEffect, useMemo, useRef, useState } from "react";

const levelIndent = 10;

const classNames = mergeStyleSets({
    pvc: {
        // process view container
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
    },
    pvcicn: {
        // process view container icon
        width: 20,
        flexGrow: 0,
    },
    pvcinp: {
        // process view container input
        flexGrow: 1,
        background: "transparent",
        border: "0px",
        margin: 0,
        padding: 0,
        selectors: {
            ":focus": {
                outline: "none",
            },
        },
    },
    stickyRowHeader: {
        borderRight: "1px solid transparent", // don't ask me/(flr) why? but Fluent UI needs this.. otherwhise a horizontal scrollbar will show up...
    },
    dragging: {},
});

const indexClassNames = [];
function _ensureIndexClassNames(index: number) {
    while (indexClassNames.length <= index) {
        const l = indexClassNames.length;
        indexClassNames.push(
            mergeStyleSets({
                pcvind: {
                    paddingLeft: levelIndent * l,
                },
            }),
        );
    }
}
_ensureIndexClassNames(10); // pre-calc 10 levels

function onChevronClick(this: LCMDContextType, selection, item: any, ev) {
    if (2 === item.s || 1 === item.s) {
        const sel = [item.tid];
        this.collapseRow(sel, 1 === item.s);
        ev.preventDefault();
        ev.stopPropagation();
    }
}

function onInputBlur(this: LCMDContextType, item: any, ev) {
    const v = (ev.target.value || "").trim();
    if (item.n !== v) {
        if (item.tid >= 0) {
            this.renameRow(item.tid, v);
        } else if (v.length > 0) {
            ev.target.value = "";
            this.appendRow(v);
        }
    }
}

function _handleKeyDown(this: LCMDContextType, item, selection: Selection, readonly, ev: KeyboardEvent) {
    if ("Tab" === ev.key) {
        if (!readonly) {
            const sel =
                selection.getSelectedCount() > 0 ? selection.getSelection().map((item: any) => item.tid) : [item.tid];
            if (ev.shiftKey) {
                this.outdentRow(sel);
            } else {
                this.indentRow(sel);
            }
        }
        ev.preventDefault();
        ev.stopPropagation();
    } else if ("Insert" == ev.key) {
        if (!readonly) {
            this.insertRow(item.tid, 1);
        }
        ev.preventDefault();
        ev.stopPropagation();
    } else if ("Delete" == ev.key) {
        if ("INPUT" !== (ev?.target as HTMLElement).nodeName) {
            if (!readonly) {
                const sel =
                    selection.getSelectedCount() > 0
                        ? selection.getSelection().map((item: any) => item.tid)
                        : [item.tid];
                this.deleteRow(sel);
            }
            ev.preventDefault();
            ev.stopPropagation();
        }
    } else if ("Enter" == ev.key) {
        if ("INPUT" === (ev.target as HTMLElement).nodeName) {
            onInputBlur.call(this, item, ev);
        }
        ev.preventDefault();
        ev.stopPropagation();
    } else if (("c" === ev.key || "x" == ev.key) && (ev.ctrlKey || ev.metaKey)) {
        if (!readonly) {
            const sel =
                selection.getSelectedCount() > 0 ? selection.getSelection().map((item: any) => item.tid) : [item.tid];
            this.copyRow(sel, "x" == ev.key);
        }
        ev.preventDefault();
        ev.stopPropagation();
    } else if ("v" === ev.key && (ev.ctrlKey || ev.metaKey)) {
        if (!readonly) {
            this.pasteRow(item.tid);
        }
        ev.preventDefault();
        ev.stopPropagation();
    } else if ("INPUT" !== (ev.target as HTMLElement)?.nodeName) {
        if (1 === ev.key.length) {
            // hack !!! normal char has length 1 ?!
            const input = (ev.target as HTMLElement).children[1] as HTMLInputElement;
            if ("INPUT" === input?.nodeName) {
                if (!readonly) {
                    input.value = "";
                }
                input.focus();
            }
        }
    }
}

export function onRenderProcessName(this: LCMDContextType, readOnly, onChevronClick, selection, readonly, item) {
    _ensureIndexClassNames(item.l);
    const icon =
        0 === item.s ? "LocationDot" : 1 === item.s ? "ChevronDownMed" : 2 === item.s ? "ChevronRightMed" : null;
    const placeholder =
        0 === item.tid && !item.n
            ? intl.get("fw.Project")
            : -1 === item.tid
              ? intl.get("legacyTaktZones.new")
              : undefined;
    return (
        <div
            className={[classNames.pvc, indexClassNames[item.l].pcvind].join(" ")}
            data-is-focusable={true}
            tabIndex={-1}
            onKeyDown={selection ? _handleKeyDown.bind(this, item, selection, readonly) : undefined}
        >
            <Icon iconName={icon} className={classNames.pvcicn} onClick={onChevronClick.bind(this, selection, item)} />
            <input
                placeholder={placeholder}
                defaultValue={item.n}
                className={classNames.pvcinp}
                onBlur={this && !readOnly ? onInputBlur.bind(this, item) : undefined}
                readOnly={readOnly || readonly}
                data-userpilot-id="processView-taktZonesPlaceholder"
            />
        </div>
    );
}

function onContextMenu(this: LCMDContextType, selection: Selection, readonly: boolean, item: any, ev, action) {
    const sel = selection.getSelectedCount() > 0 ? selection.getSelection().map((item: any) => item.tid) : [item.tid];
    if ("insert" === action.key) {
        this.insertRow(item.tid, 1);
    } else if ("delete" === action.key) {
        this.deleteRow(sel);
    } else if ("indent" === action.key) {
        this.indentRow(sel);
    } else if ("outdent" === action.key) {
        this.outdentRow(sel);
    } else if ("duplicate" === action.key || "attachGPA" === action.key) {
        this.showDialog("dialog.taktzone.clone", { sel, view: action.key });
    } else if ("copy" === action.key) {
        this.copyRow(sel);
    } else if ("cut" === action.key) {
        this.copyRow(sel, true);
    } else if ("paste" === action.key) {
        this.pasteRow(item.tid);
    }
}

const contextMenuTemplate: IContextualMenuProps = {
    items: [
        {
            key: "insert",
            //text: 'Insert',
            iconProps: { iconName: "insert" },
        },
        {
            key: "delete",
            //text: 'Delete',
            iconProps: { iconName: "delete" },
        },
        {
            key: "indent",
            //text: 'Indent',
            iconProps: { iconName: "Forward" },
        },
        {
            key: "outdent",
            //text: 'Outdent',
            iconProps: { iconName: "Back" },
        },
        {
            key: "duplicate",
            //text: 'Duplicate',
            iconProps: { iconName: "DuplicateRow" },
        },
        {
            key: "attachGPA",
            //text: 'Attach GPA',
            iconProps: { iconName: "DuplicateRow" },
        },
        {
            key: "copy",
            //text: 'Copy',
            iconProps: { iconName: "Copy" },
        },
        {
            key: "cut",
            //text: 'Cut',
            iconProps: { iconName: "Cut" },
        },
        {
            key: "paste",
            //text: 'Paste',
            iconProps: { iconName: "Paste" },
        },
    ],
    //directionalHintFixed: true,
    isBeakVisible: false,
};

export function renderDetailsHeader(props, defaultRender) {
    if (!props) {
        return null;
    }
    return (
        <Sticky
            stickyClassName={classNames.stickyRowHeader}
            stickyPosition={StickyPositionType.Header}
            isScrollSynced={true}
        >
            {defaultRender({
                ...props,
                styles: {
                    root: {
                        padding: 0,
                        margin: 0,
                    },
                },
            })}
        </Sticky>
    );
}

function renderCheckbox(this: any, props) {
    if (!props) {
        return null;
    }
    return <DetailsRowCheck {...props} />;
}

const rowStylesOverideTemplate = (h, d) => ({
    root: {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
        height: h,
        minHeight: h,
        maxHeight: h,
    },
    cell: {
        boxSizing: "border-box",
        userSelect: "none",
        selectors: {
            // see https://github.com/microsoft/fluentui/wiki/Component-Styling
            ":focus-within": {
                border: "1px solid green",
            },
        },
    },
});

const rowStylesOverride = [
    rowStylesOverideTemplate(38, 1), // hard coded, bad style, but no f***ing idea where to programmatically get this value from ...
    rowStylesOverideTemplate(32, 0), // --- ^ ----
];

export function renderRow(props, defaultRender) {
    return (
        <DetailsRow
            {...props}
            onRenderCheck={renderCheckbox.bind(props.item)}
            styles={rowStylesOverride[props.compact ? 1 : 0]}
        />
    );
}

export function generateContextMenuColumn(
    this: any,
    name: string,
    onContextMenu: (this, selection, readonly, item) => void,
    selection,
    readonly,
) {
    const contextMenu = {
        ...contextMenuTemplate,
        items: contextMenuTemplate.items.map((e) => ({
            ...e,
            text: intl.get(["legacyTaktZones.cmd", e.key, "text"].join(".")),
            title: intl.get(["legacyTaktZones.cmd", e.key, "title"].join(".")),
        })),
    };
    return {
        isGrouped: false,
        isMultiline: false,
        key: name,
        name: "",
        isIconOnly: false,
        fieldName: "key",
        minWidth: 15,
        maxWidth: 15,
        onRender: (item: any) => {
            const moreIcon: IIconProps = {
                iconName: "MoreVertical",
            };
            return readonly || 0 === item.tid ? null : (
                <IconButton
                    styles={{
                        root: {
                            height: "100%",
                        },
                    }}
                    menuProps={
                        selection.getSelectedCount() < 1 || selection.isKeySelected(getKey(item)) ? contextMenu : null
                    }
                    iconProps={moreIcon}
                    data-selection-disabled={true}
                    data-is-focusable={false}
                    split={false}
                    onRenderMenuIcon={() => null}
                    disabled={readonly}
                    onClick={() => {
                        if (selection && !selection.isKeySelected(getKey(item))) {
                            selection.setAllSelected(false);
                        }
                    }}
                    menuAs={(menuProps: IContextualMenuProps) => {
                        if (selection.getSelectedCount() < 1 || selection.isKeySelected(getKey(item))) {
                            const menuPropsBound = Object.assign({}, menuProps, {
                                items: menuProps.items.map((entry) =>
                                    Object.assign({}, entry, {
                                        onClick: onContextMenu.bind(this, selection, readonly, item),
                                    }),
                                ),
                            });
                            if (-1 === item.tid) {
                                for (let i = 0; i < menuPropsBound.items.length; i++) {
                                    menuPropsBound.items[i].disabled = "paste" !== menuPropsBound.items[i].key;
                                }
                            }
                            return <ContextualMenu {...menuPropsBound} />;
                        } else {
                            return null;
                        }
                    }}
                />
            );
        },
    };
}

const generateColumns: (LCMD: LCMDContextType, selection, readonly, onContextMenu) => IColumn[] = (
    LCMD,
    selection,
    readonly,
    onContextMenu,
) => [
    {
        isGrouped: false,
        isMultiline: false,
        key: "column1.focusable",
        name: intl.get("legacyTaktZones.header"),
        fieldName: "n",
        minWidth: 200,
        onRender: onRenderProcessName.bind(LCMD, false, onChevronClick, selection, readonly),
    },
    generateContextMenuColumn.call(LCMD, "column2", onContextMenu, selection, readonly),
];

function getKey(item) {
    return item.tid;
}

export default function ProcessView(props: { className?: string }) {
    const LCMD = useLCMD();
    const [readonly, setReadonly] = useState(true);
    const [data, setData] = useState([]);
    const vLine = useRef(null);
    const [sel_ctx] = useState(() => {
        const sel_ctx: {
            sel: Selection;
            onSelectionChanged: (ctx: { sel: Selection }) => void;
        } = {
            sel: null,
            onSelectionChanged: null,
        };
        sel_ctx.sel = new Selection({
            getKey: (item) => {
                return (item as any).tid;
            },
            onSelectionChanged: function (this: {
                sel: Selection;
                onSelectionChanged: (ctx: { sel: Selection }) => void;
            }) {
                if (this.onSelectionChanged) {
                    this.onSelectionChanged(this);
                }
            }.bind(sel_ctx),
        });
        return sel_ctx;
    });
    const [modalOpen, { toggle: toggleModalOpen }] = useBoolean(false);
    const [modalHeight, setModalHeight] = useState("250px");
    const [modalContent, setModalContent] = useState({
        headline: "",
        htmlContent: "",
    });

    const columns = useMemo(
        () => generateColumns(LCMD, sel_ctx.sel, readonly, onContextMenu),
        [LCMD, readonly, sel_ctx.sel],
    );
    const handler = useMemo(
        () => ({
            onOpen: () => {},
            onClose: () => {},
            onUpdate: (resp: { data: any[]; readonly: boolean }, focusTid?: number) => {
                setReadonly(resp.readonly);
                setData(resp.data);
            },
        }),
        [setData, setReadonly, sel_ctx.sel],
    );

    useEffect(() => {
        sel_ctx.sel.setChangeEvents(false);
        sel_ctx.sel.setItems(data, false);
        sel_ctx.sel.setChangeEvents(true);
    }, [data, sel_ctx.sel]);
    useEffect(() => {
        sel_ctx.onSelectionChanged = (ctx: { sel: Selection }) => {
            const c = ctx.sel.getSelectedCount();
            LCMD.selectionChanged(ctx.sel.getSelection().map((i: any) => i.tid));
        };
        return () => {
            sel_ctx.onSelectionChanged = null;
        };
    }, [sel_ctx]);

    const _handleDragDropEvents = useMemo(() => {
        const ctx = {
            item: null,
            index: -1,
        };
        return {
            _ctx: ctx,
            canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
                return true;
            },
            canDrag: (item?: any) => {
                return item.tid > 0;
            },
            onDragEnter: (item?: any, event?: DragEvent) => {
                return classNames.dragging;
            },

            onDrop: (dropedAtItem?: any, event?: DragEvent) => {
                if (dropedAtItem && ctx.item) {
                    LCMD.moveRow(ctx.item.tid, dropedAtItem.tid);
                }
            },
            onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
                if (document.activeElement) {
                    (document.activeElement as HTMLElement).blur();
                }
                ctx.item = item;
                ctx.index = itemIndex;
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                ctx.item = null;
                ctx.index = -1;
            },
        };
    }, []);
    useProcessView(handler);

    function onContextMenu(this: LCMDContextType, selection: Selection, readonly: boolean, item: any, ev, action) {
        const selectedItems = selection.getSelectedCount() > 0 ? selection.getSelection() : [item];
        const selectedIndices = selectedItems.map((item) => item.tid);
        if ("insert" === action.key) {
            this.insertRow(item.tid, 1);
        } else if ("delete" === action.key) {
            getDeleteConfirmHtmlByItem(selectedItems);
        } else if ("indent" === action.key) {
            this.indentRow(selectedIndices);
        } else if ("outdent" === action.key) {
            this.outdentRow(selectedIndices);
        } else if ("duplicate" === action.key || "attachGPA" === action.key) {
            this.showDialog("dialog.taktzone.clone", { selectedIndices, view: action.key });
        } else if ("copy" === action.key) {
            this.copyRow(selectedIndices);
        } else if ("cut" === action.key) {
            this.copyRow(selectedIndices, true);
        } else if ("paste" === action.key) {
            this.pasteRow(item.tid);
        }
    }

    function getDeleteConfirmHtmlByItem(items) {
        const filteredItems = items.filter((item) => item.idx != 0 && item.tid != -1);

        if (!filteredItems || filteredItems.length < 1) {
            return;
        }

        if (sel_ctx.sel.getSelectedCount() < 1 && filteredItems.length == 1) {
            sel_ctx.sel.setIndexSelected(filteredItems[0].idx, true, false);
        }

        let title, child;

        if (filteredItems.length == 1) {
            title = intl.get("legacyTaktZones.deleteDialog.singleArea.title");
            child = (
                <div style={{ height: "100%", padding: "20px 32px" }}>
                    {intl.getHTML("legacyTaktZones.deleteDialog.singleArea.subText", { name: filteredItems[0].n })}
                </div>
            );
        } else {
            title = intl.get("legacyTaktZones.deleteDialog.multipleAreas.title");
            child = (
                <Stack styles={{ root: { height: "100%", padding: "20px 32px" } }}>
                    <StackItem>{intl.get("legacyTaktZones.deleteDialog.multipleAreas.subText")}</StackItem>
                    <StackItem>
                        <ul>
                            {filteredItems.map((item) => (
                                <li key={item.tid}>{item.n}</li>
                            ))}
                        </ul>
                    </StackItem>
                </Stack>
            );
        }
        setModalContent({
            headline: title,
            htmlContent: child,
        });
        setModalHeight(() => {
            if (filteredItems.length > 4) {
                return (120 + 18 * filteredItems.length).toString() + "px";
            }
        });
        toggleModalOpen();
    }

    const modalButtons = [
        <DefaultButton onClick={toggleModalOpen} styles={{ root: { borderRadius: "4px" } }}>
            {intl.get("legacyTaktZones.deleteDialog.buttons.cancel")}
        </DefaultButton>,
        <PrimaryButton
            styles={{ root: { borderRadius: "4px", backgroundColor: "#A4262C" } }}
            onClick={() => {
                LCMD.deleteRow(sel_ctx.sel.getSelection().map((item: any) => item.tid));
                toggleModalOpen();
            }}
        >
            {intl.get("legacyTaktZones.deleteDialog.buttons.delete")}
        </PrimaryButton>,
    ];

    return (
        <>
            <ScrollablePane className={props.className} scrollbarVisibility={ScrollbarVisibility.always}>
                <SelectionZone selection={sel_ctx.sel} selectionMode={SelectionMode.multiple}>
                    <DetailsList
                        compact={true}
                        columns={columns}
                        items={data}
                        selectionMode={SelectionMode.multiple}
                        selection={sel_ctx.sel}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        layoutMode={DetailsListLayoutMode.justified}
                        useReducedRowRenderer={true}
                        dragDropEvents={_handleDragDropEvents}
                        getKey={getKey}
                        onRenderDetailsHeader={renderDetailsHeader}
                        onRenderRow={renderRow}
                        checkboxVisibility={CheckboxVisibility.always}
                        disableSelectionZone={true}
                        onActiveItemChanged={(item, index, ev) => {
                            const t = ev.target.childNodes[1]?.childNodes[0] as HTMLElement;
                            const columnName: string = t?.dataset?.automationKey || "";
                            const FOCUSABLE_SUFFIX = ".focusable";
                            if (
                                columnName.lastIndexOf(FOCUSABLE_SUFFIX) + FOCUSABLE_SUFFIX.length ===
                                columnName.length
                            ) {
                                (t.firstChild as HTMLElement).focus();
                            }
                        }}
                    />
                </SelectionZone>
            </ScrollablePane>
            <LcmdModal
                styles={{ main: { width: "340px", overflow: "hidden" } }}
                isOpen={modalOpen}
                buttons={modalButtons}
                header={{ title: modalContent.headline }}
                onDismiss={toggleModalOpen}
            >
                <Stack
                    styles={{
                        root: { paddingBottom: "300px", maxHeight: "600px", height: modalHeight, minHeight: "250px" },
                    }}
                >
                    <ScrollablePane styles={{ root: { height: "calc(100% - 90px)", marginBottom: "32px" } }}>
                        {modalContent.htmlContent}
                    </ScrollablePane>
                </Stack>
            </LcmdModal>
        </>
    );
}
