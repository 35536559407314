import { Button } from "@/components/ui/button";
import * as React from "react";

type EmptyViewProps = {
    imgSrc?: string;
    header?: string;
    text?: string;
    buttonText?: string;
    buttonCb?: () => void;
};

export function EmptyView({ imgSrc, header, text, buttonText, buttonCb }: EmptyViewProps) {
    return (
        <div className="flex h-full min-h-[500px] flex-col items-center justify-center gap-3 text-center">
            {imgSrc && <img alt="Empty View Image" src={imgSrc} />}
            {header && <h2 className="text-center text-3xl font-semibold leading-9 tracking-tight">{header}</h2>}
            {text && <p className="text-s text-center font-medium leading-5 text-gray-500">{text}</p>}
            {buttonText && buttonCb && (
                <Button onClick={buttonCb} className="text-left text-sm font-medium leading-6">
                    {buttonText}
                </Button>
            )}
        </div>
    );
}
