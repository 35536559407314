import { Telemetry } from "../../utils/Telemetry";
import { BUNDLE_HASH, WORKER_HASH } from "../../model/services";

const sentryOpt = {
    dsn: "https://3de163d8df53b7a7e5bd70801c22efbe@o4508347160068096.ingest.de.sentry.io/4508359643430992",
    environment: "live",
    release: __APP_VERSION__,
    env: "live",
};
const WebAppTelemetryFactory: Telemetry =
    // @ts-ignore
    typeof self != "undefined" && !self?.Bun
        ? new Telemetry(
              "InstrumentationKey=864fcdf4-e4cb-454e-a171-f42483e17287;IngestionEndpoint=https://germanywestcentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://germanywestcentral.livediagnostics.monitor.azure.com/",
              BUNDLE_HASH,
              WORKER_HASH,
              null,
              sentryOpt,
          )
        : null;

export { WebAppTelemetryFactory };
