﻿import { create } from "zustand";

export type LoginStore = {
    email: string;
    setEmail: (email: string) => void;
    isNewUser: boolean;
    setIsNewUser: (newUser: boolean) => void;
};

export const useLoginStore = create<LoginStore>((set) => ({
    email: "",
    setEmail: (email) => set({ email: email.toLowerCase() }),
    isNewUser: false,
    setIsNewUser: (newUser) => set({ isNewUser: newUser }),
}));
