import * as React from "react";
import { useEffect, useState } from "react";
import {
    CheckboxVisibility,
    ColumnActionsMode,
    ContextualMenu,
    ContextualMenuItemType,
    DetailsList,
    DetailsListLayoutMode,
    DirectionalHint,
    FontIcon,
    HoverCard,
    HoverCardType,
    IColumn,
    Icon,
    IconButton,
    IContextualMenuItem,
    IContextualMenuProps,
    IDetailsHeaderProps,
    IRenderFunction,
    mergeStyles,
    mergeStyleSets,
    ScrollablePane,
    Selection,
    SelectionMode,
    Sticky,
    StickyPositionType,
} from "@fluentui/react";
import { useLCMD } from "@/app/LCMContext";
import { intl } from "@/legacy/GlobalHelperReact";
import { ItemState, ItemType, ToDoProps } from "../interface";
import { Icons } from "../../RegisteredIcons";
import { Priority } from "../../common/Priority";
import { decimalToRgb } from "../../utils/color/decimal-to-rgb";
import { createUserName } from "../../Utils";
import { UserMap } from "../../hooks/UserMapTypes";
import { RouteNames } from "../../Modal/ModalToDoFilter/filter.interface";
import { getCurrentLanguage } from "../../utils/date/locale";
import { EmptyView } from "@/components/common/EmptyView";
import image from "@/assets/img/emptyState/EmptyData.svg";

type ToDoTable = {
    allItemsLength: number;
    currentItems: ToDoProps[];
    today: number;
    allUsersMap: UserMap;
    onChangeSortState: (sortState: IColumn) => void;
    onToggleStatus: (item: ToDoProps) => void;
    openEditView: () => void;
    onSelectionChanged: (selection: number[]) => void;
    isMultiline?: boolean;
    onUseRoute: (route: string) => void;
};

export function ToDoTable({
    allItemsLength,
    currentItems,
    today,
    allUsersMap,
    onChangeSortState,
    onToggleStatus,
    onSelectionChanged,
    openEditView,
    isMultiline = false,
    onUseRoute,
}: ToDoTable) {
    const [columns, setColumns] = useState<IColumn[]>(getDefaultColumns());
    const [menuState, setMenuState] = useState({ contextualMenuProps: undefined });

    const [selection] = useState(
        new Selection({
            onSelectionChanged: () => {
                onSelectionChanged(selection.getSelectedIndices());
            },
        }),
    );

    const mapColumnsToRoute: Map<string, RouteNames> = new Map([
        ["priority", RouteNames.PRIORITY],
        ["trade", RouteNames.TRADES],
        ["area", RouteNames.AREA],
        ["raisedBy", RouteNames.RAISEDBY],
        ["responsible", RouteNames.RESPONSIBLE],
        ["type", RouteNames.TYPE],
        ["status", RouteNames.STATUS],
        ["identified", RouteNames.IDENTIFIED],
        ["needed", RouteNames.NEEDED],
        ["deadline", RouteNames.DEADLINE],
        ["resolved", RouteNames.RESOLVED],
    ]);

    // const image = "/img/common/emptyState/EmptyData.svg";
    const LCMD = useLCMD();

    useEffect(() => {
        setColumns(getDefaultColumns());
    }, [isMultiline]);

    function getContextualMenuProps(ev: React.MouseEvent<HTMLElement>, column: IColumn): IContextualMenuProps {
        const iconStyle = { color: "#2C3032" };
        const items: IContextualMenuItem[] = [
            {
                key: "aToZ",
                name: intl.get("ToDo.columnsMenu.aToZ"),
                iconProps: { iconName: "SortUp", style: iconStyle },
                canCheck: false,
                onClick: (event, item) => {
                    column.isSortedDescending = false;
                    onChangeSortState(column);
                },
            },
            {
                key: "zToA",
                name: intl.get("ToDo.columnsMenu.zToA"),
                iconProps: { iconName: "SortDown", style: iconStyle },
                canCheck: false,
                onClick: (event, item) => {
                    column.isSortedDescending = true;
                    onChangeSortState(column);
                },
            },
            /*
            {
                key: 'divider',
                itemType: ContextualMenuItemType.Divider
            },
            {
                key: 'hideColumn',
                name: 'Hide column',
                iconProps: { iconName: 'Hide3', style: iconStyle },
                canCheck: false,
                onClick: (event, item)=> {
                    console.log('fa:: hide column', column)

                    column.calculatedWidth = 0;
                    column.currentWidth = 0;
                }
            }
             */
        ];

        if (mapColumnsToRoute.get(column.key)) {
            items.push({
                key: "divider",
                itemType: ContextualMenuItemType.Divider,
            });
            items.push({
                key: "filter",
                name: "Filter",
                iconProps: { iconName: "Filter", style: iconStyle },
                canCheck: false,
                onClick: () => {
                    onUseRoute(mapColumnsToRoute.get(column.key));
                },
            });
        }

        return {
            items: items,
            target: ev.currentTarget as HTMLElement,
            directionalHint: DirectionalHint.bottomLeftEdge,
            onDismiss: onContextualMenuDismissed,
        };
    }

    function onColumnContextMenu(column: IColumn, ev: React.MouseEvent<HTMLElement>): void {
        if (column.columnActionsMode !== ColumnActionsMode.disabled) {
            setMenuState({
                contextualMenuProps: getContextualMenuProps(ev, column),
            });
        }
    }

    function getDefaultColumns(): IColumn[] {
        return [
            {
                key: "id",
                name: intl.get("ToDo.listHeader.id"),
                fieldName: "id",
                minWidth: 10,
                maxWidth: 50,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                isCollapsible: true,
                isMultiline: isMultiline,
                onColumnContextMenu: (column, ev) => {
                    onColumnContextMenu(column, ev);
                },
                onColumnClick: (ev, column) => {
                    onColumnContextMenu(column, ev);
                },
                columnActionsMode: ColumnActionsMode.clickable,
            },
            {
                key: "priority",
                name: intl.get("ToDo.listHeader.priority"),
                fieldName: "priority",
                minWidth: 10,
                maxWidth: 20,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                isCollapsible: true,
                isMultiline: isMultiline,
                onColumnContextMenu: (column, ev) => {
                    onColumnContextMenu(column, ev);
                },
                onColumnClick: (ev, column) => {
                    onColumnContextMenu(column, ev);
                },
                columnActionsMode: ColumnActionsMode.clickable,
            },
            {
                key: "issue",
                name: intl.get("ToDo.listHeader.issue"),
                fieldName: "issue",
                minWidth: 160,
                maxWidth: 250,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                isCollapsible: true,
                isMultiline: isMultiline,
                onColumnContextMenu: (column, ev) => {
                    onColumnContextMenu(column, ev);
                },
                onColumnClick: (ev, column) => {
                    onColumnContextMenu(column, ev);
                },
                columnActionsMode: ColumnActionsMode.clickable,
            },
            {
                key: "functions",
                name: "",
                fieldName: "functions",
                minWidth: 20,
                maxWidth: 20,
                isResizable: false,
                isSorted: false,
                isCollapsible: true,
                isMultiline: isMultiline,
                onColumnContextMenu: (column, ev) => {
                    onColumnContextMenu(column, ev);
                },
                onColumnClick: (ev, column) => {
                    onColumnContextMenu(column, ev);
                },
                columnActionsMode: ColumnActionsMode.clickable,
            },
            {
                key: "trade",
                name: intl.get("ToDo.listHeader.trade"),
                fieldName: "trade",
                minWidth: 100,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                isCollapsible: true,
                isMultiline: isMultiline,
                onColumnContextMenu: (column, ev) => {
                    onColumnContextMenu(column, ev);
                },
                onColumnClick: (ev, column) => {
                    onColumnContextMenu(column, ev);
                },
                columnActionsMode: ColumnActionsMode.clickable,
            },
            {
                key: "area",
                name: intl.get("ToDo.listHeader.area"),
                fieldName: "area",
                minWidth: 130,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                isCollapsible: true,
                isMultiline: isMultiline,
                onColumnContextMenu: (column, ev) => {
                    onColumnContextMenu(column, ev);
                },
                onColumnClick: (ev, column) => {
                    onColumnContextMenu(column, ev);
                },
                columnActionsMode: ColumnActionsMode.clickable,
            },
            {
                key: "raisedBy",
                name: intl.get("ToDo.listHeader.raisedBy"),
                fieldName: "raisedBy",
                minWidth: 130,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                isCollapsible: true,
                isMultiline: isMultiline,
                onColumnContextMenu: (column, ev) => {
                    onColumnContextMenu(column, ev);
                },
                onColumnClick: (ev, column) => {
                    onColumnContextMenu(column, ev);
                },
                columnActionsMode: ColumnActionsMode.clickable,
            },
            {
                key: "responsible",
                name: intl.get("ToDo.listHeader.responsible") + " / " + intl.get("ToDo.listHeader.actionBy"),
                fieldName: "responsible",
                minWidth: 130,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                isCollapsible: true,
                isMultiline: isMultiline,
                onColumnContextMenu: (column, ev) => {
                    onColumnContextMenu(column, ev);
                },
                onColumnClick: (ev, column) => {
                    onColumnContextMenu(column, ev);
                },
                columnActionsMode: ColumnActionsMode.clickable,
            },
            {
                key: "type",
                name: intl.get("ToDo.listHeader.type"),
                fieldName: "type",
                minWidth: 40,
                maxWidth: 40,
                isResizable: false,
                isSorted: false,
                isSortedDescending: false,
                isCollapsible: true,
                isMultiline: isMultiline,
                onColumnContextMenu: (column, ev) => {
                    onColumnContextMenu(column, ev);
                },
                onColumnClick: (ev, column) => {
                    onColumnContextMenu(column, ev);
                },
                columnActionsMode: ColumnActionsMode.clickable,
            },
            {
                key: "status",
                name: intl.get("ToDo.listHeader.status"),
                fieldName: "status",
                minWidth: 50,
                maxWidth: 50,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                isCollapsible: true,
                isMultiline: isMultiline,
                onColumnContextMenu: (column, ev) => {
                    onColumnContextMenu(column, ev);
                },
                onColumnClick: (ev, column) => {
                    onColumnContextMenu(column, ev);
                },
                columnActionsMode: ColumnActionsMode.clickable,
            },
            {
                key: "identified",
                name: intl.get("ToDo.listHeader.identified"),
                fieldName: "identified",
                minWidth: 150,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                isCollapsible: true,
                isMultiline: isMultiline,
                onColumnContextMenu: (column, ev) => {
                    onColumnContextMenu(column, ev);
                },
                onColumnClick: (ev, column) => {
                    onColumnContextMenu(column, ev);
                },
                columnActionsMode: ColumnActionsMode.clickable,
            },
            {
                key: "needed",
                name: intl.get("ToDo.listHeader.needed"),
                fieldName: "needed",
                minWidth: 150,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                isCollapsible: true,
                isMultiline: isMultiline,
                onColumnContextMenu: (column, ev) => {
                    onColumnContextMenu(column, ev);
                },
                onColumnClick: (ev, column) => {
                    onColumnContextMenu(column, ev);
                },
                columnActionsMode: ColumnActionsMode.clickable,
            },
            {
                key: "deadline",
                name: intl.get("ToDo.listHeader.promised"),
                fieldName: "deadline",
                minWidth: 150,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                isCollapsible: true,
                isMultiline: isMultiline,
                onColumnContextMenu: (column, ev) => {
                    onColumnContextMenu(column, ev);
                },
                onColumnClick: (ev, column) => {
                    onColumnContextMenu(column, ev);
                },
                columnActionsMode: ColumnActionsMode.clickable,
            },
            {
                key: "resolved",
                name: intl.get("ToDo.listHeader.resolved"),
                fieldName: "resolved",
                minWidth: 150,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                isCollapsible: true,
                isMultiline: isMultiline,
                onColumnContextMenu: (column, ev) => {
                    onColumnContextMenu(column, ev);
                },
                onColumnClick: (ev, column) => {
                    onColumnContextMenu(column, ev);
                },
                columnActionsMode: ColumnActionsMode.clickable,
            },
        ];
    }

    function onContextualMenuDismissed(): void {
        setMenuState({
            contextualMenuProps: undefined,
        });
    }

    function renderItemColumn(item: ToDoProps, index: number | undefined, column: IColumn | undefined) {
        if (typeof column === "undefined") {
            return false;
        }

        const styles = mergeStyleSets({
            textOverflowDotted: [
                {
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "block",
                },
            ],
            font: [{ fontSize: 14 }],
        });
        const fieldContent = item[column.fieldName];
        switch (column.key) {
            case "functions":
                return (
                    <span>
                        <IconButton
                            styles={{
                                root: {
                                    color: "#999EA1",
                                },
                                rootDisabled: {
                                    backgroundColor: "transparent",
                                },
                            }}
                            menuProps={{
                                items: [
                                    {
                                        key: "editMessage",
                                        text: intl.get("ToDo.edit"),
                                        iconProps: { iconName: "Edit" },
                                        onClick: () => {
                                            openEditView();
                                        },
                                    },
                                ],
                            }}
                            iconProps={{ iconName: "MoreVertical" }}
                            onMenuClick={() => {
                                selection.setIndexSelected(index, true, true);
                            }}
                            data-selection-disabled={true}
                            data-is-focusable={false}
                            split={false}
                            onRenderMenuIcon={() => null}
                        />
                    </span>
                );
            case "status":
                return (
                    /*
                    <HoverCard
                            plainCardProps={{
                                onRenderPlainCard: ()=> {
                                    return (
                                        <>
                                        {fieldContent === ItemState.OPEN ?
                                            <div style={{padding: 20}}>{intl.get("ToDo.statusAction.setDone")}</div> :
                                            <div style={{padding: 20}}>{intl.get("ToDo.statusAction.setOpen")}</div>}
                                        </>
                                    )
                                },
                                calloutProps: {
                                    isBeakVisible: true,
                                },
                            }}
                            type={HoverCardType.plain}
                        >
                     */
                    <span
                        className={styles.font}
                        onClick={() => {
                            onToggleStatus(item);
                        }}
                    >
                        {fieldContent === ItemState.OPEN && isOverdue(item) ? (
                            <FontIcon
                                iconName={Icons.LCMD_Clock}
                                className={mergeStyles({ color: "#D83B01", fontSize: "24px" })}
                            />
                        ) : (
                            ""
                        )}
                        {fieldContent === ItemState.OPEN && !isOverdue(item) ? (
                            <Icon
                                iconName={"LocationDot"}
                                styles={{ root: { color: "#C1C5C7", fontSize: 24, marginRight: 5 } }}
                            />
                        ) : (
                            ""
                        )}
                        {fieldContent === ItemState.DONE ? (
                            <FontIcon
                                iconName={Icons.Lcmd_Checkmark}
                                className={mergeStyles({ color: "#107C10", fontSize: "24px" })}
                            />
                        ) : (
                            ""
                        )}
                    </span>
                    /*
                        </HoverCard>

                     */
                );
            case "issue":
                return (
                    <>
                        <div className={styles.font}>
                            <strong className={styles.textOverflowDotted}>{fieldContent}</strong>
                        </div>
                        <br />
                        <div className={styles.font}>{item.action}</div>
                    </>
                );
            case "area":
                return (
                    <div className={styles.font + " " + styles.textOverflowDotted}>
                        {item.process.areaPath
                            .map((area) => {
                                return area.name;
                            })
                            .join(" > ")}
                    </div>
                );
            case "type":
                return (
                    <span className={styles.font + styles.textOverflowDotted}>
                        {fieldContent === ItemType.ACTION_ITEM ? (
                            <FontIcon iconName={Icons.Lcmd_Warning} className={mergeStyles({ fontSize: "24px" })} />
                        ) : (
                            ""
                        )}
                        {fieldContent === ItemType.STABILITY_CRITERIA ? (
                            <FontIcon iconName={Icons.Lcmd_Scales} className={mergeStyles({ fontSize: "24px" })} />
                        ) : (
                            ""
                        )}
                        {fieldContent !== ItemType.ACTION_ITEM && fieldContent !== ItemType.STABILITY_CRITERIA ? (
                            <FontIcon iconName={Icons.Lcmd_Bullet_List} className={mergeStyles({ fontSize: "24px" })} />
                        ) : (
                            ""
                        )}
                    </span>
                );
            case "raisedBy":
                return (
                    <HoverCard
                        plainCardProps={{
                            onRenderPlainCard: () => {
                                return (
                                    <div style={{ padding: 10 }}>
                                        {fieldContent.map((userId) => allUsersMap[userId].email).join(",")}
                                    </div>
                                );
                            },
                            calloutProps: {
                                isBeakVisible: true,
                            },
                        }}
                        type={HoverCardType.plain}
                    >
                        <span className={styles.font + " " + styles.textOverflowDotted}>
                            {fieldContent.map((userId) => createUserName(allUsersMap[userId])).join(",")}
                        </span>
                    </HoverCard>
                );
            case "responsible":
                return (
                    <>
                        <HoverCard
                            plainCardProps={{
                                onRenderPlainCard: () => {
                                    return (
                                        <div style={{ padding: 10 }}>
                                            {fieldContent.map((userId) => allUsersMap[userId].email).join(",")}
                                        </div>
                                    );
                                },
                                calloutProps: {
                                    isBeakVisible: true,
                                },
                            }}
                            type={HoverCardType.plain}
                        >
                            <span className={styles.font + " " + styles.textOverflowDotted}>
                                {fieldContent.map((userId) => createUserName(allUsersMap[userId])).join(",")}
                            </span>
                        </HoverCard>
                        <br />
                        <span>{item.actionBy}</span>
                    </>
                );
            case "priority":
                return <Priority priorityNumber={fieldContent} />;
            case "trade":
                return (
                    <>
                        <span
                            className={styles.textOverflowDotted}
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Icon
                                iconName={Icons.Lcmd_Trade}
                                styles={{
                                    root: {
                                        fontSize: 22,
                                        color: decimalToRgb(fieldContent.map((t) => t.color).join("")),
                                    },
                                }}
                            />
                            <span className={styles.font}>{fieldContent.map((t) => t.name).join(" ")}</span>
                        </span>
                        <div style={{ paddingTop: 3 }} className={styles.font + " " + styles.textOverflowDotted}>
                            <span
                                style={{
                                    width: 5,
                                    height: 10,
                                    borderLeft: "solid 1px #C1C5C7",
                                    borderBottom: "solid 1px #C1C5C7",
                                    marginLeft: 6,
                                    marginBottom: 3,
                                    marginRight: 10,
                                    display: "inline-block",
                                }}
                            ></span>
                            {item.process.name}
                        </div>
                    </>
                );
            case "process":
                return <span className={styles.font + " " + styles.textOverflowDotted}>{item.process.name}</span>;
            case "identified":
                return formatDate(item.identified);
            case "needed":
                return formatDate(item.needed);
            case "deadline":
                return formatDate(item.deadline);
            case "resolved":
                return formatDate(item.resolved);
            default:
                return <div className={styles.font + " " + styles.textOverflowDotted}>{fieldContent}</div>;
        }
    }

    function formatDate(value: any): string {
        if (value === null || typeof value !== "number") return "";
        return new Date(value).toUTCFormatString(getCurrentLanguage(), {
            day: "numeric",
            month: "numeric",
            year: "numeric",
        });
    }

    function isOverdue(todo: ToDoProps) {
        if (todo.status === ItemState.DONE) {
            return false;
        }

        return todo.status === ItemState.OPEN && todo.deadline < today;
    }

    return (
        <ScrollablePane style={{ top: "82px", left: 22, right: 22, bottom: 22 }}>
            {allItemsLength > 0 ? (
                <DetailsList
                    checkboxVisibility={CheckboxVisibility.always}
                    items={currentItems}
                    setKey="set"
                    columns={columns}
                    selectionMode={SelectionMode.multiple}
                    selectionPreservedOnEmptyClick={true}
                    enterModalSelectionOnTouch={true}
                    selection={selection}
                    onRenderItemColumn={renderItemColumn}
                    layoutMode={DetailsListLayoutMode.justified}
                    onRenderDetailsHeader={(
                        detailsHeaderProps: IDetailsHeaderProps,
                        defaultRender: IRenderFunction<IDetailsHeaderProps>,
                    ) => (
                        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                            {defaultRender(detailsHeaderProps)}
                        </Sticky>
                    )}
                />
            ) : (
                <EmptyView
                    imgSrc={image}
                    header={intl.get("StabilityCriteria.SettingsList.NoDataHeader")}
                    text={intl.get("StabilityCriteria.SettingsList.NoDataText")}
                    buttonText={intl.get("whiteboard.emptyWhiteboard.newButton.label")}
                    buttonCb={() => LCMD.worker.dispatchMessage(["framework", "toggle", "project"])}
                />
            )}
            {menuState.contextualMenuProps && <ContextualMenu {...menuState.contextualMenuProps} />}
        </ScrollablePane>
    );
}
