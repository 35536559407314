import { useEffect, useState, KeyboardEvent } from "react";

import { Button } from "@/components/ui/button";
import { useLoginStore } from "../../store/loginStore";
import { Input } from "@/components/ui/input";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../types/routes";
import { IconButton } from "../common/iconButton/IconButton";
import { ArrowLeft } from "lucide-react";
import { intl } from "lcmd2framework";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { UserNotConfirmedError, AuthClient, InvalidCredentialsError } from "@/app/services/AuthClient.service";
import { showScreenOfDeath } from "@/legacy/ScreenOfDeath";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { SSOButton } from "@/components/common/SSOButton/SSOButton";

export type LoginProps = {
    onLogin: (sub: string, authToken: string, email: string, newlyRegistered?: boolean) => void;
};

export function Login({ onLogin }: LoginProps) {
    const navigate = useNavigate();
    const { email } = useLoginStore();
    const [isLoading, setIsLoading] = useState(false);
    //const [showUserNotVerifiedPopUp, setShowUserNotVerifiedPopUp] = useState(false);

    useEffect(() => {
        if (email == "") {
            navigate(Paths.HOME);
        }
    }, [email]);

    /*
    async function sendVerifyEmail() {
        setIsLoading(true);
        await AuthClient.resendWelcomeEmail(email);
        setIsLoading(false);
    }

     */

    const handleSubmit = async ({ password }: z.infer<typeof formSchema>) => {
        setIsLoading(true);

        try {
            const result = await AuthClient.login(email, password);
            onLogin(result.sub, result.authToken, email);
        } catch (error) {
            if (error instanceof InvalidCredentialsError) {
                form.setError("password", { message: intl.get("LcmdLogin.Login.WrongPassword") });
                setIsLoading(false);
                return;
            }

            if (error instanceof UserNotConfirmedError) {
                //setShowUserNotVerifiedPopUp(true);
                setIsLoading(false);
                return;
            }

            showScreenOfDeath(`network error ${error.status}`);
        }
    };

    const formSchema = z.object({
        password: z.string(),
    });
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            password: "",
        },
    });

    function handleForgotPassword() {
        navigate(Paths.FORGOT_PASSWORD);
    }

    function handleBack() {
        navigate(Paths.HOME);
    }

    function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            event.preventDefault();
            form.handleSubmit(handleSubmit)();
        }
    }

    return (
        <>
            {/*<MessageDialog
                onClose={() => {
                    setShowUserNotVerifiedPopUp(false);
                }}
                buttonText={intl.get("LcmdLogin.ConfirmEmail.ResendLink")}
                message={intl.get("LcmdLogin.ConfirmEmail.ConfirmEmailText")}
                onButtonClick={() => {
                    WebAppTelemetryFactory.trackEvent("resend-link-clicked");
                    sendVerifyEmail();
                }}
                open={showUserNotVerifiedPopUp}
                title={intl.get("LcmdLogin.ConfirmEmail.ConfirmEmailTitle")}
                buttonDisabled={isLoading}
                buttonLoading={isLoading}
            ></MessageDialog>*/}
            <div className={"mb-2 flex gap-1"}>
                <IconButton icon={ArrowLeft} onClick={handleBack} />
                <div className={"text-xl font-semibold text-zinc-900"}>{intl.get("LcmdLogin.Login.Headline")}</div>
            </div>
            <div className={"mb-4 text-sm font-medium text-zinc-400"}>
                {intl.get("LcmdLogin.Login.Subtitle")} <span className={"text-black"}>{email}</span>
            </div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <div className={"space-y-1"}>
                        <FormField
                            control={form.control}
                            name="password"
                            disabled={isLoading}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>{intl.get("LcmdLogin.Password.Label")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            type={"password"}
                                            placeholder={intl.get("LcmdLogin.Password.Placeholder")}
                                            autoFocus
                                            onKeyDown={handleKeyDown}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button variant={"link"} className={"p-0 text-blue-600"} onClick={handleForgotPassword}>
                            {intl.get("LcmdLogin.Login.ForgotPassword")}
                        </Button>
                    </div>
                    <div className={"mb-4 flex items-center justify-between align-middle"}>
                        <div className={"h-px w-[132px] bg-gray-300"}></div>
                        {intl.get("LcmdLogin.Home.Or")}
                        <div className={"h-px w-[132px] bg-gray-300"}></div>
                    </div>
                    <SSOButton
                        onLoadingStateChanged={(isLoading) => setIsLoading(isLoading)}
                        handleLogin={(sub, authToken) => {
                            onLogin(sub, authToken, email);
                        }}
                    ></SSOButton>
                </form>
            </Form>
        </>
    );
}
