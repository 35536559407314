import * as React from "react";
import { intl, Dialog, DialogScreen, LocalizedDatePicker } from "lcmd2framework";
import { mergeStyleSets, TextField, Stack, Toggle } from "@fluentui/react";
import { CoreExtensions } from "../../extensions/core.extensions";
import { PaperSizeSelector } from "../common/paperSizeSelector/";
import { useLayoutEffect } from "react";
import { AlertDialog } from "../common/AlertDialog";
import { useLCMD } from "../../app/LCMContext";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

const classNames = mergeStyleSets({
    main: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        flexShrink: 1,
        paddingLeft: 20,
        paddingRight: 20,
    },
});

function MainScreen({
    dialog,
    route: {
        params: { dialogId, doExport },
    },
}) {
    const LCMD = useLCMD();
    const [busy, setBusy] = React.useState(null as string | true);
    const [multiPage, setMultiPage] = React.useState(false);
    const [repeatTimeline, setRepeatTimeline] = React.useState(false);
    const [pdfExportName, setPdfExportName] = React.useState("");
    const [pdfExportDate, setPdfExportDate] = React.useState(new Date());
    const [pdfExportComment, setPdfExportComment] = React.useState("");
    const settingsExcludedFromRender = ["pageWidthMM", "pageHeightMM"];
    const [settings, setSettings] = React.useState({
        pageWidthMM: 210,
        pageHeightMM: 297,
        colsPerPage: 28,
        sidebarColWidthMM: 5,
        sidebarColExtraMM: 10,
        sidebarImageWidthMM: 30,
        pageHeaderHeightMM: 40,
        colHeaderHeight1MM: 5,
        colHeaderHeight2MM: 5,
    });
    React.useEffect(() => {
        LCMD.getProjectExtension(CoreExtensions.PDF_SETTINGS, (error, data) => {
            if (error) {
                console.warn(error);
            } else {
                console.log(data);
            }
        });
    }, []);
    React.useLayoutEffect(() => {
        dialog.setOptions({
            title: intl.get("pdf.title"),
            onCancel:
                true === busy
                    ? null
                    : () => {
                          LCMD.showDialog(dialogId, false);
                      },
            onClose:
                true === busy
                    ? null
                    : () => {
                          LCMD.showDialog(dialogId, false);
                      },
            onOK:
                true === busy
                    ? null
                    : () => {
                          if (!busy) {
                              const header = {
                                  header: {
                                      text: [pdfExportName, pdfExportDate.toLocaleDateString(), pdfExportComment].join(
                                          "\n",
                                      ),
                                  },
                                  desc: {
                                      col: intl.get("pdf.col"),
                                      row: intl.get("pdf.row"),
                                      img: intl.get("pdf.img"),
                                  },
                                  stripeColor: 0xf1f3f3,
                                  nonWorkDayColor: 0xe9eced,
                                  repeatHeader: repeatTimeline,
                                  usedTradesOnly: true,
                                  doubleRowHeader: false,
                                  sortTradesReverse: false,
                              };
                              setBusy(true);
                              const opt = multiPage ? { ...settings, ...header } : header;
                              doExport(opt, (success: boolean) => {
                                  if (success) {
                                      LCMD.showDialog(dialogId, false);
                                      WebAppTelemetryFactory.trackEvent("export-pdf", {
                                        multipage: multiPage
                                    });
                                  } else {
                                      setBusy("pdf.error");
                                  }
                              });
                          } else {
                              LCMD.showDialog(dialogId, false);
                          }
                      },
        });
    }, [busy, multiPage, settings, setBusy, pdfExportName, pdfExportDate, pdfExportComment, repeatTimeline]);

    useLayoutEffect(() => {
        LCMD.getProjectDetails((error, data) => {
            setPdfExportName(data.meta.name);
        });
    }, []);

    return (
        <div>
            <AlertDialog
                hidden={busy !== "pdf.error"}
                title={intl.get("pdf.error")}
                onClose={() => {
                    setBusy(null);
                }}
            />
            <Stack styles={{ root: { padding: "20px" } }}>
                <TextField
                    label={intl.get("pdf.export.name")}
                    value={pdfExportName}
                    onChange={(ev, value) => setPdfExportName(value || "")}
                />

                <LocalizedDatePicker
                    label={intl.get("pdf.export.date.label")}
                    placeholder={intl.get("pdf.export.date.placeholder")}
                    value={pdfExportDate}
                    onSelectDate={(date: Date) => setPdfExportDate(date)}
                />

                <TextField
                    label={intl.get("pdf.export.comment")}
                    value={pdfExportComment}
                    onChange={(ev, value) => setPdfExportComment(value || "")}
                />

                <Toggle
                    label={intl.get("pdf.export.multi-page")}
                    checked={multiPage}
                    onChange={(ev, isChecked) => {
                        setMultiPage(isChecked);
                    }}
                />

                <Toggle
                    label={intl.get("pdf.export.timeline-repeat")}
                    checked={repeatTimeline}
                    onChange={(ev, isChecked) => {
                        setRepeatTimeline(isChecked);
                    }}
                />
                {multiPage ? (
                    <PaperSizeSelector
                        onChange={(obj) => {
                            setSettings({
                                ...settings,
                                pageWidthMM: obj.width,
                                pageHeightMM: obj.height,
                                colsPerPage: obj.colsPerPageSize,
                            });
                        }}
                    />
                ) : null}

                {multiPage
                    ? Object.getOwnPropertyNames(settings)
                          .filter((setting) => !settingsExcludedFromRender.includes(setting))
                          .map((setting) => {
                              return (
                                  <TextField
                                      key={setting}
                                      label={setting}
                                      type="number"
                                      value={settings[setting]}
                                      prefix={"colsPerPage" != setting ? "mm" : intl.get("pdf.export.days")}
                                      onChange={(ev, value) => {
                                          setSettings({ ...settings, [setting]: Number.parseInt(value, 10) });
                                      }}
                                  />
                              );
                          })
                    : null}
            </Stack>
        </div>
    );
}

export function PdfExportDialog(props: { dialogId: string; doExport: (options?, onDone?: () => void) => void }) {
    return (
        <Dialog
            initialScreen={(dialog) => {
                dialog.pushScreen("main", { ...props });
            }}
            isOpen={true}
        >
            <DialogScreen name="main" component={MainScreen} />
        </Dialog>
    );
}
