import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuPortal,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { LucideProps, MoreVertical } from "lucide-react";

export type MenuMoreProps = {
    key: string;
    text: string;
    icon?: LucideProps;
    onClick?: () => void;
    options?: MenuMoreProps[];
    disabled?: boolean;
};

export const MoreButtonDropdown = ({ menuItems }: { menuItems: MenuMoreProps[] }) => (
    <DropdownMenu modal={false}>
        <DropdownMenuTrigger className="flex h-7 px-2 py-1.5 outline-none">
            <MoreVertical size={16} color="#666666" />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="left-1">
            <DropdownMenuGroup>
                <ul>
                    {menuItems.map(({ key, text, onClick, icon, options, disabled }) => (
                        <li key={key} className="row-span-3">
                            {options ? (
                                <DropdownMenuSub>
                                    <DropdownMenuSubTrigger disabled={disabled} className="data-[disabled]:hidden">
                                        {icon}
                                        <p className="mx-2 break-words text-base font-normal leading-5 tracking-tighter text-zinc-900">
                                            {text}
                                        </p>
                                    </DropdownMenuSubTrigger>
                                    <DropdownMenuPortal>
                                        <DropdownMenuSubContent>
                                            {options.map((option) => (
                                                <DropdownMenuItem
                                                    key={option.key}
                                                    onClick={option.onClick}
                                                    className="cursor-pointer"
                                                >
                                                    {option.icon}
                                                    <p className="mx-2 break-words text-base font-normal leading-5 tracking-tighter text-zinc-900">
                                                        {option.text}
                                                    </p>
                                                </DropdownMenuItem>
                                            ))}
                                        </DropdownMenuSubContent>
                                    </DropdownMenuPortal>
                                </DropdownMenuSub>
                            ) : (
                                <DropdownMenuItem onClick={onClick} className="cursor-pointer">
                                    {icon}
                                    <p className="mx-2 break-words text-sm font-normal leading-5 tracking-tighter text-zinc-900">
                                        {text}
                                    </p>
                                </DropdownMenuItem>
                            )}
                        </li>
                    ))}
                </ul>
            </DropdownMenuGroup>
        </DropdownMenuContent>
    </DropdownMenu>
);
