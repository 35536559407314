import * as React from "react";
import { assert } from "../../../model/GlobalHelper";
import { fetch_master, getLog } from "../../../model/ApiHelper";
import { FrameworkHeader } from "../FrameworkHeader/FrameworkHeader";
//import { CollaboratorFormProps } from "./SharePopup";
import { Sub } from "../../Sub";
//import { SandboxPopup, SandboxPopupOptions } from "./SandboxPopup";
import { WorkerSession } from "../../../model/DataModel";
import { intl, localizeIfNeeded } from "../../GlobalHelperReact";
import * as fluentui from "@fluentui/react";
import { Spinner } from "@fluentui/react";
import { CanvasMeta } from "../Canvas/Canvas";
import DigitalPlanningBoard from "../DigitalPlanningBoard";
import { MainWorkerPipe } from "../../MainWorkerPipe";
import { FrameworkRightHelpAlertContract } from "../FrameworkHeader/FrameworkRightHelpAlertContract";
import { Button } from "@/components/ui/button";
import { useLCMD } from "@/app/LCMContext";
import video from "./cubic-rube-animated-v-17.webm";
import { useCallback } from "react";
import { openProject } from "@/components/FilesOverlay";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

const labelStyle: React.CSSProperties = {
    fontSize: "0.8em",
    paddingTop: 10,
    color: "gray",
};

type FileData = {
    token: string;
    ts: number;
    name: string;
    color: string;
    start: string;
    end: string;
    shared: {
        [sub: string]: {
            role: string;
            trade?: number;
            status?: string;
        };
    };
    history: {
        id: string;
        ts: number;
        uploadedBy?: string;
        name?: string;
        description?: string;
        resource?: {
            id: string;
            token: string;
            filename?: string;
        };
        master_token?: string;
    }[];
};

type FileDataModel = {
    [projectId: string]: FileData;
};

type FilesProps = {
    worker: MainWorkerPipe;
    filesOverlay: boolean;
};

type FilesState = {
    uploadPopup: string | boolean;
    sharePopup: boolean;
    model: FileDataModel;
    view: string[];
    selected_project: string | null;
    pending: boolean | string;
    meta: CanvasMeta;
};

export class Files extends React.Component<FilesProps, FilesState> {
    state = {
        uploadPopup: false,
        sharePopup: false,
        model: null,
        view: null,
        selected_project: null,
        pending: false,
        meta: null,
    };

    private data: {
        commitedTS: number;
        commited: FileDataModel;
        pending: {
            apply: (model: FileDataModel) => void;
        }[];
    } = {
        commitedTS: 0,
        commited: {},
        pending: [],
    };

    private updateView() {
        const projects = this.data.commited;
        const view = Object.getOwnPropertyNames(projects);
        const model = view.reduce((ret, pid) => {
            const p = projects[pid];
            ret[pid] = p;
            if (p) {
                this.fetchMissingLog(pid);
            }
            return ret;
        }, {});
        this.setState(
            {
                model: model,
                view: view,
            },
            () => {},
        );
    }

    private setProject(pid: string, project: FileData) {
        assert(0 === this.data.pending.length); // apply pending ops... @TODO rollback
        Object.getOwnPropertyNames(project).forEach((key) => project[key] === undefined && delete project[key]); // remove undefines...
        const projects = this.data.commited;
        const instance: FileData = Object.assign(
            {},
            projects[pid] || {
                ts: 0,
                name: null,
                color: null,
                start: null,
                end: null,
                shared: {},
                history: [],
            },
            project,
        );
        projects[pid] = instance;
        this.updateView();
    }

    private _setState(name: string, value: any, cb?: () => void) {
        const state = {};
        state[name] = value;
        this.setState(state, cb);
    }

    private fetchMissingMaster(pid: string, lid: number) {
        const project = this.data.commited[pid];
        if (project) {
            const log = (project.history || [])[lid];
            if (log.master_token && !this.state[log.master_token]) {
                this._setState(log.master_token, new Date(), () => {
                    fetch_master(log.master_token, (error, result) => {
                        if (error) {
                            this._setState(log.master_token, error);
                        } else {
                            if (Array.isArray(result.sandbox)) {
                                const sbs = result.sandbox;
                                for (let i_sb = 0; i_sb < sbs.length; i_sb++) {
                                    const sb = sbs[i_sb];
                                    sb.db = sb.sid === result?.master?.db;
                                    sb.name = localizeIfNeeded(sb.name);
                                    /*
                                    if ((sb.name||"").startsWith("lcmd.")) {
                                        sb.name=intl.get(sb.name) || sb.name;
                                    }
                                    */
                                }
                                result.sandbox = sbs.filter((sb) => !(sb.ts < 0));
                            }
                            this._setState(log.master_token, result);
                        }
                    });
                });
            }
        }
    }

    private fetchMissingLog(pid: string) {
        const project = this.data.commited[pid];
        if (true && project) {
            project.history.forEach((id, i_id) => {
                let pending = null;
                if ("number" === typeof id) {
                    pending = {
                        id: id,
                        ts: id,
                    };
                } else {
                    //@TODO check ts...
                }
                if (pending) {
                    project.history[i_id] = pending;
                    getLog(project.token, pid, id as any, (error, result) => {
                        if (error) {
                            //@TODO
                        } else {
                            const _project = this.data.commited[pid];
                            const _history = _project?.history || [];
                            if (
                                i_id <= _history.length &&
                                pending === _project.history[i_id] &&
                                pending.id === result.id
                            ) {
                                const __history = _history.slice();
                                __history.splice(i_id, 1, {
                                    id: id as any,
                                    ts: id as any,
                                    uploadedBy: result?.file?.sub || undefined,
                                    name: result?.commit?.name || undefined,
                                    description: result?.commit?.message || undefined,
                                    resource: result?.file || undefined,
                                    master_token: result?.master || undefined,
                                });
                                this.setProject(pid, {
                                    token: undefined,
                                    ts: undefined,
                                    name: undefined,
                                    color: undefined,
                                    start: undefined,
                                    end: undefined,
                                    shared: undefined,
                                    history: __history,
                                });
                                this.fetchMissingMaster(pid, i_id);
                            } else {
                                console.warn("out of bound...");
                            }
                        }
                    });
                }
            });
        }
        return project;
    }

    componentDidMount(this: Files) {}

    componentDidUnMount(this: Files) {}

    onUploadPopup(show: string | boolean) {
        this.setState({
            uploadPopup: show,
        });
    }
    showCreatePopup = this.onUploadPopup.bind(this, true);
    showUploadPopup = function (this: Files) {
        if (this.state.selected_project) {
            this.onUploadPopup(this.state.selected_project);
        } else {
            this.onUploadPopup(null);
        }
    }.bind(this);

    // hideUploadPopup = function (this: Files, data?: any) {
    //     this.onUploadPopup(false);
    //     if (data?.auth_token) {
    //         this.props.worker.dispatchMessage(["auth_token", data]);
    //     }
    //     if (data?.result?.projects) {
    //         const existingProjects = this.data.commited || {};
    //         const projects = data.result.projects;
    //         const missingProjects = projects.filter((pid) => (existingProjects[pid] ? false : true));
    //         if (missingProjects.length > 0) {
    //             missingProjects.forEach((pid) => ((existingProjects[pid] as any) = false)); // mark as pending...
    //             this.data.commitedTS = data.result.ts;
    //             this.fetchMissingProjectsIfNeeded();
    //         }
    //     }
    //     if (data?.pid && data?.result?.log) {
    //         const pid = data?.pid;
    //         const log = data.result.log;
    //         const project = (this.data.commited || {})[pid];
    //         if (project) {
    //             const _log = log; //@TODO merge
    //             this.setProject(pid, {
    //                 token: undefined,
    //                 ts: undefined,
    //                 name: undefined,
    //                 color: undefined,
    //                 start: undefined,
    //                 end: undefined,
    //                 shared: undefined,
    //                 history: _log,
    //             });
    //         }
    //     }
    // }.bind(this);

    onSharePopup(show: boolean) {
        if (!show && "string" === typeof this.state.pending) {
            // clear error
            this.setState({
                pending: false,
            });
        } else {
            this.setState({
                sharePopup: show,
            });
        }
    }
    showSharePopup = this.onSharePopup.bind(this, true);
    hideSharePopup = this.onSharePopup.bind(this, false);

    /*
    REMOVE_ME_scheduleShareOperation(this:Files, data:any, selected_project:string, sub:string, props:CollaboratorFormProps) {
        this.setState({
            pending: true
        }, ()=>{
            if ("remove"===props?.role) {
                if (selected_project && sub) {
                    unlink_project(this.props.worker.auth?.auth_token, (error, result)=>{
                        if (error) {
                            this.setState({
                                pending: "Error"
                            });
                        } else {
                            if (this.data.commited[result.pid]) {
                                if (result.project) {
                                    this.setProject(result.pid, {
                                        token: result.project_token,
                                        ts: result.project?.ts,
                                        name: result.project?.name||undefined,
                                        color: undefined,
                                        start: undefined,
                                        end: undefined,
                                        shared: result.project?.shared||undefined,
                                        history: result.project?.log||undefined
                                    });
                                } else {
                                    delete this.data.commited[result.pid];
                                    if (result.pid===this.state.selected_project) {
                                        this.setState({
                                            selected_project: null
                                        });
                                    }
                                    this.updateView();
                                }
                            }
                            this.setState({
                                pending: false
                            });
                        }
                    }, selected_project, sub);
                } else {
                    this.setState({
                        pending: false
                    });
                }
            } else {
                const email=(!sub && props.email && props.email.length>0?props.email:null);
                addCollaborator(this.props.worker.auth?.auth_token, (error, result)=>{
                    if (error) {
                        this.setState({
                            pending: "Error"
                        });
                    } else {
                        this.setProject(result.pid, {
                            token: result.project_token,
                            ts: result.project?.ts,
                            name: result.project?.name||undefined,
                            color: undefined,
                            start: undefined,
                            end: undefined,
                            shared: result.project?.shared||undefined,
                            history: result.project?.log||undefined
                        });
                        this.setState({
                            pending: false
                        });
                    }
                },  selected_project, sub, email, props.role||undefined);
            }
        });
    };
    */

    onSelectProject = function (this: Files, data: any, selected_project: string) {
        if (this.state.model === data.model) {
            this.setState(
                {
                    selected_project: selected_project,
                },
                () => {
                    /*
                const project=this.state.data.model[selected_project];
                if (project && Array.isArray(project.history) && project.history.length>0 && project.history[0].ts>=0) {
                    this.props.worker.dispatchMessage(["framework", "nav", Object.assign({}, this.props.worker.nav, {
                        project: selected_project,
                        session:  project.history[0] // HACK
                    })]);
                }
                */
                },
            );
        }
    }.bind(this);

    private reloadMaster(filter_master_token: string) {
        const projects = Object.getOwnPropertyNames(this.state.model || {});
        for (let i_project = 0; i_project < projects.length; i_project++) {
            const pid = projects[i_project];
            const project = this.state.model[pid];
            if (Array.isArray(project?.history)) {
                for (let i_history = 0; i_history < project.history.length; i_history++) {
                    const h = project.history[i_history];
                    if (
                        h?.master_token &&
                        this.state[h.master_token] &&
                        !(this.state[h.master_token] instanceof Date) &&
                        (null === filter_master_token || filter_master_token === h.master_token)
                    ) {
                        this.state[h.master_token] = null;
                        this.fetchMissingMaster(pid, i_history);
                    }
                }
            }
        }
    }

    onReload = function (this: Files) {
        //@TODO
    }.bind(this);

    private onClickName(this: Files, props: any, dailyBoard: boolean) {
        let openInDB = false;
        if (undefined === props?.card || null === props?.card || props?.card >= 0) {
            // still pending...
        } else {
            const project = this.state.model[this.state.selected_project];
            if (project) {
                let session: WorkerSession = null;
                if (props.card?.master_token) {
                    const master = this.state[props.card?.master_token] || null;
                    if (master) {
                        const sandboxes = master?.sandbox || [];
                        session = {
                            pid: this.state.selected_project,
                            project_token: project.token,
                            resource: props.card?.resource,
                            master_token: props.card?.master_token,
                        };
                        if (dailyBoard && master?.master?.db) {
                            // we have a daily board
                            const db = sandboxes.find((sandbox) => sandbox.sid === master.master.db);
                            if (db) {
                                if (0 === db.ts || db.ts > 0) {
                                    Object.assign(session, {
                                        sandbox: db.sid,
                                        sandbox_name: db.name || null,
                                        sandbox_ts: db.ts || 0,
                                    });
                                    openInDB = 0 === db.ts;
                                } else if (db.ts < 0) {
                                    // commited
                                    /*
                                    this.props.worker.dispatchMessage(["framework", "sandboxPopup", {
                                        session: session,
                                        view: 0,
                                        dailyBoard: dailyBoard
                                    } as SandboxPopupOptions]);
                                    */
                                    session = null;
                                } else {
                                    session = null; // should not happen
                                }
                            } else {
                                session = null;
                            }
                        } else if (sandboxes.length >= 0) {
                            // show popup
                            /*
                            this.props.worker.dispatchMessage(["framework", "sandboxPopup", {
                                session: session,
                                view: 0,
                                dailyBoard: dailyBoard
                            } as SandboxPopupOptions]);
                            */
                            session = null;
                        }
                    } else {
                        // sandboxes not loaded yet...
                        assert(null === session);
                    }
                } else if (props.card?.resource?.token) {
                    // view master
                    session = {
                        pid: this.state.selected_project,
                        project_token: project.token,
                        resource: props.card?.resource,
                        master_token: null /* view master */,
                    };
                } else {
                    // no idea what to do
                    assert(null == session);
                }
                if (session) {
                    DigitalPlanningBoard.openProject(this.props.worker, session, openInDB);
                }
            }
        }
    }

    onSandboxClick(this: Files, master_token, master, sandbox) {
        const session: WorkerSession = {
            pid: sandbox.pid,
            project_token: null,
            resource: null,
            master_token: master_token,
            sandbox: sandbox.sid,
            sandbox_name: sandbox.name || null,
            sandbox_ts: sandbox.ts || 0,
            sandbox_db: sandbox.db || false,
        };
        DigitalPlanningBoard.openProject(this.props.worker, session, false);
    }

    sandboxList = function (
        this: Files,
        props: {
            master_token: string;
        },
    ) {
        const master = this.state[props.master_token];
        return (
            <div>
                <div style={labelStyle}>Sandboxes</div>
                {master ? (
                    master instanceof Error ? (
                        <div>{intl.get("files.errorSandboxes")}</div>
                    ) : (
                        <div>
                            {(master?.sandbox || []).map((sandbox) => {
                                const name = sandbox?.name || intl.get("files.UnnamedSandbox");
                                if (sandbox?.ts < 0) {
                                    return (
                                        <div key={sandbox.sid}>
                                            <span>{name}</span> ({intl.get("files.commited")})
                                        </div>
                                    );
                                } else if (sandbox?.ts > 0) {
                                    return (
                                        <div key={sandbox.sid}>
                                            <a
                                                className="ACLK"
                                                onClick={this.onSandboxClick.bind(
                                                    this,
                                                    props.master_token,
                                                    master,
                                                    sandbox,
                                                )}
                                            >
                                                {name}
                                            </a>{" "}
                                            (merge request)
                                        </div>
                                    );
                                } else if (sandbox?.db) {
                                    return (
                                        <div key={sandbox.sid}>
                                            <a
                                                className="ACLK"
                                                onClick={this.onSandboxClick.bind(
                                                    this,
                                                    props.master_token,
                                                    master,
                                                    sandbox,
                                                )}
                                            >
                                                {name}
                                            </a>{" "}
                                            (active)
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={sandbox.sid}>
                                            <a
                                                className="ACLK"
                                                onClick={this.onSandboxClick.bind(
                                                    this,
                                                    props.master_token,
                                                    master,
                                                    sandbox,
                                                )}
                                            >
                                                {name}
                                            </a>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    )
                ) : (
                    "..."
                )}
            </div>
        );
    }.bind(this);

    projectView = function (
        this: Files,
        props: {
            card: any;
        },
    ) {
        const cardStyle: React.CSSProperties = {
            //display: "flex",
            //flexDirection: "column",
            boxSizing: "border-box",
            border: "1px solid #EAEAEA",
            boxShadow: "3px 2px 3px #00000059",
            backgroundColor: "white",
            width: "calc(100% - 40px)",
            maxWidth: "calc(100% - 40px)",
            //minHeight: 200,
            padding: 10,
            marginLeft: 20,
            marginRight: 20,
            marginTop: 20,
            marginBottom: 20,
            overflow: "auto",
            overflowWrap: "normal",
            wordWrap: "normal",
        };
        const isEditing = this.props.worker.isLicEditing();
        const isWeeklyLookAhead = isEditing && props.card?.master_token;
        const titleWeeklyLookAhead = isEditing
            ? props.card?.master_token
                ? "Weekly Lookahead"
                : "No active Weekly Lookahead"
            : "Beta Version Only";
        return props.card >= 0 ? (
            <div style={cardStyle}>
                <Spinner />
            </div>
        ) : (
            <div style={cardStyle}>
                <div style={{ fontWeight: "bold" }}>{props.card.date}</div>
                <div style={labelStyle}>Name</div>
                <div style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                    {props?.card?.name || "-"}{" "}
                    <fluentui.DefaultButton text="Open" onClick={this.onClickName.bind(this, props, false)} />{" "}
                    <fluentui.DefaultButton
                        text="Weekly Lookahead"
                        onClick={this.onClickName.bind(this, props, true)}
                        disabled={!isWeeklyLookAhead}
                        title={titleWeeklyLookAhead}
                    />
                </div>
                {props.card?.master_token ? <this.sandboxList master_token={props.card.master_token} /> : null}
                <div style={labelStyle}>Change Log</div>
                <div style={{ overflow: "auto", whiteSpace: "pre" }}>{props?.card?.description || "-"}</div>
                <hr />
                <div
                    style={{
                        clear: "both",
                        display: "none",
                    }}
                >
                    <div style={{ float: "left", width: 150 }}>
                        <div style={labelStyle}>Start</div>
                        <div>{props?.card?.start || "-"}</div>
                    </div>
                    <div style={{ float: "left", width: 150 }}>
                        <div style={labelStyle}>End</div>
                        <div>{props?.card?.end || "-"}</div>
                    </div>
                </div>
                <div
                    style={{
                        clear: "both",
                        display: "none",
                    }}
                >
                    <div style={{ float: "left", width: 150 }}>
                        <div style={labelStyle}>Tasks</div>
                        <div>{props?.card?.tasks || "-"}</div>
                    </div>
                    <div style={{ float: "left", width: 150 }}>
                        <div style={labelStyle}>Milestones</div>
                        <div>{props?.card?.milestones || "-"}</div>
                    </div>
                    <div style={{ float: "left", width: 150 }}>
                        <div style={labelStyle}>Trades</div>
                        <div>{props?.card?.trades || "-"}</div>
                    </div>
                </div>
                <div
                    style={{
                        clear: "both",
                    }}
                >
                    <div style={labelStyle}>Original File</div>
                    <div>{props?.card?.filename || "-"}</div>
                </div>
                <div
                    style={{
                        clear: "both",
                    }}
                >
                    <div style={{ float: "left", minWidth: 150, marginRight: 10 }}>
                        <div style={labelStyle}>Uploaded by</div>
                        <div>
                            <Sub worker={this.props.worker} sub={props?.card?.uploadedBy} />
                        </div>
                    </div>
                    <div style={{ float: "left", minWidth: 150, marginRight: 10, display: "none" }}>
                        <div style={labelStyle}>Approved by</div>
                        <div>{props?.card?.approvedBy || "-"}</div>
                    </div>
                    <div style={{ float: "left", minWidth: 150, marginRight: 10, display: "none" }}>
                        <div style={labelStyle}>Plan update confirmed by</div>
                        <div>{(props?.card?.confirmedBy || []).join(", ")}</div>
                    </div>
                </div>
            </div>
        );
    }.bind(this);

    mainView = function (this: Files, props: any) {
        return (
            <div
                style={{
                    width: "100%",
                    backgroundColor: "#F2F2F2",
                    height: "100%",
                    overflow: "auto",
                }}
            >
                <div
                    style={{
                        position: "sticky",
                        top: 0,
                        marginLeft: 2,
                        boxSizing: "border-box",
                        paddingLeft: 10,
                        fontSize: 20,
                        minHeight: 72,
                        lineHeight: "72px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        boxShadow: "0px 3px 6px #00000029",
                        width: "calc(100% - 2px)",
                        backgroundColor: "white",
                        overflowWrap: "normal",
                    }}
                >
                    <span
                        style={{
                            textTransform: "uppercase",
                            float: "left",
                        }}
                    >
                        {null !== this.state.selected_project
                            ? this.state.model[this.state.selected_project].name
                            : null}
                    </span>
                    <span
                        style={{
                            position: "absolute",
                            right: 0,
                        }}
                    >
                        <div
                            style={{
                                display: "inline-block",
                                lineHeight: "inherit",
                                height: 72,
                                marginRight: 10,
                                overflow: "hidden",
                                visibility: null === this.state.selected_project ? "hidden" : undefined,
                            }}
                        >
                            <fluentui.DefaultButton
                                text="Upload New Version"
                                disabled={null === this.state.selected_project}
                                onClick={this.showUploadPopup}
                            />{" "}
                            <fluentui.DefaultButton
                                text="Share Project..."
                                disabled={null === this.state.selected_project}
                                onClick={this.showSharePopup}
                            />
                        </div>
                    </span>
                </div>
                {null !== this.state.selected_project ? (
                    (this.state.model[this.state.selected_project]?.history || []).map((X_card, _i_card, a_card) => {
                        const i_card = a_card.length - 1 - _i_card;
                        const card = a_card[i_card]; // reverse order
                        return <this.projectView key={i_card} card={card} />;
                    })
                ) : (
                    <div
                        style={{
                            boxSizing: "border-box",
                            width: "100%",
                            height: "calc(100% - 72px)",
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        no project selected
                    </div>
                )}
            </div>
        );
    }.bind(this);

    private toggleLegacy = function (this: Files) {
        this.props.worker.dispatchMessage(["framework", "toggle", "legacy"]);
    }.bind(this);

    render(this: Files) {
        return (
            <div className="FE">
                <FrameworkHeader
                    key={5}
                    activeView="folder"
                    filesOverlay={this.props.filesOverlay}
                    worker={this.props.worker}
                    right={[
                        <FrameworkRightHelpAlertContract key="frahc" {...this.props} synced={null} selected="folder" />,
                    ]}
                    metaData={this.state.meta}
                />
                <div style={{ backgroundColor: "#09090B" }}>
                    <div
                        className="relative mx-20 flex h-screen items-center justify-center"
                        style={{ width: "calc(100% - 420px)", left: "420px", gap: "30px" }}
                    >
                        <div className="flex flex-col gap-4" style={{ minWidth: "350px", objectFit: "contain" }}>
                            <p
                                style={{
                                    fontSize: "22px",
                                    fontWeight: 300,
                                    lineHeight: "25.69px",
                                    textAlign: "left",
                                    color: "#FFFFFF",
                                }}
                            >
                                {this.props.worker?.userMeta?.firstName}{" "}
                                <span
                                    style={{
                                        fontSize: "22px",
                                        fontWeight: 600,
                                        lineHeight: "25.69px",
                                        textAlign: "left",
                                        color: "#FFFFFF",
                                    }}
                                >
                                    {this.props.worker?.userMeta?.lastName}
                                </span>
                            </p>
                            <p
                                style={{
                                    fontSize: "45px",
                                    fontWeight: 600,
                                    lineHeight: "52.55px",
                                    textAlign: "left",
                                    color: "#FFFFFF",
                                }}
                            >
                                {intl.get("welcomeScreen.title")}
                            </p>
                            <NewProjectButton {...this.props} />
                        </div>
                        <div>
                            <video src={video} autoPlay muted style={{ height: "374px" }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function NewProjectButton(props: FilesProps) {
    const LCMD = useLCMD();

    const onUploadDone = useCallback(
        (details) => {
            LCMD.showDialog("dialog.project.upload", null);
            props.worker.dispatchMessage(["framework", "reload"]);

            details &&
                details.project_token &&
                getLog(details.project_token, details.pid, 0, (error, log) => {
                    const session: WorkerSession = {
                        pid: details.pid,
                        pid_ts: 0,
                        project_token: details.project_token,
                        resource: log.file,
                        master_token: undefined,
                        sandbox: Date.now() as any,
                    };
                    openProject(LCMD.worker, session, false);
                });
        },
        [LCMD],
    );
    return (
        <Button
            style={{ backgroundColor: "#60D4C5", color: "black", width: "170px" }}
            data-userpilot-id="filesOverlay-addProject"
            onClick={() => {
                WebAppTelemetryFactory.trackEvent('new-project-green-button-clicked')
                LCMD.showDialog("dialog.project.upload", {
                    view: "details",
                    onDone: onUploadDone,
                    auth_token: LCMD.worker.auth?.auth_token || null,
                    auth_warmup: LCMD.worker.warmup_result?.req ? (LCMD.worker.warmup_result as { req: string }) : null,
                });
            }}
        >
            {intl.get("legacyProjectDialog.details.title")}
        </Button>
    );
}
