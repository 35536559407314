import React, { FC, useState, useEffect, useMemo, useCallback } from "react";
import { MainWorkerPipe } from "@/legacy/MainWorkerPipe";
import { IFacepilePersona } from "@fluentui/react";
import { CanvasRTSession, CanvasRTSessionState } from "@/model/DataModel";
import { assert, intl, DigitalPlanningBoardViewKey } from "lcmd2framework";
import { UserSettingButton } from "@/legacy/GlobalHelperFluentUI";
import { SubCache } from "@/legacy/SubCache";
import { Button } from "@/components/ui/button";
import { CircleHelp, LucideProps } from "lucide-react";
import { SyncedSpinnerButton } from "@/components/common/SyncedSpinnerButton/SyncedSpinnerButton";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { LCMDTooltip } from "@/components/common/Tooltip/LCMDTooltip";

const FrameworkFacepile: FC<{ worker: MainWorkerPipe; sessions: CanvasRTSession[] }> = ({ worker, sessions }) => {
    const [personas, setPersonas] = useState<IFacepilePersona[]>([]);

    useEffect(() => {
        const ctx = {
            subs: sessions.map((session) => session.sub),
        };

        SubCache.getPersonas(worker?.auth?.auth_token, ctx.subs, (personas) => {
            if (ctx.subs) {
                setPersonas(
                    personas.map((persona, i_persona) => {
                        const session = sessions[i_persona];
                        assert(session?.sub === persona?.data.sub);
                        return { ...persona, data: { ...persona.data, session: session } };
                    }),
                );
            }
        });

        return () => {
            ctx.subs = null;
        };
    }, [worker, setPersonas, sessions]);

    const activePersonas = useMemo(
        () => personas.filter((persona) => CanvasRTSessionState.ACTIVE == persona.data.session.state),
        [personas],
    );

    return worker.config?.CollaborationFacepile ? (
        <worker.config.CollaborationFacepile personas={personas} activePersonas={activePersonas} />
    ) : null;
};

function FrameworkRightHelpAlertContract({
    worker,
    synced,
    sessions,
    selected,
}: {
    worker: MainWorkerPipe;
    synced: boolean | null;
    sessions?: CanvasRTSession[];
    selected: DigitalPlanningBoardViewKey;
}) {
    const handleHelpButtonClick = () => {
        window.open(intl.get("links.helpURL"), "_blank");
    };

    const handleSync = useCallback(() => {
        if (selected === "dashboard") {
            fetch(
                `https://lcmd-rest-api.azurewebsites.net/api/dashboards/${worker.nav?.session?.pid}/refresh`,
                {
                    headers: {
                        Authorization: `Bearer ${worker.auth.auth_token}`,
                    },
                    method: "POST",
                },
            );
            return;
        }

        worker.forceSync(selected);
    }, [worker, selected]);

    return (
        <>
            {sessions ? <FrameworkFacepile worker={worker} sessions={sessions} /> : null}
            <LCMDTooltip text={intl.get("CommonUse.Help")}>
            <IconButton
                className="mx-0.5 h-8 w-[36px] self-center rounded-md p-2 hover:bg-accent/20 hover:text-white"
                icon={CircleHelp}
                onClick={handleHelpButtonClick}
                iconProps={{size:20}}
            />
            </LCMDTooltip>
            {synced === null ? <></> : <SyncedSpinnerButton name="Submit" isLoading={!!synced} onClick={handleSync} />}
            <UserSettingButton key="contact" worker={worker} />
        </>
    );
}

export { FrameworkRightHelpAlertContract };
