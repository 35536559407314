import { create } from "zustand";
import {
    ViewTypeValueI,
    ViewTypes,
    ContentTypes,
    ContentTypeValueI,
    ViewNamesType,
    viewNames,
    UserSessionPosition,
} from "@/components/view/constants/index";
import { persist } from "zustand/middleware";

type UserJourneyState = {
    userView: ViewTypeValueI;
    areaAttachments: boolean;
    showNonWorkingDays: boolean;
    showTaktZones: boolean;
    showProjectWeeks: boolean;
    splitView: boolean;
    contentType: ContentTypeValueI;
    scaleSettings: keyof typeof viewNames;
    position: UserSessionPosition | null;
    filter: any;
    showStatusBar: boolean;
    showDependencies: boolean;
};

type UserJourneyActions = {
    actions: {
        setUserView: (view: ViewTypeValueI) => void;
        setAreaAttachments: (areaAttachments: boolean) => void;
        setShowNonWorkingDays: (showNonWorkingDays: boolean) => void;
        setShowTaktZones: (showTaktZones: boolean) => void;
        setShowProjectWeeks: (showProjectWeeks: boolean) => void;
        setSplitView: (splitView: boolean) => void;
        setContentType: (contentType: ContentTypeValueI) => void;
        setScaleSettings: (scaleSettings: number) => void;
        setPosition: (position: UserSessionPosition) => void;
        setFilter: (filter: any) => void;
        setShowStatusBar: (showStatusBar: boolean) => void;
        setShowDependencies: (showDependencies: boolean) => void;
        reset: () => void;
    };
};

type UserJourneyStore = UserJourneyState & UserJourneyActions;

const initalState: UserJourneyState = {
    userView: ViewTypes.TRADE,
    areaAttachments: true,
    showNonWorkingDays: false,
    showTaktZones: false,
    showProjectWeeks: false,
    splitView: false,
    contentType: ContentTypes.MILESTONES,
    scaleSettings: 1,
    position: null,
    filter: null,
    showStatusBar: false,
    showDependencies: false,
};

// @important: DANGER! When calling reset the initial state will be persisted as well!
export const useUserJourneyStore = create<UserJourneyStore>()(
    persist(
        (set) => ({
            ...initalState,
            actions: {
                setUserView: (userView: ViewTypeValueI) => set({ userView }),
                setAreaAttachments: (areaAttachments: boolean) => set({ areaAttachments }),
                setShowNonWorkingDays: (showNonWorkingDays: boolean) => set({ showNonWorkingDays }),
                setShowTaktZones: (showTaktZones: boolean) => set({ showTaktZones }),
                setShowProjectWeeks: (showProjectWeeks: boolean) => set({ showProjectWeeks }),
                setSplitView: (splitView: boolean) => set({ splitView }),
                setContentType: (contentType: ContentTypeValueI) => set({ contentType }),
                setScaleSettings: (scaleSettings: ViewNamesType) => set({ scaleSettings }),
                setPosition: (position: UserSessionPosition) => set({ position }),
                setFilter: (filter: any) => set({ filter }),
                setShowStatusBar: (showStatusBar: boolean) => set({ showStatusBar }),
                setShowDependencies: (showDependencies: boolean) => set({ showDependencies }),
                reset: () => set({ ...initalState }),
            },
        }),
        {
            name: "UserJourneyStore",
            partialize: ({ actions, ...rest }: any) => rest,
        },
    ),
);

function useUserView() {
    return useUserJourneyStore((state) => state.userView);
}

function useSetUserView() {
    return useUserJourneyStore((state) => state.actions.setUserView);
}
function useAreaAttachments() {
    return useUserJourneyStore((state) => state.areaAttachments);
}
function useSetAreaAttachments() {
    return useUserJourneyStore((state) => state.actions.setAreaAttachments);
}

function useShowNonWorkingDays() {
    return useUserJourneyStore((state) => state.showNonWorkingDays);
}
function useSetShowNonWorkingDays() {
    return useUserJourneyStore((state) => state.actions.setShowNonWorkingDays);
}

function useShowTaktZones() {
    return useUserJourneyStore((state) => state.showTaktZones);
}
function useSetShowTaktZones() {
    return useUserJourneyStore((state) => state.actions.setShowTaktZones);
}

function useShowProjectWeeks() {
    return useUserJourneyStore((state) => state.showProjectWeeks);
}
function useSetShowProjectWeeks() {
    return useUserJourneyStore((state) => state.actions.setShowProjectWeeks);
}
function useSplitView() {
    return useUserJourneyStore((state) => state.splitView);
}
function useSetSplitView() {
    return useUserJourneyStore((state) => state.actions.setSplitView);
}

function useContentType() {
    return useUserJourneyStore((state) => state.contentType);
}
function useSetContentType() {
    return useUserJourneyStore((state) => state.actions.setContentType);
}

function useScaleSettings() {
    return useUserJourneyStore((state) => state.scaleSettings);
}

function useSetScaleSettings() {
    return useUserJourneyStore((state) => state.actions.setScaleSettings);
}

function useSetShowStatusBar() {
    return useUserJourneyStore((state) => state.actions.setShowStatusBar);
}

function useShowStatusBar() {
    return useUserJourneyStore((state) => state.showStatusBar);
}

async function renameUserJourneyStore(userId: string, sessionId: string) {
    //rename the userjourney store name in local storage so that it is different for each user and project
    const PERSISTENCE_KEY = `UserJourneyStore-${userId}-${sessionId}`;

    useUserJourneyStore?.persist.setOptions({
        name: PERSISTENCE_KEY,
    });
    if (!window.localStorage.getItem(PERSISTENCE_KEY)) {
        useUserJourneyStore.getState().actions.reset();
    } else {
        // rehydrate the store
        await useUserJourneyStore?.persist.rehydrate();
    }
}

export {
    useUserView,
    useSetUserView,
    useAreaAttachments,
    useSetAreaAttachments,
    useShowNonWorkingDays,
    useSetShowNonWorkingDays,
    useShowTaktZones,
    useSetShowTaktZones,
    useShowProjectWeeks,
    useSetShowProjectWeeks,
    useSplitView,
    useSetSplitView,
    useContentType,
    useSetContentType,
    useScaleSettings,
    useSetScaleSettings,
    useSetShowStatusBar,
    renameUserJourneyStore,
    useShowStatusBar,
};
