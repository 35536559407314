import {
    Facepile,
    getPersonaInitialsColor,
    IFacepilePersona,
    mergeStyleSets,
    OverflowButtonType,
    Stack,
    StackItem,
    TooltipHost,
} from "@fluentui/react";
import { createUserName, getHashCode } from "../../Utils";
import { User } from "../../hooks/UserMapTypes";

export function LcmFacepile({
    persona,
    showTooltip = true,
    tooltipQuestionMark,
    onClickQuestionMark: handleQuestionMarkClick,
    onClickPersona: handlePersonaClick,
}: {
    persona: User[];
    showTooltip?: boolean;
    tooltipQuestionMark?: string;
    onClickQuestionMark?: () => void;
    onClickPersona?: () => void;
}) {
    const onClickQuestionMarkExists = handleQuestionMarkClick !== undefined;
    const onClickPersonaExists = handlePersonaClick !== undefined;

    const styles = mergeStyleSets({
        questionMark: {
            width: 24,
            height: 24,
            backgroundColor: "#F1F3F3",
            borderRadius: "50%",
            border: "1px dashed #82878A",
            color: "#82878A",
            "&:hover": {
                cursor: onClickQuestionMarkExists ? "pointer" : "default",
            },
        },
    });

    const questionMarkHTML = (
        <Stack
            className={styles.questionMark}
            horizontalAlign={"center"}
            verticalAlign={"center"}
            onClick={handlePersonaClick}
        >
            ?
        </Stack>
    );

    function tooltipForQuestionMark() {
        if (tooltipQuestionMark === undefined) {
            return questionMarkHTML;
        }
        const tooltip = <TooltipHost content={tooltipQuestionMark.toString()}>{questionMarkHTML}</TooltipHost>;
        return tooltip;
    }

    const facepilePersonas: IFacepilePersona[] = persona.map((person) => {
        let name, imageInitials;
        name = createUserName(person);

        if (
            person.meta &&
            person.meta.firstName &&
            person.meta.firstName.length > 0 &&
            person.meta.lastName &&
            person.meta.lastName.length > 0
        ) {
            imageInitials = person.meta.firstName.charAt(0) + person.meta.lastName.charAt(0);
        } else {
            imageInitials = person.email.charAt(0);
        }

        return {
            personaName: name,
            imageInitials: imageInitials.toUpperCase(),
            title: name,
            initialsColor: Math.abs(getHashCode(name)) % 25,
        };
    });

    const overFlowUsers = facepilePersonas.slice(5).map((user) => user.personaName);
    function getPersonaWrapper(person) {
        return (
            <Stack
                style={{
                    width: "22px",
                    height: "22px",
                    borderRadius: "50%",
                    color: "white",
                    fontSize: 10,
                    backgroundColor: getPersonaInitialsColor(person),
                }}
                horizontalAlign={"center"}
                verticalAlign={"center"}
            >
                <StackItem
                    style={{
                        fontFamily: "Inter",
                        fontSize: "10px",
                        fontWeight: "500",
                        lineHeight: "20px",
                    }}
                >
                    {person.imageInitials}
                </StackItem>
            </Stack>
        );
    }

    function getPersonaWrapperWithTooltip(person) {
        return (
            <TooltipHost content={person.personaName}>
                {getPersonaWrapper(person)}
            </TooltipHost>
        );
    }

    function getOverflow() {
        return (
            <TooltipHost content={overFlowUsers.join(", ")}>
                <Stack
                    style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        color: "#1B2126",
                        fontSize: 10,
                        backgroundColor: "rgb(243, 242, 241)",
                    }}
                    horizontalAlign={"center"}
                    verticalAlign={"center"}
                >
                    <StackItem>+{overFlowUsers.length}</StackItem>
                </Stack>
            </TooltipHost>
        );
    }

    return (
        <div>
            {persona.length > 0 ? (
                <Stack
                    styles={{ root: { "&:hover": { cursor: onClickPersonaExists ? "pointer" : "default" } } }}
                    onClick={handlePersonaClick}
                >
                    <Facepile
                        personas={facepilePersonas}
                        onRenderPersonaWrapper={showTooltip ? getPersonaWrapperWithTooltip : getPersonaWrapper}
                        maxDisplayablePersonas={5}
                        overflowButtonType={OverflowButtonType.descriptive}
                        overflowButtonProps={{
                            title: undefined,
                            styles: { root: { cursor: "default" } },
                            onRenderChildren: getOverflow,
                        }}
                    />
                </Stack>
            ) : (
                tooltipForQuestionMark()
            )}
        </div>
    );
}
